import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@core/service/login.service';
import { ModalService } from '@app/shared/services/modal.service';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StoreService } from '@core/service/store.service';
import { CookieService } from 'ngx-cookie-service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '@env/environment';
import { element } from '@app/core/models/user-login.model';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { FlowService } from '@core/service/flow.service';
import { ArrayDefinitionService } from '@core/service/array.definition.service';

@Component({
  selector: 'paef-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public device: any;
  public isSafari: any;
  public loginForm: FormGroup;
  public focusDocNumber = false;
  public focusDigit = false;
  public focusRadicado = false;
  public checkRadio = new Array();
  public checkMes = new Array();
  public selectedOption = 0;
  public errorMessage: string;
  public urlData = "";
  public urlDataPopupWindows = environment.endpointManager + 'documents/s3?key=public/GuiaparaHabilitarPantallasEmergenteenGoogleChrome.pdf';
  public urldDataRestitucion = environment.endpointUrlRestitucion;
  public flow : any = {};
  public meses = [];
  public flujo = 0;
  public deviceInfo = null;

  //deprecated
  /*
  public docTypes: { name: string, id: string }[] = [
    { name: 'NIT Persona Jurídica', id: 'N' },    //OK
    { name: 'Cédula de ciudadanía', id: 'C' },    //OK
    { name: 'Cédula de extranjería', id: 'E' },   //OK
    { name: 'Pasaporte', id: 'P' },               //OK
    { name: 'NIT Persona Natural', id: 'L' },     //OK
    { name: 'NIT de Extranjería', id: 'I' }       //OK
  ];

//login
public tipoEmpresaArray = [
	{code: -1, id: -1, name: "-"},
	{code: 0,  id: 1,  name: "Persona Jurídica"},
	{code: 1,  id: 4,  name: "Persona Natural"},
	{code: 2,  id: 2,  name: "Entidad sin ánimo de lucro"},
	{code: 3,  id: 3,  name: "Consorcio o unión temporal"},
	{code: 4,  id: 4,  name: "Patrimonio Autónomo"},
	{code: 5,  id: 5,  name: "Cooperativas"}
];

//login
public stepFlujoArray = [
    {id: 1, code: 0},   //"PAEF";
    {id: 2, code: 1},   //"PAP";
    {id: 3, code: 2},   //"PAP + PAEF"";
    {id: 4, code: 3},   //??
    {id: 5, code: 4},   //"SACUDETE";
    {id: 6, code: 5},   //PAEF PARO
    {id: 7, code: 6},   //EMPLEADOS
];
*/

public docTypes = [];
public tipoEmpresaArray = [];
public stepFlujoArray = [];
  
  constructor(
    private route: Router,
    private flowService: FlowService,
    private arrays: ArrayDefinitionService,
    private authServerProviderService: AuthServerProviderService,
    private loginService: LoginService,
    private modalService: ModalService,
    private storeService: StoreService,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    console.log("constructor");
    this.flow = this.storeService.get("flow");
    this.meses = this.flow.months;
    this.urlData = environment.endpointManager + this.flow.guiaRapida;
    this.urldDataRestitucion = environment.endpointManager + this.flow.guiaRestitucion;

    this.docTypes = this.arrays.getDocTypes();
    this.tipoEmpresaArray = this.arrays.getTipoEmpresaArray();
    this.stepFlujoArray = this.arrays.getStepFlujoArray();

    console.log("docTypes: ", this.docTypes);
    console.log("tipoEmpresaArray: ", this.tipoEmpresaArray);
    console.log("stepFlujoArray: ", this.stepFlujoArray);
  }

  ngOnInit() {
    console.log("ngOnInit");
    this.loginForm = this.fb.group({
      type: new FormControl('', Validators.required),
      number: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9ñÑ]*$/), Validators.maxLength(30)]),
      digit: new FormControl({value: '', disabled: true}),
      radicado: new FormControl(''),
      date: new FormControl('')
    });

    if (this.storeService.get("flow") == false) {
      this.storeService.set('flow', false);
      this.route.navigate(['login']);
    } else{
      this.flowService.getFlow(this.flow.flujo)
      .then(
        (response: any) => {
          this.flow = response;
          this.storeService.set('flow', this.flow);
          this.meses = this.flow.months;
          this.urlData = environment.endpointManager + this.flow.guiaRapida;
          this.urldDataRestitucion = environment.endpointManager + this.flow.guiaRestitucion;
        }
      ).catch((error)=>{
        console.log("error getting flow");
      });
  
      //set retakeStep in false, default => no precarga 
      this.storeService.set("retakeStep", false);
      this.flujo = this.flow.flujo
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.cookieService.deleteAll('/');

      setTimeout(() => {
        console.log("setTimeout");
        document.body.style.backgroundColor = '#ffffff'
        window.scrollBy(0, -document.body.scrollHeight * 5);
        var elems = document.getElementsByTagName('html');
        for (var i = 0; i < elems.length; i++) {
          elems[i].style.backgroundColor = '#ffffff';
        }
      }, 800);
  
  
      //Active validator for digit when type is NIT
      this.loginForm.controls.type.valueChanges.subscribe(id => {
        if (id == "N" || id == "I" || id == "L") {
          this.loginForm.controls.digit.setValidators([Validators.required, Validators.maxLength(1)])
          this.loginForm.controls.digit.enable();
        } else {
          this.loginForm.controls.digit.setValidators(null);
          this.loginForm.controls.digit.setValue('');
          this.loginForm.controls.digit.disable();
        }
        this.loginForm.controls.digit.updateValueAndValidity();
      })
  
      this.device = this.deviceService.getDeviceInfo()['browser'].toLowerCase();
      if (this.device == 'safari') {
        this.isSafari = true;
      } else {
        this.isSafari = false;
      }
      //this.epicFunction()
      this.checkRadio.push(true);
      this.checkRadio.push(false);
      this.checkRadio.push(false);
      this.changeRadioButton(2);
      this.storeService.set("estado", "login");
  
      if([0, 5, 4, 6].indexOf(this.flow.flujo) && this.storeService.get('flow') != false){
        this.modalService.firstModal((resp) => {});
      }
      
      if (this.deviceInfo["browser"].toLowerCase() != "chrome") {
        this.infoOpenModal();
      } else {
        if (this.flow.flujo == 2) {
          let newdocTypes = [
            { name: 'Cédula de ciudadanía', id: 'C' },    //OK
            { name: 'Cédula de extranjería', id: 'E' },   //OK
            { name: 'Pasaporte', id: 'P' },               //OK
            { name: 'NIT Persona Natural', id: 'L' }      //OK
          ];
          this.docTypes = newdocTypes;
        }
      }
    }
  }

  //show modal functions
  infoOpenModal() {
    this.modalService.noValidateBrowser((result) => {})
  }

  showModal() {
    let modalInputData: Array<element> = [
      { k: "Tipo ID", v: "N" },
      { k: "Nº ID", v: "45785412" },
      { k: "Nº radicado", v: "01_1234567890_12345" },
      { k: "Correo electrónico", v: "empresasubsidio@empresasubsidio.com.co" },
      { k: "Celular", v: "Celular" }
    ];
    let state = this.transformState(4);
    let texto = 'Motivo(s) de rechazo:';
    let causales = 'Cámara y comercio fuera de vigencia solicitada, Razón 2, Razón 3';
    let btnArray = [{ k: "preguntas", v: "Modificar información" }, { k: "documentos", v: "Cargar documentos" }];
    this.modalService.applicationStateModal(modalInputData, state, btnArray, texto, causales, (result) => {
    })
  }

  confirmActionModal(inputData, state: string, text: string, reasons: string) {
    return new Promise((resolve, reject) => {
      let causales = reasons;
      let btnArray = [{ k: "preguntas", v: "Modificar información" }, { k: "documentos", v: "Cargar documentos" }];
      this.modalService.applicationStateModal(inputData, state, btnArray, text, causales, (result) => {
        resolve(result);
      });
    });
  }

  //controls functions
  activeFocusDocNumber() {
    this.focusDocNumber = true;
  }

  inactiveFocusDocNumber() {
    this.focusDocNumber = false;
    let documentNumber = this.loginForm.value.number;
    if (documentNumber.length < 1) {
    }
  }

  activeFocusDigit() {
    this.focusDigit = true;
  }

  inactiveFocusDigit() {
    this.focusDigit = false;
  }

  activeFocusRadicado() {
    this.focusRadicado = true;
  }

  inactiveFocusRadicado() {
    this.focusRadicado = false;
  }

  formatNumberAndChar(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9a-zA-ZñÑ]/g, '');
    this.loginForm.controls[field].setValue(newValue);
  }

  formatNumber(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9]/g, '');
    this.loginForm.controls[field].setValue(newValue);
  }

  formatRadicado(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9_]/g, '');
    this.loginForm.controls[field].setValue(newValue);
  }

  changeRadioButton(position) {
    for (let index = 0; index < this.checkRadio.length; index++) {
      this.checkRadio[index] = false;
    }
    this.checkRadio[position] = true;
    this.selectedOption = position;
    (this.selectedOption == 1 || this.selectedOption == 2) ? this.loginForm.controls.radicado.setValidators([Validators.required, Validators.maxLength(30)]) : this.loginForm.controls.radicado.setValidators(null);
    this.loginForm.controls.radicado.updateValueAndValidity();
    console.log(this.selectedOption)
  }

  changeMes() {
    if (this.loginForm.controls.date.value == '9,2020' || this.loginForm.controls.date.value == '10,2020' || this.loginForm.controls.date.value == '11,2020') {
      return true
    } else {
      return false
    }
  }

  validForm() {
    if (this.loginForm.valid) {
      if (this.selectedOption == 0) {
        if (this.loginForm.controls.date.value != '' && this.loginForm.controls.date.value != undefined && this.loginForm.controls.date.value != false) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  //transform functions
  transformState(id: number) {
    switch (true) {
      case id === 1:
        return 'INICIADO'
      case id === 2 || id == 3 || id === 6:
        return 'EN REVISIÓN'
      case id === 4:
        return 'DEVUELTO'
      case id === 5 || id === 7:
        return 'EN REVISIÓN UGPP'
      case id === 8:
        return 'ELIMINADA POR DUPLICIDAD';
      case id === 9 || id === 13:
        return 'APROBADO UGPP';
      case id === 10:
        return 'RECHAZADO UGPP';
      case id === 11 || id === 14:
        return 'ABONO REALIZADO'
      case id === 12:
        return 'ABONO NO REALIZADO'
      default:
        break;
    }
  }

  transformTextState(id: number) {
    switch (true) {
      case id === 1:
        return 'Su solicitud NO irá a revisión si no completa la carga de documentos.'
      case id === 2 || id === 3 || id === 6:
        return 'Su solicitud está siendo revisada por el Banco. '
      case id == 4:
        return 'Motivo(s) de rechazo:'
      case id === 5 || id === 7:
        return 'Su solicitud ha sido enviada a la UGPP para estudio.'
      case id === 8:
        return 'Este caso ha sido eliminado porque usted ya tiene una solicitud en curso.'
      case id === 9 || id === 13:
        return 'La UGPP ha aprobado su solicitud, por favor verifique el detalle en el correo enviado.';
      case id === 10:
        return 'La UGPP ha rechazado su solicitud, por favor verifique el detalle en el correo enviado.';
      case id === 11 || id === 14:
        return 'Se ha abonado el subsidio a su cuenta.'
      case id === 12:
        return 'Hay una inconsistencia con su cuenta de abono por favor comuníquese con su oficina o comercial.'
      default:
        break;
    }
  }

  setPreguntas(preguntas: any) {
    let emptiedArray = [];
    for (let index = 0; index < preguntas.length; index++) {
      const element = preguntas[index];
      element.v = '';
      emptiedArray.push(element);
    }
    return emptiedArray;
  }

  //get functions
  getRazonesSociales(docTypeString, docNumberString, digit, token, date) {
    return new Promise((resolve, reject) => {
      this.loginService.getRazonSocial(docTypeString, docNumberString, digit, token, this.arrays.transformReverseFlujo(this.flow.flujo), date).subscribe((response: any) => {
        console.log("resp: ", response);
        let result =  (response.body);
        console.log("result['body']: ", result);

        if (result['statusCode'] == 200) {
          let body = result['body'];
          console.log("va body");

          if (body !== undefined && body != null && body['tieneCasos']) {
            if (body['tieneCasos'].toLowerCase() == 'si') {

              this.modalService.closeModalDefinitive();
              this.modalService.closeModal('login');
              console.log("tiene casos");

              let razonSocialList = body['cases'];
              let razonString = "<p>";
              for (let index = 0; index < razonSocialList.length; index++) {
                const razon = razonSocialList[index];
                razonString = razonString + razon.razonSocial;
                if (index < razonSocialList.length - 1)
                  razonString = razonString + ", ";
              }
              razonString = razonString + "</p>"
              this.modalService.solicitudEnProgreso(razonString, (resp) => {
                console.log(resp);
                resolve(resp);
              });
            } else {
              resolve("");
            }
          }
        }
      }, (err) => {
        console.log(err);
        this.modalService.closeModal('login');
        this.errorMessage = 'Estamos experimentando fallas técnicas, intente de nuevo más tarde.';
        reject("Error");
      });
    });
  }

  //deprecated
  /*
  getTipoEmpresa(id) {
    return this.tipoEmpresaArray.find(data => data.id = id).code;
    switch (id) {
      case -1:
        return "-";
      case 1:
        return 0;//"Persona jurídica";
      case 4:
        return 1;//"Persona Natural";
      case 2:
        return 2;//"Entidad sin ánimo de lucro";
      case 3:
        return 3;//"Consorcio o unión temporal";
      case 5:
        return 4;//"Patrimonio autónomo";
      case 6:
        return 5;//"Cooperativa";
      default:
        break;
    }
  }
  */
  
  //deprecated
  /*
  transformStepFlujo(key) {
    return this.stepFlujoArray.find(data => data.id == key).code
    switch (key) {
      case 1:
        return 0;//"PAEF";
      case 2:
        return 1;//"PAP";
      case 3:
        return 2;//"PAP + PAEF"";
      case 5:
        return 4;//"SACUDETE";
      case 6:
        return 5;//PAEF PARO
      case 7:
        return 6;//EMPLEADOS
      default:
        break;
    }
  }
  */

  //deprecated
  /*
  transformReverseFlujo(key) {
    return this.stepFlujoArray.find(data => data.code == key).id
    switch (key) {
      case 0:
        return 1;//"PAEF";
      case 1:
        return 2;//"PAP";
      case 2:
        return 3;//"PAP + PAEF"";
      case 4:
        return 5;//"SACUDETE"";
      case 5:
        return 6;//"PAEF PARO";
      case 6:
        return 7;//"EMPLEADOS";
      default:
        break;
    }
  }
  */

  goBack(){
    this.authServerProviderService.logoutBasic()
  }

  loginUser() {
    this.errorMessage = '';

    console.log('loginUser ', this.loginForm.valid,
      this.loginForm.controls.type.value,
      this.loginForm.controls.number.value,
      this.loginForm.controls.digit.value,
      this.loginForm.controls.radicado.value
    );

    let isValid: boolean;
    console.log('FIRST ', this.loginForm.controls.type.value, this.loginForm.controls.digit.value === 'N');
    console.log('SECOND  ', this.loginForm.controls.number.value.length, this.loginForm.controls.number.value.length != 9);
    if (this.loginForm.controls.type.value === 'N' && this.loginForm.controls.number.value.length != 9) {
      this.errorMessage = 'El Nit persona jurídica debe ser de 9 dígitos';
      isValid = false;
    } else {
      isValid = true;
    }
    console.log('isvalid', isValid);
    if (this.validForm() && isValid) {
      this.modalService.loadingModal('login', resp => {
      });
      let docTypeString = this.loginForm.controls.type.value;
      let docNumberString = this.loginForm.controls.number.value;
      let digit: number = this.loginForm.controls.digit.value;
      let radicado: string = this.loginForm.controls.radicado.value;
      let date: string = this.loginForm.controls.date.value;

      this.recaptchaV3Service.execute('authentication')
        .subscribe(async (token) => {
          console.log("tokenCaptcha: " + token)
          if (this.flow.flujo == 2) {
            date = '11,2020'
          }
          if (this.selectedOption == 0) {
            let casoNuevo = true;
            await this.getRazonesSociales(docTypeString, docNumberString, digit, token, date).then((result: string) => {
              if (result == 'no') {
                casoNuevo = false;
                setTimeout(() => {
                  this.modalService.closeModalDefinitive();
                  this.modalService.closeModal('login');
                  this.changeRadioButton(1);
                  this.loginForm.patchValue([{ type: this.loginForm.controls.type.value, number: this.loginForm.controls.number.value, digit: this.loginForm.controls.digit.value }])
                }, 0);
              } else if (result == "continue") {
                this.modalService.loadingModal('login', resp => {
                });
              }
            }).catch((err) => {
              console.log(err);
              return;
            });

            if (casoNuevo) {
              this.storeService.set("phase_month", this.loginForm.controls.date.value)
              console.log("----------MON------------", this.storeService.get("phase_month"));
              this.loginService.getLoginDiligenciar(docTypeString, docNumberString, digit, token, this.storeService.comberStep(this.flow.flujo)).subscribe((res) => {
                console.log(res);
                this.modalService.closeModalDefinitive();
                this.modalService.closeModal('login');
                let respuesta = (res.body)
                console.log(respuesta)
                if (respuesta.statusCode == 404) {
                  console.log('BODY', respuesta.body, 'showErrorMessage', respuesta.body.showErrorMessage)
                  if (respuesta.body && respuesta.body.showErrorMessage) {
                    this.errorMessage = respuesta.body.showErrorMessage;
                  } else {
                    this.errorMessage = "Cliente no encontrado. Por favor verifique la información ingresada.";
                  }
                }
                if (respuesta.statusCode == 200) {
                  let isLoggedIn = respuesta.body.token;
                  let docTypeSS = docTypeString;
                  let docNumberSS = docNumberString;
                  let radicado = {
                    typedoc: docTypeSS,
                    doc: docNumberSS,
                    token: isLoggedIn,
                    numradicado: this.loginService.radicado
                  }
                  console.log("DATA CORRECT", radicado);
                  this.loginService.dataRadicado = radicado
                  if (this.flow.flujo == 2 || this.flow.flujo == 0 || this.flow.flujo == 4 || this.flow.flujo == 5 || this.flow.flujo == 6) {
                    let isLoggedIn = respuesta.body.token;
                    let docTypeSS = docTypeString;
                    let docNumberSS = docNumberString;
                    let urlFiles = ''
                    if (respuesta.body.downfiles) {
                      urlFiles = respuesta.body.downfiles
                    }
                    this.storeService.set("request_type_new", this.arrays.transformReverseFlujo(this.flow.flujo))
                    console.log(this.storeService.get("request_type_new"));
                    this.storeService.set("urlFiles", urlFiles)
                    this.storeService.set("isLoggedIn", isLoggedIn)
                    this.storeService.set("docTypeSS", docTypeSS)
                    this.storeService.set("docNumberSS", docNumberSS)
                    this.storeService.set("verifyDigitSS", digit)
                    this.storeService.set("estado", "preguntas")
                    this.storeService.set("retakeStep", false);
                    console.log(this.flow.flujo);
                    this.storeService.set("flujo", this.flow.flujo)
                    console.log(this.storeService.get("flujo"))
                    this.route.navigate(['paef/inbox/preguntas'])
                  } else {
                    this.modalService.modalSearchRadicated((respm) => {
                      console.log('RADICADO', respm);
                      if (respm == 'no') {
                        let isLoggedIn = respuesta.body.token;
                        let docTypeSS = docTypeString;
                        let docNumberSS = docNumberString;
                        let urlFiles = ''
                        if (respuesta.downfiles) {
                          urlFiles = respuesta.downfiles
                        }
                        this.storeService.set("request_type_new", this.arrays.transformReverseFlujo(this.flow.flujo))
                        console.log(this.storeService.get("request_type_new"));
                        this.storeService.set("urlFiles", urlFiles)
                        this.storeService.set("isLoggedIn", isLoggedIn)
                        this.storeService.set("docTypeSS", docTypeSS)
                        this.storeService.set("docNumberSS", docNumberSS)
                        this.storeService.set("verifyDigitSS", digit)
                        this.storeService.set("estado", "preguntas")
                        this.storeService.set("retakeStep", false);
                        console.log(this.flow.flujo);
                        this.storeService.set("flujo", this.flow.flujo)
                        console.log(this.storeService.get("flujo"))
                        this.route.navigate(['paef/inbox/preguntas'])
                      } else if (respm == 'cerrar') {
                      } else {
                        //BUSCAR DATA RADICADO
                        let dataRadicate = this.loginService.resultRadicado
                        let t = dataRadicate['body']['info'][0].v
                        console.log("------------TIPO----------", t);
                        let tipo = this.arrays.getTipoEmpresa(parseInt(t));
                        let numRadicado = this.loginService.radicado;
                        let email = '';
                        let cellPhone = '';
                        if (dataRadicate['body']['periodovalido'] === "NO") {
                          this.storeService.set("periodoValido", false);
                        } else {
                          this.storeService.set("periodoValido", true);
                        }
                        console.log("BOTONES", this.storeService.get('periodoValido'));
                        for (const iterator of dataRadicate['body']['info']) {
                          if (iterator.k == 'conEmail') {
                            email = iterator.v;
                          } else if (iterator.k == 'conCelular') {
                            cellPhone = iterator.v;
                          }
                        }
                        this.storeService.set("selectedOption", tipo)
                        this.storeService.set("isLoggedIn", isLoggedIn)
                        this.storeService.set("docTypeSS", docTypeSS)
                        this.storeService.set("docNumberSS", docNumberSS)
                        this.storeService.set("verifyDigitSS", digit)
                        this.storeService.set("numRadicado", numRadicado)
                        this.storeService.set("lastInfo", dataRadicate['body']['info'])
                        this.storeService.set("lastQuestions", dataRadicate['body']['questions'])
                        this.storeService.set("preguntasObject", {});
                        this.storeService.set("estado", "preguntas");
                        this.storeService.set("retakeStep", "preguntas");//ENVIAR
                        console.log(this.flow.flujo);
                        this.storeService.set("flujo", this.flow.flujo)
                        console.log(this.storeService.get("flujo"))
                        this.route.navigate(['paef/inbox/preguntas']);
                      }
                    })
                  }
                }
              });
            }
          }
          if (this.selectedOption == 1 || this.selectedOption == 2) {
            this.loginService.getLoginCargar(docTypeString, docNumberString, digit, radicado, token, this.storeService.comberStep(this.flow.flujo)).subscribe(async (res) => {
              console.log(res.body);
              this.modalService.closeModalDefinitive();
              this.modalService.closeModal('login');
              let respuesta = (res.body)
              if (respuesta['statusCode'] == 404) {
                console.log('BODY', respuesta['body'], 'showErrorMessage', respuesta['body'].showErrorMessage)
                if (respuesta['body'] && respuesta['body'].showErrorMessage) {
                  this.errorMessage = respuesta['body'].showErrorMessage;
                } else {
                  this.errorMessage = "Cliente no encontrado. Por favor verifique la información ingresada.";
                }
              }

              if (respuesta['statusCode'] == 200) {
                if (respuesta['body']['estado']) {
                  this.modalService.accessDenied((resp) => {
                  });
                  /* this.modalService.solicitudCompletada((resp)=>{                    
                  });    */
                } else {
                  //CARGAR DATOS
                  let isLoggedIn = (respuesta)['body']['token'];
                  let t = (respuesta)['body']['tipoEmpresa']
                  let phasemonth = ""
                  let tipo = this.arrays.getTipoEmpresa(parseInt(t));
                  let docTypeSS = docTypeString;
                  let docNumberSS = docNumberString;
                  let urlFiles = respuesta['body']['downfiles']
                  let numRadicado = radicado;
                  console.log(tipo);
                  let email = '';
                  let cellPhone = '';
                  this.storeService.set("showPap", false);
                  for (const iterator of respuesta['body']['info']) {
                    if (iterator.k == 'conEmail') {
                      email = iterator.v;
                    } else if (iterator.k == 'conCelular') {
                      cellPhone = iterator.v;
                    } else if (iterator.k == 'PreguntaPAP') {
                      if (iterator.v == 'SI') {
                        this.storeService.set("showPap", true);
                      }
                    }
                    if (iterator.k == 'phase_month') {
                      phasemonth = iterator.v;
                      this.storeService.set("phase_month", phasemonth)
                    }
                  }
                  console.log("email******", email);
                  console.log("celu****", cellPhone);
                  let state = respuesta['body']['request_states_id'];
                  let request_type = respuesta['body']['request_type'];
                  this.storeService.set("request_type_new", request_type)
                  let reasons = '';
                  if (state == 4)  //Caso DEVUELTO                    
                    reasons = respuesta['body']['devol_causes_text'];
                  let modalInputData: Array<element> = [
                    { k: "Tipo ID", v: docTypeSS },
                    { k: "Nº ID", v: docNumberSS },
                    { k: "Nº radicado", v: numRadicado },
                    { k: "Correo electrónico", v: email },
                    { k: "Celular", v: cellPhone }
                  ];
                  let actionToTake = '';
                  if (respuesta['body']['periodovalido'] === "SI") {
                    this.storeService.set("periodoValido", true);
                  } else {
                    this.storeService.set("periodoValido", false);
                  }
                  //Variable samePeriod valida si el radicado consultado, pertenece al flujo actual
                  console.log("[requestBody] samePeriod", respuesta['body']['samePeriod']);
                  this.storeService.set("preguntasObject", respuesta['body']['questions']);
                  if (respuesta['body']['samePeriod'] === "NO") {
                    this.modalService.wrongFlowRequest(resp => { });
                  } else {
                    console.log("BOTONES", this.storeService.get('periodoValido'));
                    await this.confirmActionModal(modalInputData, this.transformState(state), this.transformTextState(state), reasons).then((resp: string) => {
                      actionToTake = resp;
                      console.log("-------- continue to:----------", actionToTake);
                    }).catch((err) => {
                      console.log(err);
                    });
                  }
                  console.log("-------- continue to:----------", actionToTake);
                  this.storeService.set("selectedOption", tipo)
                  this.storeService.set("isLoggedIn", isLoggedIn)
                  this.storeService.set("docTypeSS", docTypeSS)
                  this.storeService.set("docNumberSS", docNumberSS)
                  this.storeService.set("verifyDigitSS", digit)
                  this.storeService.set("urlFiles", urlFiles)
                  this.storeService.set("numRadicado", numRadicado)
                  this.storeService.set("lastInfo", respuesta['body']['info'])
                  this.storeService.set("lastQuestions", respuesta['body']['questions'])
                  if (actionToTake != 'no') {
                    if (respuesta['body']['samePeriod'] === "SI") {
                      if (actionToTake == 'preguntas') {
                        this.storeService.set("preguntasObject", {});
                        this.storeService.set("estado", "preguntas");
                        this.storeService.set("retakeStep", "preguntas");
                        console.log("va por preguntas");
                        console.log(this.flow.flujo);
                        this.storeService.set("flujo", this.flow.flujo)
                        console.log(this.storeService.get("flujo"))
                        this.route.navigate(['paef/inbox/preguntas']);
                      } else {
                        this.storeService.set("estado", "documentos");
                        this.storeService.set("retakeStep", "documentos");
                        console.log("va por documentos");
                        console.log(this.flow.flujo);
                        this.storeService.set("flujo", this.arrays.transformStepFlujo(request_type))
                        console.log(this.storeService.get("flujo"))
                        this.route.navigate(['paef/inbox/documentos']);
                      }
                    }
                  }
                }
              }
            });
          }
        }, err => {
          this.modalService.closeModal('login');
          this.errorMessage = 'Estamos experimentando fallas técnicas, intente de nuevo más tarde.';
        });
    }
  }
}