import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { InboxModule } from './inbox/inbox.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from '@app/shared/material.module';
import { JwtInterceptor } from '@app/core/_helper/jwt.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [    
  ],
  imports: [
    CommonModule,
    RouterModule,
    InboxModule,
    SharedModule,
    MaterialModule,
    FormsModule, ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
   
  ]
})
export class BdbPaefModule { }
