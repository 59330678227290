import { Component, OnInit } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
import { ModalGlobalComponent } from '@app/shared/modal-global/modal-global.component';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '@app/shared/services/modal.service';
import { StoreService } from '@core/service/store.service';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginStepService } from '@app/authentication/service/login-step.service';


@Component({
  selector: 'adpe-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @SessionStorage('message')
  public message
  @SessionStorage('channel')
  public channel;
  private timerStartSubscription: Subscription;
  private timeoutSubscription: Subscription;
  private pingSubscription: Subscription;
  dialogRef = null;

  constructor(
    private route: Router,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService,
    private storeService: StoreService,
    public authServerProviderService: AuthServerProviderService,
    private modalService: ModalService,
    private cookieService: CookieService,
    public loginStepService: LoginStepService) {
  }

  ngOnInit() {
    if (!this.storeService.get("isLoggedIn") || this.storeService.get("isLoggedIn") == undefined || this.storeService.get("isLoggedIn") == null ) {
      this.closeSession();
    } else {
      console.log(new Date() + ">> Start watching for user inactivity");
      this.onStartWatching()
    }
  }

  onStartWatching() {
    this.userIdle.stopWatching();
    this.userIdle.resetTimer()
    console.log("this.timerStartSubscription ", this.timerStartSubscription);
    console.log("this.timeoutSubscription ", this.timeoutSubscription);
    console.log("this.pingSubscription ", this.pingSubscription);
    this.userIdle.stopTimer();

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.timerStartSubscription = this.userIdle.onTimerStart()
      .pipe(tap(() => console.log("Begin Timer ")))
      .subscribe(count => {

        this.endOnLoginPath();

        if (count === 1) {
          console.log(new Date() + ">> inactivity");
          this.inactiveModal(count)
        } else {
          // this.userIdle.resetTimer()
        }

        console.log("count ", count)

        var eventList = ["click"];
        for (let event of eventList) {
          document.body.addEventListener(event, () => this.userIdle.resetTimer());
        }
      });

    // Start watch when time is up.
    this.timeoutSubscription = this.userIdle.onTimeout()
      .subscribe(() => {
        this.endOnLoginPath();
        console.log("Inicia contador Timer ");
      });

    this.pingSubscription = this.userIdle.ping$
      .subscribe(value => {
        this.endOnLoginPath();
        console.log(`#${value} at ${new Date().toString()}`)
      });
  }


  onStopWatching() {
    this.userIdle.stopWatching();
    if(this.timerStartSubscription != undefined && this.timerStartSubscription != null){
      this.timerStartSubscription.unsubscribe();
    }
    if(this.timeoutSubscription != undefined && this.timeoutSubscription != null){
      this.timeoutSubscription.unsubscribe();
    }
    if(this.pingSubscription != undefined && this.pingSubscription != null){
      this.pingSubscription.unsubscribe();
    }
  }

  onStopTimer() {
    this.userIdle.stopTimer();
  }

  onResetTimer() {
    this.userIdle.resetTimer();
  }

  inactiveModal(count = 1) {
    this.modalService.closeModalDefinitive();                                                                                                                                  
    this.modalService.inactiveSessionModal((result) => {
      if (result === 'si') {
        this.closeSession();
      } else {
        this.userIdle.resetTimer()
      }
    }, count)
  }

  closeSession() {
    this.authServerProviderService.clearSesion()
    this.authServerProviderService.logoutBasic()
    this.authServerProviderService.getcloseSession()
    this.onStopWatching()
     this.modalService.closeModalDefinitive();
     this.modalService.closeSession(
       (result) => {
       }
     )
  }

  endOnLoginPath(){
    if(document.location.pathname == '/login'){
      console.log("pathname: ", document.location.pathname);
      this.authServerProviderService.clearSesion()
      this.authServerProviderService.logoutBasic()
      this.onStopWatching()
    }
  }

}
