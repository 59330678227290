import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '@core/service/login.service';
import { ModalService } from '@app/shared/services/modal.service';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StoreService } from '@core/service/store.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { MotivosRechazoService } from '@core/service/motivos-rechazo.service';
import { FlowService } from '@core/service/flow.service';
import { UserLogout } from '@app/core/models/user-login.model';


@Component({
	selector: 'aliv-login-operativa',
	templateUrl: './login-operativa.component.html',
	styleUrls: ['./login-operativa.component.scss']
})
export class LoginOperativaComponent implements OnInit {

	@SessionStorage('username')
	public usernameSS;
	@SessionStorage('channel')
	public channel;
	@SessionStorage('form')
	public formSesion;
	public device: any;
	public isSafari: any;
	public loginForm: FormGroup;
	public focusDocNumber = false;
	public focusDigit = false;
	public focusRadicado = false;
	public checkRadio = new Array();
	public selectedOption = 0;
	public errorMessage: string;
	public months = [];
	public flow: any = [];
	//public urlData = environment.endpointManager + 'documents/s3?key=public/guiaparaelPAEF.pdf';
	//public urlDataPopupWindows = environment.endpointManager + 'documents/s3?key=public/GuiaparaHabilitarPantallasEmergenteenGoogleChrome.pdf';

	public deviceInfo = null;
	public docTypes: { name: string, id: string }[] = [
		{ name: 'NIT Persona Jurídica', id: 'N' },  //OK
		{ name: 'Cédula de ciudadanía', id: 'C' },  //OK
		{ name: 'Cédula de extranjería', id: 'E' }, //OK
		{ name: 'Pasaporte', id: 'P' },             //OK
		{ name: 'NIT Persona Natural', id: 'L' }
	];

	constructor(
		private route: Router,
		private loginService: LoginService,
		private modalService: ModalService,
		private storeService: StoreService,
		private fb: FormBuilder,
		private rechazoService: MotivosRechazoService,
		private deviceService: DeviceDetectorService,
		private cookieService: CookieService,
		private flowService: FlowService,
		private $sessionStorage: SessionStorageService,
	) {
		this.modalService.loadingModal('login', resp => {});
		this.flowService.getFlows()
		.then((response: any) => {
			this.modalService.closeModal('login');
			this.flow = response;
		});
	}

	ngOnInit() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktopDevice = this.deviceService.isDesktop();
		this.cookieService.deleteAll('/')
		this.device = this.deviceService.getDeviceInfo()['browser'].toLowerCase();
		if (this.device == 'safari') {
			this.isSafari = true;
		} else {
			this.isSafari = false;
		}
		if (this.deviceInfo["browser"].toLowerCase() != "chrome") {
			this.infoOpenModal();
		}
		
		//ejemplo chapcha
		/*this.storeService.set("prueba",5)
		console.log(this.storeService.get("prueba"));*/

			//ejemplo token
		/*this.recaptchaV3Service.execute('authentication')
			.subscribe((token) => {
			console.log(token);
			}
		)*/

		this.loginForm = this.fb.group({
			user: new FormControl('', Validators.required),
			/* password: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9ñÑ]*$/), Validators.maxLength(30)]), */
			password: new FormControl('', [Validators.required, Validators.maxLength(30)]),
			digit: new FormControl(''),
			radicado: new FormControl(''),
			requestType: new FormControl('',[Validators.required]),
			date: new FormControl('',[Validators.required])
		});

		if (this.deviceInfo["browser"].toLowerCase() != "chrome") {
			this.infoOpenModal();
		}
	}

	infoOpenModal() {
		this.modalService.noValidateBrowser((result) => {})
	}

	activeFocusDocNumber() {
		this.focusDocNumber = true;
	}

	inactiveFocusDocNumber() {
		this.focusDocNumber = false;
		let documentNumber = this.loginForm.value.number;
		if (documentNumber != undefined && documentNumber.length < 1) {}
	}

	activeFocusDigit() {
		this.focusDigit = true;
	}

	inactiveFocusDigit() {
		this.focusDigit = false;
	}

	activeFocusRadicado() {
		this.focusRadicado = true;
	}

	inactiveFocusRadicado() {
		this.focusRadicado = false;
	}

	formatNumberAndChar(value, field) {
		let newValue = '';
		newValue = value.replace(/[^0-9a-zA-ZñÑ]/g, '');
		this.loginForm.controls[field].setValue(newValue);
	}

	formatNumber(value, field) {
		let newValue = '';
		newValue = value.replace(/[^0-9]/g, '');
		this.loginForm.controls[field].setValue(newValue);
	}

	formatRadicado(value, field) {
		let newValue = '';
		newValue = value.replace(/[^0-9_]/g, '');
		this.loginForm.controls[field].setValue(newValue);
	}


	showQuestions() {
		this.modalService.terms((result) => {})
	}

	comberStep(id){
        switch (id) {
            case 0:
              return "PAEF";
            case 1:
              return "PAP";
            case 2:
              return "Tanto PAEF como PAP";
			  //return "PAP AGROPECUARIO";
			case 4:
			  return "SACÚDETE";
				//return "PAP AGROPECUARIO";
			case 5:
				return "PARO";
				//return "PAP AGROPECUARIO"
			case 6:
				return "EMPLEADOS";
				//return "PAP AGROPECUARIO";
            default:
              break;
          }
	}
	
	comberStepBack(id){
        switch (id) {
            case -1:
              	return "-";
            case 0:
				this.storeService.set("tipoSolicitud","Únicamente a PAEF MICRO")
              	return 1;//"PAEF";				  
            case 1:
				this.storeService.set("tipoSolicitud","Únicamente a PAP")
              	return 2;//"PAP";
            case 2:
				this.storeService.set("tipoSolicitud","Tanto PAEF como PAP")
				return 3;//Tanto PAEF como PAP
              //return 4;//"PAP AGROPECUARIO"";
			return 2;//"PAP";
			case 4:
				  this.storeService.set("tipoSolicitud","Sacudete")
				  return 5;//Tanto PAEF como PAP
				//return 4;//"PAP AGROPECUARIO"";
			case 5:
				this.storeService.set("tipoSolicitud","Únicamente a PARO")
				return 6;//Tanto PAEF como PAP
			case 6:
				this.storeService.set("tipoSolicitud","Creación de Nuevos Empleos")
				return 7;//Nuevos Empleos
            default:
              break;
          }
    }

	loginUser() {
		this.storeService.flujo = parseInt(this.loginForm.controls.requestType.value);
		let flowFilter = this.flow.find(data => data.flujo == this.storeService.flujo);
		this.storeService.set("flow", flowFilter);
		
		let data = {
			user: this.loginForm.controls.user.value,     
			securedata: this.loginForm.controls.password.value,
			request_type: this.storeService.comberStep(this.storeService.flujo)
		}

		this.modalService.loadingModal('loginOperativa', resp => {});   
		this.loginService.getLoginOperative(data).subscribe((res)=>{
			
			/* this.modalService.closeModalDefinitive(); */
			this.modalService.closeModal('loginOperativa');
			console.log(res.body);
			let respuesta = res.body;

			if(respuesta['statusCode']==200 && respuesta.body.token != undefined  && respuesta.body.token != null || 
				respuesta['statusCode']==201 && respuesta.body.token != undefined  && respuesta.body.token != null ||
				respuesta.body.checksum == null && respuesta.body.token != undefined || respuesta.body.checksum == undefined && respuesta.body.token != undefined){
				console.log("respuesta: " + respuesta)
				
				let isLoggedIn = respuesta['body']['token'];
				let radicado = respuesta['body']['numradicado']
				let questions = respuesta['body']['questions'] != undefined ? JSON.parse(respuesta['body']['questions']) : false;
				this.channel = 'internal'
				this.usernameSS = this.loginForm.controls.user.value
				this.storeService.set("isLoggedInComercial",isLoggedIn )
				this.storeService.set("radicado",radicado )
				this.storeService.set("questions", questions);
				this.formSesion = respuesta['body']['info'] //cargar mes
				//this.storeService.set("form", )
				this.storeService.set("flujoBack",this.comberStepBack(this.storeService.flujo))
				this.modalService.restrictedAccess(this.comberStep(this.storeService.flujo) ,resp => {
					if (resp == "continue") {
						let company = ''
						console.log(respuesta['companyInfo']);
						if(respuesta['companyInfo'] != undefined){//TENER EN CUENTA si tiene datos
							this.modalService.closeModalDefinitive();
							let company = respuesta['companyInfo']
							let info = JSON.parse(respuesta['body']['info'])
							console.log(info);
							this.storeService.set("showPap", false);
							this.storeService.set("flujo", this.storeService.flujo)
							for (const iterator of info) {
								if(iterator.k == 'PreguntaPAP'){
									if(iterator.v == 'SI'){
									  this.storeService.set("showPap", true);
									}
								}
							}
							this.storeService.set("data",company )
							this.storeService.set("periodDate", this.loginForm.controls.date.value);
							this.route.navigate(['asignar'])
						} else{
							this.modalService.caseAssigment(resp => {
								if (resp == "continue") {
									//consultar nuevo caso						
									this.modalService.loadingModal('consult', resp => {});   
									
									this.rechazoService.consulCase({
										username: this.usernameSS,
										request_type: this.storeService.comberStep(this.storeService.flujo),
										period : this.loginForm.controls.date.value
									}).subscribe((respues)=>{
										this.modalService.closeModal('consult');
										console.log('respppp',respues);
										if(respues.body.statusCode == 400 || respues.errorType != undefined){
											this.modalService.closeModal('loading')
											this.modalService.closeModalDefinitive();
											this.getcloseSessionUser();
											this.errorMessage = "No hay casos para asignar en este momento.";
										} else{
											console.log("into else: ", respues);
											let info = respues['body']['companyInfo']
											this.formSesion = info //cargar mes
											let questions = respues['body']['body']['questions'] != undefined ? JSON.parse(respues['body']['body']['questions']) : false;
											console.log(info);
											this.storeService.set("showPap", false);
											let arrayInfo = Array.of(info);
											for (const iterator of arrayInfo) {
												if(iterator.k == 'PreguntaPAP'){
													if(iterator.v == 'SI'){
													  this.storeService.set("showPap", true);
													}
												}
											}
											this.storeService.set("data",respues['body']['companyInfo'])//TENER EN CUENTA guardar datos compañia
											this.storeService.set("flujo", this.storeService.flujo)
											this.storeService.set("questions", questions);
											this.storeService.set("periodDate", this.loginForm.controls.date.value);
											console.log("Before routing to asignar")
											this.route.navigate(['asignar'])
											console.log("After routing to asignar")
										}
									}, (err) => {										
										this.modalService.closeModal('consult');
									})
								}
						   })
						}
					} else {
						this.modalService.closeModalDefinitive();
					}
				})
			} else if(respuesta['statusCode'] == 400) {
				this.modalService.closeModalDefinitive();
				this.errorMessage = "Sus datos son incorrectos.";
			} else if(respuesta['statusCode'] == 403) {
				this.modalService.closeModalDefinitive();				
				this.errorMessage = "El usuario no cuenta con los privilegios de acceso necesarios";
			}  else {
				this.modalService.closeModalDefinitive();
				this.errorMessage = "Estamos experimentando fallas técnicas, intente de nuevo más tarde.";
			}
		}, (err) => {			
			/* this.modalService.closeModalDefinitive(); */
			this.modalService.closeModal('loginOperativa');
		});

	}

	getcloseSessionUser(){
		this.cookieService.deleteAll('/')
		setTimeout(() => {
		  this.$sessionStorage.clear();
		  setTimeout(() => {
			console.log('salir');
			
			this.route.navigate(['/operativa'])
		  }, 200);
		}, 300);
		let requestDTO = new UserLogout();
		requestDTO.user = this.storeService.get("aliv.username");
		requestDTO.token = this.storeService.get("isLoggedInComercial");
		this.loginService.getCloseSesssion(requestDTO).subscribe(() => {})
	}
	
	changeValue(){
		if(this.loginForm.controls.requestType.value != ""){
			let monthsFiler = this.flow.find(data => {if(data.flujo == this.loginForm.controls.requestType.value) return data.months});
			this.months = monthsFiler.months.map(month => {return {code: month.phase, month: month.name}})
		} else{
			this.months = [];
		}
	}
}
