<div class="row">
    <div class="logo divLeft">
        <div class="row position">
            <div class="col background-logo">
                <div class="row">
                    <img class="logo-wt" src="assets/img/BDB-logo2.png">
                    <img class="logo-m" src="assets/img/bdb-grupoaval.svg">
                </div>
            </div>
        </div>
        <div class="row position margin-top-7">
            <div class="block"></div>
            <div class="left-side">
                <h1 class="tituloApp">
                    Bienvenido,
                </h1>
                <h1 class="tituloApp">
                    ¿Cuál solicitud desea realizar?
                </h1>
                <div class="width-full margin-top-5">
                    <div [ngClass]="listButtons[i].disable ? 'card disable': 'card'" *ngFor="let buttons of listButtons; let i=index" (click)="next(buttons.flujo,i)">
                        <div class="left">
                            <img [src]="buttons.icon">
                        </div>
                        <div class="right">
                            <h3>
                                {{buttons.title}}
                            </h3>
                            <p>
                                {{buttons.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 150px;">

        </div>
    </div>
    <div [ngClass]="isSafari ? 'width-background-blue-s center-align background-image-blue col-hide col-tablet image-tablet' : 'width-background-blue center-align background-image-blue col-hide col-tablet image-tablet'">
        <div class="web">
            <img src="assets/img/BDB-logo.png">
        </div>
        <div class="tablet">
            <img src="assets/img/monogram.svg">
        </div>
        <div class="divFloat">
        </div>
    </div>
</div>