import { DynQuestion } from './formularios';

export const papaForms: Array<DynQuestion[]> = [
    [
    ],
    [
        {
            "name": "tipoIdEmpresa"   
        },
        {
            "name": "numeroPrimas"   
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",/**** */
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "sexo"/**** */
        },
        {
            "name": "edad",/**** */
            "validation": {
                "maxLength": 3
            }
        },
        {
            "name": "namePredio"/**** */
        },
        {
            "name": "conflicArmy"/**** */
        },
        {
            "name": "mother"/**** */
        },
        {
            "name": "etnia"/**** */
        },
        {
            "name": "etniaWhich"/**** */
            ,
            "optional": true 
        },
        {
            "name": "discapacidad"/**** */
        },
        {
            "name": "discapacidadWhich"/**** */
            ,
            "optional": true 
        },
        {
            "name": "discapacidad"/**** */
        },
        {
            "name": "discapacidadWhich"/**** */
            ,
            "optional": true 
        },
        {
            "name": "agremiacion"/**** */
        },
        {
            "name": "agremiacionWhich"/**** */
            ,
            "optional": true 
        },
        {
            "name": "ciiu"
        },
        // {
        //     "name": "cuSusTipoId1",
        //     "optional": true 
        // },
        // {
        //     "name": "cuSusNumId1",
        //     "validation": {
        //         "minLength": 6,
        //         "maxLength": 15
        //     },
        //     "optional": true
        // },
        // {
        //     "name": "cuSusTipoId2",
        //     "optional": true 
        // },
        // {
        //     "name": "cuSusNumId2",
        //     "validation": {
        //         "minLength": 6,
        //         "maxLength": 15
        //     },
        //     "optional": true
        // },
        // {
        //     "name": "cuSusTipoId3",
        //     "optional": true  
        // },
        // {
        //     "name": "cuSusNumId3",
        //     "validation": {
        //         "minLength": 6,
        //         "maxLength": 15
        //     },
        //     "optional": true
        // },
        // {
        //     "name": "cuSusTipoId4",
        //     "optional": true 
        // },
        // {
        //     "name": "cuSusNumId4",
        //     "validation": {
        //         "minLength": 6,
        //         "maxLength": 15
        //     },
        //     "optional": true
        // },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "vereda",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            },
            "optional": true
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",   
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            } 
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            } 
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numPlanA1",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        },
        {
            "name": "numPlanA2",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        },
        {
            "name": "numPlanA3",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        },
        {
            "name": "numPlanA4",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        },
        {
            "name": "numPlanA5",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        },
        {
            "name": "numPlanA6",
            "validation": {
                "minLength": 6,
                "maxLength": 250
            }
        }
    ],
    [
    ],
    [
    ]
]