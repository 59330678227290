import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
//import { LoginStepService } from '@app/authentication/service/login-step.service';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '../services/modal.service';
import { environment } from '../../../environments/environment';
import { StoreService } from '@core/service/store.service';
import { LoginService } from '@core/service/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @SessionStorage('username')
  public usernameSS;
  @SessionStorage('channel')
  public channel;

  constructor(public route: Router,
    private sessionStorage: SessionStorageService,
    public authServerProviderService: AuthServerProviderService,
    //public loginStepService: LoginStepService,
    public loginService: LoginService,
    private httpClient: HttpClient,
    private modalService: ModalService,
    private activatedRoute : ActivatedRoute,
    public storeService: StoreService) {
  }

  ngOnInit() {
    if (environment.production == true) {	      
    }
  }

  closeSession() {
    let typeModal = this.channel == 'internal' ? 2: 1;
    this.modalService.closeSessionNavbar(typeModal, resp => {
      if (resp == "continue") {
        if(this.channel == 'internal'){
          this.authServerProviderService.logoutOperative()
        }else{
          this.authServerProviderService.logoutBasic()
        }
      } else {
        this.modalService.closeModalDefinitive();
      }
    })
  }

  return(){
    //this.route.navigate(['aliv/inbox/home'])
  }


}
