import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServerProviderService } from '../../../core/services/auth-server-provider.service';
import { StoreService } from '@core/service/store.service';


@Component({
  selector: 'aliv-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  constructor(
    private route: Router,
    private storeService: StoreService,
    public authServerProviderService: AuthServerProviderService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      document.body.style.backgroundColor = '#f6f8fa'
      window.scrollBy(0, -document.body.scrollHeight * 5);
      var elems = document.getElementsByTagName('html');      
      for (var i = 0; i < elems.length; i++) {
        elems[i].style.backgroundColor = '#f6f8fa';
      }
    }, 500)

    let estado = this.storeService.get("estado");
    console.log("estado: " + estado);    
    if(estado == "login" || estado == "terminos" || estado == "preguntas"){
      this.storeService.set("estado", "terminos")
    }
    else{
      this.return();
    }
  }

  continuar(){
    this.route.navigate(['paef/inbox/preguntas'])
  }

  return() {
    this.authServerProviderService.logoutBasic()
  }

}
