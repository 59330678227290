import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WelcomeComponent } from './welcome/welcome.component';
import { UserIdleModule } from 'angular-user-idle';
import { BdbPaefModule } from '@app/bdb-paef/bdb-paef.module';
import { SharedModule } from '@app/shared/shared.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BdbPaefModule,
    SharedModule,
    UserIdleModule.forRoot({idle: 540, timeout: 60, ping: 10})
  ],
  providers:[],
  declarations: [WelcomeComponent],
})
export class WelcomeModule { }

//UserIdleModule.forRoot({idle: 240, timeout: 60, ping: 10})
