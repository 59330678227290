<div class="row login-body">
    <div class="logo divLeft">
        <div class="row position">
            <div class="col background-logo">
                <div class="row">
                    <img class="logo-wt" src="assets/img/BDB-logo2.png">
                    <img class="logo-m" src="assets/img/bdb-grupoaval.svg">
                </div>
            </div>
        </div>
        <div class="row position margin-top-7">
            <div class="block"></div>
            <div class="left-side">
                <h1 class="tituloApp">
                    Programa apoyo al empleo formal PAEF,
                </h1>
                <h1 class="tituloApp">
                    Programa a empresas afectadas por el paro Nacional PARO y Programa Generación de Nuevos Empleos.
                </h1>

                <div class="width-full margin-top-5">
                    <form [formGroup]="loginForm" class="formulario">
                        <div class="width-full">
                            <div class="input-group">
                                <div class="container-payment-radio">
                                </div>
                            </div>
                        </div>
                        <div class="width-full">
                            <span class="subtitles">Usuario de red</span>
                            <div class="input-group mb-3">
                                <input id="user" type="text" maxlength="15" class="forms-type-n form-control font-20 roboto black-font noFocus" (click)="activeFocusDocNumber();" (focusout)="inactiveFocusDocNumber();" [ngClass]="focusDocNumber ? 'focusInput' : 'unFocus'" formControlName="user"
                                    (input)="$event.target.value" placeholder="Ingrese el usuario">
                            </div>
                        </div>
                        <div class="width-full">
                            <div class="width-num-ide">
                                <span class="subtitles">Contraseña</span>
                                <div class="input-group mb-3">
                                    <input id="password" type="password" maxlength="15" class="forms-type-n form-control font-20 roboto black-font noFocus" (click)="activeFocusDocNumber();" (focusout)="inactiveFocusDocNumber();" [ngClass]="focusDocNumber ? 'focusInput' : 'unFocus'" formControlName="password"
                                        placeholder="Ingrese la contraseña">
                                    <!-- (input)="formatNumberAndChar($event.target.value, 'password')" placeholder="Ingrese la contraseña"> -->
                                </div>
                            </div>
                        </div>

                        <div class="width-full">
                            <span class="subtitles">Tipo de solicitud a revisar</span>
                            <div class="input-group mb-3">
                                <select (ngModelChange)="changeValue()" formControlName="requestType" class="forms-type-n form-control-select form-control font-20 roboto black-font noFocus" name="" id="requestType">
                                    <option value="" selected>Seleccione</option>
                                    <option value="0" disabled>únicamente a PAEF</option>
                                    <option value="5" disabled>únicamente a PARO</option>
                                    <!-- <option value="1" disabled>únicamente a PAP</option>
                                    <option value="2" disabled>Tanto PAEF como PAP</option>
                                    <option value="4" disabled>Programa Sacúdete</option> -->
                                    <option value="6" >Nuevos Empleos</option>
                                </select>
                            </div>
                        </div>

                        <div class="width-full">
                            <span class="subtitles">Mes</span>
                            <div class="input-group mb-3">
                                <select formControlName="date" class="forms-type-n form-control-select form-control font-20 roboto black-font noFocus" name="" id="date">
                                    <option value="" selected>Seleccione</option>
                                    <option *ngFor="let month of months" [value]="month.code">
                                        {{month.month}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="width-full">
                            <button id="ingresar" [ngClass]="loginForm.valid ? 'btn button-app font-16 button-app-active' : 'btn button-app font-16 button-app-active button-disable'" [disabled]="!loginForm.valid" (click)="loginUser()">
								Consultar ›
							</button>
                        </div>

                        <div class="width-full">
                            <div *ngIf="errorMessage != ''" class="error-text">
                                <p>
                                    {{errorMessage}}
                                </p>
                            </div>

                            <p class="capcha">
                                Este sitio está protegido por reCAPTCHA y Google <a href="https://policies.google.com/privacy"> Política
									de privacidad </a> y Se aplican <a href="https://policies.google.com/terms"> Términos de servicio </a> .
                            </p>
                        </div>
                    </form>
                    <div style="height: 150px;">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="body-rigth" [ngClass]="isSafari ? 'width-background-blue-s center-align background-image-blue col-hide col-tablet image-tablet' : 'width-background-blue center-align background-image-blue col-hide col-tablet image-tablet'">
        <div class="web">
            <img src="assets/img/BDB-logo.png">
        </div>
        <div class="tablet">
            <img src="assets/img/monogram.svg">
        </div>
        <div class="divFloat">
        </div>
    </div>
</div>