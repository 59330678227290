import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/shared/services/modal.service';
import { FormGroup} from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StoreService } from '@core/service/store.service';
import { CookieService } from 'ngx-cookie-service';
import { FlowService } from '@core/service/flow.service';


@Component({
  selector: 'paef-login',
  templateUrl: './login-select.component.html',
  styleUrls: ['./login-select.component.scss']
})
export class LoginSelectComponent implements OnInit {

  public device: any;
  public isSafari: any;
  public deviceInfo = null;
  public listButtons = [];
  public loginForm: FormGroup;

  constructor(
    private route: Router,
    private flowService: FlowService,
    private modalService: ModalService,
    private storeService: StoreService,
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService
  ) {
    this.modalService.loadingModal('login', resp => {});
    this.flowService.getFlows().then(
      (response: any) => {
        this.listButtons = response;
        this.modalService.closeModal('login');
        setTimeout(() => {
          window.scrollBy(0, -document.body.scrollHeight * 5);
        }, 500);
      }
    );
    //this.listButtons = this.flowService.getFlowsOrdered(true, this.flowService.listFlowsBk);
    // setTimeout(() => {
    //   this.modalService.closeModal('login');
    // }, 2000);
  }

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.cookieService.deleteAll('/');
    this.storeService.set('flow', false);
    
    this.device = this.deviceService.getDeviceInfo()['browser'].toLowerCase();
    if (this.device == 'safari') {
      this.isSafari = true;
    } else {
      this.isSafari = false;
    }
  }

  next(flujo, i) {
    if (!this.listButtons[i].disable) {
      let dataFlow = this.listButtons.find(data => data.flujo == flujo);
      this.storeService.set('flujo', flujo);
      this.storeService.flujo = flujo;
      this.storeService.set('flow', dataFlow);
      console.log(this.storeService.get('flujo'));
      this.route.navigate([this.listButtons[i].router]);
    }
  }
}
