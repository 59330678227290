import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { NaclSbxUtil } from '@npm-bbta/bbog-ate-sbx-utilities-lib';
import { Md5 } from 'ts-md5';
import * as CryptoJS from 'crypto-js';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class CryptoService {
  private readonly whiteToken = 'whiteToken';

  //esta funcion es la vamos a consumir para encriptar los request que salen
  //desde el front, bien sea los params o el body 
  setEncryptionRequest(body:any, pAuthToken: string, options:any, headers:any): any {
    const parameters: any = environment.SBX_PARAMETERS_v2;
    parameters.Seed = {
      nonce: NaclSbxUtil.generateCustomNonce(pAuthToken || this.whiteToken),
    };

    const encryptedBody = NaclSbxUtil.generateJWTPublicKey(
      body,
      environment.SBX_RQ_PBK,
      parameters
    );

    if (headers) {
      const headersResp: any[] = [];
      const headersDef: string[] = Object.keys(headers);
      headersDef.forEach((name) => {
        if (name && headers[name] && !options.get[name]) {
          headersResp.push({ [name]: headers[name] });
        }
      });
      const encryptedHeaders = NaclSbxUtil.generateJWTPublicKey(
        JSON.stringify(headersResp),
        environment.SBX_RQ_PBK,// la publica
        parameters
      );
      options = options.set('x-custom-headers', encryptedHeaders);
    }

    options = options.set('x-auth-token', pAuthToken || this.whiteToken);
    options = options.set('x-strcode', Md5.hashStr(encryptedBody as string));

    return { encryptedBody, options };
  }

// esta función permite encriptar recursos que se quieran agregar el sessionStorage
  encrypt(data: any): string {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      environment.CYPHER_JS_KEY
    );
    return cipherText.toString();
  }

// esta función permite desencriptar los recursos que se extraigan del sessionStorage
  decrypt(cipherText: string): any {
    const bytes = CryptoJS.AES.decrypt(cipherText, environment.CYPHER_JS_KEY);
    return this.parseItem(bytes.toString(CryptoJS.enc.Utf8));
  }

  parseItem(item: any): any {
    if (item === null || item === undefined) {
      return null;
    }
    return JSON.parse(item);
  }

//esta función permite desencriptar el response que biene desde los backend
//de java libreria que se creo desde el coe
  decryptResponse(word:any):any{
    const bytes = CryptoJS.AES.decrypt(
      word,
      CryptoJS.enc.Utf8.parse(environment.PV_KEY),
      {
        keySize: 16,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(environment.PB_KEY),
      },
    );
    const decryptedStr = CryptoJS.enc.Utf8.stringify(bytes);
    return decryptedStr.toString();
  }

  //funcion para la asignación de los params
  defineParams(pParams: any, pUri: any): any {
    let options = new HttpHeaders();
    //const userStorage = this.storageService.getUser() as IUserStorage;
    pParams = pParams || {};
    pParams.body = pParams.body || {};
    pParams.responseType = pParams.responseType || 'json';
    pUri = pParams.externalEndpoint ? pUri : environment.apiSbx.concat(pUri);
    //pUri = this.testLocal(pUri);

    options = !!pParams.externalEndpoint
      ? this.assingHeaders(options, pParams.headers)
      : options;

    options = options.set('Content-Type', 'application/json');

    if (pParams.body.constructor.name === 'FormData') {
      options = options.delete('Content-Type', '');
    }
    return { pUri, pParams, options };
  }

//funcion para asignación de los headers
  assingHeaders(head: HttpHeaders, pHeaders: any): HttpHeaders {
    const headerNames: string[] = Object.keys(pHeaders);
    headerNames.forEach((name) => {
      head = head.set(name, pHeaders[name]);
    });
    return head;
  }
}
