import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DesconectComponent } from './desconect/desconect.component';
import { LoginSelectComponent } from './login-select/login-select.component';

export const AuthRouting: Routes = [
	//{ path: 'alert', component: DesconectComponent},
	{ path: 'logins', component: LoginComponent },
	{ path: 'login', component: LoginSelectComponent },
	{ path: '*', redirectTo: '/login', pathMatch: 'full' },
	{ path: '', redirectTo: '/login', pathMatch: 'full' }
];