<div class="slide-nav">
    <div class="back" *ngIf="loginService.back" (click)="return()">
        <img src="assets/img/new/arrow-back.svg">
    </div>
    <div class="data-left">
        <img src="assets/img/new/logo-nav.svg">
    </div>
    <div class="data-right">
        <img *ngIf="channel == 'internal'" src="assets/img/area.png" class="user-image"> &nbsp;&nbsp;
        <span *ngIf="channel == 'internal'" class="user-name">{{usernameSS}}</span>
        <div *ngIf="channel == 'internal'" class="Line-3-Copy-9"></div>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <a class="n-movile" (click)="closeSession()">
            <p>Salida segura</p><img src="assets/img/new/logout.svg">
        </a>
        <a class="l-movile" (click)="closeSession()">
            <img src="assets/img/new/logout.svg">
        </a>
    </div>
</div>