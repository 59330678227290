import { Routes } from '@angular/router';
import { AuthRouting } from './authentication/authentication.routing';
import { WelcomeRouting } from './welcome/welcome.routing';
import { ExceptionsRouting } from './exceptions/exceptions.routing';
import { BdbOperativaRouting } from './bdb-operativa/operativa.routing';

export const AppRoutingModule: Routes = [
	...BdbOperativaRouting,
	...AuthRouting,
	...ExceptionsRouting,
	...WelcomeRouting,
	{ path: '**', redirectTo: '/login', pathMatch: 'full' },
	{ path: '', redirectTo: '/login', pathMatch: 'full' }
];