import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})

export class StoreService {
    public flujo;
    constructor(private sessionStorage: SessionStorageService) { }

     // The set method is use for encrypt the value.
     set(keys, value: any) {
      if (environment.cryptoCookies) {
          const iv = CryptoJS.enc.Utf8.parse(keys);
          const key = btoa(keys);
          this.sessionStorage.clear(key);
          const encrypted = CryptoJS.AES.encrypt((JSON.stringify(value)), key,
          {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
          });
          sessionStorage.setItem(key, encrypted.toString());
      } else {
          sessionStorage.setItem(keys, JSON.stringify(value));
      }
  }

    // The get method is use for decrypt the value.
    get(keys) {
      if (environment.cryptoCookies) {
          const iv = CryptoJS.enc.Utf8.parse(keys);
          const key = btoa(keys);
          const value = sessionStorage.getItem(key);
          if (value !== null && value !== undefined && value !== '') {
              const decrypted = CryptoJS.AES.decrypt(value, key,
                  {
                      keySize: 128 / 8,
                      iv: iv,
                      mode: CryptoJS.mode.CBC,
                      padding: CryptoJS.pad.Pkcs7
                  });
              const a =  decrypted.toString(CryptoJS.enc.Utf8);
              const c = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
              return c;
          } else {
              return false;
          }
      } else {
          const value = sessionStorage.getItem(keys);
          if (value !== null && value !== undefined && value !== '') {
              return JSON.parse(sessionStorage.getItem(keys));
          } else {
              return false;
          }
      }
  }

    comberStep(id){
        switch (id) {
            case -1:
              return "-";
            case 0:
              return 1;//"PAEF";
            case 1:
              return 2;//"PAP";
            case 2:
              return 3;//"tanto PAEF como PAP"";
              //return 4;//"PAP AGROPECUARIO"";
            case 4:
                return 5;//"SACUDETE"";
            case 5:
                return 6;//"PARO"";
            case 6:
                return 7;//"EMPLEADOS";
            default:
              break;
          }
    }

    deleteKey(keys) {
      const key = btoa(keys);
      this.sessionStorage.clear(key);
  }
}