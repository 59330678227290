<div [ngClass]="'msg-data ' + type">
    <!-- <img src="assets/img/new/warning.svg"> -->
    <img src="assets/img/new/red-warning.svg">
    <p>
        <span>
        AVISO IMPORTANTE:
       <!-- </span> Las aplicaciones para el mes de {{data.mes}} han cerrado. Usted está diligenciando la solicitud para el mes de {{data.mesd}} y tendrá plazo hasta el {{data.diaV}} de {{data.mesV}} a las {{data.horaV}} para completarla. -->
        </span>
        <!-- Las aplicaciones para el mes de {{data.mes}} han cerrado. Usted va a iniciar la solicitud para el mes de {{data.mesd}}. -->
        <!-- Las aplicaciones para el mes de JULIO han cerrado. Este portal solo está habilitado para realizar correcciones y envío de solicitudes en curso. -->
        <!-- Las aplicaciones para el mes de JULIO han cerrado. Usted va a iniciar la solicitud para el mes de AGOSTO y tendrá plazo hasta el 20 de agosto a las 4 pm para completarla. -->
        <!-- Las aplicaciones para el mes de AGOSTO han cerrado. Este portal solo está habilitado para realizar correcciones y envío de solicitudes en curso. -->
        {{title}}
    </p>
</div>