import { Routes } from '@angular/router';
import { AuthGuard } from '@app/core/_guards/auth.guard';
import { FormularioComponent } from './formulario/formulario.component'
import { TerminosComponent } from './terminos/terminos.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { NewPreguntasComponent } from './new-preguntas/new-preguntas.component';



export const InboxRouting: Routes = [
  {
    path: 'inbox', component: null, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'terminos', component: TerminosComponent },
      { path: 'formulario', component: FormularioComponent },
      // preguntas old { path: 'preguntas', component: PreguntasComponent },
      { path: 'preguntas', component: NewPreguntasComponent },
      { path: 'documentos', component: DocumentosComponent },
    ]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];
