import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OutMessageInput } from '../model/mcomponent';
import { SessionStorage } from 'ngx-webstorage';
import { StoreService } from '@core/service/store.service';

@Injectable({
  providedIn: 'root'
})
export class NoReturnService {
  @SessionStorage('message')
  public message
  public TextInfo: any
  public TextInfo2: any
  DinamicTitle: string = ""
  DinamicDescription: string = ""
  outMessageInput: OutMessageInput;

  constructor(private route: Router,
    public storeService: StoreService) {
      this.TextInfo = this.storeService.get("dataRejected");
    if(this.message !== null && this.message !== undefined){
      this.TextInfo = this.message['dataRejected']
      this.TextInfo2 = this.message['docCompleted']
    }
   }

  redirect() { 
    this.outdated();
  }



  outdated() {
    this.initOutdated();
    this.route.navigate(['outdated'])
  }


  outservice() {
    this.initOutService();
    this.route.navigate(['outservice']);
  }

  initOutdated() {
      this.outMessageInput = {
        "emoji": this.TextInfo.emoji,
        "title": this.TextInfo.title,
        "description": this.TextInfo.description,
        "button": this.TextInfo.button,
        "buttonRedirect": this.TextInfo.buttonRedirect
      }    
  }

  initOutService() {
    this.outMessageInput = { 
      "emoji": "assets/img/emoji/grimacing.png",
      "title": "Lo sentimos, el servicio no está disponible en este momento.",
      "description": "Inténtalo de nuevo más tarde.",
      "button": "Finalizar › ",
      "buttonRedirectInternal": "login"
    }
  }

  
}