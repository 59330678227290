<div class="barr">
    <div class="line">
        <div id="progreso" class="progresss"></div>
    </div>
    <div class="text">
        <p class="pint">
            01. Preguntas
        </p>
        <p>
            02. Formulario
        </p>
        <p class="opac">
            03. Documentos
        </p>
    </div>
</div>
<div class="container" *ngIf="loaded">
    <aliv-corte [type]="'type-2'" [message]="flow.subtitle"></aliv-corte>
    <h2 *ngIf="flujo != 2 && flujo != 4 && flujo != 6 && flujo != 5">
        En los términos establecidos por el artículo 79 de la Ley 2294 de 2023, el Decreto 0533 del 29 de abril de 2024 y la Resolución 2200 del 7 de junio de 2024, sus modificaciones y demás normas que regulen la materia, presento bajo la gravedad del juramento, la postulación al Incentivo a la Creación y Permanencia de Nuevos Empleos Formales bajo el cual, previo cumplimiento de requisitos, se otorgará un aporte monetario de naturaleza estatal, mensualmente y hasta por veinticuatro (24) veces por cada empleado, con el objeto generar nuevos empleos formales en el país mediante la contratación de nuevos trabajadores durante un término no inferior a seis (06) meses.
    </h2>
    <h2 *ngIf="flujo == 4">
        En los términos establecidos por el artículo 79 de la Ley 2294 de 2023, el Decreto 1736 de 2023 y la Resolución 4451 de 2023 y sus
        modificaciones, presento, bajo la gravedad del juramento, la postulación al Apoyo para la generación de empleo
        para jóvenes dentro de la Estrategia Sacúdete, bajo el cual, previo cumplimiento de requisitos, se otorgará un
        aporte monetario de naturaleza estatal, mensualmente y hasta por doce veces, con el objeto de generar empleo
        para la población joven del país.
    </h2>
    <h2 *ngIf="flujo == 5">
        En los términos establecidos por el artículo 79 de la Ley 2294 de 2023, el Decreto 1736 de 2023 y la Resolución 4451 de 2023, expedida por el Ministerio de Hacienda y Crédito Público, presento bajo la gravedad del juramento la postulación al Programa de apoyo a empresas afectadas por el paro nacional, y certifico la veracidad de la información contenida en el presente formulario, que me permitirá, previo cumplimiento de los requisitos, acceder al aporte monetario de naturaleza estatal, con el fin de reactivar la economía y apoyar a las empresas afectadas por el paro nacional.
    </h2>
    <h2 *ngIf="flujo == 6">
        En los términos establecidos por el artículo 79 de la Ley 2294 de 2023, el Decreto 0533 del 29 de abril de 2024 y la Resolución 2200 del 7 de junio de 2024, sus modificaciones y demás normas que regulen la materia, presento bajo la gravedad del juramento, la postulación al Incentivo a la Creación y Permanencia de Nuevos Empleos Formales bajo el cual, previo cumplimiento de requisitos, se otorgará un aporte monetario de naturaleza estatal, mensualmente y hasta por veinticuatro (24) veces por cada empleado, con el objeto generar nuevos empleos formales en el país mediante la contratación de nuevos trabajadores durante un término no inferior a seis (06) meses.   </h2>
    <h1>
        Por favor ingresa los siguientes datos para registrar tu solicitud:
    </h1>
    <form [formGroup]="Form">
        <div class="column-input">
            <p class="text-data">
                Los campos marcados con (*) son obligatorios
            </p>

            <div class="title-section" *ngIf="flujo != 4 && flujo != 6 && flujo != 0 && flujo != 5">
                <div>
                    PROGRAMA AL QUE DESEA APLICAR
                </div>
            </div>
            <p class="text-data" *ngIf="flujo != 4 && flujo != 6 && flujo != 0 && flujo != 5">
                Seleccione la opción según su necesidad *
            </p>

            <div class="radiobuttons" *ngIf="flujo != 4 && flujo != 6 && flujo != 0 && flujo != 5">

                <div class="container-payment-radio">
                    <div class="payment-radio-group">
                        <div class="payment-radio-button">
                            <div [id]="'rb-0'" class="radio-button-uncheck"
                                [ngClass]="checkFlujo == 0 ? 'radio-button-check' : 'radio-button-uncheck'"
                                (click)="changeRadioButtonFlujo(0)"></div>
                            <p>Únicamente PAEF</p>
                        </div>
                        <div class="payment-radio-button">
                            <div [id]="'rb-0'" class="radio-button-uncheck"
                                [ngClass]="checkFlujo == 4 || flujo == 6 ? 'radio-button-check' : 'radio-button-uncheck'"
                                (click)="changeRadioButtonFlujo(4)"></div>
                            <p>Únicamente Sacudete</p>
                        </div>
                        <div class="payment-radio-button payment-radio-button-right">
                            <div [id]="'rb-1'" class="radio-button-uncheck"
                                [ngClass]="checkFlujo == 1 ? 'radio-button-check' : 'radio-button-uncheck'"
                                (click)="changeRadioButtonFlujo(1)"></div>
                            <p>Únicamente PAP</p>
                        </div>
                        <div class="payment-radio-button payment-radio-button-right">
                            <div [id]="'rb-2'" class="radio-button-uncheck"
                                [ngClass]="checkFlujo == 2 ? 'radio-button-check' : 'radio-button-uncheck'"
                                (click)="changeRadioButtonFlujo(2)"></div>
                            <p>Tanto PAEF como PAP</p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="textAlerOne" *ngIf="flujo != 4 && flujo != 6 && flujo != 0 && flujo != 5">
                Si marcó “Únicamente PAP” o “Tanto PAEF como PAP”, recuerde anexar el formato “Intención de ser
                beneficiario del Programa de Apoyo para el Pago de la Prima de Servicios – PAP
            </p>

            <div class="title-section">
                <div>
                    INFORMACIÓN GENERAL DE LA POSTULACIÓN
                </div>
            </div>

            <div class="input-group" *ngIf="flujo == 4 || flujo == 6">
                <p>Fecha de solicitud</p>
                <input formControlName="date_request" name="date_request" type="text" disabled="true">
            </div>

            <!-- Start Bloqueados -->
            <div class="input-group" *ngIf="flujo == 0 || flujo == 5">
                <p>Mes de postulación *</p>
                <select formControlName="phase_month" id="phase_month"
                    class="forms-type form-control font-20 roboto black-font noFocus" disabled="true">
                    <option *ngFor="let docTyp of mesesTypes" [value]="docTyp.id" selected>
                        {{docTyp.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 0">
                <p>Fecha de radicado *</p>
                <input formControlName="date_request" name="date_request" type="text" disabled="true">
            </div>

            <div class="input-group" *ngIf="flujo == 4 || flujo == 6">
                <p>Ciclo de postulación</p>
                <select formControlName="phase_month" id="phase_month"
                    class="forms-type form-control font-20 roboto black-font noFocus" disabled="true">
                    <option *ngFor="let docTyp of mesesTypes" [value]="docTyp.id" selected>
                        {{docTyp.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 1 ">
                <p>Número de primas a solicitar *</p>
                <input [ngClass]="!Form.controls.numeroPrimas.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="numeroPrimas" name="numeroPrimas" type="text" placeholder="ingrese el número"
                    maxlength=8 (input)="formatNaturalNumber($event.target.value,
                                    'numeroPrimas')">
                <span *ngIf="!Form.controls.numeroPrimas.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group" *ngIf="flujo == 2 ">
                <p>Número de primas a solicitar *</p>
                <input [ngClass]="!Form.controls.numeroPrimas.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="numeroPrimas" name="numeroPrimas" type="text" placeholder="ingrese el número"
                    maxlength=3 (input)="formatNaturalNumber($event.target.value,
                                    'numeroPrimas')">
                <span *ngIf="!Form.controls.numeroPrimas.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <br *ngIf="flujo != 2 && flujo != 5">

            <!-- Start Bloqueados -->
            <div class="input-group">
                <p>Tipo de identificación *</p>
                <select formControlName="tipoIdEmpresa" id="tipoIdEmpresa"
                    class="forms-type form-control font-20 roboto black-font noFocus" disabled="true">
                    <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                        {{docTyp.name}}
                    </option>
                </select>
            </div>

            <div class="input-group">
                <p>Número de identificación</p>
                <p class="anotacion"> (Sin código de verificación)</p>
                <input formControlName="numIdEmpresa" name="numIdEmpresa" type="text" disabled="true">
            </div>

            <!-- Pap Agro -->
            <div class="input-group" *ngIf="flujo == 2">
                <p>Nombre persona natural *</p>
                <input [ngClass]="!Form.controls.razonSocial.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="razonSocial" name="razonSocial" type="text" placeholder="Nombre" maxlength=50
                    (input)="formatRazonSocial($event.target.value,
                                    'razonSocial')">
                <!-- <span *ngIf="!Form.controls.razonSocial.valid && (check1 || check2)">
                    *Campo obligatorio
                </span> -->
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>Sexo *</p>
                <select [ngClass]="!Form.controls.sexo.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="sexo" id="sexo" class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeGender" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>Edad *</p>
                <input
                    [ngClass]="(!Form.controls.edad.valid || (intP(Form.controls.edad.value) < 18)) && (check1 || check2) ? 'error' : ''"
                    formControlName="edad" name="edad" type="text" placeholder="Edad" maxlength=3
                    (input)="formatEdad($event.target.value,'edad')">
                <span *ngIf="!Form.controls.edad.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>Nombre del predio o finca *</p>
                <input [ngClass]="!Form.controls.namePredio.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="namePredio" name="namePredio" type="text" placeholder="Nombre" maxlength=50
                    (input)="formatRazonSocial($event.target.value,'namePredio')">
                <p class="anotacion2">(sin caracteres especiales, ni tildes)</p>
                <span class="span2" *ngIf="!Form.controls.namePredio.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>¿Usted es victima del conflicto armado? *</p>
                <select [ngClass]="!Form.controls.conflicArmy.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="conflicArmy" id="conflicArmy"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeNoYes" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>¿Usted es madre cabeza de familia? *</p>
                <select [ngClass]="!Form.controls.mother.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="mother" id="mother"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeNoYes" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>
            <!------------------------->


            <!-- End Bloqueados -->
            <div class="input-group" *ngIf="flujo != 2">
                <p>Nombre o razón social *</p>
                <input [ngClass]="!Form.controls.razonSocial.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="razonSocial" name="razonSocial" type="text" placeholder="Nombre razón social"
                    maxlength=150 (input)="formatRazonSocial($event.target.value,
                                    'razonSocial')">
                <p class="anotacion2">(sin caracteres especiales, ni tildes)</p>
                <span class="razonvalidator" *ngIf="!Form.controls.razonSocial.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group">
                <p>Codigo CIIU *</p>
                <select formControlName="ciiu" name="ciiu" id="ciiu"
                    [ngClass]="!Form.controls.ciiu.valid && (check1 || check2) ? 'error' : ''"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let ciiu of ciiuCodes" [value]="ciiu.id" selected>
                        {{ciiu.id}}
                    </option>
                </select>
                <span *ngIf="!Form.controls.ciiu.valid && (check1 || check2)" class="spanSelect">
                    *Campo obligatorio
                </span>
            </div>

            <div class="question" *ngIf="validShowQuestion() && flujo != 2 && flujo != 4 && flujo != 6 && flujo != 5">
                <p>
                    Sólo para los postulantes clasificados en alguna de las actividades establecidas en el literal b del
                    artículo 1 de la Resolución 2162 del 13 de noviembre de 2020: ¿Ha venido desarrollando dicha
                    actividad, como actividad principal, al menos desde el 06
                    de mayo de 2020 y hasta el mes anterior a la presente postulación de forma ininterrumpida?
                </p>
                <div class="buttons-res">
                    <button (click)="validDataPre('SI')" id="r1s" [ngClass]="validBots()">
                        Sí
                    </button>
                    <button (click)="validDataPre('NO')" id="r1n" [ngClass]="validBotn()">
                        No
                    </button>
                </div>
            </div>

            <!------------------------start agro--------------->
            <div class="input-group" *ngIf="flujo == 2">
                <p>Pertenencia Étnica *</p>
                <select [ngClass]="!Form.controls.etnia.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="etnia" id="etnia"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeNoYes" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 2 && Form.controls.etnia.value == 'SI'">
                <p>¿Cuál? *</p>
                <input [ngClass]="Form.controls.etniaWhich.value == '' && (check1 || check2) ? 'error' : ''"
                    formControlName="etniaWhich" name="etniaWhich" type="text" placeholder="" maxlength=50 (input)="formatRazonSocial($event.target.value,
                                    'etniaWhich')">
                <span *ngIf="Form.controls.etniaWhich.value == '' && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <br *ngIf="flujo == 2">

            <div class="input-group" *ngIf="flujo == 2">
                <p>¿Presenta usted alguna discapacidad? *</p>
                <select [ngClass]="!Form.controls.discapacidad.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="discapacidad" id="discapacidad"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeNoYes" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 2 && Form.controls.discapacidad.value == 'SI'">
                <p>¿Cuál? *</p>
                <input [ngClass]="Form.controls.discapacidadWhich.value == '' && (check1 || check2) ? 'error' : ''"
                    formControlName="discapacidadWhich" name="discapacidadWhich" type="text" placeholder="" maxlength=50
                    (input)="formatRazonSocial($event.target.value,
                                    'discapacidadWhich')">
                <span *ngIf="Form.controls.discapacidadWhich.value == '' && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <br *ngIf="flujo == 2">

            <div class="input-group" *ngIf="flujo == 2">
                <p>¿Pertenece a alguna asociación-agremiación? *</p>
                <select [ngClass]="!Form.controls.agremiacion.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="agremiacion" id="agremiacion"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeNoYes" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class="input-group" *ngIf="flujo == 2 && Form.controls.agremiacion.value == 'SI'">
                <p>¿Cuál? *</p>
                <input [ngClass]="Form.controls.agremiacionWhich.value == '' && (check1 || check2) ? 'error' : ''"
                    formControlName="agremiacionWhich" name="agremiacionWhich" type="text" placeholder="" maxlength=50
                    (input)="formatRazonSocial($event.target.value,
                                    'agremiacionWhich')">
                <span *ngIf="Form.controls.agremiacionWhich.value == '' && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <br>
            <!-------------------end agro------------------------>

            <!------------------------start sustitución--------------->
            <p class="parrafo-0" *ngIf="flujo == 0 || flujo == 5 || (flujo == 4 && showSus)">
                <b>
                    <u>Si usted hace parte de una sustitución patronal o de empleador:</u>
                    Tipo y número de identificación de las personas naturales o jurídicas 
                    que hacen parte de la sustitución patronal o de empleador, en calidad 
                    de empleador sustituido y nuevo empleador.  
                </b>
            </p>

            <p class="parrafo-0" *ngIf="(flujo == 6 && showSus)">
                <b>
                    <u>Si usted hace parte de una sustitución patronal o de empleador ocurrida después del mes de mayo de 2023:
                        Tipo y número de identificación de los empleadores de los que recibió empleados, en virtud del proceso de sustitución patronal o de empleado..</u>
                </b>
            </p> 
            
            <p class="parrafo-1" *ngIf="flujo == 0 || flujo == 5 || (flujo == 4 && showSus)">Nota: Ninguno de los documentos de identificación de personas naturales o jurídicas aquí listadas debe corresponder a la identificación del postulante o a la de sus empleados.</p>
            
            <div *ngIf="flujo == 0 || flujo == 5 || (flujo == 4 || flujo == 6 && showSus)">
                <div *ngFor="let item of sustitucionArray; let i=index">
                    <div class="input-group">
                        <p>Tipo de identificación</p>
                        <select [formControlName]=" 'cuSusTipoId'+ (i+1)" [id]=" 'cuSusTipoId'+ (i+1)"
                            class="forms-type form-control font-20 roboto black-font noFocus"
                            [ngClass]=" !Form.controls[ 'cuSusTipoId'+ (i+1)].valid && (check1 || check2) ? 'error' : ''"
                            (ngModelChange)="onChangesPat( 'cuSusNumId'+ (i+1))">
                            <option value="" disabled selected>Seleccione...</option>
                            <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                                {{docTyp.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-group">
                        <p>Número de identificación</p>
                        <p class="anotacion"> (NIT sin código de verificación)</p>
                        <input [ngClass]=" !Form.controls[ 'cuSusNumId'+ (i+1)].valid && (check1 || check2) ? 'error' : ''"
                            [formControlName]=" 'cuSusNumId'+ (i+1)" [name]=" 'cuSusNumId'+ (i+1)" type="text"
                            placeholder="Ingrese el número" (input)="formatIdentityType($event.target.value,
                                    'cuSusNumId'+ (i+1), 'cuSusTipoId'+ (i+1))" minlength=6 maxlength=15 disabled>
                        <span *ngIf="!Form.controls[ 'cuSusNumId'+ (i+1)].valid && (check1 || check2)">
                            Campo incompleto
                        </span>
                    </div>

                </div>
            </div>
            <!-------------------end sustitución------------------------>

            <!------------------------start consorcio--------------->
            <p class="parrafo-0" *ngIf="this.selectedOption==3 && this.flujo != 4 && flujo != 6">
                <b><u>Si usted hace parte de un consorcio o unión temporal:</u> Tipo y número de identificación de las personas naturales o jurídicas que hacen parte del consorcio o unión temporal.</b>
            </p>

            <p class="parrafo-1" *ngIf="this.selectedOption==3 && (flujo == 5 || flujo == 0)">Nota: Ninguno de los documentos de identificación de personas naturales o jurídicas aquí listadas debe corresponder a la identificación del postulante o a la de sus empleados.</p>
            
            <div *ngIf="this.selectedOption==3 && this.flujo != 4 && flujo != 6">
                <div *ngFor="let item of consorcioArray; let i=index">

                    <div *ngIf="i==0" class="input-group">
                        <p>Tipo de identificación *</p>
                        <select [ngClass]="!Form.controls.cuTipoId1.valid && (check1 || check2)? 'error' : ''"
                            [formControlName]=" 'cuTipoId'+ (i+1)" [id]=" 'cuTipoId'+ (i+1)"
                            class="forms-type form-control font-20 roboto black-font noFocus">
                            <option value="" disabled selected>Seleccione...</option>
                            <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                                {{docTyp.name}}
                            </option>
                        </select>
                        <span *ngIf="!Form.controls.cuTipoId1.valid && (check1 || check2)" class="spanSelect">
                            *Campo obligatorio
                        </span>
                    </div>

                    <div *ngIf="i==0" class="input-group">
                        <p>Número de identificación *</p>
                        <p class="anotacion"> (NIT sin código de verificación)</p>
                        <input [ngClass]="!Form.controls.cuNumId1.valid && (check1 || check2)? 'error' : ''"
                            [formControlName]=" 'cuNumId'+ (i+1)" [name]=" 'cuNumId'+ (i+1)" type="text"
                            placeholder="Ingrese el número" (input)="formatNumberAndChar($event.target.value,
                                    'cuNumId'+ (i+1) )" maxlength=15>
                        <span style="margin-top:50px !important;"
                            *ngIf="!Form.controls.cuNumId1.valid && (check1 || check2)" class="spanSelect">
                            *Campo obligatorio
                        </span>
                    </div>

                    <div *ngIf="i==1" class="input-group">
                        <p>Tipo de identificación *</p>
                        <select [ngClass]="!Form.controls.cuTipoId2.valid && (check1 || check2)? 'error' : ''"
                            [formControlName]=" 'cuTipoId'+ (i+1)" [id]=" 'cuTipoId'+ (i+1)"
                            class="forms-type form-control font-20 roboto black-font noFocus">
                            <option value="" disabled selected>Seleccione...</option>
                            <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                                {{docTyp.name}}
                            </option>
                        </select>
                        <span *ngIf="!Form.controls.cuTipoId2.valid && (check1 || check2)" class="spanSelect">
                            *Campo obligatorio
                        </span>
                    </div>

                    <div *ngIf="i==1" class="input-group">
                        <p>Número de identificación *</p>
                        <p class="anotacion"> (NIT sin código de verificación)</p>
                        <input [ngClass]="!Form.controls.cuNumId2.valid && (check1 || check2)? 'error' : ''"
                            [formControlName]=" 'cuNumId'+ (i+1)" [name]=" 'cuNumId'+ (i+1)" type="text"
                            placeholder="Ingrese el número" (input)="formatNumberAndChar($event.target.value,
                                    'cuNumId'+ (i+1) )" maxlength=15>
                        <span style="margin-top:50px !important;"
                            *ngIf="!Form.controls.cuNumId2.valid && (check1 || check2)" class="spanSelect">
                            *Campo obligatorio
                        </span>
                    </div>

                    <div *ngIf="i !=0 && i !=1" class="input-group">
                        <p>Tipo de identificación</p>
                        <select [formControlName]=" 'cuTipoId'+ (i+1)" [id]=" 'cuTipoId'+ (i+1)"
                            class="forms-type form-control font-20 roboto black-font noFocus">
                            <option value="" disabled selected>Seleccione...</option>
                            <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                                {{docTyp.name}}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="i !=0 && i !=1" class="input-group">
                        <p>Número de identificación</p>
                        <p class="anotacion"> (NIT sin código de verificación)</p>
                        <input [formControlName]=" 'cuNumId'+ (i+1)" [name]=" 'cuNumId'+ (i+1)" type="text"
                            placeholder="Ingrese el número"
                            (input)="formatNumberAndChar($event.target.value, 'cuNumId'+ (i+1) )" maxlength=15>
                    </div>

                </div>
            </div>
            <!-------------------end consorcio------------------------>

            <div class="title-section">
                <div>
                    UBICACIÓN
                </div>
            </div>

            <div class=" input-group">
                <p>Dirección *</p>
                <input [ngClass]="!Form.controls.direccion.valid && (check1 || check2)? 'error' : ''"
                    formControlName="direccion" name="direccion" type="text" placeholder="Ingresa la dirección"
                    maxlength=150 (input)="formatDireccion($event.target.value,
                                    'direccion')">
                <p class="anotacion2">(sin caracteres especiales, tildes, guiones[-] , signo número[#])</p>
                <span class="span2" *ngIf="!Form.controls.direccion.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group">
                <p>Departamento *</p>
                <select formControlName="departamento" id="departamento"
                    [ngClass]="!Form.controls.departamento.valid && (check1 || check2)? 'error' : ''"
                    class="forms-type form-control font-20 roboto black-font noFocus" (ngModelChange)="onChangesDep()"
                    [attr.disabled]="this.disableDepartament ? '' : null">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let dep of departments" [value]="dep.id" selected>
                        {{dep.name}}
                    </option>
                </select>
                <span *ngIf="!Form.controls.departamento.valid && (check1 || check2)" class="spanSelect">
                    *Campo obligatorio
                </span>
            </div>
            <p></p>

            <div class="input-group">
                <p>Municipio * </p>
                <select formControlName="municipio" id="municipio" name="municipio"
                    [ngClass]="!Form.controls.municipio.valid && (check1 || check2)? 'error' : ''"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let city of cities" [value]="city.id" selected>
                        {{city.name}}
                    </option>
                </select>
                <span *ngIf="!Form.controls.municipio.valid && (check1 || check2)" class="spanSelect">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group" *ngIf="flujo == 2">
                <p>Vereda *</p>
                <input [ngClass]="!Form.controls.vereda.valid && (check1 || check2)? 'error' : ''"
                    formControlName="vereda" name="vereda" type="text" placeholder="Ingresa la dirección" maxlength=50
                    (input)="formatDireccion($event.target.value,
                                    'vereda')">
                <span *ngIf="!Form.controls.vereda.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="title-section">
                <div>
                    CONTACTO
                </div>
            </div>

            <div class=" input-group" *ngIf="flujo != 2">
                <p>Teléfono Fijo *</p>
                <input [ngClass]="!Form.controls.conFijo.valid && (check1 || check2)? 'error' : ''"
                    formControlName="conFijo" name="conFijo" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el número" (input)="formatNumber($event.target.value, 'conFijo')" minlength=7
                    maxlength=20>
                <span *ngIf="!Form.controls.conFijo.valid && (check1 || check2)">
                    Campo incompleto
                </span>
            </div>

            <div class=" input-group" *ngIf="flujo == 2">
                <p>Teléfono Fijo</p>
                <input formControlName="conFijo" name="conFijo" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el número" (input)="formatNumber($event.target.value, 'conFijo')" minlength=7
                    maxlength=20>
                <span *ngIf="!Form.controls.conFijo.valid && (check1 || check2)">
                    Campo incompleto
                </span>
            </div>

            <div class=" input-group">
                <p>Celular *</p>
                <input [ngClass]="!Form.controls.conCelular.valid && (check1 || check2)? 'error' : ''"
                    formControlName="conCelular" name="conCelular" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el número" (input)="formatNumber($event.target.value,
                                    'conCelular')" maxlength=10 minlength=10>
                <span *ngIf="!Form.controls.conCelular.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group">
                <p>Correo electrónico *</p>
                <input (input)="formatEmail($event.target.value, 'conEmail')"
                    [ngClass]="!Form.controls.conEmail.valid && (check1 || check2)? 'error' : ''"
                    formControlName="conEmail" 
                    name="conEmail" 
                    (ngModelChange)="verifiFacs()" 
                    type="text" 
                    placeholder="Ingresa el correo" 
                    maxlength=150>
                <span *ngIf="!Form.controls.conEmail.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class="input-group">
                <p>Confirme el correo electrónico *</p>
                <input (input)="formatEmail($event.target.value, 'conEmail2')"
                    [ngClass]="!compareCorreo1() ? 'error' : ''" formControlName="conEmail2" name="conEmail2"
                    (ngModelChange)="verifiFacs()" type="text" placeholder="Ingresa el correo" maxlength=150>
                <span *ngIf="!compareCorreo1()">
                    *Campo obligatorio
                </span>
            </div>

            <p class="anotacion3"
                [ngClass]="{ 'anotacion3-high': (!Form.controls.conEmail.valid && (check1 || check2)) || !compareCorreo1()}">
                (POR FAVOR VERIFIQUE EL CORREO INGRESADO. Las novedades de su solicitud serán notificadas a través de
                este correo electrónico.)</p>

            <!------------REPRESENTANTE LEGAL--------------->
            <div class="title-section" *ngIf="this.selectedOption != 1">
                <div>
                    INFORMACIÓN DEL REPRESENTANTE LEGAL
                </div>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1">
                <p>Nombres *</p>
                <p class="anotacion"> (sin caracteres especiales, ni tildes)</p>
                <input [ngClass]="!Form.controls.rlNombre.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlNombre" name="rlNombre" (ngModelChange)="verifiFacs()" type="text" placeholder="Ingresa los nombres
                                   " maxlength=50 (input)="formatCharAndSpace($event.target.value, 'rlNombre')">
                <span *ngIf="!Form.controls.rlNombre.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1">
                <p>Apellidos *</p>
                <p class="anotacion"> (sin caracteres especiales, ni tildes)</p>
                <input [ngClass]="!Form.controls.rlApellido.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlApellido" name="rlApellido" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa los apellidos
                                   " maxlength=50 (input)="formatCharAndSpace($event.target.value, 'rlApellido')">
                <span *ngIf="!Form.controls.rlApellido.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1">
                <p>Tipo de documento de identidad *</p>
                <select [ngClass]="!Form.controls.rlTipoDoc.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlTipoDoc" id="rlTipoDoc"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let docTyp of docTypesRl" [value]="docTyp.id">
                        {{docTyp.name}}
                    </option>
                </select>
                <span *ngIf="!Form.controls.rlTipoDoc.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1">
                <p>Número de documento de identidad *</p>
                <input [ngClass]="!Form.controls.rlNumDoc.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlNumDoc" name="rlNumDoc" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el número" maxlength=10 (input)="formatNumberAndChar($event.target.value,
                                    'rlNumDoc')">
                <span *ngIf="!Form.controls.rlNumDoc.valid && (check1 || check2)">
                    Campo incompleto
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1 && (this.flujo != 4 && flujo != 6 && this.flujo != 0 && this.flujo != 5)">
                <p>Fecha de expedición *: </p>
                <input [ngClass]="!Form.controls.rlfechaExp.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlfechaExp" name="rlfechaExp" (ngModelChange)="verifiFacs()" type="date">
                <span *ngIf="!Form.controls.rlfechaExp.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1 && (this.flujo != 4 && flujo != 6 && this.flujo != 0 && this.flujo != 5)">
                <p>Cargo *</p>
                <p class="anotacion"> (Como aparece en Cámara y comercio)</p>
                <input [ngClass]="!Form.controls.rlCargo.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlCargo" name="rlCargo" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el cargo" maxlength=50>
                <span *ngIf="!Form.controls.rlCargo.valid && (check1 || check2)">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group" *ngIf="this.selectedOption != 1">
                <p>Celular *</p>
                <input [ngClass]="!Form.controls.rlCelular.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlCelular" name="rlCelular" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el número" (input)="formatNumber($event.target.value,
                                    'rlCelular')" maxlength=10 minlength=10>
                <span *ngIf="!Form.controls.rlCelular.valid && (check1 || check2)">
                    Debe ingresar 10 dígitos
                </span>
            </div>

            <div class="input-group" *ngIf="this.selectedOption != 1">
                <p>Correo electrónico *</p>
                <input (input)="formatEmail($event.target.value, 'rlEmail')"
                    [ngClass]="!Form.controls.rlEmail.valid && (check1 || check2) ? 'error' : ''"
                    formControlName="rlEmail" name="rlEmail" (ngModelChange)="verifiFacs()" type="text"
                    placeholder="Ingresa el correo" maxlength=150>
                <span *ngIf="!Form.controls.rlEmail.valid && (check1 || check2)">
                    Debe ingresar un email válido
                </span>
            </div>
            <!--------------------------->
            <div class="title-section">
                <div>
                    DATOS CUENTA BANCO DE BOGOTA
                </div>
                <p class="parrafo">
                    Debe registrar al menos una (1) cuenta bancaria. Para sociedades de economía mixta, ingrese una
                    cuenta que no sea maestra ni tenga restricciones para abonos y/o dispersiones.
                </p>
            </div>

            <div class=" input-group">
                <p>Tipo de cuenta *</p>
                <select [ngClass]="!Form.controls.tipoCuentaBanc.valid && (check1 || check2)? 'error' : ''"
                    formControlName="tipoCuentaBanc" id="tipoCuentaBanc"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeAccount" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
                <span *ngIf="!Form.controls.tipoCuentaBanc.valid && (check1 || check2)" class="spanSelect">
                    *Campo obligatorio
                </span>
            </div>

            <div class=" input-group">
                <p>Número de cuenta *</p>
                <p class="anotacion">(9 dígitos incluídos los ceros)</p>
                <input
                    [ngClass]="(!Form.controls.numCuentaBanc.valid || verificate(0)) && (check1 || check2)? 'error' : ''"
                    formControlName="numCuentaBanc" (input)="formatNumber($event.target.value, 'numCuentaBanc')" name="numCuentaBanc
                                   " (ngModelChange)="verifiFacs()" type="text" placeholder="Ingresa el número"
                    minlength=9 maxlength=9>
                <!-- <span class="Comunquese-con-su-o">Comuníquese con su oficina o gerente para validar el estado de su producto.</span> -->
                <span *ngIf="(!Form.controls.numCuentaBanc.valid || verificate(0)) && (check1 || check2)">
                    {{verificateBillError[0]}}
                </span>
            </div>

            <div class=" input-group">
                <p>Confirme el número de cuenta *</p>
                <input [ngClass]=" !compareCuenta1()? 'error' : ''" formControlName="numCuentaBancC"
                    (input)="formatNumber($event.target.value, 'numCuentaBancC')" name="numCuentaBancC"
                    (ngModelChange)="verifiFacs()" type="text" placeholder="Ingresa el número" minlength=9 maxlength=9>
                <span *ngIf="(Form.controls.numCuentaBancC.value !=Form.controls.numCuentaBanc.value)">
                    *Verifique que la información coincida
                </span>
            </div>

            <!-- New -->
            <br>
            <div class=" input-group">
                <p>Tipo de cuenta 2</p>
                <select formControlName="tipoCuentaBanc2" id="tipoCuentaBanc2"
                    class="forms-type form-control font-20 roboto black-font noFocus">
                    <option value="" disabled selected>Seleccione...</option>
                    <option *ngFor="let typeA of typeAccount" [value]="typeA.id">
                        {{typeA.name}}
                    </option>
                </select>
            </div>

            <div class=" input-group">
                <p>Número de cuenta 2</p>
                <p class="anotacion">(9 dígitos incluídos los ceros)</p>
                <input
                    [ngClass]="!Form.controls.numCuentaBanc2.valid || verificate(1) && (check1 || check2)? 'error' : ''"
                    formControlName="numCuentaBanc2" (input)="formatNumber($event.target.value, 'numCuentaBanc2')" name="numCuentaBanc2
                                   " (ngModelChange)="verifiFacs()"
                    (input)="formatNumber($event.target.value, 'numCuentaBanc2')" type="text"
                    placeholder="Ingresa el número" minlength=9 maxlength=9>
                <span *ngIf="!Form.controls.numCuentaBanc2.valid || verificate(1)">
                    {{verificateBillError[1]}}
                </span>
            </div>

            <div class=" input-group">
                <p>Confirme el número de cuenta 2</p>
                <input [ngClass]="!Form.controls.numCuentaBanc2C.valid || !compareCuenta2()? 'error' : ''" maxlength=9
                    formControlName="numCuentaBanc2C" (input)="formatNumber($event.target.value, 'numCuentaBanc2C')"
                    name="numCuentaBanc2C
                                   " (ngModelChange)="verifiFacs()" type="text" placeholder="Ingresa el número"
                    minlength=9 maxlength=9>
                <span *ngIf="(Form.controls.numCuentaBanc2C.value !=Form.controls.numCuentaBanc2.value)">
                    Verifique que la información coincida
                </span>
            </div>

            <!-- finish -->
            <div *ngIf="flujo != 0 && flujo != 4 && flujo != 6 && flujo != 5">
                <div class="title-section">
                    <div>
                        INFORMACIÓN PARA SUBSIDIO
                    </div>
                    <p class="parrafo">
                        Con el objetivo de que la UGPP pueda adelantar la veriﬁcación de requisitos señalada en el
                        artículo 7 de la Resolución 2162, certiﬁco que se encuentran pagados los aportes en la(s)
                        Planilla Integrada de Liquidación de Aportes (PILA) tipo E, (tipo X para
                        empresas en liquidación) de los trabajadores objeto del subsidio, así:
                    </p>
                </div>

                <div class="title-section">
                    <div class="div2">
                        Número de Planilla Integrada de Liquidación de Aportes (PILA) de {{data.planilla}} *
                    </div>
                    <p class="anotacion-2">Las planillas a ingresar deben corresponder al mes anterior del mes de la
                        postulación (Ej. Mes de postulación Diciembre, los números de planilla deben corresponder al mes
                        de Noviembre). Si tiene números adicionales puede ingresarlos
                        separados de un Guión. (Ej. 123456-456789-123789).El máximo de caracteres permitidos es de 250
                        (35 planillas).</p>
                </div>
                <p class="textAlerTwo">
                    Si marcó "Únicamente PAP” ingrese la planillas del mes de diciembre en la siguiente casilla
                    separadas por guiones. Si marcó "Únicamente PAEF” ingrese las planillas del mes de febrero en la
                    siguiente casilla separadas por guiones. Si marcó “tanto PAEF
                    con PAP”, recuerde ingresar los números de planillas de los meses de diciembre y febrero en la
                    siguiente casilla separadas por guiones.
                </p>
                <div class=" input-group" style="width: 90%;">
                    <input [ngClass]="!Form.controls.numPlanilla.valid && (check1 || check2)? 'error' : ''"
                        formControlName="numPlanilla" name="numPlanilla" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanilla')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanilla.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
            </div>

            <div *ngIf="flujo == 2">
                <div class="title-section">
                    <div>
                        INFORMACIÓN PARA SUBSIDIO
                    </div>
                    <p class="parrafo">
                        En desarrollo de lo establecido en el artículo 3 del Decreto Legislativo 803 de 2020, los
                        empleados sobre los que se reconoce el presente subsidio, corresponden a aquellos reportados en
                        la Planilla Integrada de Liquidación de Aportes (PILA) desde enero
                        hasta para el periodo de cotización del mes de junio, en las condiciones señaladas en el
                        artículo 3 del Decreto 803 de 2020.
                    </p>
                </div>

                <div class="title-section">
                    <div class="div2">
                        Número de Planilla Integrada de Liquidación de Aportes (PILA) de {{data.planilla}} *
                    </div>
                    <p class="anotacion-2">Con el objetivo de que la UGPP pueda adelantar la verificación de requisitos
                        señalada en la Resolución 178 de 2020, certifico que se encuentran pagados los aportes en la(s)
                        Planilla Integrada de Liquidación de Aportes (PILA) de los
                        trabajadores objeto del subsidio, así:
                        <br>Si tiene números adicionales por mes, puede ingresarlos separados de un Guión. (Ej.
                        123456-456789-123789). El máximo de caracteres permitidos es de 250 (35 planillas).
                    </p>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de enero 2020 No. *</p>
                    <input [ngClass]="!Form.controls.numPlanA1.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA1" name="numPlanA1" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA1')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA1.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de febrero 2020 No. * </p>
                    <input [ngClass]="!Form.controls.numPlanA2.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA2" name="numPlanA2" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA2')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA2.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de marzo 2020 No. *</p>
                    <input [ngClass]="!Form.controls.numPlanA3.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA3" name="numPlanA3" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA3')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA3.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de abril 2020 No. *</p>
                    <input [ngClass]="!Form.controls.numPlanA4.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA4" name="numPlanA4" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA4')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA4.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de mayo 2020 No. *</p>
                    <input [ngClass]="!Form.controls.numPlanA5.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA5" name="numPlanA5" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA5')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA5.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
                <div class=" input-group" style="width: 90%;" ng>
                    <p>Planilla(s) del periodo de junio 2020 No. *</p>
                    <input [ngClass]="!Form.controls.numPlanA6.valid && (check1 || check2)? 'error' : ''" maxlength=250
                        formControlName="numPlanA6" name="numPlanA6" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingrese el/los números de planilla(s)"
                        (input)="formatNumberPlanilla($event.target.value, 'numPlanA6')" maxlength=250>
                    <span *ngIf="!Form.controls.numPlanA6.valid && (check1 || check2)">
                        *Campo obligatorio
                    </span>
                </div>
            </div>

            <div class="espacio-sacudete">
                <div class="title-section" *ngIf="flujo == 4" style="height: 20px;">
                    <div class="div2">
                        Número de jóvenes adicionales contratados por los que se está solicitando el apoyo de este programa
                    </div>
                </div>
                <div class="input-group" *ngIf="flujo == 4" >
                    <p *ngIf="flujo == 4">Número de jóvenes</p>
                    <input (input)="formatNumberAndChar($event.target.value, 'numJovenes')"
                        formControlName="numJovenes" name="numJovenes" (ngModelChange)="verifiFacs()" type="text"
                        placeholder="Ingresa número" maxlength=10>
                </div>

                <div class="title-section" *ngIf="flujo == 6" style="height: 20px;">
                    <div class="div2">
                        Número de empleados nuevos adicionales contratados por los que se está solicitando el apoyo.
                    </div>
                </div>
                <div class="input-group" *ngIf="flujo == 6" >
                    <p>Número de empleados</p>
                    <input
                        (input)="formatNumber($event.target.value, 'numEmpleados')"
                        formControlName="numEmpleados" 
                        name="numEmpleados"
                        (ngModelChange)="verifiFacs()"
                        type="text"
                        placeholder="Ingresa número" 
                        maxlength=10>
                        <span *ngIf="isZero(Form.controls.numEmpleados.value) == '0' && (check1 || check2)">
                            *El Número de Empleados no puede ser 0
                        </span>
                </div>
            </div>

            <div class="check-group" *ngFor="let item of checksLabel; let i=index">
                <div class="con-1">
                    <!--div *ngIf="i == 0 ? !check1 : i == 1 ? !check2 : i == 2 ? !check3 : i == 3 ? !check5 : i == 4 ? !check4 : false" (click)="checkSwif(i+1)" id="terms-check-1"></div-->
                    <div *ngIf="i == 0 ? !check1 : i == 1 ? !check2 : i == 2 ? !check3 : i == 3 ? !check4 : i == 4 ? !check5 : false" (click)="checkSwif(i+1)" id="terms-check-1"></div>
                    <img *ngIf="i == 0 ? check1 : i == 1 ? check2 : i == 2 ? check3 : i == 3 ? check4 : i == 4 ? check5 : true" (click)="checkSwif(i+1)" id="terms-check-1" src="assets/img/generic/selected.svg" alt="Aceptar">
                    <!--img *ngIf="i == 0 ? check1 : i == 1 ? check2 : i == 2 ? check3 : i == 3 ? check5 : i == 4 ? check4 : true" (click)="checkSwif(i+1)" id="terms-check-1" src="assets/img/generic/selected.svg" alt="Aceptar"-->
                </div>
                <div class="con-2">
                    <p>{{item}}</p>
                </div>
            </div>
            <div class="button-data">
                <div>
                    <a class="btn-outline" (click)="goBack()">
                        Cancelar
                    </a>
                    <button [ngClass]="verifiFacs() ? 'btn-defauld': 'btn-unable'" [disabled]="!verifiFacs()" (click)="continuar()">
                        Continuar >
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>