<div class="barr">
    <div class="line">
        <div id="progreso" class="progresss"></div>
    </div>
    <div class="text">
        <p>
            01. Preguntas
        </p>
        <p class="opac">
            02. Formulario
        </p>
        <p class="opac">
            03. Documentos
        </p>
    </div>
</div>

<div class="container">
    <aliv-corte [type]="'type-1'" [message]="flow.subtitle"></aliv-corte>
    <h1>
        Por favor responda a las siguientes preguntas:
    </h1>

    <h6>Los campos marcados con (*) son obligatorios
    </h6>
    <h2 *ngIf="flujo == 2">
        De acuerdo con lo establecido en el Decreto Legislativo 803 de 2020, los
        requisitos manifestados a continuación son habilitantes para continuar
        con la postulación al beneficio del PAP para el Sector Agropecuario. Si
        no cumple con alguno de ellos, absténgase
        de continuar con esta solicitud. Su respuesta puede ser SI o NO
        dependiendo del caso.
    </h2>
    <p *ngIf="flujo == 2" class="s">
        El postulante al programa evidenció una disminución del veinte por
        ciento (20%) o más en sus ingresos, calculada con base en el método
        establecido por el Ministerio de Hacienda y Crédito Público, en el
        artículo 3º de la Resolución No. 1129 del 20 de mayo
        de 2020.
    </p>
    <p *ngIf="flujo == 2" class="p">
        Seleccione el método que definió para evaluar su disminución de
        ingresos. Escoja solo una de las opciones:
    </p>
    <div class="" *ngIf="flujo != 2">
        <div class="container-payment-radio" style="margin-top: 20px;">
            <p>Tipo de empresa *</p>
            <div class="payment-radio-group">
                <div class="payment-radio-button">
                    <div [id]="'rb-0'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[0], 'radio-button-disabled': isNaturePersonType()}" (click)="changeRadioButton(0)"></div>
                    <p>Persona <br>jurídica</p>
                </div>

                <div class="payment-radio-button">
                    <div [id]="'rb-1'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[1], 'radio-button-disabled': !isNaturePersonType()}" (click)="changeRadioButton(1)"></div>
                    <p>Persona <br>natural</p>
                </div>

                <div class="payment-radio-button" *ngIf="flujo != 6">
                    <div [id]="'rb-2'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[2]}" (click)="changeRadioButton(2)"></div>
                    <p>Entidad sin <br>ánimo de lucro</p>
                </div>

                <div class="payment-radio-button" *ngIf="flujo == 6">
                    <div [id]="'rb-2'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[2]}" (click)="changeRadioButton(2)"></div>
                    <p>Cooperativa de Trabajo Asociado (postuló en vigencia del Decreto 1376 de 2023)</p>
                </div>

                <div class="payment-radio-button">
                    <div [id]="'rb-3'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[3]}" (click)="changeRadioButton(3)"></div>
                    <p>Consorcio o <br>unión temporal</p>
                </div>

                <div class="payment-radio-button">
                    <div [id]="'rb-4'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[4]}" (click)="changeRadioButton(4)"></div>
                    <p>Patrimonio <br>Autónomo <br>Declarante</p>
                </div>

                <div class="payment-radio-button" *ngIf="flujo == 0">
                    <div [id]="'rb-5'" class="radio-button-uncheck" [ngClass]="{'radio-button-check' : checkRadio[5]}" (click)="changeRadioButton(5)"></div>
                    <p>Cooperativa de Trabajo Asociado (postuló en vigencia del Decreto 1376 de 2023)</p>
                </div>
            </div>
        </div>
        <p class="not-data" *ngIf="flujo == 4 || flujo == 6">
            Si es un tipo de empresa Sin Animo de Lucro seleccione la opción Persona Jurídica
        </p>
        <p class="not-data" *ngIf="flujo == 5">
            Si es un tipo de empresa Cooperativa seleccione la opción Persona jurídica
        </p><br>
        <h2>
            {{flow.resolucion}}
        </h2>
    </div>

    <div class="content">
        <h3 *ngIf="flujo == 0">{{messagesShow.resolucion}}</h3>
        <h5 *ngIf="flujo == 0">
            Seleccione el método que definió su organización para evaluar su disminución de ingresos. Escoja solo una de las opciones:
        </h5>

        <div *ngFor="let item of this.answers[this.selectedOption]; let i=index">
            <div [ngClass]="'question'">
                <p [ngClass]="validText(i) ? 'error-question' : ''" [innerHTML]="item['text']">
                </p>
                <div class="buttons-res">
                    <button (click)=validDatars(i) [id]="'r'+ i +'s'" [ngClass]="validBots(i)">
                        Sí
                    </button>
                    <button (click)=validDatarn(i) [id]="'r'+ i +'n'" [ngClass]="validBotn(i)">
                        No
                    </button>
                </div>
            </div>

            <a *ngIf="validText(i)">
                <div *ngIf="this.answers[this.selectedOption][i].respUser == 'si'">
                    {{messagesShow.respsi}}
                </div>
                <div *ngIf="this.answers[this.selectedOption][i].respUser == 'no'">
                    {{messagesShow.respno}}
                </div>
            </a>
            <h4 *ngIf="item['sub'] && flujo != 2">
                {{item['sub']}}
            </h4>         
        </div>
        <!--Pregunta lista desplegable flujo 6 (Nuevos Empleos)-->
        <div *ngIf="questionshow">
            <div class="question">
                <p>Seleccione el n&uacute;mero registrado en la casilla 53 del RUT que corresponda seg&uacute;n su r&eacute;gimen.</p>
            </div>
            <div class="question">
                <select [(ngModel)]="selectedDropDown" name="casilla53RUT">
                    <option disabled selected value="">Seleccione</option>
                    <option *ngFor="let option of casilla53RUTValues"
                        [ngValue]="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <!--FIN PARAMETRIZACION-->
    </div>
    <div class="button-data">
        <div>
            <button class="btn-outline" (click)="return()">
                Cancelar
            </button>
            <button (click)="validres()" [ngClass]="validButton() ? 'btn-defauld' : 'btn-unable'">
                Continuar >
            </button>
        </div>
    </div>
</div>