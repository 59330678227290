import { Component, OnInit } from '@angular/core';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../../core/services/login.service';
import { ModalService } from '../../../shared/services/modal.service';
import { saveRequest, element } from '../../../core/models/user-login.model';
import { Router } from '@angular/router';
import { StoreService } from '@core/service/store.service';
import { environment } from '@env/environment';
import { papForms } from '@app/core/models/pap-forms';
import { DynQuestion } from '@app/core/models/formularios';
import { paefForms } from '@app/core/models/paef-forms';
import { papaForms } from '@app/core/models/papa-forms';
import { sacudeteForms } from '@app/core/models/sacudete-forms';
import { paroForms } from '@app/core/models/paro-forms';
import { nuevoEmpleoForm } from '@app/core/models/nuevo-empleo-forms';
import { ArrayDefinitionService } from '@core/service/array.definition.service';
import { Console } from 'console';

@Component({
  selector: 'aliv-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {
  public loaded = false;
  public flow: any = {};
  public validateBill = [false, false];
  public verificateBill = [false, false];
  public verificateBillError = ['*Campo obligatorio', 'Número incompleto'];
  public data = environment;
  public check1: boolean = false;
  public check2: boolean = false;
  public check3: boolean = false;
  public check4: boolean = false;
  public check5: boolean = false;
  public checkArray = [false, false, false];
  public mon: any;
  public error: boolean = false;
  public Form: FormGroup;
  public checkRadio = new Array();
  public selectedOption: number = -1;
  public solicitudSelected: number = -1;
  public consorcioArray = new Array();
  public sustitucionArray = new Array();
  public planillasArray = new Array()
  public departments = new Array();
  public cities = new Array();
  public ciiuCodes = new Array();
  public municipio: any;
  public prima: String;
  public flujo: number;
  public disableDepartament = false;
  public checkFlujo: number;

  //deprecated
  // public docTypesRl: { name: string, id: string }[] = [
  //   { name: 'Cédula de ciudadanía', id: 'CC' },
  //   { name: 'Cédula de extranjería', id: 'CE' },
  //   { name: 'Pasaporte', id: 'PA' },
  //   { name: 'Salvoconducto de Permanencia', id: 'SC' },
  //   { name: 'Carnet diplomático', id: 'CD' }
  // ];
  // public docTypes: { name: string, id: string }[] = [
  //   { name: 'NIT Persona Jurídica', id: 'N' },    //OK
  //   { name: 'Cédula de ciudadanía', id: 'C' },    //OK
  //   { name: 'Cédula de extranjería', id: 'E' },   //OK
  //   { name: 'Pasaporte', id: 'P' },               //OK
  //   { name: 'NIT Persona Natural', id: 'L' },     //OK
  //   { name: 'NIT de Extranjería', id: 'I' },      //OK
  // ];
  // public typeAccount: { name: string, id: string }[] = [
  //   { name: 'Ahorros', id: 'AH' },
  //   { name: 'Corriente', id: 'CC' },
  // ];
  // public typeGender: { name: string, id: string }[] = [
  //   { name: 'Hombre', id: 'Hombre' },
  //   { name: 'Mujer', id: 'Mujer' },
  // ];
  // public typeNoYes: { name: string, id: string }[] = [
  //   { name: 'SI', id: 'SI' },
  //   { name: 'NO', id: 'NO' },
  // ];
  // public questionShow = [
  //   5511,
  //   5512,
  //   5530,
  //   5513,
  //   5520,
  //   5514,
  //   5519,
  //   5590,
  //   5611,
  //   5613,
  //   5612,
  //   5621,
  //   5629,
  //   5619,
  //   5630,
  //   7911,
  //   7912,
  //   7990,
  //   9101,
  //   9001,
  //   9002,
  //   9003,
  //   9004,
  //   9005,
  //   9006,
  //   9007,
  //   9008,
  //   9321,
  //   9329,
  //   9102,
  //   9103,
  //   9311,
  //   9312,
  //   9319,
  //   9200
  // ];

  public tipoEmpresaArray = [];
  public tipoEmpresaSAArray = []
  public stepFlujoArray = [];

  public docTypesRl = [];
  public docTypes = [];
  public typeAccount = [];
  public typeGender = [];
  public typeNoYes = [];
  public questionShow = [];
  public showSus = true;
  public mesesTypes = [];
  public checksLabel = [];

  constructor(
    private route: Router,
    private arrays: ArrayDefinitionService,
    private loginService: LoginService,
    private storeService: StoreService,
    private authServerProviderService: AuthServerProviderService,
    private modalService: ModalService,
  ) {
    this.flow = this.storeService.get("flow");
    this.checksLabel = this.flow.formChecks;
    this.mesesTypes = this.flow.months;

    this.tipoEmpresaArray = this.arrays.getTipoEmpresaArray();
    this.tipoEmpresaSAArray = this.arrays.getTipoEmpresaSAArray();
    this.stepFlujoArray = this.arrays.getStepFlujoArray();

    this.docTypesRl = this.arrays.getDocTypesRl();
    this.docTypes = this.arrays.getDocTypes();
    this.typeAccount = this.arrays.getTypeAccount();
    this.typeGender = this.arrays.getTypeGender();
    this.typeNoYes = this.arrays.getTypeNoYes();
    this.questionShow = this.arrays.getQuestionShow();
  }

  ngOnInit() {
    this.checkArray[3] = false
    setTimeout(() => {
      console.log('data');
      document.body.style.backgroundColor = '#f6f8fa'
      window.scrollBy(0, -document.body.scrollHeight * 5);
      var elems = document.getElementsByTagName('html');
      for (var i = 0; i < elems.length; i++) {
        elems[i].style.backgroundColor = '#f6f8fa';
      }
      document.getElementById('progreso').style.width = '33%';
    }, 500);

    // this.flowService.getFlow(this.storeService.get('flujo'))
    // .then(
    //   (response: any) => {
    //     this.flow = response;
    //     this.storeService.set('flow', this.flow);
    //     this.checksLabel = this.flow.formChecks;
    //   }
    // ).catch((error)=>{
    //   console.log("error getting flow");
    //   //this.route.navigate(['login']);
    // });

    /*------------------------------*/
    //this.storeService.set('flujo', 6);
    /*------------------------------*/
  
    this.flujo = this.storeService.get('flujo');
    this.selectedOption = this.storeService.get("selectedOption")
    this.checkFlujo = this.arrays.transformStepFlujo(this.storeService.get("request_type_new"))
    console.log("||||||||||", this.checkFlujo);
    
    /*------------------------------*/
    //this.selectedOption = 1;
    /*------------------------------*/

    console.log(this.selectedOption);
    let estado = this.storeService.get("estado");
    let preguntas = this.storeService.get("preguntasObject");
    console.log("estado: " + estado);
    console.log("preguntas: " + preguntas);
    
    if (estado == "documentos") {
      this.route.navigate(['paef/inbox/documentos'])
    } else {
      if (preguntas != false && (estado == "preguntas" || estado == "formulario")) {
        this.storeService.set("estado", "formulario")
        let numRadicado = this.storeService.get("numRadicado");
        let urlFiles = this.storeService.get("urlFiles");
        let retakeStep = this.storeService.get("retakeStep");
        if (numRadicado != false && urlFiles != false && retakeStep != 'formulario') {
          this.modalService.confirmCreationFormModal(numRadicado, resp => {
            console.log(resp)
            this.descargarDocumentos();
            this.modalService.popupWindowsWarning(resp2 => {
              this.route.navigate(['paef/inbox/documentos']);
            });
          });
        }
      }
      else {
        this.goBack();
      }
    }
    
    let docTypeSS = this.storeService.get("docTypeSS")
    let docNumberSS = this.storeService.get("docNumberSS")
    console.log(docTypeSS + " " + docNumberSS)

    for (let i = 0; i < 10; i++) {
      this.consorcioArray.push(i)
      this.sustitucionArray.push(i);
    }
    
    for (let i = 0; i < 2; i++) {
      this.planillasArray.push(i)
    }

    //old static form initialization commented at the bottom once was hare
    this.createControls(docTypeSS, docNumberSS);

    for (let index = 0; index < 2; index++) {
      this.checkRadio.push(false);
    }
    this.departments = this.fillDepartments();
    this.fillCiiuCodes();
    if (this.storeService.get("retakeStep") == 'formulario') {
      this.modalService.loadingModal('reloadForm', resp => {
      });
      this.patchFormValues('all');
      let a = setInterval(() => {
        console.log("init iteration");
        this.onChangesDep();
        this.patchFormValues('municipio');
      }
        , 500);
      setTimeout(() => {
        this.modalService.closeModal('reloadForm');
        clearInterval(a);
      }, 4000);
    }
    if (this.flujo == 0 || this.flujo == 4 || this.flujo == 5 || this.flujo == 6) {
      let m = this.storeService.get("phase_month")
      console.log(m);
      let tiempoTranscurrido = Date.now();
      let hoy = new Date(tiempoTranscurrido).toLocaleDateString('ES-CO', {day: '2-digit', month: '2-digit', year:'numeric'})
      this.Form.controls.date_request.setValue(hoy.toString())

      if (this.storeService.get("phase_month") != false) {
        this.Form.controls.phase_month.setValue(this.storeService.get("phase_month"))
        if (this.Form.controls.phase_month.value == '9,2020' || this.Form.controls.phase_month.value == '10,2020' || this.Form.controls.phase_month.value == '11,2020'
        ) {
          this.disableDepartament = true
          this.Form.controls.departamento.setValue('88')

          console.log(this.Form.controls.municipio.value);

          if (this.Form.controls.municipio.value == undefined || this.Form.controls.municipio.value == false || this.Form.controls.municipio.value == '') {
            setTimeout(() => {
              this.onChangesDep()
            }, 4300);
          }
        }
      }
      for (const iterator of this.mesesTypes) {
        if (iterator.id == this.Form.controls.phase_month.value) {
          this.data.planilla = iterator.after
        }
      }
    }
    if (this.flujo == 4 || this.flujo == 6) {
      let tiempoTranscurrido = Date.now();
      let hoy = new Date(tiempoTranscurrido).toLocaleDateString('ES-CO', {day: '2-digit', month: '2-digit', year:'numeric'})
      this.Form.controls.date_request.setValue(hoy.toString())
      let preguntas = this.storeService.get("preguntasObject");
      console.log("PREGUNTAS", preguntas);
      
      if(preguntas != null && preguntas != undefined){
        if(preguntas[5].v.toLowerCase() == "no" ){
          this.showSus = false
          for (let i = 0; i < 10; i++) {
            this.Form.controls["cuSusTipoId" + (i + 1)].setValue("")
            this.Form.controls["cuSusNumId" + (i + 1)].setValue("")
          }
        }else{
          this.Form.controls["cuSusTipoId1"].validator = Validators.compose([Validators.required]);
            this.Form.controls["cuSusNumId1"].validator = Validators.compose([Validators.required]);
        }
      }
    }
    this.storeService.set("showPap", false);

  }

  closeInterval(a) {
    if (this.cities.length > 0) {
      this.modalService.closeModal('reloadForm');
      clearInterval(a);
    }
  }

  createControls(docType, docNum) {
    let formArray = this.getCurrentForm();
    let group = {};
    formArray[this.selectedOption].forEach((quest) => {
      // preload cases
      if (quest.name == 'tipoIdEmpresa') {
        group[quest.name] = new FormControl(docType);
      } else if (quest.name == 'numIdEmpresa') {
        group[quest.name] = new FormControl(docNum);
      } else {
        group[quest.name] = new FormControl('');
      }

      let validationObj = []
      if (!quest.optional) {
        validationObj.push(Validators.required)
      }
      if (quest.validation) {
        if (quest.validation.minLength) {
          validationObj.push(Validators.minLength(quest.validation.minLength))
        }
        if (quest.validation.maxLength) {
          validationObj.push(Validators.maxLength(quest.validation.maxLength))
        }
        if (quest.validation.pattern) {
          //validationObj.push(Validators.pattern(new RegExp(quest.validation.pattern, "g")))
          validationObj.push(Validators.pattern(/^[a-zA-Z0-9Ññ_.-]+@[a-zA-ZÑñ_-]+?\.[a-zA-Z]{2,3}(.[a-zA-Z]{2})*$/))
        }
      }
      group[quest.name].setValidators(validationObj);
    });
    this.Form = new FormGroup(group);
    this.loaded = true;
  }

  verificate(i) {
    if (this.validateBill[i]) {
      if (this.verificateBill[i]) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  intP(t) {
    let n = parseInt(t)
    return n
  }

  log() {
    console.log(this.Form.controls.etnia.value)
  }

  formatNaturalNumber(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9]/g, '');
    this.Form.controls[field].setValue(newValue == '' || newValue == '0' ? '' : Number(newValue));
  }

  formatNumber(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatEdad(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatNumberAndChar(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9a-zA-ZñÑ]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatIdentityType(value, field1, field2) {
    let newValue = '';
    if (this.Form.controls[field2].value == 'P') {
      newValue = value.replace(/[^0-9a-zA-ZñÑ]/g, '');
    } else {
      newValue = value.replace(/[^0-9]/g, '');
    }
    this.Form.controls[field1].setValue(newValue);
  }

  formatNumberPlanilla(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9a-zA-ZñÑ-]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatCharAndSpace(value, field) {
    let newValue = '';
    newValue = value.replace(/[^a-zA-Z\sñÑ]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatRazonSocial(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9a-zA-Z\s]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatDireccion(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9a-zA-Z\s]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  formatEmail(value, field) {
    let newValue = '';
    newValue = value.replace(/[^a-zA-Z0-9@._]/g, '');
    newValue = newValue.replace('xnida', '');
    newValue = newValue.replace(/[Ññ]/g, '');
    newValue = newValue.replace(/[áàäâ]/g, '');
    newValue = newValue.replace(/[éèëê]/g, '');
    newValue = newValue.replace(/[íìïî]/g, '');
    newValue = newValue.replace(/[óòôö]/g, '');
    newValue = newValue.replace(/[úùüü]/g, '');
    this.Form.controls[field].setValue(newValue);
  }

  checkSwif(id: number){
    console.log("checkSwif id:",id)
    switch(id){
      case 1: this.checkSwif1(); break;
      case 2: this.checkSwif2(); break;
      case 3: this.checkSwif3(); break;
      case 4: this.checkSwif4(); break;
      case 5: this.checkSwif5(); break;
      default: break;
    }
  }

  checkSwif1() {
    
    if (this.check1) {
      this.check1 = false
    } else {
      this.check1 = true
    }
  }

  checkSwif2() {
    
    if (this.check2) {
      this.check2 = false
    } else {
      this.check2 = true
    }
  }

  checkSwif3() {
    console.log(this.Form.controls.valid);
    console.log(this.Form.controls);
    if (this.check3) {
      this.check3 = false
    } else {
      this.check3 = true
    }
  }

  checkSwif4() {
    console.log(this.Form.controls.valid);
    console.log(this.Form.controls);
    if (this.check4) {
      this.check4 = false
    } else {
      this.check4 = true
    }
  }

  checkSwif5() {
    console.log("-->",this.Form.controls.valid);
    console.log("--->", this.Form.controls);
    if (this.check5) {
      this.check5 = false
    } else {
      this.check5 = true
    }
  }

  verifiFacs() {
    if (this.Form.valid 
      && this.isZero(this.Form.controls.numEmpleados.value) != "0"
      && (this.check1 && this.check2)
      && this.compareCuenta1()
      && this.compareCuenta2()
      && this.compareCorreo1()
      && this.compareOther()
      //&& this.validDataPreRes() //Se retira validación de pregunta dependiente del CIIU
      && this.checkFlujo != undefined
      //&& this.validatePrima()
      && this.check3
      && this.check4
      && ((!this.check5 && this.flujo == 6) || this.check5)
    ) {
      return true
    } else {
      return false;
    }
  }

  isZero(strValue){
    return strValue != undefined && strValue != null && strValue != "" ? parseInt(strValue).toString() : "";
  }

  compareOther() {
    if (this.flujo == 2) {
      let n = 0
      if (this.Form.controls.etnia.value == 'SI') {
        if (this.Form.controls.etniaWhich.value == '' || this.Form.controls.etniaWhich.value == undefined) {
          n++
        }
      }
      if (this.Form.controls.discapacidad.value == 'SI') {
        if (this.Form.controls.discapacidadWhich.value == '' || this.Form.controls.discapacidadWhich.value == undefined) {
          n++
        }
      }
      if (this.Form.controls.agremiacion.value == 'SI') {
        if (this.Form.controls.agremiacionWhich.value == '' || this.Form.controls.agremiacionWhich.value == undefined) {
          n++
        }
      }
      if (n > 0) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  onChangesPat(field) {
    this.Form.controls[field].enable();
    this.Form.controls[field].setValue('');
  }

  changeRadioButton(position) {
    for (let index = 0; index < this.checkRadio.length; index++) {
      this.checkRadio[index] = false;
    }
    this.checkRadio[position] = true;
  }

  goBack() {
    this.authServerProviderService.logoutBasic()
  }

  continuar() {
    if (this.verifiFacs()) {
      this.modalService.confirmSendFormModal(resp => {
        console.log(resp)
        if (resp == 'continue') {
          this.modalService.loadingModal('login', resp => {});

          let departamento = this.Form.controls.departamento.value;
          let municipio = this.Form.controls.municipio.value;
          this.Form.controls.municipio.setValue(this.Form.controls.municipio.value.substring(2, 5));
          let formValues = this.Form.value;
          let mappedInfo = [];
          for (const key in formValues) {
            if (Object.prototype.hasOwnProperty.call(formValues, key)) {
              mappedInfo.push(this.addfield(key, formValues[key]))
            }
          }
          
          let tipoEmpresa = this.selectedOption;
          let tipoIdEmpresa = this.storeService.get("docTypeSS");
          let numIdEmpresa = this.storeService.get("docNumberSS");
          let checkDigit = this.storeService.get("verifyDigitSS");
          let ciiu = this.Form.controls.ciiu.value;

          let cuSusNumId1 = this.Form.controls.cuSusNumId1 ? this.Form.controls.cuSusNumId1.value : '';
          let cuSusNumId2 = this.Form.controls.cuSusNumId2 ? this.Form.controls.cuSusNumId2.value : '';
          let cuSusNumId3 = this.Form.controls.cuSusNumId3 ? this.Form.controls.cuSusNumId3.value : '';
          let cuSusNumId4 = this.Form.controls.cuSusNumId4 ? this.Form.controls.cuSusNumId4.value : '';
          let cuSusNumId5 = this.Form.controls.cuSusNumId5 ? this.Form.controls.cuSusNumId5.value : '';
          let cuSusNumId6 = this.Form.controls.cuSusNumId6 ? this.Form.controls.cuSusNumId6.value : '';
          let cuSusNumId7 = this.Form.controls.cuSusNumId7 ? this.Form.controls.cuSusNumId7.value : '';
          let cuSusNumId8 = this.Form.controls.cuSusNumId8 ? this.Form.controls.cuSusNumId8.value : '';
          let cuSusNumId9 = this.Form.controls.cuSusNumId9 ? this.Form.controls.cuSusNumId9.value : '';
          let cuSusNumId10 = this.Form.controls.cuSusNumId10 ? this.Form.controls.cuSusNumId10.value : '';

          let cuNumId1 = this.Form.controls.cuNumId1 ? this.Form.controls.cuNumId1.value : '';
          let cuNumId2 = this.Form.controls.cuNumId2 ? this.Form.controls.cuNumId2.value : '';
          let cuNumId3 = this.Form.controls.cuNumId3 ? this.Form.controls.cuNumId3.value : '';
          let cuNumId4 = this.Form.controls.cuNumId4 ? this.Form.controls.cuNumId4.value : '';
          let cuNumId5 = this.Form.controls.cuNumId5 ? this.Form.controls.cuNumId5.value : '';
          let cuNumId6 = this.Form.controls.cuNumId6 ? this.Form.controls.cuNumId6.value : '';
          let cuNumId7 = this.Form.controls.cuNumId7 ? this.Form.controls.cuNumId7.value : '';
          let cuNumId8 = this.Form.controls.cuNumId8 ? this.Form.controls.cuNumId8.value : '';
          let cuNumId9 = this.Form.controls.cuNumId9 ? this.Form.controls.cuNumId9.value : '';
          let cuNumId10 = this.Form.controls.cuNumId10 ? this.Form.controls.cuNumId10.value : '';

          let tipoCuentaBanc = this.Form.controls.tipoCuentaBanc.value;
          let numCuentaBanc = this.Form.controls.numCuentaBanc.value;
          let tipoCuentaBanc2 = this.Form.controls.tipoCuentaBanc2.value;
          let numCuentaBanc2 = this.Form.controls.numCuentaBanc2.value;
          let numCuentaBanc2C = this.Form.controls.numCuentaBanc2C.value;
          //init value transformation
          let departamentoD = this.getDepartment(departamento);
          let cities = this.getCities(departamento);
          let municipioD = this.getMunicipio(municipio, cities);

          let actIndex = this.ciiuCodes.findIndex(elemento => elemento.id === ciiu);
          let actividad = this.ciiuCodes[actIndex].name;
          let numPlanilla = '';
          let pl1 = '';
          let pl2 = '';
          if (this.Form.controls.numPlanilla) {
            numPlanilla = this.Form.controls.numPlanilla ? this.Form.controls.numPlanilla.value : '';
            pl1 = this.Form.controls.numPlanA1 ? this.Form.controls.numPlanA1.value : '';
            pl2 = this.Form.controls.numPlanA2 ? this.Form.controls.numPlanA2.value : '';
          }
          let pl = [numPlanilla, pl1, pl2]
          let cl = [cuNumId1, cuNumId2, cuNumId3, cuNumId4, cuNumId5, cuNumId6, cuNumId7, cuNumId8, cuNumId9, cuNumId10]
          let ut = [cuSusNumId1, cuSusNumId2, cuSusNumId3, cuSusNumId4, cuSusNumId5, cuSusNumId6, cuSusNumId7, cuSusNumId8, cuSusNumId9, cuSusNumId10]
          let preguntas = this.storeService.get("preguntasObject");
          let questions = new Array();
          for (let i = 0; i < preguntas.length; i++) {
            questions.push(this.addfield(preguntas[i].k, preguntas[i].v));
          }
          if (cuSusNumId1 != '') {
            mappedInfo.push(this.addfield("cuSusNumId1", cuSusNumId1));
            mappedInfo.push(this.addfield("cuSusNumId2", cuSusNumId2))
            mappedInfo.push(this.addfield("cuSusNumId3", cuSusNumId3))
            mappedInfo.push(this.addfield("cuSusNumId4", cuSusNumId4))
            mappedInfo.push(this.addfield("cuSusNumId5", cuSusNumId5));
            mappedInfo.push(this.addfield("cuSusNumId6", cuSusNumId6))
            mappedInfo.push(this.addfield("cuSusNumId7", cuSusNumId7))
            mappedInfo.push(this.addfield("cuSusNumId8", cuSusNumId8))
            mappedInfo.push(this.addfield("cuSusNumId9", cuSusNumId9))
            mappedInfo.push(this.addfield("cuSusNumId10", cuSusNumId10))
            mappedInfo.push(this.addfield("cuSusNumId", this.getPlanilla(ut)))
          }
          if (cuNumId1 != '') {
            mappedInfo.push(this.addfield("cuNumId1", cuNumId1))
            mappedInfo.push(this.addfield("cuNumId2", cuNumId2))
            mappedInfo.push(this.addfield("cuNumId3", cuNumId3))
            mappedInfo.push(this.addfield("cuNumId4", cuNumId4))
            mappedInfo.push(this.addfield("cuNumId5", cuNumId5))
            mappedInfo.push(this.addfield("cuNumId6", cuNumId6))
            mappedInfo.push(this.addfield("cuNumId7", cuNumId7))
            mappedInfo.push(this.addfield("cuNumId8", cuNumId8))
            mappedInfo.push(this.addfield("cuNumId9", cuNumId9))
            mappedInfo.push(this.addfield("cuNumId10", cuNumId10))
            mappedInfo.push(this.addfield("cuNumId", this.getPlanilla(cl)))
          }

          if (this.flujo == 2) {
            let pl = "" + this.Form.controls.numPlanA1.value + "-" + this.Form.controls.numPlanA2.value + "-" + this.Form.controls.numPlanA3.value + "-" + this.Form.controls.numPlanA4.value + "-" + this.Form.controls.numPlanA5.value + "-" + this.Form.controls.numPlanA6.value
            mappedInfo.push(this.addfield("numPlanilla", pl))
          } else {
            mappedInfo.push(this.addfield("numPlanilla", this.getPlanilla(pl)))
          }

          if (this.flujo == 4) {
            mappedInfo.push(this.addfield("ciclo_postulacion", 2))//ciclo de postulacion de agosto
            mappedInfo.push(this.addfield("tipoEmpresaSAQDT", this.arrays.getTipoEmpresaSA(tipoEmpresa)))//TIPO EMPRESA NUEVO
          }

          mappedInfo.push(this.addfield("tipoEmpresa", this.arrays.getTipoEmpresaReverse(tipoEmpresa)));

          let mesesTypesFilter = this.mesesTypes.find(data => data.id == this.storeService.get("phase_month"))

          if(mesesTypesFilter != undefined){
            mappedInfo.push(this.addfield("ciclo_postulacion", mesesTypesFilter.phase))//ciclo de postulacion
            mappedInfo.push(this.addfield("tipoEmpresaSAQDT", this.flujo == 6 ? this.arrays.getTipoEmpresaSA(tipoEmpresa) : "")) //TIPO EMPRESA NUEVO
          } /*else {
            //deprecated
            if (this.flujo == 0 || this.flujo == 5) {
              if(this.storeService.get("phase_month") == "5,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 12))//ciclo de postulacion de mayo
              }else if(this.storeService.get("phase_month") == "6,2021") {
                mappedInfo.push(this.addfield("ciclo_postulacion", 13))//ciclo de postulacion de junio
              } else if(this.storeService.get("phase_month") == "7,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 14))//ciclo de postulacion de julio
              } else if(this.storeService.get("phase_month") == "8,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 15))//ciclo de postulacion de agosto
              } else if(this.storeService.get("phase_month") == "9,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 16))//ciclo de postulacion de septiembre
              } else if(this.storeService.get("phase_month") == "10,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 17))//ciclo de postulacion de octubre
              } else if(this.storeService.get("phase_month") == "11,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 18))//ciclo de postulacion de noviembre
              } else if(this.storeService.get("phase_month") == "12,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 19))//ciclo de postulacion de diciembre
              }
            }

            if (this.flujo == 6) {
              if(this.storeService.get("phase_month") == "10,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 1))//ciclo de postulacion de octubre
              } else if(this.storeService.get("phase_month") == "11,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 2))//ciclo de postulacion de noviembre
              } else if(this.storeService.get("phase_month") == "12,2021"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 3))//ciclo de postulacion de diciembre
              } else if(this.storeService.get("phase_month") == "1,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 4))//ciclo de postulacion de enero
              } else if(this.storeService.get("phase_month") == "2,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 5))//ciclo de postulacion de enero
              } else if(this.storeService.get("phase_month") == "3,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 6))//ciclo de postulacion de enero
              } else if(this.storeService.get("phase_month") == "4,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 7))//ciclo de postulacion de Abril
              } else if(this.storeService.get("phase_month") == "5,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 8))//ciclo de postulacion de Mayo
              } else if(this.storeService.get("phase_month") == "6,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 9))//ciclo de postulacion de Junio
              } else if(this.storeService.get("phase_month") == "7,2022"){
                mappedInfo.push(this.addfield("ciclo_postulacion", 10))//ciclo de postulacion de Julio
              }
              mappedInfo.push(this.addfield("tipoEmpresaSAQDT", this.arrays.getTipoEmpresaSA(tipoEmpresa)))//TIPO EMPRESA NUEVO
            }
          }*/

          mappedInfo.push(this.addfield("actEconomicaP", actividad));
          //Campos quemados que necesita el back
          mappedInfo.push(this.addfield("entidadCuentaEmpresarial", "1"));
          //solicitud no existe en PAP {flujo 1}
          //info.push(this.addfield("solicitud", this.solicitudSelected + ""))

          //mappedInfo.push(this.addfield("solicitud", this.flujo == 0 || this.flujo == 5 ? this.solicitudPeriodo() + "" : ""));
          mappedInfo.push(this.addfield("solicitud", this.flujo == 0 || this.flujo == 5 ? mesesTypesFilter.phase + "" : ""));

          mappedInfo.push(this.addfield("departamentoD", departamentoD));
          mappedInfo.push(this.addfield("municipioD", municipioD));
          console.log("municipioD: ", municipioD);
          //this.awaitSetMunAndDep(mappedInfo);
          // Validar segun negocio informe

          mappedInfo.push(this.addfield("request_type", this.arrays.transformReverseFlujo(this.checkFlujo)));
          //prima
          /*info.push(this.addfield("PreguntaPAP", this.prima))
          info.push(this.addfield("numPAP", nPrima))*/
          //info.push(this.addfield("PreguntaPAP", null))
          //info.push(this.addfield("numPAP", null))

          mappedInfo.push(this.addfield("checkDigit", checkDigit))

          let requestDTO = new saveRequest();
          requestDTO.tipoId = tipoIdEmpresa;
          requestDTO.id = numIdEmpresa
          requestDTO.token = this.storeService.get("isLoggedIn");
          requestDTO.info = mappedInfo;
          requestDTO.questions = questions;
          requestDTO.request_type = this.arrays.transformReverseFlujo(this.checkFlujo);

          if (this.storeService.get("retakeStep") == 'formulario') {
            requestDTO.numradicado = this.storeService.get("numRadicado");
          }
          console.log("-----------------ENVIO DATA--------------------");

          console.log(requestDTO)

          console.log('CUENTA', this.Form.controls.numCuentaBanc2.value);
          if (this.Form.controls.numCuentaBanc2.value != '') {
            this.validateBill[0] = true
            this.validateBill[1] = true
          } else {
            this.validateBill[0] = true
            this.validateBill[1] = false
          }
          //validar cuentas          
          this.loginService.getValidatedBill(requestDTO).subscribe((res) => {
            console.log(res);
            this.modalService.closeModal('login');
            let result =  JSON.parse(res.body);
            if (result['statusCode']) {
              if (result['statusCode'] == 200) {
                this.validateBill[0] = false;
                this.validateBill[1] = false;
                let cuentasValidadas = true;
                if (this.Form.controls.numCuentaBanc2.value != '') {
                  if (res['body']['validateCta1'] == false || res['body']['validateCta2'] == false) {
                    cuentasValidadas = false;
                    let cuentaInvalida = '';
                    let cuentaValida = '';
                    if (res['body']['validateCta1'] == false) {
                      cuentaValida = tipoCuentaBanc2 + " " + numCuentaBanc2;
                      cuentaInvalida = tipoCuentaBanc + " " + numCuentaBanc;
                      //setear los valores de cuenta 2 en cuenta 1 - cuenta 2 a string vacio
                      for (let index = 0; index < requestDTO.info.length; index++) {
                        const element = requestDTO.info[index];
                        if (element.k == "numCuentaBanc") {
                          requestDTO.info[index].v = numCuentaBanc2;
                        }
                        if (element.k == "tipoCuentaBanc") {
                          requestDTO.info[index].v = tipoCuentaBanc2;
                        }
                        if (element.k == "numCuentaBancC") {
                          requestDTO.info[index].v = numCuentaBanc2C;
                        }
                        if (element.k == "numCuentaBanc2" || element.k == "tipoCuentaBanc2" || element.k == "numCuentaBanc2C") {
                          requestDTO.info[index].v = "";
                        }
                      }
                    }
                    if (res['body']['validateCta2'] == false) {
                      cuentaValida = tipoCuentaBanc + " " + numCuentaBanc;
                      cuentaInvalida = tipoCuentaBanc2 + " " + numCuentaBanc2;
                      //setear los valores de cuenta 2 a string vacio
                      for (let index = 0; index < requestDTO.info.length; index++) {
                        const element = requestDTO.info[index];
                        if (element.k == "numCuentaBanc2" || element.k == "tipoCuentaBanc2" || element.k == "numCuentaBanc2C") {
                          requestDTO.info[index].v = "";
                        }
                      }
                    }
                    //modal
                    this.modalService.cuentaNoHabilitada(cuentaValida, cuentaInvalida, resp => {
                      console.log(resp);
                      this.guardarFormulario(requestDTO);
                    });
                  }
                }
                if (cuentasValidadas) {
                  this.guardarFormulario(requestDTO);
                }
              } else if (result['statusCode'] == 412) {
                this.storeService.set("estado", "error");
                this.modalService.blackListModal((resp) => {
                  this.goBack()
                })
              } else if (result['statusCode'] == 401) {
                this.storeService.set("estado", "error");
                this.modalService.closeSession((resp) => {
                  this.goBack()
                })
              } else if (result['statusCode'] == 404) {
                // this.patchFormValues('municipio');
                this.errorValidation();
              } else {
                //ninguna de las dos es valida                
                this.storeService.set("estado", "error");
                this.modalService.errorModal(resp => {
                  this.goBack();
                });
              }
            } else {
              this.storeService.set("estado", "error");
              this.modalService.errorModal(resp => {
                this.goBack();
              });
            }
          });
        }
      });
    }
  }

  guardarFormulario(requestDTO) {
    //guardar formulario
    this.modalService.loadingModal('login', resp => {});
    this.loginService.saveRequest(requestDTO).subscribe((res) => {
      console.log(res);
      this.modalService.closeModalDefinitive();
      this.modalService.closeModal('login');
      if (res == 'null') {
        this.storeService.set("estado", "error");
        this.modalService.errorModal(resp => {
          this.goBack()
        });
      } else {
        let respuesta = JSON.parse(res.body)
        if (respuesta['statusCode'] == 201) {
          let numRadicado = respuesta['body']['numradicado']
          this.storeService.set("numRadicado", numRadicado);
          let urlFiles = respuesta['body']['downfiles']
          this.storeService.set("urlFiles", urlFiles);
          this.modalService.confirmCreationFormModal(numRadicado, resp => {
            console.log(resp)
            this.descargarDocumentos();
            this.modalService.popupWindowsWarning(resp2 => {
              this.storeService.set('flujo', this.checkFlujo)
              this.route.navigate(['paef/inbox/documentos']);
            });
          });
        }
        if (respuesta['errorType']) {
          this.storeService.set("estado", "error");
          this.modalService.errorModal(resp => {
            this.goBack()
          });
        }
        if (respuesta['statusCode'] == 412) {
          this.storeService.set("estado", "error");
          this.modalService.blackListModal((resp) => {
            this.goBack()
          })
        }
        if (respuesta['statusCode'] == 401) {
          //sesion invalida por token vencido
          this.storeService.set("estado", "error");
          this.modalService.closeSession(resp => {
            this.goBack()
          });
        }
        if (respuesta['statusCode'] >= 500) {
          this.storeService.set("estado", "error");
          this.modalService.errorModal(resp => {
            this.goBack()
          });
        }
      }
    }, 
    error =>{
      console.log("error in this.loginService.saveRequest: ", error);
      this.modalService.closeModal('login');
      if(error){
        this.storeService.set("estado", "error");
        this.modalService.customErrorModal("Servicio no disponible", "Ha ocurrido un error al tratar de enviar su peticion.", "Cerrar", resp => {});
          //this.goBack()
      }
    });
  }

  errorValidation() {
    if (this.Form.controls.numCuentaBanc2.value != '') {
      this.verificateBill[0] = false
      this.verificateBill[1] = false
      this.verificateBillError[0] = 'Comuníquese con su oficina o gerente para validar el estado de su producto.'
      this.verificateBillError[1] = 'Comuníquese con su oficina o gerente para validar el estado de su producto.'
      this.modalService.closeModalDefinitive();
      this.modalService.closeModal('login');
    } else {
      this.verificateBill[0] = false
      this.verificateBillError[0] = 'Comuníquese con su oficina o gerente para validar el estado de su producto.'
      this.modalService.closeModalDefinitive();
      this.modalService.closeModal('login');
    }
  }

  addfield(name, value) {
    if (value == undefined || value == null) {
      value = "";
    }
    value = value + ''
    let valueClean = value.trim();
    valueClean = valueClean.replace(/\t/g, ' ');
    let e = new element();
    e.v = valueClean;
    e.k = name;
    return e;
  }

  getCurrentForm(): Array<DynQuestion[]> {
    switch (this.flujo) {
      case 0:
        return paefForms;
      case 1:
        return papForms;
      case 2:
        return papaForms;
      case 4:
        return sacudeteForms;
      case 5:
        return paroForms;
      case 6:
          return nuevoEmpleoForm;
      default:
        break;
    }
    return papForms;
  }

  //deprecated
  /*
  transformStepFlujo(key) {
    return this.stepFlujoArray.find(data => data.id = key).code;
    switch (key) {
      case 0:
        return 1;//"PAEF";
      case 1:
        return 2;//"PAP";
      case 2:
        return 3;//"PAP + PAEF"";
      //return 4;//"PAP AGROPECUARIO"";
      case 4:
        return 5;//"SACUDETE"";
      case 5:
        return 6;//"PARO"";
      case 6:
          return 7;//"EMPLEOS"";
      default:
        break;
    }
  }
  */

  //deprecated
  /*
  transformReverseFlujo(key) {
    return this.stepFlujoArray.find(data => data.code = key).id;
    switch (key) {
      case 1:
        return 0;//"PAEF";
      case 2:
        return 1;//"PAP";
      case 3:
        return 2;//"PAP + PAEF"";
      //return 4;//"PAP AGROPECUARIO"";
      case 5:
        return 4;//"SACUDETE"";
      case 6:
        return 5;//"PARO"";
      case 7:
        return 6;//"EMPLEOS"";
      default:
        break;
    }
  }
  */

  //deprecated
  /*
  getTipoEmpresaReverse(id) {
    return this.tipoEmpresaArray.find(data=>data.id == id).code;
    switch (id) {
      case -1:
        return "-";
      case 0:
        return 1;//"Persona jurídica";
      case 1:
        return 4;//"Persona Natural";
      case 2:
        return 2;//"Entidad sin ánimo de lucro";
      case 3:
        return 3;//"Consorcio o unión temporal";
      case 4:
        return 5;//"Patrimonio Autónomo Declarante";
      case 5:
        return 6;//Cooperativas
      default:
        break;
    }
  }
  */

  //deprecated
  /*
  getTipoEmpresaSA(id) {
    return this.tipoEmpresaSAArray.find(data=>data.id == id).code;
    switch (id) {
      case -1:
        return "-";
      case 0:
        return 1;//"Persona jurídica";
      case 1:
        return 5;//"Persona Natural";
      case 2:
        return 4;//"Entidad sin ánimo de lucro / cooperativa";
      case 3:
        return 2;//"Consorcio o unión temporal";
      case 4:
        return 3;//"Patrimonio Autónomo Declarante";
      default:
        break;
    }
  }
  */

  //deprecated
  getSolicitud(id) {
    switch (id) {
      case 0:
        return "2";
      case 1:
        return "1";
      default:
        break;
    }
  }

  getPlanilla(pl) {
    let num = ""
    for (let i = 0; i < pl.length; i++) {
      if (pl[i] != "") {
        if (num == "") {
          num = pl[i];
        } else {
          num = num + "-" + pl[i];
        }
      }
    }
    return num;
  }

  async descargarDocumentos() {
    console.log("Comienza a descargar documentos");
    let urlFiles = this.storeService.get("urlFiles");
    for (let i = 0; i < urlFiles.length; i++) {
      if( i == (urlFiles.length-1) ) await new Promise(resolve => setTimeout(resolve, 700));
      //window.open(urlFiles[i].ruta, "_blank");
      let url = urlFiles[i].ruta
      this.openNewWindow(url);
    }
  }

  async openNewWindow(url, type="_blank",location=false,scrollbars=false,stats=false,resizable=false){
    let windowToOpen = window;
    let options = ""; 
    options += location ? "location=yes," : "";
    options += scrollbars ? "scrollbars=yes," : "";
    options += stats ? "status=yes," : "";
    options += resizable ? "resizable=yes," : ""
    windowToOpen.open(url, type, options);
  }

  fillDepartments() {
    this.loginService.getCiudades().subscribe((data: any) => {
      let resultado = JSON.parse(data.body)
      resultado.forEach(dep => {
        let e = {
          "name": dep.name,
          "id": dep.id,
          "cities": dep.cities
        }
        e.cities.sort((a, b) => a.name.localeCompare(b.name));
        this.departments.push(e)
      });
      this.departments.sort((a, b) => a.name.localeCompare(b.name));
    });
    return this.departments;
  }

  ordenarAsc(p_array_json, p_key) {
    p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });
  }

  onChangesDep() {
    let indexDep = this.Form.controls.departamento.value;
    var indexCity = '';
    /* let index = this.departments.findIndex(elemento => elemento.id == indexDep ); */
    for (let index = 0; index < this.departments.length; index++) {
      const element = this.departments[index];
      if (element.id == indexDep) {
        this.cities = element.cities;
        console.log(this.cities);
      }
    }
    this.Form.controls['municipio'].setValue(indexCity);
    console.log("||||||||||||||||||||||||||||", this.departments);
    console.log("||||||||||||||||||||||||||||", this.cities);
  }

  fillCiiuCodes() {
    this.loginService.getCiiuCodes().subscribe((data: any) => {
      let resultado = (data.body)
      let ciiuArray = resultado['body'][0]['DATOS']['L']
      ciiuArray.forEach(elem => {
        let e = {
          "id": elem.M.name.S,
          "name": elem.M.value.S
        }
        this.ciiuCodes.push(e)
      });
    });
  }

  //deprecated
  getIdType(type) {
    switch (type) {
      case "C":
        return "CC";
      case "E":
        return "CE";
      case "L":
        return "NIT";
      case "P":
        return "PA";
      case "I":
        return "NIT";
      case "N":
        return "NIT";
      default:
        break;
    }
  }

  patchFormValues(key: string) {
    if (key == "municipio") {
      let indexDep = this.Form.controls['departamento'].value;
      let indexCity = indexDep + (this.municipio).padStart(3, '0');
      //let codeTotal = `${this.Form.controls['departamento'].value}${this.municipio}`
      this.Form.controls[key].setValue(indexCity);
    } else {
      let lastInfo = this.storeService.get("lastInfo");
      for (let index = 0; index < lastInfo.length; index++) {
        const element = lastInfo[index];
        if (this.Form.get(element.k)) {
          this.Form.controls[element.k].setValue(element.v)
          if (element.k == "municipio") {
            this.municipio = element.v;
          }
          if (element.k == "numCuentaBanc") {
            this.Form.controls['numCuentaBancC'].setValue(element.v)
          }
          if (element.k == "numCuentaBanc2") {
            this.Form.controls['numCuentaBanc2C'].setValue(element.v)
          }
          if (element.k == "conEmail") {
            this.Form.controls['conEmail2'].setValue(element.v)
          }
        }
      }
    }
  }

  getFalse() {
    console.log(this.Form.get("gfdgdf5545"));
  }

  getMetodoCum3(val2019, val2020) {
    if (val2019 == "si" && val2020 == "no") {
      return "METODO 1"
    }
    if (val2019 == "no" && val2020 == "si") {
      return "METODO 2"
    }
    if (val2019 == "si" && val2020 == "si") {
      return "METODO " + Math.floor(Math.random() * ((3) - 1) + 1);
    }
  }

  compareCuenta1() {
    if (this.Form.controls.numCuentaBanc.value == this.Form.controls.numCuentaBancC.value) {
      return true;
    } else {
      return false;
    }
  }

  compareCuenta2() {
    if (this.Form.controls.numCuentaBanc2.value == this.Form.controls.numCuentaBanc2C.value) {
      return true;
    } else {
      return false;
    }
  }

  compareCorreo1() {
    if (this.Form.controls.conEmail.value == this.Form.controls.conEmail2.value) {
      return true;
    } else {
      return false;
    }
  }

  clickMon(i) {
    this.mon = -1
    if (this.checkArray[i]) {
      this.checkArray[i] = false
    } else {
      this.checkArray[i] = true
    }
    if (i == 3) {
      this.checkArray[0] = false
      this.checkArray[1] = false
      this.checkArray[2] = false
      this.mon = 1
    } else {
      let c = 0;
      for (let index = 0; index < this.checkArray.length - 1; index++) {
        if (this.checkArray[index]) {
          c++
        }
      }
      console.log(c);
      if (c == 1) {
        this.mon = 2
      }

      if (c == 2) {
        this.mon = 3
      }

      if (c == 3) {
        this.mon = 4
      }

      if (this.checkArray[3]) {
        this.checkArray[3] = false
      }
    }
    if (!this.checkArray[0] && !this.checkArray[1] && !this.checkArray[2] && !this.checkArray[3]) {
      this.mon = undefined
      this.solicitudSelected = -1
    } else {
      this.solicitudSelected = this.mon
    }
    console.log(this.mon);
    console.log(this.solicitudSelected);
  }

  classCheck(i) {
    if ((this.check1 || this.check2) && this.prima == undefined) {
      return 'radio-button-uncheck-error'
    } else {
      if (this.checkRadio[i]) {
        return 'radio-button-check'
      } else {
        return 'radio-button-uncheck'
      }
    }
  }

  validatePrima() {
    let r = false
    console.log(this.Form.controls.numPrima.value);
    let numero = this.Form.controls.numPrima.value;
    if (this.prima == undefined) {
      r = false
    }
    if (this.prima == 'SI' && numero != null && numero != '' && parseInt(numero) > 0) {
      r = true
    }
    if (numero == null || numero == '') {
      r = false
    }
    if (this.prima == 'NO') {
      r = true
    }

    return r
  }

  validDataPre(i) {
    this.Form.controls.preguntaDlloDichaAct.setValue(i)
  }

  validDataPreRes() {
    if (this.flujo == 0 || this.flujo == 5) {
      if (this.validShowQuestion()) {
        if (this.Form.controls.preguntaDlloDichaAct.value != '' && this.Form.controls.preguntaDlloDichaAct.value != false && this.Form.controls.preguntaDlloDichaAct.value != undefined) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return true
    }
  }

  validBots() {
    if (this.Form.controls.preguntaDlloDichaAct.value == 'SI') {
      return 'btn-res-con'
    } else {
      return 'btn-res'
    }
  }

  validBotn() {
    if (this.Form.controls.preguntaDlloDichaAct.value == 'NO') {
      return 'btn-res-con'
    } else {
      return 'btn-res'
    }
  }

  validShowQuestion() {
    if (this.flujo != 2) {
      for (const iterator of this.questionShow) {
        if (this.Form.controls.ciiu.value == iterator) {
          return true
        }
      }
      if (this.Form.controls.preguntaDlloDichaAct.value != undefined) {
        this.Form.controls.preguntaDlloDichaAct.setValue("")
      }
      return false
    } else {
      return true
    }
  }

  //deprecated
  solicitudPeriodo() {
    let id = this.Form.controls.phase_month.value
    switch (id) {
      case '9,2020':
        return 5;
      case '10,2020':
        return 6;
      case '11,2020':
        return 7;
      case '12,2020':
        return 8;
      case '1,2021':
        return 9;
      case '2,2021':
        return 10;
      case '3,2021':
        return 11;
      case '4,2021':
        return 12;
      case '5,2021':
        return 13;
      case '6,2021':
        return 14;
      case '7,2021':
        return 15;
      case '8,2021':
        return 16;
      case '9,2021':
        return 17;
      case '10,2021':
        return 18;
      case '11,2021':
        return 19;
      case '12,2021':
        return 20;
      default:
        break;
    }
  }

  changeRadioButtonFlujo(position) {
    this.checkFlujo = position
    console.log(this.checkFlujo);
  }

  getDepartment(deparment: string){
    var departmentsFilter = this.departments.filter((dep) => dep.id == deparment);
    return departmentsFilter.length > 0 ? departmentsFilter[0].name : "";
  }

  getCities(deparment: string){
    var departmentsFilter = this.departments.filter((dep) => dep.id == deparment);
    return departmentsFilter.length > 0 ? departmentsFilter[0].cities : false;
  }

  getMunicipio(municipio: string, cities: Array<any>){
    var citiesFilter = cities.filter((city) => city.id == municipio);
    return citiesFilter.length > 0 ? citiesFilter[0].name : "";
  }

  // async awaitSetMunAndDep(mappedInfo: any){
  //   console.log(1);
  //   await new Promise(resolve => setTimeout(resolve, 1000)); // 3 sec
  //   console.log(2);
  //   console.log("[BABZ] [awaitSetMunAndDep] finalizacion de setinterval")
  //   console.log("mappedInfo: ", mappedInfo);

  //   var deparment = mappedInfo.filter((mapObject) => mapObject.k == "departamento")[0].v;
  //   var mapperFilterDep = mappedInfo.filter((mapObject) => mapObject.k == "departamentoD" && mapObject.k != "");
  //   var mapperFilterMun = mappedInfo.filter((mapObject) => mapObject.k == "municipioD" && mapObject.k != "");

  //   if(mapperFilterDep.length == 0){
  //     console.log("departamentoD Vacio!!!!!!");
  //     mappedInfo.push(this.addfield("departamentoD", this.getDepartment(deparment)));
  //   }
  //   if(mapperFilterMun.length == 0){
  //     console.log("municipioD Vacio!!!!!!");
  //     mappedInfo.push(this.addfield("municipioD", this.getMunicipio(deparment, this.getCities(deparment))));
  //   }
  // }
}
