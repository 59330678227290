import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';
import { CryptoService } from './crypto/crypto.service';


@Injectable({
  providedIn: 'root'
})
export class FlowService {
  public listFlows = [];
  public listFlowsBk = [
    {
      "order": 0,
      "flujo": 6,
      "nemonic": "empleos",
      "disable": true,
      "datePhaseEnd": "2022-08-26 16:00:00",
      "datePhaseInit": "2022-08-18 06:00:00",
      "datePhaseSubsEnd": "2022-08-30 10:00:00",
      "datePhaseSubsInit": "2022-08-26 16:00:00",
      "description": "ABIERTO: desde el {{datePhaseInit}} al {{datePhaseEnd}}",
      "descriptionSubs": "SUBSANACIONES: desde el {{datePhaseSubsInit}} al {{datePhaseSubsEnd}}",
      "subtitle": "Usted va realizar postulación al Programa Creación de Nuevos Empleos el cual cierra el {{datePhaseEnd}}",
      "subtitleSubs": "Las aplicaciones para el Programa Creación de Nuevos Empleos han cerrado. Este portal solo está habilitado para realizar correcciones y envío de solicitudes en curso hasta el {{datePhaseSubsEnd}}",
      "subsanacion": false,
      "visible": true,
      "icon": "assets/img/menu-docum.svg",
      "guiaRapida":"documents/s3?key=public/GuiaRapidaNuevosEmpleos.pdf", 
      "guiaRestitucion":"documents/s3?key=public/Manual_de_Restitucio%CC%81n_PAEF.PDF",
      "title": "Programa Empleo para la vida, Fomento y protección para nuevos empleos",
      "subTitleLogin": "Solicite aquí el apoyo Creación de Nuevos Empleos.",
      "important": "Si es una persona natural que tiene más de un tipo de documento de identificación, debe señalar únicamente el tipo y número de identidad con el cual realiza sus pagos en PILA y cumple los demás requisitos del programa.",
      "resolucion":"De acuerdo con lo establecido en el Decreto 1736 de 2023 y la Resolución 4451 de 2023, sus modificaciones y sus normas reglamentarias, los requisitos manifestados a continuación son habilitantes para continuar con la postulación al Incentivo para el empleo para la vida, fomento y protección para nuevos empleos. Si no cumple con alguno de ellos, absténgase de continuar con esta solicitud. Su respuesta puede ser SI o NO dependiendo del caso.", 
      "router": "logins",
      "loginChecks": [
        {
          "checkb": true, 
          "check": "Radicar formulario"
        },
        {
          "checkb": true, 
          "check": "Carga de documentos o correcciones"
        },
        {
          "checkb": true, 
          "check": "Consultar estado de solicitud"
        }
      ],
      "months": [
        // { "name": "Junio", "id": "6,2022", "phase": "9"},
        //{ "name": "Julio", "id": "7,2022", "phase": 10},
        { "name": "Agosto", "id": "8,2022", "phase": 11}
      ],
      "formChecks": [
        "En desarrollo de lo establecido en el artículo 24 de la Ley 2155 de 2021, el Decreto 1736 de 2023 y la Resolución 4451 de 2023, sus modificaciones y demás normas que regulan la materia, los empleados sobre los que se reconoce el presente subsidio, corresponden a aquellos empleados adicionales que tenga el potencial beneficiario, en los casos en los que se encuentre que en la Planilla Integrada de Liquidación de Aportes (PILA) para el periodo de cotización del mes inmediatamente anterior al de la postulación existe un número mayor de trabajadores frente al mes de marzo de 2021, en las condiciones señaladas en el artículo 8 de la Resolución 4451 de 2023, por los cuales se cumpla el requisito de permanencia de 6 meses.",
        "De acuerdo con lo establecido en el artículo 24 de la Ley 2155 de 2021, el Decreto 1736 de 2023 y la Resolución 4451 de 2023, expreso mi consentimiento y certifico el cumplimiento de los requisitos establecidos en la norma, los cuales he declarado bajo la gravedad del juramento y asumo la responsabilidad derivada de cualquier inexactitud o incumplimiento de las disposiciones pertinentes.",
        "En el caso en que la(s) cuenta(s) inscrita(s) en el presente formulario presenten o alguna novedad (sobregiros, embargos u otros) que no permita el abono de los recursos del programa para la vida, fomento y protección para nuevos empleos, solicito al Banco de Bogotá la apertura de una cuenta de ahorros cuyo uso exclusivo me comprometo sea la recepción y disposición de dichos recursos. Para tales efectos, declaro que Banco de Bogotá me informó las condiciones del reglamento de la cuenta de ahorros, el cual he tenido a mi disposición en la página www.bancodebogotá.com.co, y con la firma del presente formulario declaro conocer y aceptar expresamente."
      ],
      "questionsArray": [
        { "k": "CUM1-PAEF", "v": "" },
        { "k": "CUM2-PAEF", "v": "" },
        { "k": "CUM3-PAEF", "v": "" },
        { "k": "CUM4-PAEF", "v": "" },
        { "k": "CUM5-PAEF", "v": "" },
        { "k": "CUM6-PAEF", "v": "" },
        { "k": "CUM8-PAEF", "v": "" },
        { "k": "CUM7-PAEF", "v": "" },
        { "k": "COOP1-PAEF", "v": "" },
        { "k": "NAT1-PAEF", "v": "" },
        { "k": "CUM71-PAEF", "v": "" }
      ],

      "questions": {
        "messages": {
          "resolucion": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en la el artículo 5 de la Resolución 2162 o el artículo 3 de la Resolución 0360 *",
          "respno": "Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 4451 de 2023 para más información.",
          "respsi": "Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 4451 de 2023 para más información."      },
        // ------------- NUEVOS EMPLEOS Persona juridica      
        "0": [
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "2",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "3",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.",
            "number": "4",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).",
            "number": "5",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.",
            "number": "6",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "7",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "8",
            "resp": "no",
            "opc": true,
            "visible": false
          }
        ],
        // ------------- NUEVOS EMPLEOS Persona natural      
        "1": [
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "2",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "3",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.",
            "number": "4",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).",
            "number": "5",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.",
            "number": "6",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del decreto 1674 de 2016.",
            "number": "7",
            "resp": "no",
            "visible": true
          },    
          {
            "text": "Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "8",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "9",
            "resp": "no",
            "opc": true,
            "visible": false
          }
        ],
        // ------------- NUEVOS EMPLEOS Cooperativa     
         "2": [
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "2",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "3",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.",
            "number": "4",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).",
            "number": "5",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.",
            "number": "6",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Se encuentra debidamente registrada ante la Cámara de Comercio de su domicilio principal.",
            "number": "7",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "8",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "9",
            "resp": "no",
            "opc": true,
            "visible": false
          }
        ],
        // ------------- NUEVOS EMPLEOS Consorcio o union temporal      
        "3": [
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "2",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "3",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.",
            "number": "4",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).",
            "number": "5",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.",
            "number": "6",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "7",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "8",
            "resp": "no",
            "opc": true,
            "visible": false
          }
        ],
        // ------------- NUEVOS EMPLEOS Patrimonio Autónomo Declarante      
        "4": [
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "2",
            "resp": "no",
            "visible": true
            },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "3",
            "resp": "no",
            "visible": true
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.",
            "number": "4",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).",
            "number": "5",
            "resp": "si",
            "visible": true
          },
          {
            "text": "Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.",
            "number": "6",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "7",
            "resp": "no",
            "opc": true,
            "visible": true
          },
          {
            "text": "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
            "number": "8",
            "resp": "no",
            "opc": true,
            "visible": false
          }
        ]
      },

      "notNeededArray": [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        []
      ],
      
      "answers": {
        "r1": ["si", "no", "no", "si", "si", "no", "no", "no"],
        "r2": ["si", "no", "no", "si", "si", "no", "no", "no", "no"],
        "r3": ["si", "no", "no", "si", "si", "no", "si", "no", "no"],
        "r4": ["si", "no", "no", "si", "si", "no", "no", "no"],
        "r5": ["si", "no", "no", "si", "si", "no", "no", "no"]
      }
    },
    {
      "order": 1,
      "flujo": 0,
      "nemonic": "paef",
      "disable": true,
      "visible": true,
      "datePhaseEnd": "",
      "datePhaseInit": "",
      "datePhaseSubsInit": "2022-07-11 06:00:00",
      "datePhaseSubsEnd": "2022-07-14 16:00:00",
      "icon": "assets/img/checke.svg",
      "guiaRapida":"documents/s3?key=public/Guia_PAEF_MICRO.pdf", 
      "guiaRestitucion":"documents/s3?key=public/Manual_de_Restitucio%CC%81n_PAEF.PDF",
      "description": "SUBSANACIONES: desde el 11 de Febrero al 17 de Febrero del 2022 hasta las 10:00 am.",
      "title": "Programa Apoyo al Empleo Formal Subsidio a la nómina - PAEF MICRO",
      "subTitleLogin": "Solicite aquí el subsidio de nómina para su empresa.",
      "subtitle": "Las aplicaciones para PAEF MICRO han cerrado. Este portal solo está habilitado para realizar correcciones y envío de solicitudes en curso hasta el 17 de Febrero a las 10 am.",
      "important": "Si es una persona natural que tiene más de un tipo de documento de identificación, debe señalar únicamente el tipo y número de identidad con el cual realiza sus pagos en PILA, está registrado en el RUES, el RUAF y cumple los demás requisitos del programa",
      "resolucion":"De acuerdo con lo establecido en el Decreto Legislativo 639 de 2020, modificado por los Decretos Legislativos 677 de 2020 y 815 del 4 de junio de 2020 y por las Leyes 2060 de 2020 y 2155 de 2021, los requisitos manifestados a continuación son habilitantes para continuar con la postulación al beneficio del PAEF. Si no cumple con alguno de ellos, absténgase de continuar con esta solicitud. Su respuesta puede ser SI o NO dependiendo del caso.", 
      "subsanacion": true,
      "router": "logins",
      
      "loginChecks": [
        {
          "checkb": true, 
          "check": "Radicar PAEF MICRO del mes, por primera vez"
        },
        {
          "checkb": true, 
          "check": "Carga de documentos o correcciones para PAEF MICRO"
        },
        {
          "checkb": true, 
          "check": "Consultar estado de solicitud"
        }
      ],
      
      "months": [
        { "name": "Octubre", "id": "10,2021", "phase": "17" },
        { "name": "Noviembre", "id": "11,2021", "phase": "18" },
        { "name": "Diciembre", "id": "12,2021", "phase": "19" }
      ],
      
      "formChecks": [
        "En desarrollo de lo establecido en el artículo 3 del Decreto Legislativo 639 de 2020, modificado por el artículo 2 del Decreto Legislativo 677 de 2020 y por el artículo 3 del Decreto Legislativo 815 de 2020, los empleados sobre los que se reconoce el presente subsidio, corresponden a aquellos reportados en la Planilla Integrada de Liquidación de Aportes (PILA) para el periodo de cotización del mes inmediatamente anterior al de la postulación a cargo de dicho beneficiario, en las condiciones señaladas en el artículo 7 de la Resolución 2162 de 2020, modificado por el artículo 6 de la Resolución 2430 de 2021.",
        "Verificación de requisitos: Con el objetivo de que la UGPP pueda adelantar la verificación de requisitos señalada en el artículo 7 de la Resolución 2162 de 2020, modificado por el artículo 6 de la Resolución 2430 de 2021, certifico que se encuentran pagados los aportes de los trabajadores objeto del subsidio.",
        "De acuerdo con lo establecido en el Decreto Legislativo 639 de 2020, modificado por los Decretos Legislativos 677 de 2020 y 815 del 4 de junio de 2020 y por las Leyes 2060 de 2020 y 2155 de 2021, expreso mi consentimiento y certifico el cumplimiento de los requisitos establecidos en la norma, los cuales he declarado bajo la gravedad del juramento y asumo la responsabilidad derivada de cualquier inexactitud o incumplimiento de las disposiciones pertinentes.",
        "En el caso en que la(s) cuenta(s) inscrita(s) en el presente formulario presenten o alguna novedad (sobregiros, embargos u otros) que no permita el abono de los recursos del Programa de Apoyo al Empleo Formal - PAEF, solicito al Banco de Bogotá la apertura de una cuenta de ahorros cuyo uso exclusivo me comprometo sea la recepción y disposición de dichos recursos. Para tales efectos, declaro que Banco de Bogotá me informó las condiciones del reglamento de la cuenta de ahorros, el cual he tenido a mi disposición en la página www.bancodebogotá.com.co, y con la firma del presente formulario declaro conocer y aceptar expresamente."
      ],

      "questions": {
        "messages": {
          "resolucion": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en el artículo 5 de la Resolución 2162 de 2020, modificado por el artículo 5 de la Resolución 2430 de 2021 *",
          "respno": "Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2430 del 2021 para más información.",
          "respsi": "Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2430 del 2021 para más información."
        },
        "0": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.",
            "number": "8",
            "resp": "si"
          }
      
        ],
        "1": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene una participación de la Nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.",
            "number": "8",
            "resp": "si"
          },
          {
            "text": "Tuvo dos (2) o más empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) correspondiente al periodo de cotización del mes de febrero de 2020, en las condiciones establecidas en el artículo 7 de la Resolución 2162 de 2020, modificado por el artículo 6 de la Resolución 2430 de 2021.",
            "number": "9",
            "resp": "si"
          },
          {
            "text": "Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del Decreto 1081 de 2015",
            "number": "10",
            "resp": "no"
          }
        ],
        "2": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.",
            "number": "8",
            "resp": "si"
          }
        ],
        "3": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020",
            "number": "8",
            "resp": "si"
          }
        ],
        "4": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.",
            "number": "8",
            "resp": "si"
          }
        ],
      
        "5": [
          {
            "text": "¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ",
            "number": "1",
            "sub": "Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.",
            "resp": "si",
            "opc": true
          },
          {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.",
            "number": "8",
            "resp": "si"
          }
        ]
      },
      "questionsArray": [
        { "k": "POS15-PAEF", "v": "" },
        { "k": "CUM1-PAEF", "v": "" },
        { "k": "CUM2-PAEF", "v": "" },
        { "k": "CUM4-PAEF", "v": "" }, 
        { "k": "CUM5-PAEF", "v": "" },
        { "k": "CUM6-PAEF", "v": "" },
        { "k": "CUM7-PAEF", "v": "" },
        { "k": "CON1-PAEF", "v": "" },
        { "k": "NAT1-PAEF", "v": "" },
        { "k": "NAT2-PAEF", "v": "" },
        { "k": "CUM3-PAEF", "v": "" } 
      ],

      "notNeededArray": [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        [],
        //Cooperativa
        []
      ],
      
      "answers": {
        "r1": ["si", "si", "si", "no", "no", "si", "si", "si"],
        "r2": ["si", "si", "si", "no", "no", "si", "si", "si", "si", "no"],
        "r3": ["si", "si", "si", "no", "no", "si", "si", "si"],
        "r4": ["si", "si", "si", "no", "no", "si", "si", "si"],
        "r5": ["si", "si", "si", "no", "no", "si", "si", "si"],
        "r6": ["si", "si", "si", "no", "no", "si", "si", "si"]
      }
    },
    {
      "order": 2,
      "flujo": 5,
      "nemonic": "paro",
      "disable": true,
      "visible": true,
      "datePhaseEnd": "",
      "datePhaseInit": "",
      "datePhaseSubsInit": "",
      "datePhaseSubsEnd": "",
      "icon": "assets/img/area-copy-2.svg",
      "guiaRapida":"documents/s3?key=public/Guia_PARO.pdf", 
      "guiaRestitucion":"documents/s3?key=public/Manual_de_Restitucio%CC%81n_PAEF.PDF",
      "description": "SUBSANACIONES: desde el 04 de Diciembre al 09 de Diciembre del 2021 hasta las 10:00 am",
      "title": "Programa de apoyo a empresas afectadas por el PARO NACIONAL.",
      "subTitleLogin": "Solicite aquí el apoyo para su empresa.",
      "subtitle": "Las aplicaciones para Paro Nacional han cerrado. Este portal solo está habilitado para realizar correcciones y envío de solicitudes en curso hasta el 09 de Diciembre a las 10 am.",
      "important": "Si es una persona natural que tiene más de un tipo de documento de identificación, debe señalar únicamente el tipo y número de identidad con el cual realiza sus pagos en PILA, está registrado en el RUES, el RUAF y cumple los demás requisitos del programa",
      "resolucion":"De acuerdo con lo establecido en el artículo 26 de la Ley 2155 de 2021 y la Resolución 2474 del mismo año, los requisitos manifestados a continuación son habilitantes para continuar con la postulación al beneficio del Programa de apoyo a empresas afectadas por el paro nacional. Si no cumple con alguno de ellos, absténgase de continuar con esta solicitud. Su respuesta puede ser SI o NO dependiendo del caso.",
      "subsanacion": false,
      "router": "logins",
      
      "loginChecks": [
        {
          "checkb": true, 
          "check": "Radicar formulario del mes, por primera vez"
        },
        {
          "checkb": true, 
          "check": "Carga de documentos o correcciones"
        },
        {
          "checkb": true, 
          "check": "Consultar estado de solicitud"
        }
      ],
      
      "months": [
        { "name": "Mayo", "id": "5,2021" },
        { "name": "Junio", "id": "6,2021" }
      ],
      
      "formChecks": [
        "En desarrollo de lo establecido en el artículo 26 de la Ley 2155 de 2021, los empleados sobre los que se reconoce el presente subsidio, corresponden a aquellos reportados en la Planilla Integrada de Liquidación de Aportes (PILA) para los periodos de cotización de los meses de mayo y/o junio de 2021 a cargo de dicho beneficiario, en las condiciones señaladas en el artículo 7 de la Resolución 2474 de 2021.",
        "Con el objetivo de que la UGPP pueda adelantar la verificación de requisitos señalada en el artículo 7 de la Resolución 2474 de 2021, certifico que se encuentran pagados los aportes de los trabajadores objeto del subsidio.",
        "De acuerdo con lo establecido en el artículo 26 de la Ley 2155 de 2021 y la Resolución 2474 del mismo año, expreso mi consentimiento y certifico el cumplimiento de los requisitos establecidos en la norma, los cuales he declarado bajo la gravedad del juramento y asumo la responsabilidad derivada de cualquier inexactitud o incumplimiento de las disposiciones pertinentes.",
        "En el caso en que la(s) cuenta(s) inscrita(s) en el presente formulario presenten o alguna novedad (sobregiros, embargos u otros) que no permita el abono de los recursos para apoyar a las empresas afectadas por el paro nacional - PARO , solicito al Banco de Bogotá la apertura de una cuenta de ahorros cuyo uso exclusivo me comprometo sea la recepción y disposición de dichos recursos. Para tales efectos, declaro que Banco de Bogotá me informó las condiciones del reglamento de la cuenta de ahorros, el cual he tenido a mi disposición en la página www.bancodebogotá.com.co, y con la firma del presente formulario declaro conocer y aceptar expresamente."
      ],

      "questions": {
        "messages": {
          "resolucion": "De acuerdo con lo establecido en el artículo 26 de la Ley 2155 de 2021 y la Resolución 2474 del mismo año, los requisitos manifestados a continuación son habilitantes para continuar con la postulación al beneficio del Programa de apoyo a empresas afectadas por el paro nacional. Si no cumple con alguno de ellos, absténgase de continuar con esta solicitud. Su respuesta puede ser SI o NO dependiendo del caso.",
          "respno": "Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2474 del 2021 para más información.",
          "respsi": "Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2474 del 2021 para más información."
        },
      
      "0": [
        {
          "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
          "number": "1",
          "resp": "si"
        },
        {
          "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
          "number": "2",
          "resp": "si"
        },
        {
          "text": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021",
          "number": "3",
          "resp": "si"
        },
        {
          "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
          "number": "4",
          "resp": "no"
        },
        {
          "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
          "number": "5",
          "resp": "no"
        },
        {
          "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
          "number": "6",
          "resp": "si"
        },
        {
          "text": "Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.",
          "number": "7",
          "resp": "si"
        }
      ],
      "1": [
        {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.",
            "number": "7",
            "resp": "si"
          },
          {
            "text": "Tuvo dos (2) o más empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) correspondiente al periodo de cotización del mes de febrero de 2020, en las condiciones establecidas en el artículo 7 de la Resolución 2474 de 2021.",
            "number": "8",
            "resp": "si"
          },
          {
            "text": "Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del Decreto 1081 de 2015.",
            "number": "9",
            "resp": "no"
          }
      ],
      "2": [
        {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.",
            "number": "7",
            "resp": "si"
          }
      ],
      "3": [
        {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si" 
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.",
            "number": "7",
            "resp": "si"
          }
      ],
      "4": [
        {
            "text": "Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*",
            "number": "1",
            "resp": "si"
          },
          {
            "text": "Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.",
            "number": "2",
            "resp": "si"
          },
          {
            "text": "Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021",
            "number": "3",
            "resp": "si"
          },
          {
            "text": "Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *",
            "number": "4",
            "resp": "no"
          },
          {
            "text": "Ha  presentado esta misma solicitud ante otra entidad financiera. *",
            "number": "5",
            "resp": "no"
          },
          {
            "text": "Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.",
            "number": "6",
            "resp": "si"
          },
          {
            "text": "Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.",
            "number": "7",
            "resp": "si"
          }
        ]
      },
      
      "questionsArray": [
        { "k": "CUM1-PAEF", "v": "" },
        { "k": "CUM2-PAEF", "v": "" },
        { "k": "CUM3-PAEF", "v": "" },
        { "k": "CUM4-PAEF", "v": "" }, 
        { "k": "CUM5-PAEF", "v": "" },
        { "k": "CUM6-PAEF", "v": "" },
        { "k": "CON1-PAEF", "v": "" },
        { "k": "NAT1-PAEF", "v": "" },
        { "k": "NAT2-PAEF", "v": "" }
      ],

      "notNeededArray": [
        [8, 9, 10],
        [2, 5, 10],
        [8, 9, 10],
        [8, 9],
        [8, 9, 10]
      ],
  
      "answers": {
        "r1": ["si", "si", "si", "no", "no", "si", "si"],
        "r2": ["si", "si", "si", "no", "no", "si", "si", "si", "no"],
        "r3": ["si", "si", "si", "no", "no", "si", "si"],
        "r4": ["si", "si", "si", "no", "no", "si", "si"],
        "r5": ["si", "si", "si", "no", "no", "si", "si"]
      }
    }
  ];

  // public readonly months = [
  //   {month: 1,  name: "Enero"},
  //   {month: 2,  name: "Febrero"},
  //   {month: 3,  name: "Marzo"},
  //   {month: 4,  name: "Abril"},
  //   {month: 5,  name: "Mayo"},
  //   {month: 6,  name: "Junio"},
  //   {month: 7,  name: "Julio"},
  //   {month: 8,  name: "Agosto"},
  //   {month: 9,  name: "Septiembre"},
  //   {month: 10, name: "Octubre"},
  //   {month: 11, name: "Noviembre"},
  //   {month: 12, name: "Diciembre"}
  // ];

  public urlData = environment.endpointManager;

  constructor(
    private http: HttpClient,
    private cryptoService : CryptoService
  ) { }

  ngOnInit() {
    console.log("[FlowService][ngOnInit]");
  }
  
  public sendRequestGetFlows(){
    return this.http.get(this.urlData + 'core/getflows/');
  }
  
  public sendRequestGetFlows1(){
    const headers = {
      'Content-Type': 'application/json'
    };
    const headers2 = {
      'Content-Type': 'application/json'
    };
    const queryParams = {
    };
    const { encryptedBody,options } =
      this.cryptoService.setEncryptionRequest(queryParams,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers2),
        headers
      );
    return this.http.get(environment.apiSbx.concat(`core/getflows/?cypher=${encryptedBody}`),
    {
      headers: options,
      params: undefined,
      responseType: 'text',
      observe: 'response',
    }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }
  
  public sendRequestGetFlow(flowId: number = null){
    const headers= new HttpHeaders().set('flow', flowId+"");
    return this.http.get(this.urlData + 'core/getflows/' + flowId + '/', {'headers': headers});
  }

  public getFlow(flowId: number = null){
    return new Promise((resolve, reject) => {
      let request = this.sendRequestGetFlow(flowId);
      request.subscribe(
        (resp: any) => {
          //console.log("response: ", resp);
          //resolve(this.getFlowOrdered(resp[0]));
          resolve(resp[0])
        },
        (err: any) => {
          console.log("error: ", err);
          //resolve(this.getFlowOrdered(this.getFlowByID(flowId)));
          resolve(this.getFlowByID(flowId));
        }
      );
    });
  };

  public getFlows(){
    return new Promise((resolve, reject) => {
      let request = this.sendRequestGetFlows();
      request.subscribe(
        (resp: any) => {
          this.listFlows = JSON.parse(resp.body);
          resolve(this.getFlowsOrdered(true, this.listFlows));
        },
        (err: any) => {
          console.log("error: ", err);
          resolve(this.getFlowsOrdered(true, this.listFlowsBk));
        }
      );
    });
  }

  // public getFlowOrdered(currentFlow: any){
  //   let datePhaseInit = new Date(currentFlow.datePhaseInit);
  //   let datePhaseEnd = new Date(currentFlow.datePhaseEnd);
  //   let datePhaseSubsInit = new Date(currentFlow.datePhaseSubsInit);
  //   let datePhaseSubsEnd = new Date(currentFlow.datePhaseSubsEnd);

  //   console.log("postulation date: " + this.isOnDate(datePhaseInit, datePhaseEnd));
  //   console.log("subsanation date: " + this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd));

  //   currentFlow.disable = this.isOnDate(datePhaseInit, datePhaseEnd) || this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? false : true;

  //   currentFlow.subtitle = this.isOnDate(datePhaseInit, datePhaseEnd) ? currentFlow.subtitle.replace('{{datePhaseInit}}', this.transformDate(datePhaseInit, false)) : currentFlow.subtitle;
  //   currentFlow.subtitle = this.isOnDate(datePhaseInit, datePhaseEnd) ? currentFlow.subtitle.replace('{{datePhaseEnd}}', this.transformDate(datePhaseEnd, true)) : currentFlow.subtitle;

  //   currentFlow.subtitleSubs = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.subtitleSubs.replace('{{datePhaseSubsInit}}', this.transformDate(datePhaseSubsInit, false)) : currentFlow.subtitleSubs;
  //   currentFlow.subtitleSubs = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.subtitleSubs.replace('{{datePhaseSubsEnd}}', this.transformDate(datePhaseSubsEnd, true)) : currentFlow.subtitleSubs;

  //   currentFlow.description = this.isOnDate(datePhaseInit, datePhaseEnd) ? currentFlow.description.replace('{{datePhaseInit}}', this.transformDate(datePhaseInit, false)) : currentFlow.description;
  //   currentFlow.description = this.isOnDate(datePhaseInit, datePhaseEnd) ? currentFlow.description.replace('{{datePhaseEnd}}', this.transformDate(datePhaseEnd, true)) : currentFlow.description;

  //   currentFlow.descriptionSubs = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.descriptionSubs.replace('{{datePhaseSubsInit}}', this.transformDate(datePhaseSubsInit, false)) : currentFlow.descriptionSubs;
  //   currentFlow.descriptionSubs = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.descriptionSubs.replace('{{datePhaseSubsEnd}}', this.transformDate(datePhaseSubsEnd, true)) : currentFlow.descriptionSubs;

  //   currentFlow.description = currentFlow.disable ? "Cronograma por definir" : currentFlow.description;
  //   currentFlow.subsanacion = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd);
  //   currentFlow.subtitle = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.subtitleSubs : currentFlow.subtitle;
  //   currentFlow.description = this.isOnDate(datePhaseSubsInit, datePhaseSubsEnd) ? currentFlow.descriptionSubs : currentFlow.description;
  //   currentFlow.loginChecks[0].checkb = !currentFlow.subsanacion;

  //   return currentFlow;
  // }

  public getFlowsOrdered(reorder:boolean = false, data: any[]){
    let sortedList = data.sort((a: any, b: any) => {return a.order - b.order});
    
    return reorder ? sortedList.map((currentFlow, index) => {
      currentFlow.order = index+1;
      return currentFlow;
    }) : sortedList;
  }

  public isNotEmpty(value){
    return (value != undefined && value != null && value != "")
  }

  // public isOnDate(datePhaseInit, datePhaseEnd){
  //   let actualDatePhase = new Date();
  //   return (this.isNotEmpty(datePhaseInit) && this.isNotEmpty(datePhaseEnd)) ? (actualDatePhase >= datePhaseInit && actualDatePhase < datePhaseEnd) : false;
  // }

  public getFlowByID(id: number = 0){
    let dataList = this.getFlowsOrdered(true, this.listFlowsBk);
    return dataList.find(data => data.flujo == id);
  }

  public cloneJson(arrData: any[]){
    return JSON.parse(JSON.stringify(arrData));
  }

  public getInitialArray(){
    return this.listFlows;
  }

  // public transformDate(date, withHour=true){
  //   //25 de agosto del 2022 hasta las 4:00 pm.
  //   let day = date.getDate();
  //   let monthEs = this.getMonth(date.getMonth()+1);
  //   let year = date.getFullYear();
  //   let formatted12Hour = date.toLocaleString('es-CO', { hour: 'numeric', minute: 'numeric', hour12: true });

  //   return withHour ? (day + ' de ' + monthEs + ' del ' + year + ' hasta las ' + formatted12Hour) : (day + ' de ' + monthEs + ' del ' + year);
  // }

  // public getMonth(month){
  //   let monthFilter = this.months.find(data => data.month == month);
  //   return monthFilter != undefined ? monthFilter.name : '';
  // }
}