import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from '@app/shared/shared.module';
import { OutdatedComponent } from './outdated/outdated.component';
import { OutserviceComponent } from './outservice/outservice.component';

@NgModule({
  declarations: [
    OutdatedComponent,
    OutserviceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    DeviceDetectorModule.forRoot()
  ],
  providers:[]
})
export class ExceptionsModule { }