

import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { UploadDocService } from '@core/service/upload-doc.service';
import { UtilsService } from './utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var Jimp = require('jimp');

@Injectable({
  providedIn: 'root'
})
export class JimpService {
  data: any;
  original: any;
  sizeOriginal: any;
  sizeNew: any;
  sizeConverter: any;

  constructor(private uploadDocService: UploadDocService, 
    private utilsService: UtilsService,
    private httpClient: HttpClient) {
  }

  encoding64(inputValue: any) {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    var image: any
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      var base64 = myReader.result.toString()
      this.original = base64
      Jimp.read(base64).then((img) => {
        this.dataURItoBlob(base64.split('base64,')[1], img.getMIME()).subscribe(value => {
          const imageFile = new File([value], status, { type: img.getMIME() }); //Valideted for update Angular
          this.sizeConverter = imageFile.size
          console.log("Imagen Convertida", imageFile.size);
        })
      })
      return base64
    }
  }

  dataURItoBlob(dataURI, type): Observable<Blob> {
    console.log("my url -------------------------",dataURI);
    
    return Observable.create((observer: Observer<Blob>) => {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: type });
      observer.next(blob);
      observer.complete();
    });
  }

  urlFrom(dataURI){
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json'})
    };
    return this.httpClient.get(dataURI);
  }

  loadPdf(inputValue: any, name, key, id, shortname, i, remplaze, callback: () => void, ) {
    var file: File = inputValue;
    var myReader: FileReader = new FileReader();
    var image: any
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      var base64 = myReader.result.toString()
      this.dataURItoBlob(base64.split('base64,')[1], file.type).subscribe(value => {
        const imageFile = new File([value], name, { type: value.type });
        if (remplaze) {
          this.uploadDocService.replaceDocument(imageFile, key, base64, id, shortname, name, i, "pdf")
        } else {
          this.uploadDocService.addDocumentToUpload(imageFile, key, base64, id, shortname, name, "pdf")
        }
        callback()
      })
    }
  }

  optimize(inputValue: any, name, key, id, shortname, i, remplaze, callback: (result) => void) {
    var file: File = inputValue;
    this.sizeOriginal = file.size
    console.log("imagen original", file.size);
    var myReader: FileReader = new FileReader();
    var image: any
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      var base64 = myReader.result.toString()
      const imageFile = file;//COMENTAR
      const base64Image = myReader.result.toString()//COMENTAR
      /*Jimp.read(base64).then((img) => {
        let w = img.bitmap.width - 120; // the width of the image
        let h = img.bitmap.height;
        img.resize(w, Jimp.AUTO);
        img.scale(0.60);
        img.quality(85);
        img.getBase64Async(img.getMIME()).then(base64Image => {
          this.data = base64Image
          this.dataURItoBlob(base64Image.split('base64,')[1], img.getMIME()).subscribe(value => {
            const imageFile = new File([value], name, { type: img.getMIME() });*/
            if (remplaze) {
              this.uploadDocService.replaceDocument(imageFile, key, base64.split('base64,')[1], id, shortname, name, i, "img")
            } else {
              this.uploadDocService.addDocumentToUpload(imageFile, key, base64Image.split('base64,')[1], id, shortname, name, "img")
            }
            /*this.sizeNew = imageFile.size*/
            console.log("Imagen Optimizada", imageFile.size);
            callback(imageFile)
          /*})
        })
      });*/
    }
  }


}