import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { WelcomeModule } from './welcome/welcome.module';
import { MaterialModule } from './shared/material.module';
import { ErrorInterceptorService } from './core/_helper/error.interceptor.service';
import { BdbPaefModule } from './bdb-paef/bdb-paef.module';
import { ModalGlobalComponent } from './shared/modal-global/modal-global.component';
import { ExceptionsModule } from './exceptions/exceptions.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { OperativaModule } from './bdb-operativa/operativa.module';
import { CryptoService } from '@core/service/crypto/crypto.service';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		RouterModule.forRoot(AppRoutingModule, { relativeLinkResolution: 'legacy' }),
		HttpClientModule,
		CoreModule,
		SharedModule,
		MaterialModule,
		AuthenticationModule,
		ExceptionsModule,
		OperativaModule,
		BdbPaefModule,
		WelcomeModule,
		NgxWebstorageModule.forRoot({ prefix: 'aliv', separator: '.', caseSensitive: true }),
		RecaptchaV3Module
	],
	providers: [
		CryptoService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptorService,
			multi: true
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcvvPcUAAAAAGZCwpoqZ7oM5cn8_BI-4rj6LBMc'
		},
		CookieService 
	],
	bootstrap: [AppComponent],
	entryComponents: [ModalGlobalComponent]
})
export class AppModule { }

