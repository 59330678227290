import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/shared/services/modal.service';
import { ArrayDefinitionService } from '@core/service/array.definition.service';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { FlowService } from '@core/service/flow.service';
import { StoreService } from '@core/service/store.service';


@Component({
  selector: 'aliv-new-preguntas',
  templateUrl: './new-preguntas.component.html',
  styleUrls: ['./new-preguntas.component.scss']
})
export class NewPreguntasComponent implements OnInit {
  public questionshow = false
  public answers = [];
  
  public generic = {
    si: "Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 4451 del 2023 para más información.",
    no: "Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 4451 del 2023 para más información."
  };

  public validac: boolean = false;
  public resp: any[] = [null];
  public checkRadio = new Array();
  public selectedOption: number = -1;
  public selectedDropDown: any;
  public flow: any = {};
  public questions: { k: string, v: string }[] = [];
  public notNeededArray = [];
  public valid: boolean = false;
  public respb: any[] = [null];
  public r1 = [];
  public r2 = [];
  public r3 = [];
  public r4 = [];
  public r5 = [];
  public r6 = [];
  public validBotPr: any[] = [null];
  public validBotPr4s: boolean = false;
  public validBotPr4n: boolean = false;
  public questionsShow: any = [];
  public flujo = 0;
  public nemonic = "";
  public messagesShow: any = {};
  question8Added: boolean = false;
  question7Added: boolean = false;

  // public casilla53RUTValues: { name: string, id: string }[] = [
  //   { name: '04 - Régimen especial', id: '04' },
  //   { name: '05 - Régimen ordinario', id: '05' },
  //   { name: '47 - Régimen de simple tributación', id: '47' }
  // ];

  public casilla53RUTValues = [];

  constructor(
    private authServerProviderService: AuthServerProviderService,
    private flowService: FlowService,
    private arrays: ArrayDefinitionService,
    private storeService: StoreService,
    private route: Router,
    private modalService: ModalService
  ) {
    this.flow = this.storeService.get("flow");
    this.flujo = this.storeService.get('flujo');
    this.nemonic = this.flow.nemonic;
    this.questions = this.flow.questionsArray;
    this.answers = this.flow.answers

    this.selectedOption = this.storeService.get("selectedOption") != false ? this.storeService.get("selectedOption") : 0;
    this.notNeededArray = this.flow.notNeededArray;
    this.questionsShow = this.flow.questions[this.selectedOption];
    this.messagesShow = this.flow.questions['messages'];
    this.casilla53RUTValues = this.arrays.getCasilla53RUTValues();

  }

  ngOnInit() {
    setTimeout(() => {
      document.body.style.backgroundColor = '#f6f8fa'
      window.scrollBy(0, -document.body.scrollHeight * 5);
      var elems = document.getElementsByTagName('html');
      for (var i = 0; i < elems.length; i++) {
        elems[i].style.backgroundColor = '#f6f8fa';
      }
      document.getElementById('progreso').style.width = '0%';
    }, 500)
    let estado = this.storeService.get("estado");
    console.log("estado: " + estado);
    if (estado == "formulario") {
      this.route.navigate(['paef/inbox/formulario'])
    } else {
      if (estado == "terminos" || estado == "preguntas") {
        this.storeService.set("estado", "preguntas")
      }
      else {
        this.return();
      }
    }

    // this.flowService.getFlow(this.storeService.get('flujo'))
    //   .then(
    //     (response: any) => {
    //       this.flow = response;
    //       this.flujo = this.storeService.get('flujo');
    //       this.storeService.set('flow', this.flow);
    //       this.nemonic = this.flow.nemonic;
    //       this.questions = this.flow.questionsArray;
    //       this.answers = this.flow.answers
    //       this.notNeededArray = this.flow.notNeededArray;

    //       this.selectedOption = this.storeService.get("selectedOption") != false ? this.storeService.get("selectedOption") : 0;

    //       this.questionsShow = this.flow.questions[this.selectedOption];
    //       this.messagesShow = this.flow.questions['messages'];
    //     }
    //   ).catch((error) => {
    //     console.log("error getting flow");
    //     //this.route.navigate(['login']);
    //   });

    for (let index = 0; index < 4; index++) {
      this.checkRadio.push(false);
    }

    // Precargar respuestas
    if (this.flujo !== 2) {
      if (this.storeService.get('retakeStep') == 'preguntas') {
        setTimeout(() => {
          this.patchLastValues();
        }, 500)
      }
    }
  }

  changeRadioButton(position) {
    for (let index = 0; index < this.checkRadio.length; index++) {
      this.checkRadio[index] = false;
    }
    this.checkRadio[position] = true;
    this.selectedOption = position;

    this.questionsShow = this.flow.questions[this.selectedOption];
    this.messagesShow = this.flow.questions['messages'];

    //this.questionsShow = this.questionService.questionsRepo[this.nemonic]['0']
    //this.messagesShow = this.questionService.questionsRepo[this.nemonic]['messages']
  }

  isTypeNit() {
    let docType = this.storeService.get('docTypeSS');
    return (docType == 'N' || docType == 'L' || docType == 'I')
  }

  isNaturePersonType() {
    let docType = this.storeService.get('docTypeSS');
    return !(docType == 'N' || docType == 'I')
  }

  selectButons(element, index) {
    if (element.v.toLowerCase() === 'si') {
      this.validDatars(index);
    } else if (element.v.toLowerCase() === 'no') {
      this.validDatarn(index);
    }
  }

  isRetaking(index) {
    return ((this.storeService.get('retakeStep') == 'preguntas') && this.storeService.get('selectedOption') != index);
  }

  return() {
    this.authServerProviderService.logoutBasic2()
  }

  patchLastValues() {
    let s = this.storeService.get("selectedOption")
    console.log(s);
    this.changeRadioButton(s);
    let lastArray = this.storeService.get('lastQuestions');
    console.log(lastArray);
    for (let i = 0; i < lastArray.length; i++) {
      const element = lastArray[i];
      console.log("Element->", i, element);
      this.searchQuestion(element['k'],element['v'],i)
      if(element['k'] == "CUM71-PAEF"){
        this.selectedDropDown = element['v']
      }
    }
  }

  searchQuestion(key,value,i){
    let array = this.answers[this.selectedOption]
    for (const iterator of array) {
      if(iterator.id == key){
        iterator.respUser = value
        iterator.check = true
        if(value == "si"){
          this.showQuestions(i)
        }else{
          this.hideQuestions(i)
        }
      }
    }
  }

  continuar() {
    let preguntasObject = this.questions;
    this.storeService.set("selectedOption", this.selectedOption);
    this.storeService.set("preguntasObject", preguntasObject);
    if (this.storeService.get("retakeStep")) {
      this.storeService.set("retakeStep", "formulario");//ENVIAR
    }
    this.route.navigate(['paef/inbox/formulario'])
  }

  validres() {
    this.questions = []
    console.log('[VALIDBUTON] in [VALIDRES]' + this.validButton())
    if (this.validButton()) {
      let array = this.answers[this.selectedOption]
      for (let i = 0; i < array.length; i++) {
        let item = {
          v: array[i]['respUser'], k: array[i]['id']
        }
        this.questions.push(item)
      }
      this.validateCampsZero(()=>{
        if(this.questionshow){
          let item = {
            v: this.selectedDropDown, k: "CUM71-PAEF"
          }
          this.questions.push(item)
        }
        this.continuar()
      })
      console.log('QUESTIONS----------', this.questions)
    } else {
      this.modalService.solicitudRechazada(resp => {
      });
    }
  }

  validateCampsZero(callback:() => void){
    let a = 0,b = 0,c = 0,d= 0
    console.log(a,b,c);
    
    for (const e of this.questions) {
      if(e.k == 'CUM8-PAEF'){
        a++
      }
      if(e.k == 'CON1-PAEF'){
        b++
      }
      if(e.k == 'NAT1-PAEF'){
        c++
      }
      if(e.k == 'COOP1-PAEF'){
        d++
      }
    }
    console.log(a,b,c);
    if(a==0){
      this.questions.push({v: "", k: "CUM8-PAEF"})
      this.questions.push({v: "", k: "CUM71-PAEF"})
    }
    if(b==0){
      this.questions.push({v: "", k: "CON1-PAEF"})
    }
    if(c==0){
      this.questions.push({v: "", k: "NAT1-PAEF"})
    }
    if(d==0){
      this.questions.push({v: "", k: "COOP1-PAEF"})
    }
    callback()
  }

  validButton() {
    let n = 0
    if(this.answers.length>0){
      for (let i = 0; i < this.answers[this.selectedOption].length; i++) {
        let item = this.answers[this.selectedOption][i]
        if (item["opc"]) {
          if (item["check"]) {
            n++
          }
        } else {
          if (item["check"]) {
            if (item["respCorrect"] == item["respUser"]) {
              n++
            }
          }
        }
      }
      if (n == this.answers[this.selectedOption].length) {
        if (this.questionshow) {
          if (this.selectedDropDown != undefined && this.selectedDropDown != "") {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }

  validDatars(i) {
    this.answers[this.selectedOption][i]["respUser"] = "si"
    this.answers[this.selectedOption][i]["check"] = true
    this.showQuestions(i)
  }

  validDatarn(i) {
    this.answers[this.selectedOption][i]["respUser"] = "no"
    this.answers[this.selectedOption][i]["check"] = true
    
      this.hideQuestions(i); // Ensure it removes the added questio
  }

  validText(i) {
    let item = this.answers[this.selectedOption][i]
    if (item["check"] == false) {
      return false
    } else {
      if (item["opc"] != false) {
        return false
      } else {
        if (item["respCorrect"] !== item["respUser"]) {
          return true
        } else {
          return false
        }
      }
    }
  }

  validBots(i) {
    let item = this.answers[this.selectedOption][i]
    if (item["opc"] == true) {
      if (item["respUser"] == "si") {
        return 'btn-res-con'
      } else {
        return 'btn-res'
      }
    } else {
      if (item["respCorrect"] === item["respUser"]) {
        if (item["respUser"] == "si") {
          return 'btn-res-con'
        } else {
          return 'btn-res'
        }
      } else {
        if (item['check'] == false) {
          return 'btn-res'
        } else {
          if (item["respUser"] == "si") {
            return 'btn-res-err'
          } else {
            return 'btn-res-erro'
          }
        }
      }
    }
  }

  validBotn(i) {
    let item = this.answers[this.selectedOption][i]
    if (item["opc"] == true) {
      if (item["respUser"] == "no") {
        return 'btn-res-con'
      } else {
        return 'btn-res'
      }
    } else {
      if (item["respCorrect"] === item["respUser"]) {
        if (item["respUser"] == "no") {
          return 'btn-res-con'
        } else {
          return 'btn-res'
        }
      } else {
        if (item['check'] == false) {
          return 'btn-res'
        } else {
          if (item["respUser"] == "no") {
            return 'btn-res-err'
          } else {
            return 'btn-res-erro'
          }
        }
      }
    }
  }

  showQuestions(i) {
    if (this.flujo == 6) {
        if (this.selectedOption == 1 || this.selectedOption == 2) {
            if (i == 7 && !this.question8Added) {
                this.addQuestionAutorretenedor(8);
                this.question8Added = true; // Set the flag to true to prevent re-adding
            }
        } else {
            if (i == 6 && !this.question7Added) {
                this.addQuestionAutorretenedor(7);
                this.question7Added = true; // Set the flag to true to prevent re-adding
            }
        }
    }
}

  addQuestionAutorretenedor(i){
    let item = {
      id: "CUM8-PAEF",
      text: "Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.",
      subInfo: "",
      infoAlertError: "",
      respUser: "",
      respCorrect: "no",
      check: false,
      opc: true,
      visible: false,
      number: i
    }
    this.selectedDropDown = ""
    this.questionshow = true
    this.answers[this.selectedOption].push(item)
  }

  hideQuestions(i) {
    console.log("hideQuestions called with index:", i);
    console.log("Current length of answers array:", this.answers[this.selectedOption].length);

    // Check if the question should be hidden
    if (this.flujo == 6) {
        if (this.selectedOption == 1 || this.selectedOption == 2) {
            // Case for selectedOption 1 or 2
            if (i == 7 && this.answers[this.selectedOption].length > 8) {
                this.removeQuestion(8); // Call to remove specific question
            }
        } else {
            // Case for other selectedOptions
            if (i == 6 && this.answers[this.selectedOption].length > 7) {
                this.removeQuestion(7); // Call to remove specific question
            }
        }
    }
}
removeQuestion(questionNumber: number) {
  // Iterate through the answers array and find the question with the matching number
  this.answers[this.selectedOption] = this.answers[this.selectedOption].filter(question => question.number !== questionNumber);

  // Optionally reset flags or states if needed
  if (questionNumber === 8) {
      this.question8Added = false; // Reset specific flag if applicable
  }

  this.selectedDropDown = ""; // Clear dropdown if needed
  this.questionshow = false; // Hide the question section
}

}

