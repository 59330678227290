import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { ModalService } from '@app/shared/services/modal.service';
//import { LoginStepService } from '../../../authentication/service/login-step.service';
import { StoreService } from '@core/service/store.service';
import { QuestionService } from '@core/service/question.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FlowService } from '@core/service/flow.service';


@Component({
  selector: 'aliv-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.scss']
})
export class PreguntasComponent implements OnInit {

  public validac: boolean = false;
  public resp: any[] = [null];
  public checkRadio = new Array();
  public selectedOption: number = -1;
  public selectedDropDown: any;
  public flow: any = {};
  public questions: { k: string, v: string }[] = [];
  public notNeededArray = [];
  public valid: boolean = false;
  public respb: any[] = [null];
  public r1 = [];
  public r2 = [];
  public r3 = [];
  public r4 = [];
  public r5 = [];
  public r6 = [];
  public validBotPr: any[] = [null];
  public validBotPr4s: boolean = false;
  public validBotPr4n: boolean = false;
  /*public questions: { k: string, v: string }[] = [
    { k: 'garantiasFNG', v: '' },
    { k: 'prodVigilado', v: '' },
    { k: 'costrAntes2020', v: '' },
    { k: 'ingresos2019', v: '' },
    { k: 'ingresos2020', v: '' },
    { k: 'superior50', v: '' },
    { k: 'mismaSolicitud', v: '' },
    // { k: 'usoRecursos', v: '' },
    { k: 'recibeAporte', v: '' },
    { k: 'emplPlanilla', v: '' },
    { k: 'expuPolitica', v: '' },
    { k: 'cumple', v: '' },
    { k: 'declaraRenta', v: '' },
    { k: 'cum3Metodo', v: '' },
  ];
  public notNeededArray = [
    //Persona Jurídica
    [8, 9, 10],
    //Persona natural
    [2, 5, 10],
    //Entidad sin ánimo de lucro
    [8, 9, 10],
    //Consorcio o unión temporal
    [8, 9],
    //Patrimonio Autónomo Declarante
    [8, 9, 10]
  ]
  public valid: boolean = false
  public respb: any[] = [null]
  public r1 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si']
  public r2 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si', 'no', 'si']
  public r3 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si']
  public r4 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
  public r5 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si']
  public r6 = ['si', 'si', 'si', 'si', 'si', 'no', 'no', 'si', 'si']*/
  public questionsShow: any = [];
  public flujo = 0;
  public nemonic = "";
  public messagesShow: any = {};

  public casilla53RUTValues: { name: string, id: string }[] = [
    { name: '04 - Régimen especial', id: '04' },
    { name: '05 - Régimen ordinario', id: '05' },
    { name: '47 - Régimen de simple tributación', id: '47' }
  ];

  constructor(
    public authServerProviderService: AuthServerProviderService,
    private flowService: FlowService,
    private storeService: StoreService,
    private route: Router,
    public modalService: ModalService,
    public questionService: QuestionService
  ) { 
    this.flow = this.storeService.get("flow");
    this.flujo = this.storeService.get('flujo');
    this.nemonic = this.flow.nemonic;
    this.questions = this.flow.questionsArray;

    this.selectedOption = this.storeService.get("selectedOption") != false ? this.storeService.get("selectedOption") : 0;

    this.notNeededArray = this.flow.notNeededArray;

    this.r1 = this.flow.answers.r1;
    this.r2 = this.flow.answers.r2;
    this.r3 = this.flow.answers.r3;
    this.r4 = this.flow.answers.r4;
    this.r5 = this.flow.answers.r5;
    this.r6 = this.flow.answers.r6;

    this.questionsShow = this.flow.questions[this.selectedOption];
    this.messagesShow = this.flow.questions['messages'];

    //this.questionsShow = this.questionService.questionsRepo[this.nemonic]['0']
    //this.messagesShow = this.questionService.questionsRepo[this.nemonic]['messages']
  }

  ngOnInit() {
    setTimeout(() => {
      document.body.style.backgroundColor = '#f6f8fa'
      window.scrollBy(0, -document.body.scrollHeight * 5);
      var elems = document.getElementsByTagName('html');
      for (var i = 0; i < elems.length; i++) {
        elems[i].style.backgroundColor = '#f6f8fa';
      }
      document.getElementById('progreso').style.width = '0%';
    }, 500)
    let estado = this.storeService.get("estado");
    console.log("estado: " + estado);
    if (estado == "formulario") {
      this.route.navigate(['paef/inbox/formulario'])
    } else {
      if (estado == "terminos" || estado == "preguntas") {
        this.storeService.set("estado", "preguntas")
      }
      else {
        this.return();
      }
    }

    // this.flowService.getFlow(this.storeService.get('flujo'))
    // .then(
    //   (response: any) => {
    //     this.flow = response;
    //     this.flujo = this.storeService.get('flujo');
    //     this.storeService.set('flow', this.flow);
    //     this.nemonic = this.flow.nemonic;
    //     this.questions = this.flow.questionsArray;

    //     this.notNeededArray = this.flow.notNeededArray;

    //     this.r1 = this.flow.answers.r1;
    //     this.r2 = this.flow.answers.r2;
    //     this.r3 = this.flow.answers.r3;
    //     this.r4 = this.flow.answers.r4;
    //     this.r5 = this.flow.answers.r5;
    //     this.r6 = this.flow.answers.r6;

    //     this.selectedOption = this.storeService.get("selectedOption") != false ? this.storeService.get("selectedOption") : 0;

    //     this.questionsShow = this.flow.questions[this.selectedOption];
    //     this.messagesShow = this.flow.questions['messages'];
    //   }
    // ).catch((error)=>{
    //   console.log("error getting flow");
    //   //this.route.navigate(['login']);
    // });

    for (let index = 0; index < 4; index++) {
      this.checkRadio.push(false);
    }

    // Para cargar por defecto las preguntas
    //this.storeService.set('flujo',6)
    /*this.flujo = this.storeService.get('flujo');
    console.log("flujo " + this.flujo)
    if (this.flujo == 0) {
      this.r1 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
      this.r2 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si', 'si', 'no']
      this.r3 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
      this.r4 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
      this.r5 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
      this.r6 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si']
      this.notNeededArray = [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        [],
        //Cooperativa
        []
      ]
      this.questions = [
        { k: 'POS15-PAEF', v: '' }, //0
        { k: 'CUM1-PAEF', v: '' }, //1
        { k: 'CUM2-PAEF', v: '' }, //2
        { k: 'CUM4-PAEF', v: '' }, //3 
        { k: 'CUM5-PAEF', v: '' }, //4
        { k: 'CUM6-PAEF', v: '' }, //5
        { k: 'CUM7-PAEF', v: '' }, //6
        { k: 'CON1-PAEF', v: '' }, //7
        { k: 'NAT1-PAEF', v: '' }, //8
        { k: 'NAT2-PAEF', v: '' }, //9
        { k: 'CUM3-PAEF', v: '' } //10
      ];
      this.questionsShow = this.questionService.questionsRepo['paef']['0']
      this.messagesShow = this.questionService.questionsRepo['paef']['messages']

    }
    if (this.flujo == 1) {
      this.questionsShow = this.questionService.questionsRepo['pap']['0']
      this.messagesShow = this.questionService.questionsRepo['pap']['messages']
    }
    if (this.flujo == 2) {
      this.r1 = ['si', 'si', 'no', 'si', 'no', 'no', 'si', 'si', 'si']
      this.resp[4] = ''
      this.resp[9] = ''
      this.changeRadioButton(1, true)
      this.questionsShow = this.questionService.questionsRepo['papa']['1']
      this.messagesShow = this.questionService.questionsRepo['papa']['messages']
      this.notNeededArray = [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        []
      ]
    }
    if (this.flujo == 4) {
      this.r1 = ['si', 'no', 'no', 'si', 'si', 'no']
      this.r2 = ['si', 'no', 'no', 'si', 'si', 'no', 'no']
      this.r3 = ['si', 'no', 'no', 'si', 'si', 'no', 'si']
      this.r4 = ['si', 'no', 'no', 'si', 'si', 'no']
      this.r5 = ['si', 'no', 'no', 'si', 'si', 'no']
      this.notNeededArray = [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        []
      ]
      this.questions = [
        { k: 'CUM1-SAQDT', v: '' },
        { k: 'CUM2-SAQDT', v: '' },
        { k: 'CUM3-SAQDT', v: '' },
        { k: 'CUM4-SAQDT', v: '' },
        { k: 'CUM5-SAQDT', v: '' },
        { k: 'CUM6-SAQDT', v: '' },
        { k: 'CUM7-SAQDT', v: '' },
        { k: 'CUM8-SAQDT', v: '' }
      ];
      this.questionsShow = this.questionService.questionsRepo['sacudete']['0']
      this.messagesShow = this.questionService.questionsRepo['sacudete']['messages']
    }
    if (this.flujo == 5) {
      this.r1 = ['si', 'si', 'si', 'no', 'no', 'si', 'si']
      this.r2 = ['si', 'si', 'si', 'no', 'no', 'si', 'si', 'si', 'no']
      this.r3 = ['si', 'si', 'si', 'no', 'no', 'si', 'si']
      this.r4 = ['si', 'si', 'si', 'no', 'no', 'si', 'si']
      this.r5 = ['si', 'si', 'si', 'no', 'no', 'si', 'si']
      this.questions = [
        { k: 'CUM1-PAEF', v: '' }, //0
        { k: 'CUM2-PAEF', v: '' }, //1
        { k: 'CUM3-PAEF', v: '' }, //2
        { k: 'CUM4-PAEF', v: '' }, //3 
        { k: 'CUM5-PAEF', v: '' }, //4
        { k: 'CUM6-PAEF', v: '' }, //5
        { k: 'CON1-PAEF', v: '' }, //6
        { k: 'NAT1-PAEF', v: '' }, //7
        { k: 'NAT2-PAEF', v: '' }, //8

      ];
      this.questionsShow = this.questionService.questionsRepo['paro']['0']
      this.messagesShow = this.questionService.questionsRepo['paro']['messages']
    }
    if (this.flujo == 6) {
      this.r1 = ['si', 'no', 'no', 'si', 'si', 'no', 'no', 'no'] //Jurídica
      this.r2 = ['si', 'no', 'no', 'si', 'si', 'no', 'no', 'no', 'no'] //Narutal
      this.r3 = ['si', 'no', 'no', 'si', 'si', 'no', 'si', 'no', 'no'] //Cooperativa
      this.r4 = ['si', 'no', 'no', 'si', 'si', 'no', 'no', 'no'] //Consorcio
      this.r5 = ['si', 'no', 'no', 'si', 'si', 'no', 'no', 'no'] //Patrimonio autónomo
      this.notNeededArray = [
        //Persona Jurídica
        [],
        //Persona natural
        [],
        //Entidad sin ánimo de lucro / Cooperativa
        [],
        //Consorcio o unión temporal
        [],
        //Patrimonio Autónomo Declarante
        []
      ]
      this.questions = [
        { k: 'CUM1-PAEF', v: '' },
        { k: 'CUM2-PAEF', v: '' },
        { k: 'CUM3-PAEF', v: '' },
        { k: 'CUM4-PAEF', v: '' },
        { k: 'CUM5-PAEF', v: '' },
        { k: 'CUM6-PAEF', v: '' },
        { k: 'CUM8-PAEF', v: '' },
        { k: 'CUM7-PAEF', v: '' },
        { k: 'COOP1-PAEF', v: '' },
        { k: 'NAT1-PAEF', v: '' },
        { k: 'CUM71-PAEF', v: '' }
      ];
      this.questionsShow = this.questionService.questionsRepo['empleos']['0']
      this.messagesShow = this.questionService.questionsRepo['empleos']['messages']

    }*/

    // Precargar respuestas
    if (this.flujo !== 2) {
      if (this.storeService.get('retakeStep') == 'preguntas') {
        setTimeout(() => {
          this.patchLastValues();
        }, 500)
      }
    }
  }

  changeRadioButton(position, isManual: boolean) {
    for (let index = 0; index < this.checkRadio.length; index++) {
      this.checkRadio[index] = false;
    }
    this.checkRadio[position] = true;
    this.selectedOption = position;

    this.questionsShow = this.flow.questions[this.selectedOption];
    this.messagesShow = this.flow.questions['messages'];

    //this.questionsShow = this.questionService.questionsRepo[this.nemonic]['0']
    //this.messagesShow = this.questionService.questionsRepo[this.nemonic]['messages']
  }

  validDatars(i) {
    this.validBotPr[i - 1] = false
    this.resp[i - 1] = null
    if (document.getElementById('r' + 1 + 's').textContent = " Sí ") {
      this.resp[i - 1] = "si"
      this.validBotPr[i - 1] = true
    }
    this.validinfo()
  }

  validDatarn(i) {
    this.validBotPr[i - 1] = false
    this.resp[i - 1] = null
    if (document.getElementById('r' + 1 + 'n').textContent = " No ") {
      this.resp[i - 1] = "no"
      this.validBotPr[i - 1] = true
    }
    console.log(this.resp);
    this.validinfo()
  }

  validDatar4s() {
    this.validBotPr4s = false
    this.resp[10] = null
    this.resp[10] = "si"
    this.validBotPr4s = true
    this.validinfo()
  }

  validDatar4n() {
    this.validBotPr4n = false
    this.resp[10] = null
    this.resp[10] = "no"
    this.validBotPr4n = true
    this.validinfo()
  }

  isTypeNit() {
    let docType = this.storeService.get('docTypeSS');
    return (docType == 'N' || docType == 'L' || docType == 'I')
  }

  isNaturePersonType() {
    let docType = this.storeService.get('docTypeSS');
    return !(docType == 'N' || docType == 'I')
  }

  //validacion de las respuestas
  validinfo() {
    let n = 0
    for (let i = 0; i < 10; i++) {
      console.log(i);
      if (this.flujo != 5) {
        if (this.selectedOption == 1) {
          if (i != 3 && i != 4 && i != 0 && i != 2 && i != 5) {
            if (this.r1[i] == this.resp[i]) {
              this.respb[i] = false
            } else {
              n++
              this.respb[i] = true
            }
          }
        } else {
          if (i != 3 && i != 4 && i != 0) {
            if (this.r1[i] == this.resp[i]) {
              this.respb[i] = false
            } else {
              n++
              this.respb[i] = true
            }
          }
        }
      }

      else {
        if (i != 0) {
          if (this.r1[i] == this.resp[i] && this.selectedOption == 0) {
            this.respb[i] = false
          } else if (this.r2[i] == this.resp[i] && this.selectedOption == 1) {
            this.respb[i] = false
          } else if (this.r3[i] == this.resp[i] && this.selectedOption == 2) {
            this.respb[i] = false
          } else if (this.r4[i] == this.resp[i] && this.selectedOption == 3) {
            this.respb[i] = false
          } else if (this.r5[i] == this.resp[i] && this.selectedOption == 4) {
            this.respb[i] = false
          } else if (this.r6[i] == this.resp[i] && this.selectedOption == 5) {
            this.respb[i] = false
          } else {
            n++
            this.respb[i] = true
          }
        }
      }
    }

    if (this.selectedOption == 0) {

    } else if (this.selectedOption == 1) {

      if (this.resp[9] == this.r2[9]) {
        this.respb[9] = false
      } else {
        n++
        this.respb[9] = true
      }
      if (this.resp[8] == this.r2[8]) {
        this.respb[8] = false
      } else {
        n++
        this.respb[8] = true
      }
    } else if (this.selectedOption == 2) {

    } else if (this.selectedOption == 3) {
      if (this.resp[10] == this.r4[8]) {
        this.respb[10] = false
      } else {
        n++
        this.respb[10] = true
      }
    } else if (this.selectedOption == 4) {

    }
    if (n > 0) {
      this.validac = false
    } else {
      this.validac = true
    }
    if (this.flujo == 2) {
      n = 0
      console.log(this.r1);
      console.log(this.resp);
      for (let i = 0; i < this.r1.length; i++) {
        if (i != 3 && i != 4) {
          if (this.r1[i] == this.resp[i]) {
            this.respb[i] = false
          } else {
            n++
            this.respb[i] = true
          }
        }
      }
      if (n > 0) {
        this.validac = false
      } else {
        this.validac = true
      }
    }
    if (this.flujo == 4 || this.flujo == 0 || this.flujo == 5) {
      let respu = []
      if (this.selectedOption == 0) {
        respu = this.r1
      }
      if (this.selectedOption == 1) {
        respu = this.r2
      }
      if (this.selectedOption == 2) {
        respu = this.r3
      }
      if (this.selectedOption == 3) {
        respu = this.r4
      }
      if (this.selectedOption == 4) {
        respu = this.r5
      }
      if (this.flujo == 0 && this.selectedOption == 5) {
        respu = this.r6
      }
      n = 0
      for (let i = 0; i < respu.length; i++) {
        if (i == 5 && this.flujo != 0 && this.selectedOption != 1) {
          if (this.resp[i] == undefined) {
            n++
          }
        } else {
          if (respu[i] == this.resp[i] || this.questionsShow[i].opc) {
            this.respb[i] = false
          } else {
            n++
            this.respb[i] = true
          }

        }
      }
      console.log(n);
      console.log(this.resp);
      console.log(respu);
      console.log(this.respb);
      if (n > 0) {
        this.validac = false
      } else {
        this.validac = true
      }
    }
    if (this.flujo == 6) {
      console.log("SelectedDropDown->", this.selectedDropDown);
      let respu = []
      if (this.selectedOption == 0) {
        respu = this.r1
      }
      if (this.selectedOption == 1) {
        respu = this.r2
      }
      if (this.selectedOption == 2) {
        respu = this.r3
      }
      if (this.selectedOption == 3) {
        respu = this.r4
      }
      if (this.selectedOption == 4) {
        respu = this.r5
      }
      n = 0
      for (let i = 0; i < respu.length; i++) {
        if (respu[i] == this.resp[i] || this.questionsShow[i].opc) {
          this.respb[i] = false
        } else {
          n++
          this.respb[i] = true
        }
      }
      console.log(n);
      console.log(this.resp);
      console.log(respu);
      console.log(this.respb);
      if (n > 0) {
        this.validac = false
      } else {
        this.validac = true
      }
    }
    console.log("validacion", this.validac);

  }

  validres() {
    console.log('[VALIDBUTON] in [VALIDRES]' + this.validButton())
    if (this.validButton()) {
      this.validinfo()
      if (this.validac == true) {
        if (this.flujo == 4) {
          this.questions[0].v = this.resp[0]
          this.questions[1].v = this.resp[1]
          this.questions[2].v = this.resp[2]
          this.questions[3].v = this.resp[3]
          this.questions[4].v = this.resp[4]
          this.questions[5].v = this.resp[5]
          if (this.selectedOption == 2) {
            this.questions[6].v = this.resp[6]
          } else {
            //this.questions[7].v = this.resp[6]
          }
        } else if (this.flujo == 0) {
          if (this.selectedOption == 1) {
            this.questions[0].v = this.resp[0]
            this.questions[1].v = this.resp[1]
            this.questions[2].v = this.resp[2]
            this.questions[3].v = this.resp[3]
            this.questions[4].v = this.resp[4]
            this.questions[5].v = this.resp[5]
            this.questions[6].v = this.resp[6]
            this.questions[7].v = this.resp[7]
            this.questions[8].v = this.resp[8]
            this.questions[9].v = this.resp[9]
            this.questions[10].v = this.resp[10]

          } else if (this.selectedOption == 3) {
            this.questions[0].v = this.resp[0]
            this.questions[1].v = this.resp[1]
            this.questions[2].v = this.resp[2]
            this.questions[3].v = this.resp[3]
            this.questions[4].v = this.resp[4]
            this.questions[5].v = this.resp[5]
            this.questions[6].v = this.resp[6]
            this.questions[7].v = this.resp[7]
            this.questions[8].v = ""
            this.questions[9].v = ""
            this.questions[10].v = this.resp[10]

          } else {
            this.questions[0].v = this.resp[0]
            this.questions[1].v = this.resp[1]
            this.questions[2].v = this.resp[2]
            this.questions[3].v = this.resp[3]
            this.questions[4].v = this.resp[4]
            this.questions[5].v = this.resp[5]
            this.questions[6].v = this.resp[6]
            this.questions[7].v = this.resp[7]
            this.questions[8].v = ""
            this.questions[9].v = ""
            this.questions[10].v = this.resp[10]
          }
        } else if (this.flujo == 5) {
          if (this.selectedOption == 1) {
            this.questions[0].v = this.resp[0]
            this.questions[1].v = this.resp[1]
            this.questions[2].v = this.resp[2]
            this.questions[3].v = this.resp[3]
            this.questions[4].v = this.resp[4]
            this.questions[5].v = this.resp[5]
            this.questions[6].v = this.resp[6]
            this.questions[7].v = this.resp[7]
            this.questions[8].v = this.resp[8]

          } else {
            this.questions[0].v = this.resp[0]
            this.questions[1].v = this.resp[1]
            this.questions[2].v = this.resp[2]
            this.questions[3].v = this.resp[3]
            this.questions[4].v = this.resp[4]
            this.questions[5].v = this.resp[5]
            this.questions[6].v = this.resp[6]
            this.questions[7].v = ""
            this.questions[8].v = ""

          }
        } else if (this.flujo == 6) {
          this.questions[0].v = this.resp[0]//CUM1
          this.questions[1].v = this.resp[1]//CUM2
          this.questions[2].v = this.resp[2]//CUM3
          this.questions[3].v = this.resp[3]//CUM4
          this.questions[4].v = this.resp[4]//CUM5
          this.questions[5].v = this.resp[5]//CUM6
          this.questions[10].v = this.selectedDropDown//CUM7.1

          //Si selecciona Persona Natural
          if (this.selectedOption == 1) {
            this.questions[6].v = this.resp[8]//CUM7
            this.questions[7].v = this.resp[7]//CUM8
            this.questions[8].v = ""//COOP1
            this.questions[9].v = this.resp[6]//NAT1
            //Si selecciona Cooperativa  
          } else if (this.selectedOption == 2) {
            this.questions[6].v = this.resp[8]//CUM7
            this.questions[7].v = this.resp[7]//CUM8
            this.questions[8].v = this.resp[6]//COOP1
            this.questions[9].v = ""//NAT1
            //Las demás 
          } else {
            this.questions[6].v = this.resp[7]//CUM7
            this.questions[7].v = this.resp[6]//CUM8
            this.questions[8].v = ""//COOP1
            this.questions[9].v = ""//NAT1            
          }
        }

      }

      else {
        if (this.resp[9] == "no") {
          this.questions[12].v = 'METODO 2'
          this.questions[9].v = 'no'
          this.questions[4].v = 'si'
          this.resp[4] = 'si'
        } else {
          this.questions[12].v = 'METODO 1'
          this.questions[9].v = 'si'
          this.questions[4].v = 'no'
          this.resp[4] = 'no'
        }
        if (this.flujo == 2) {
          this.questions[0].v = this.resp[0]
          this.questions[1].v = this.resp[1]
          this.questions[2].v = this.resp[2]
          //this.questions[3].v = this.resp[9]
          //this.questions[4].v = this.resp[4]
          this.questions[5].v = this.resp[5]
          this.questions[6].v = this.resp[6]
          //this.questions[7].v = this.resp[7]
          this.questions[8].v = this.resp[8]
        } else {
          this.questions[0].v = this.resp[0]
          this.questions[1].v = this.resp[1]
          this.questions[2].v = this.resp[2]
          //this.questions[3].v = this.resp[9]
          //this.questions[4].v = this.resp[4]
          this.questions[5].v = this.resp[5]
          this.questions[6].v = this.resp[6]
          //this.questions[7].v = this.resp[7]
          this.questions[8].v = this.resp[8]
          this.questions[9].v = this.resp[9]
          this.questions[10].v = this.resp[10]
          this.questions[11].v = this.resp[11]
        }
      }
      console.log(this.resp);
      console.log('QUESTIONS----------', this.questions)
      this.continuar();
      return this.questions
    } else {
      this.modalService.solicitudRechazada(resp => {
      });
    }
  }


  transformQuestions(input: { k: string, v: string }[]): any {
    for (const iterator of this.notNeededArray[this.selectedOption]) {
      input[iterator].v = undefined;
    }
    return input;
  }

  patchLastValues() {
    let d = this.storeService.get("selectedOption")
    console.log(d);
    this.changeRadioButton(this.storeService.get("selectedOption"), false);
    let lastArray = this.storeService.get('lastQuestions');
    for (let index = 0; index < lastArray.length; index++) {
      const element = lastArray[index];
      console.log("Element->", index, element);
      if (this.flujo == 4) {
        if (index == 6) {
          if (this.selectedOption == 2) {
            this.selectButons(element, index + 1)
          }
        } else if (index == 7) {
          if (this.selectedOption == 1) {
            this.selectButons(element, index)
          }
        } else {
          this.selectButons(element, index + 1)
        }
      } else if (this.flujo == 6) {
        if (this.selectedOption == 1 || this.selectedOption == 2) {
          switch (this.selectedOption) {
            case 1://Persona Natural
              if (index <= 5) {
                this.selectButons(element, index + 1);
              } else {
                switch (index) {
                  case 6:
                    this.selectButons(element, 9);
                    break;
                  case 7:
                    this.selectButons(element, 8);
                    break;
                  case 8:
                    break;
                  case 9:
                    this.selectButons(element, 7);
                    break;
                  case 10:
                    this.selectedDropDown = element.v;
                }
              }
              break;
            case 2://Cooperativa
              if (index <= 5) {
                this.selectButons(element, index + 1);
              } else {
                switch (index) {
                  case 6:
                    this.selectButons(element, 9);
                    break;
                  case 7:
                    this.selectButons(element, 8);
                    break;
                  case 8:
                    this.selectButons(element, 7);
                    break;
                  case 9:
                    break;
                  case 10:
                    this.selectedDropDown = element.v;
                }
              }
              break;
          }
        } else {
          if (index <= 5) {
            this.selectButons(element, index + 1);
          } else {
            switch (index) {
              case 6:
                this.selectButons(element, 8);
                break;
              case 7:
                this.selectButons(element, 7);
                break;
              case 8:
                break;
              case 9:
                break;
              case 10:
                this.selectedDropDown = element.v;
            }
          }
        }
        /*
        if (index == 8) {
          if (this.selectedOption == 2) {
            this.selectButons(element, index + 1)
          }
        } else if (index == 9) {
          if (this.selectedOption == 1) {
            this.selectButons(element, index)
          }
        } else {
          this.selectButons(element, index + 1)
        }
        */
      }
      else if (this.flujo == 0) {
        if (this.selectedOption == 3 || this.selectedOption == 1) {
          this.selectButons(element, index + 1)
          if (index == 10) {
            if (element.v.toLowerCase() === 'si') {
              this.validDatar4s();
            } else if (element.v === 'no') {
              this.validDatar4n();
            }
          }
        } else {
          if (index != 7) {
            if (index > 7) {
              //Pregunta tipo r4 (Seleccione). CUM3-PAEF
              this.selectButons(element, index + 1)
              if (index == 10) {
                if (element.v.toLowerCase() === 'si') {
                  this.validDatar4s();
                } else if (element.v === 'no') {
                  this.validDatar4n();
                }
              } else {
                this.selectButons(element, index)
              }
            } else {
              this.selectButons(element, index + 1)
            }
          } else {
            this.selectButons(element, index + 1)
          }
        }
      } else if (this.flujo == 5) { // FLUJO 5 PARO
        this.selectButons(element, index + 1)
      }

      else {
        if (index != 3 && index != 4 && index != 12) {
          this.selectButons(element, index + 1)
        } else {
          if (index == 3) {
            if (element.v.toLowerCase() === 'si') {
              this.validDatar4s();
            } else if (element.v === 'no') {
              this.validDatar4n();
            }

          }
        }
      }
    }

  }

  selectButons(element, index) {
    if (element.v.toLowerCase() === 'si') {
      this.validDatars(index);
    } else if (element.v.toLowerCase() === 'no') {
      this.validDatarn(index);
    }
  }

  validButton() {
    let r = false
    if (this.flujo == 4) {
      if (this.selectedOption == 0 || this.selectedOption == 3 || this.selectedOption == 4) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null
        ) {
          r = true
        } else {
          r = false
        }
      } else if (this.selectedOption == 1 || this.selectedOption == 2) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[9] != null
        ) {
          r = true;
        } else {
          r = false
        }
      }
    } else if (this.flujo == 6) {
      if (this.selectedOption == 0 || this.selectedOption == 3 || this.selectedOption == 4) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null && this.validac == true
        ) {
          //Validar respuesta casilla 53 RUT
          if (this.resp[6] == "si") {
            if (this.selectedDropDown != "" && this.resp[7] != "") {
              r = true
            } else {
              r = false;
            }
          } else {
            r = true;
          }
        } else {
          r = false
        }
      } else if (this.selectedOption == 1 || this.selectedOption == 2) {
        if (
          this.resp[0] != "" &&
          this.resp[1] != "" &&
          this.resp[2] != "" &&
          this.resp[3] != "" &&
          this.resp[4] != "" &&
          this.resp[5] != "" &&
          this.resp[6] != "" &&
          this.resp[7] != "" && this.validac == true
        ) {
          //Validar respuesta casilla 53 RUT
          if (this.resp[7] == "si") {
            if ((this.selectedDropDown != "") && this.resp[8] != "") {
              r = true
            } else {
              r = false;
            }
          } else {
            r = true;
          }
        } else {
          r = false
        }
      }
    }
    else if (this.flujo == 0) {
      if (this.selectedOption == 1) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[7] != null &&
          this.resp[8] != null &&
          this.resp[9] != null &&
          this.resp[10] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }
      }
      else if (this.selectedOption == 3) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[7] != null &&
          this.resp[10] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }

      } else {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[7] != null &&
          this.resp[10] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }
      }
    } else if (this.flujo == 5) { //FLUJO PARO
      if (this.selectedOption == 1) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[7] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }
      }
      else if (this.selectedOption == 3) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null &&
          this.resp[6] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }

      } else {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[4] != null &&
          this.resp[5] != null && this.validac == true
        ) {
          r = true
        } else {
          r = false
        }
      }
    }
    else {
      if (this.selectedOption == 0 || this.selectedOption == 4) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[7] != null
        ) {
          r = true;
        } else {
          r = false
        }
      } else if (this.selectedOption == 1) {
        if (this.flujo == 2) {
          if (
            this.resp[0] != null &&
            this.resp[1] != null &&
            this.resp[2] != null &&
            this.resp[3] != null &&
            this.resp[4] != null &&
            this.resp[5] != null &&
            this.resp[6] != null &&
            this.resp[7] != null &&
            this.resp[8] != null
          ) {
            r = true;
          } else {
            r = false
          }
        } else {
          if (
            this.resp[0] != null &&
            this.resp[1] != null &&
            this.resp[3] != null &&
            this.resp[6] != null &&
            this.resp[7] != null &&
            this.resp[8] != null &&
            this.resp[9] != null
          ) {
            r = true;
          } else {
            r = false
          }
        }
      } else if (this.selectedOption == 2) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[5] != null &&
          this.resp[6] != null
        ) {
          r = true;
        } else {
          r = false
        }
      } else if (this.selectedOption == 3) {
        if (
          this.resp[0] != null &&
          this.resp[1] != null &&
          this.resp[2] != null &&
          this.resp[3] != null &&
          this.resp[5] != null &&
          this.resp[6] != null &&
          this.resp[10] != null
        ) {
          r = true;
        } else {
          r = false
        }
      } else {
        r = false
      }
    }
    return r
  }

  validText(i) {
    if (this.questionsShow[i - 1]['opc'] != undefined) {
      return false
    } else {
      if (this.validBotPr[i - 1]) {
        if (this.respb[i - 1] == true) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  validBots(i) {
    if (this.questionsShow[i - 1]['opc'] != undefined) {
      if (this.resp[i - 1] == "si") {
        return 'btn-res-con'
      } else {
        return 'btn-res'
      }
    } else {
      if (this.validBotPr[i - 1]) {
        if (this.respb[i - 1] == true) {
          if (this.resp[i - 1] == "si") {
            return 'btn-res-err'
          } else {
            return 'btn-res-erro'
          }
        } else {
          if (this.resp[i - 1] == "si") {
            return 'btn-res-con'
          } else {
            return 'btn-res'
          }
        }
      } else {
        return 'btn-res'
      }
    }
  }

  validBotn(i) {
    if (this.questionsShow[i - 1]['opc'] != undefined) {
      if (this.resp[i - 1] == "no") {
        return 'btn-res-con'
      } else {
        return 'btn-res'
      }
    } else {
      if (this.validBotPr[i - 1]) {
        if (this.respb[i - 1] == true) {
          if (this.resp[i - 1] == "no") {
            return 'btn-res-err'
          } else {
            return 'btn-res-erro'
          }
        } else {
          if (this.resp[i - 1] == "no") {
            return 'btn-res-con'
          } else {
            return 'btn-res'
          }
        }
      } else {
        return 'btn-res'
      }
    }
  }

  validBot4s() {
    if (this.resp[10] == "si") {
      return true
    } else { return false }
  }
  validBot4n() {
    if (this.resp[10] == "no") {
      return true
    } else { return false }
  }

  isRetaking(index) {
    return ((this.storeService.get('retakeStep') == 'preguntas') && this.storeService.get('selectedOption') != index);
  }

  continuar() {
    let preguntasObject = this.questions;
    this.storeService.set("selectedOption", this.selectedOption);
    this.storeService.set("preguntasObject", preguntasObject);
    if (this.storeService.get("retakeStep")) {
      this.storeService.set("retakeStep", "formulario");//ENVIAR
    }
    this.route.navigate(['paef/inbox/formulario'])
  }
  return() {
    this.authServerProviderService.logoutBasic2()
  }

  validateSelectQuestion() {
    let tipoEmpresa = this.selectedOption;
    if (this.flujo == 6) {
      if (tipoEmpresa == 1 || tipoEmpresa == 2) {
        if (this.resp[7] == "si") {
          //Habilitar pregunta Autoretenedor
          this.questionsShow[8]['visible'] = true;
          return true;
        } else {
          //Deshaabilitar pregunta Autoretenedor y borrar respuestas seleccionadas 
          this.resp[8] = "";
          this.selectedDropDown = "";
          this.questionsShow[8]['visible'] = false;
          return false;
        }
        return false;
      } else {
        if (this.resp[6] == "si") {
          //Habilitar pregunta Autoretenedor
          this.questionsShow[7]['visible'] = true;
          return true;
        } else {
          //Deshaabilitar pregunta Autoretenedor y borrar respuestas seleccionadas
          this.resp[7] = "";
          this.selectedDropDown = "";
          this.questionsShow[7]['visible'] = false;
          return false;
        }
        return false;
      }
    }
  }
}