<!-------------------------------------------------------------->
<div class="modale-w" *ngIf="showModal == 'searchRadicate'">
    <div class="cerrar-close-mini" (click)="onClick('cerrar')">
        <img src="assets/img/modals/close-mini.svg" alt="">
    </div>
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="'content-modal newText'" id="txt">
            {{ data.attribute['body'] }}
        </p>
    </div>

    <div class="container-form">
        <form [formGroup]="loginForm" class="search">
            <div class="form-group input-search">
                <div class="">
                    <input formControlName="number" id="login-text-number" placeholder="01_123456678990_1" (input)="formatRadicado($event.target,'number')" maxlength="30" minlength="3" [ngClass]="((!loginForm.controls.number.valid && loginService.activeError) || loginService.activeError) ? 'error-input' : ''">
                    <span class="technical-failure-text">{{loginService.errorMessage}}</span>
                </div>
                <p>
                    Si no tiene número puede continuar sin carga información.
                </p>
            </div>
        </form>
    </div>

    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="searchRadicated()" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>
<!--Modal para radicados consultados desde el flujo incorrecto-->
<div class="modale-w" *ngIf="showModal == 'restrictedFlow'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined">
            {{ data.attribute['body2']}}
        </p>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-ok-modal button-no">
			{{ data.attribute['btn'] }}
		</button>       
    </div>
</div>
<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'twoButtonsAssigment'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined">
            {{ data.attribute['body2']}}
        </p>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'twoButtonsRestrictedAccess'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined">
            {{ data.attribute['body2']}}
        </p>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'oneButtonsOnCourse'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue font-roboto-medium" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos content-short" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <!--  <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined" style="margin-bottom: 10px;">
            {{ data.attribute['body2']}}
        </p>
        <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined">
            {{ data.attribute['body3']}}
        </p> -->
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-ok-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <!--  <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button> -->
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w-xl" *ngIf="showModal == 'threeButtonsApplicationState'">
    <div class="row justify-content-center">
        <div class="title-modal-blue-state font-roboto-medium" id="title">
            {{ data.attribute['title'] }}
        </div>
        <img [src]="data.attribute['icon']" class="equis-modal" (click)="onClick('no')">
    </div>
    <div class="row justify-content-center">
        <div class="body-pad-10">
        </div>
        <div class="table-state-container">
            <table>
                <tr class="table-state-titles">
                    <td *ngFor="let item of data.attribute['tableArray']">{{item.k}}</td>
                </tr>
                <tr class="table-state-data">
                    <td *ngFor="let item of data.attribute['tableArray']"><strong>{{item.v}}</strong></td>
                </tr>
            </table>
        </div>
        <div class="content-modal2-state">
            <p class="p-title"> {{ data.attribute['body3'].split("..")[0]}} </p>
            <p class="p-state" [ngClass]="{ 'p-state-blue': tagColor('state') == 'blue', 'p-state-green': tagColor('state') == 'green' }"> {{ data.attribute['body3'].split("..")[1]}} </p>
            <p class="p-text" [ngClass]="{ 'p-blue': tagColor('reason') == 'blue' }"> {{ data.attribute['body3'].split("..")[2]}} </p>
            <p class="p-reasons" [ngClass]="{ 'p-blue': tagColor('reason') == 'blue'}"> {{ data.attribute['body3'].split("..")[3]}} </p>
        </div>
        <br>
    </div>
    <!-- <div class="modal-bottom-padding"></div> -->
    <div *ngIf="true && storeService.get('periodoValido') === true" class="container-btn-two-round">
        <div *ngIf="false" class="modal-bottom-padding"></div>
        <button *ngIf="data.attribute['btnArray'][0] && activeBtn('formulario')" id="form" (click)="onClick('preguntas')" class="button-two-modal-round">
			{{ data.attribute['btnArray'][0].v }}
		</button>
        <button *ngIf="data.attribute['btnArray'][1] && activeBtn('documentos')" id="docs" (click)="onClick('documentos')" class="button-two-modal-round">
			{{ data.attribute['btnArray'][1].v }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'twoButtonsRadio'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 27px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-blue font-roboto-medium" style="margin-top: 0;" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <div class="radio-group">
            <div class="radio-button-div">
                <div [id]="'rb-0'" class="radio-button-uncheck" [ngClass]="checkRadio[0] ? 'radio-button-check' : 'radio-button-uncheck'" (click)="changeRadioButton(0)"></div>
                <p>{{data.attribute['body2']}}</p>
            </div>
            <div class="radio-button-div radio-button-right">
                <div [id]="'rb-1'" class="radio-button-uncheck" [ngClass]="checkRadio[1] ? 'radio-button-check' : 'radio-button-uncheck'" (click)="changeRadioButton(1)"></div>
                <p>{{data.attribute['body3']}}</p>
            </div>
        </div>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('select-radio')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'twoButtonsSuccessAssigment'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-successful" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'twoButtonsReject'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <br>
    </div>

    <div class="content-select">
        <div class="select-item">
            <select (change)="llenarLista($event.target.value)" class="clasic">
                <option value="" disabled selected>Selecciona el motivo general</option>
                <option id="select" *ngFor="let item of filterRejectCategories(data.attribute['type'])" value={{item.idPapa}} >{{item.nombre}}</option>
            </select>
        </div>
    </div>

    <div class="row justify-content-center" *ngIf="listaParametros!=null">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body2']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <br>
    </div>

    <div id="content-motivos">
        <div class="content-list" *ngIf="listaParametros!=null">
            <div class="list-card" *ngFor="let lista of listaParametros; let i=index">
                <div [ngClass]="stateCusal(i) ? 'body-card-select' : 'body-card'" (click)="click(i)">
                    <div class="description-card">
                        <p>{{lista['texto']}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-text" *ngIf="listaParametros!=null">
        <p>*Puede seleccionar más de un motivo general y más de un motivo específico.</p>
    </div>

    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'unBoton'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center font-roboto-medium">
        <div [ngClass]="retrunStyle()" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body'] }}
        </p>
        <p *ngIf="data.attribute['body2'] != undefined" class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body2'] }}
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<!------------------------------------------------------------------------->

<div class="modale-w" *ngIf="showModal == 'unBoton5'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center font-roboto-medium">
        <div [ngClass]="retrunStyle()" id="title">
            {{ data.attribute['title'] }} <br> subsidio</div>
    </div>
    <br>

    <div class="row justify-content-center">

        <p class="content-modal span5-p" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            <span [innerHTML]="data.attribute['body']" class="span5"></span> {{ data.attribute['body2'] }}
            <br> {{ data.attribute['body3'] }}


        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>
<!------------------------------------------------------------------------->



<div class="modale-w" *ngIf="showModal == 'unBotonBlue'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div [ngClass]="'title-modal-blue'" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body'] }}
        </p>
        <p *ngIf="data.attribute['body2'] != undefined" class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body2'] }}
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'unBotonError'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div [ngClass]="retrunStyle() + ' error-title'" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body'] }}
        </p>
        <p *ngIf="data.attribute['body2'] != undefined" class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body2'] }}
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'unBoton2'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div [ngClass]="'newTitle'" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="'content-modal newText'" id="txt">
            {{ data.attribute['body'] }}
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<div class="modale-w modal3" *ngIf="showModal == 'unBoton3'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>

    <div class="row justify-content-center">
        <div class="title-modal-successful" id="title">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body'] }}
        </p>
        <p class="content-modal" [innerHTML]="data.attribute['body2']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'unBoton4'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div [ngClass]="retrunStyle()" id="title" class="title-orange">
            {{ data.attribute['title'] }}</div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body'] }}
        </p>
        <p *ngIf="data.attribute['body2'] != undefined" class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body2'] }}
        </p>
    </div>
    <div *ngIf="data.attribute['btn'] == undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">OK</button>
    </div>
    <div *ngIf="data.attribute['btn'] != undefined">
        <button id="ok" (click)="onClick('no')" class="button-ok-modal">{{data.attribute['btn']}}</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'formulario'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape" />
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-upload" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>

    <div class="container-form">
        <form [formGroup]="loginForm" class="search">
            <div class="form-group">
                <div>
                    <p>Tipo de documento</p>
                    <select formControlName="type" id="login-select-type" [ngClass]="((!loginForm.controls.type.valid && activeError) || activeError) ? 'error-input' : ''">
                        <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                            {{docTyp.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group ">
                <div>
                    <p>Número de Documento</p>
                    <input formControlName="number" id="login-text-number" placeholder="Número de identificación" (input)="formatNotSpace($event.target)" maxlength="12" minlength="3" [ngClass]="((!loginForm.controls.number.valid && activeError) || activeError) ? 'error-input' : ''">
                    <span class="technical-failure-text">{{errorMessage}}</span>
                </div>
            </div>
        </form>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="changeData()" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'loading'">
    <div class="row justify-content-center">
        <img src="assets/img/generic/loading.gif" alt="" class="loading-icon">
    </div>
    <div class="row justify-content-center">
        <div class="title-modal-loading" id="title">{{ data.attribute['body'] }}</div>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'chargeDocument'">
    <div class="row justify-content-center">
        <div class="title-modal-charge" id="title">{{ data.attribute['title'] }}
            <br> {{ data.attribute['body'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <img src="assets/img/generic/phone-img.svg" alt="" class="charge-document-icon">
    </div>
    <div class="row justify-content-center">
        <div class="content-modal-charge-div" id="txt">
            <p class="content-modal-charge">
                {{ data.attribute['body2'] }}
            </p>
            <p class="content-modal-charge-light">
                {{ data.attribute['body3'] }}
            </p>
        </div>
    </div>
    <div>
        <button id="ok" (click)="onClick('continue')" class="button-continue-blue">
			{{ data.attribute["btn"] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'twoButtons'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-upload" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal span-dos" [innerHtml]="data.attribute['body']" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">

        </p>
        <p class="content-modal2" *ngIf="data.attribute['body2'] != undefined">
            {{ data.attribute['body2']}}
        </p>
        <br>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute['btn'] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute['btnR'] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'twoButtons2'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-upload" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body']}}
            <br> {{ data.attribute['body2']}}
        </p>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute["btn"] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute["btnR"] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'twoButtons3'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-pap" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            {{ data.attribute['body']}}
            <br> {{ data.attribute['body2']}}
        </p>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('no')" class="button-two-modal button-no">
			{{ data.attribute["btn"] }}
		</button>
        <button id="ok" (click)="onClick('continue')" class="button-two-modal button-yes">
			{{ data.attribute["btnR"] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'formCreated'">
    <div *ngIf="data.attribute['icon'] != undefined" class="row justify-content-center img-modal" style="padding-top: 43px;">
        <img [src]="data.attribute['icon']" class="shape">
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-upload title-green" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <p class="content-modal" [ngClass]="data.attribute['OpenSansLight'] != undefined ? 'content-modal-light' : 'content-modal'" id="txt">
            Su número de radicado es <b class="id-consecutivo">{{ data.attribute['body']}}</b> <b class="b-underline"> por favor consérvelo para futuras consultas.</b>
            <br> Para continuar con la solicitud, siga los pasos descritos a continuación:
        </p>
        <p class="content-modal-2">
            1. Descargue los formatos haciendo click en el botón al borde de este recuadro.
            <br> 2. Imprímalos en hoja tamaño carta y fírmelos.
            <br> 3. Escanee en formato: (formato).JPG, .PNG, .PDF.
            <br> 4. Cargue el formulario y los demás documentos para completar su solicitud.
        </p>
    </div>
    <div class="container-btn-two">
        <button id="no" (click)="onClick('continue')" class="button-ok-modal">
			{{ data.attribute["btn"] }}
		</button>
    </div>
</div>

<div class="modale-w" *ngIf="showModal == 'inactiveSession'">
    <div class="row justify-content-center">
        <div class="col justify-content-center text-center" style="padding-top: 43px;">
            <img [src]="data.attribute['icon']" class="shape">
        </div>
    </div>
    <p></p>
    <div class="row justify-content-center">
        <div class="title-modal-light title-modal-idle" id="title">
            {{ data.attribute['title'] }}
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="content-modal-light content-modal-idle" id="txt">
            {{ data.attribute['body']}}<b>{{second}}{{ data.attribute['body2']}}</b>
        </div>
    </div>
    <div>
        <button id="ok-close-sesion" (click)="onClick('no')" class="button-ok-modal">
			{{ data.attribute['btn'] }}
		</button>
    </div>
</div>

<div class="terms" *ngIf="showModal == 'terms'">
    <div>
        <div class="closec" (click)="onClick('no')">
            <img src="assets/img/modals/closed-data.svg" alt="">
        </div>
        <div class="title-text" id="title">
            <img (click)="onClick('no')" src="assets/img/modals/back.svg"> {{ data.attribute['title'] }}
        </div>
    </div>
    <div id="content-terms" class="container-text">
        <p class="title-3">
            ¿Dónde envío los documentos físicos para solicitar el subsidio?
        </p>
        <p>
            No es necesario enviar documentos físicos, los documentos se adjuntan en la URL previamente diligenciados
        </p>
        <p class="title-3">
            ¿Por cuánto tiempo es el subsidio?
        </p>
        <p>
            Por 3 meses: mayo junio y julio y si la empresa tiene crédito FNG aplica para junio, julio y agosto.
        </p>
        <p class="title-3">
            Si tengo crédito para la nómina garantizado con FNG y luego entra el subsidio, ¿me lo debitan para pagar el crédito?
        </p>
        <p>
            No, son dos operaciones independientes y el subsidio solo puede ser utilizado exclusivamente para el pago de la nómina.
        </p>
        <p class="title-3">
            ¿Debo solicitar el subsidio en el mismo banco donde me aprobaron el crédito para pago de nómina con el FNG?
        </p>
        <p>
            Si es necesario.
        </p>
        <p class="title-3">
            ¿Tengo que certificar que los recursos fueron usados para el pago de la nómina?
        </p>
        <p>
            En el formulario de solicitud por segunda vez por medio de certificado emitido por revisor fiscal o contador.
        </p>
        <p class="title-3">
            ¿Qué documentos debo aportar para solicitar de nuevo el subsidio?
        </p>
        <p>
            Realiza nuevamente el proceso de solicitud, diligenciando en el formulario el campo de solicitud por 2da y 3ra vez. Adicionalmente si se realiza la devolución de recursos del mes anterior se adjunta la certificación.
        </p>
        <p class="title-3">
            ¿Cuánto tiempo se demoran en entrar los recursos del subsidio a mi cuenta?
        </p>
        <p>
            Una vez la UGPP confirme el cumplimiento de los requisitos aproximadamente en 2 o 3 días hábiles los recursos estarán en la cuenta del cliente.
        </p>
        <p class="title-3">
            ¿Cómo se dispersan los recursos a mis empleados?
        </p>
        <p>
            La nómina se pagará como regularmente lo realiza la empresa a sus empleados bancarizados y no bancarizados.
        </p>
        <p class="title-3">
            ¿Qué costo tiene hacer la dispersión a las cuentas de mis empleados?
        </p>
        <ul>
            <li>
                Para clientes con convenio negociado: el valor de la dispersión negociada.
            </li>
            <li>
                Para clientes sin convenio: tarifa plena.
            </li>
        </ul>
        <p class="title-3">
            ¿En qué casos debo devolver los recursos del subsidio?
        </p>
        <p>
            Si se realizan despidos de personal o desvinculación voluntaria, se deberán devolver el valor de los subsidios correspondientes a los empleados que formen parte de este proceso.
        </p>
        <p class="title-3">
            ¿Cómo se realizar la devolución del subsidio?
        </p>
        <p>
            Diligenciando el formulario de devolución de subsidio y comunicarse con el comercial asignado para obtener más información.
        </p>
        <p class="title-3">
            ¿Cómo sé que mi empresa aplica para la solicitud del subsidio?
        </p>
        <p>
            Aplica para cualquier tipo de empresa pequeña, mediana o grande, y aplica para empresas que fueron constituidas antes de abril de 2019, si la empresa tuvo disminución de al menos el 20% de las ventas comparando el mismo mes del año anterior por ejemplo
            abril de 2019 vs abril de 2020. No aplica para clientes que tengan más del 50% del capital de la Nación o de sus entidades descentralizadas, no podrá beneficiarse de este programa.
        </p>
        <p class="title-3">
            ¿Cuáles son los requisitos para solicitar el subsidio a la nómina?
        </p>
        <p>
            Al ingresar en la URL www.subsidionomina.bancodebogota.co:
        </p>
        <ul>
            <li>
                Diligenciar formulario de solicitud del subsidio a la nómina (firmado por el representante legal).
            </li>
            <li>
                Diligenciar certificación primera solicitud (certificación de disminución de ingresos o ventas).
            </li>
            <li>
                Certificación de existencia y representación legal (cámara de comercio).
            </li>
            <li>
                Certificado mercantil (persona jurídica) o RUT (aplica para empresas sin animo de lucro).
            </li>
        </ul>
        <p>
            Los formatos se pueden descargar una vez se diligencie la solicitud en la página de registro.
        </p>
        <p class="title-3">
            ¿Cuántas veces puedo solicitar el subsidio al mes?
        </p>
        <p>
            Una sola vez al mes.
        </p>
        <p class="title-3">
            ¿Cuál es el monto del subsidio por empresa?
        </p>
        <p>
            40% de un SMLV por cada colaborador que no esté en suspensión con excepciones de licencias no remuneradas.
        </p>
        <p class="title-3">
            ¿Necesito tener convenio de nómina con el Banco para que me otorguen el subsidio?
        </p>
        <p>
            No, solo debe contar con la cuenta activa para hacer el depósito.
        </p>
        <p class="title-3">
            ¿Se puede solicitar el subsidio para la nómina así ya haya desembolsado crédito para nómina con el FNG?
        </p>
        <p>
            Si, siempre y cuando la suma del desembolso con FNG + el subsidio no supere el 100% del valor de la nómina mensual de su compañía.
        </p>
        <p class="title-3">
            ¿Necesito una cuenta corriente o de ahorros para recibir el subsidio?
        </p>
        <p>
            Si necesita una cuenta activa sin importar si es de Ahorros o Corriente, en cualquiera de las dos se puede recibir teniendo en cuenta que debe estar activa, no estar embargada o estar congelada.
        </p>
    </div>
    <a id="bown-paginator" [ngClass]="down ? 'btn-down-page-a':'btn-down-page'" (click)="downPaginator()">
        <img src="assets/img/generic/arrow-down-screen.svg">
        <div>
            <img src="assets/img/generic/arrowDown.svg">
        </div>
    </a>
</div>

<div class="terms" *ngIf="showModal == 'terms2'">
    <div>
        <div class="closec" (click)="onClick('no')">
            <img src="assets/img/modals/closed-data.svg" alt="">
        </div>
        <div class="title-text" id="title">
            <img (click)="onClick('no')" src="assets/img/modals/back.svg"> {{ data.attribute['title'] }}
        </div>
    </div>
    <div id="content-terms" class="container-text">
        <h1>
            ¿Qué documentos debo cargar?
        </h1>

        <p class="contract">
            Si usted se postula como
            <span>
                PERSONA JURÍDICA: 
            </span>
        </p>

        <p>
            <span>
                · Formulario postulante PAEF firmado y escaneado:
            </span> Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.
        </p>
        <p>
            <span>
                · Certificación sobre disminución de ingresos y declaración de empleados sobre los que recibirá el subsidio: 
            </span> Certificación firmado por el representante legal, persona natural, promotor o liquidador + el revisor fiscal o contador (en caso que no esté obligado a tener revisor fiscal), que contenga:
        </p>
        <p>
            - Declaración de la disminución del 20% de sus ingresos:, especificando si la disminución se da producto de comparar los ingresos del mes inmediatamente anterior al de la solicitud respecto de:
        </p>
        <p style="margin-bottom: 0;">
            a. Los ingresos del mismo mes del año 2019
        </p>
        <p style="margin-top: 0;">
            b. El promedio aritmético de los ingresos de enero y febrero de 2020 El aporte recibido no deberá ser tenido en cuenta para el cálculo de disminución de ingresos
        </p>
        <p>
            - Declaración que los empleados sobre los cuales se recibirá el aporte recibieron el salario del mes inmediatamente anterior o
        </p>

        <p>
            - Declaración que se pagarán las obligaciones laborales adeudadas dentro de los 5 días hábiles siguientes a la recepción de los recursos. Esta declaración solo procede para pagar la nómina de abril con la postulación en mayo de 2020 · Cámara y comercio.
        </p>


        <p class="contract">
            Si usted se postula como
            <span>
                PERSONA NATURAL: 
            </span>
        </p>

        <p>
            <span>
    · Formulario postulante PAEF firmado y escaneado: 
</span> Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.
        </p>

        <p>
            <span>
        · Certificación sobre disminución de ingresos y declaración de empleados sobre los que recibirá el subsidio: 
    </span> Certificación firmado por el representante legal, persona natural, promotor o liquidador + el revisor fiscal o contador (en caso que no esté obligado a tener revisor fiscal), que contenga:
        </p>

        <p>
            - Declaración de la disminución del 20% de sus ingresos:, especificando si la disminución se da producto de comparar los ingresos del mes inmediatamente anterior al de la solicitud respecto de:
        </p>

        <p style="margin-bottom: 0;">
            a. Los ingresos del mismo mes del año 2019
        </p>
        <p style="margin-top: 0;">
            b. El promedio aritmético de los ingresos de enero y febrero de 2020 El aporte recibido no deberá ser tenido en cuenta para el cálculo de disminución de ingresos
        </p>
        <p>
            - Declaración que los empleados sobre los cuales se recibirá el aporte recibieron el salario del mes inmediatamente anterior o
        </p>
        <p>
            - Declaración que se pagarán las obligaciones laborales adeudadas dentro de los 5 días hábiles siguientes a la recepción de los recursos. Esta declaración solo procede para pagar la nómina de abril con la postulación en mayo de 2020
        </p>





        <p class="contract">
            Si usted se postula como
            <span>
                CONSORCIO:
            </span>
        </p>

        <p>
            <span>

                · Formulario postulante PAEF firmado con  y escaneado: Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. 
            </span>
        </p>
        <p>
            <span>

                · Certificación sobre disminución de ingresos y declaración de empleados sobre los que recibirá el subsidio: 
            </span> Certificación firmado por el representante legal, persona natural, promotor o liquidador + el revisor fiscal o contador (en caso que no esté obligado a tener revisor fiscal), que contenga:
        </p>

        <p>
            - Declaración de la disminución del 20% de sus ingresos:, especificando si la disminución se da producto de comparar los ingresos del mes inmediatamente anterior al de la solicitud respecto de:
        </p>

        <p style="margin-bottom: 0;">
            a. Los ingresos del mismo mes del año 2019
        </p>

        <p style="margin-top: 0;">
            b. El promedio aritmético de los ingresos de enero y febrero de 2020 El aporte recibido no deberá ser tenido en cuenta para el cálculo de disminución de ingresos
        </p>

        <p>
            - Declaración que los empleados sobre los cuales se recibirá el aporte recibieron el salario del mes inmediatamente anterior o
        </p>

        <p>
            - Declaración que se pagarán las obligaciones laborales adeudadas dentro de los 5 días hábiles siguientes a la recepción de los recursos. Esta declaración solo procede para pagar la nómina de abril con la postulación en mayo de 2020
        </p>



        <p class="contract">
            Si usted se postula como
            <span>
                UNIÓN TEMPORAL:
            </span>
        </p>

        <p>
            <span>

                · Formulario postulante PAEF firmado y escaneado: 
            </span> Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.

        </p>

        <p>
            <span>
                · Certificación sobre disminución de ingresos y declaración de empleados sobre los que recibirá el subsidio: 
            </span>
        </p>

        <p>
            Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.
        </p>

        <p style="margin-top: 10px;margin-bottom: 20px;">
            <span>

                · Registro Único Tributario (RUT).
            </span>
        </p>

        <p class="contract">
            Si usted se postula como
            <span>
                ENTIDAD SIN ÁNIMO DE LUCRO: 
            </span>
        </p>


        <p>
            <span>
        · Formulario postulante PAEF firmado y escaneado:
    </span> Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.

        </p>


        <p>

            <span>
        · Certificación sobre disminución de ingresos y declaración de empleados sobre los que recibirá el subsidio: 
    </span> Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.

        </p>

        <p>
            <span>
                · Registro Único Tributario (RUT).
            </span>
        </p>

    </div>
    <a id="bown-paginator" [ngClass]="down ? 'btn-down-page-a':'btn-down-page'" (click)="downPaginator()">
        <img src="assets/img/generic/arrow-down-screen.svg">
        <div>
            <img src="assets/img/generic/arrowDown.svg">
        </div>
    </a>
</div>