<div class="row">
    <div class="logo divLeft">
        <div class="row position">
            <div class="col background-logo">
                <div class="row">
                    <img class="logo-wt" src="assets/img/BDB-logo2.png">
                    <img class="logo-m" src="assets/img/bdb-grupoaval.svg">
                </div>
            </div>
        </div>
        <div class="row position margin-top-7">
            <div class="block"></div>
            <div class="left-side">
                <h1 class="tituloApp">
                    {{flow.title}}
                </h1>
                <h1 class="subtituloApp">
                    {{flow.subTitleLogin}}
                </h1>

                <aliv-corte [type]="'type-0'" [message]="flow.subtitle"></aliv-corte>

                <div class="width-full margin-top-5">
                    <form [formGroup]="loginForm" class="formulario">
                        <div class="width-full">
                            <div class="input-group">
                                <div class="container-payment-radio">
                                    <div class="payment-radio-group">
                                        <div *ngFor="let item of flow.loginChecks; let i=index" class="payment-radio-button" [ngClass] = "(i > 0) ? 'payment-radio-button-right' : (i == 0 && !item.checkb) ? 'hidden' : ''">
                                            <div *ngIf="item.checkb" [id]="'rb-' + i" class="rb-{{i}} radio-button-uncheck" [ngClass]="checkRadio[i] ? 'radio-button-check' : 'radio-button-uncheck'" (click)="changeRadioButton(i)"></div>
                                            <p *ngIf="item.checkb" [ngClass] = "i == 2 ? 'p-right' : ''">{{item.check}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="class-msg-paef">
                            <p>
                                * <strong>IMPORTANTE: </strong> {{flow.important}}
                            </p>
                        </div>
                        <div class="width-full">
                            <span class="subtitles">Tipo de Identificación</span>
                            <div class="input-group mb-3">
                                <select formControlName="type" id="docType" class="forms-type form-control font-20 roboto black-font noFocus">                                    
                                    <option value="" selected>Seleccione</option>  
                                    <option *ngFor="let docTyp of docTypes" [value]="docTyp.id" selected>
                                        {{docTyp.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="div-num">
                            <div class="width-num-ide">
                                <span class="subtitles">Número de Identificación</span>
                                <div class="input-group mb-3">
                                    <input id="docNumber" type="text" maxlength="15" class="forms-type-n form-control font-20 roboto black-font noFocus" (click)="activeFocusDocNumber();" (focusout)="inactiveFocusDocNumber();" [ngClass]="focusDocNumber ? 'focusInput' : 'unFocus'" formControlName="number"
                                        (input)="formatNumberAndChar($event.target.value, 'number')" placeholder="Ingresa el número">
                                </div>
                            </div>
                            <div class="width-dig-ver">
                                <span class="subtitles">Dígito de verificación</span>
                                <div class="input-group mb-3">
                                    <input id="digit-ver" type="text" maxlength="1" class="forms-type-n form-control font-20 roboto black-font noFocus" (click)="activeFocusDigit();" (focusout)="inactiveFocusDigit();" [ngClass]="focusDigit ? 'focusInput' : 'unFocus'" formControlName="digit"
                                        (input)="formatNumber($event.target.value, 'digit')" placeholder="Ingresa el dígito">
                                </div>
                            </div>
                        </div>

                        <div class="width-full" *ngIf="this.selectedOption == 0 && this.flow.flujo == 0 || this.selectedOption == 0 && this.flow.flujo == 4  || this.selectedOption == 0 && this.flow.flujo == 5 || this.selectedOption == 0 && this.flow.flujo == 6">
                            <span class="subtitles">Mes de postulación</span>
                            <div class="input-group mb-3">
                                <select formControlName="date" id="date" class="forms-type form-control font-20 roboto black-font noFocus">          
                                    <option value="" selected>Seleccione</option>                        
                                    <option *ngFor="let docTyp of meses" [value]="docTyp.id"  selected>
                                        {{docTyp.name}}
                                    </option>
                                </select>
                            </div>

                        </div>
                        <div class="subtitles-adver" *ngIf="this.selectedOption == 0 && this.changeMes()">
                            <p>
                                * <strong>IMPORTANTE: </strong> Si es una persona natural que tiene más de un tipo de documento de identificación, debe señalar únicamente el tipo y número de identidad con el cual realiza sus pagos en PILA y cumple los demás requisitos del programa.
                            </p>
                            
                        </div>
                        <div class="class-msg-paef" *ngIf="this.selectedOption == 0 && this.flow.flujo == 6">
                            <p>
                                * <strong>IMPORTANTE: </strong> Recuerda que la nómina de la que se recibe el subsidio es la del mes anterior a la postulación.
                            </p>
                        </div>

                        <div *ngIf="this.selectedOption == 0" class="width-full button-box">
                            <button id="volver" class="btn button-app font-16 button-app-back button-cargar" (click)="goBack()">
                                ‹ Volver
                            </button>
                            <button id="ingresar" [ngClass]="validForm() ? 'btn button-app font-16 button-app-active button-cargar' : 'btn button-app font-16 button-app-active button-cargar button-disable'" [disabled]="!validForm()" (click)="loginUser()">
                                Continuar ›
                            </button>
                        </div>

                        <div *ngIf="this.selectedOption == 1 || this.selectedOption == 2">
                            <div class="width-full">
                                <span class="subtitles">Número de radicado</span>
                                <p class="anotacion">Este se encuentra en el fomulario diligenciado.</p>
                                <div class="input-group mb-3">
                                    <input id="numRadicado" type="text" maxlength="30" class="forms-type-n form-control font-20 roboto black-font noFocus" (click)="activeFocusRadicado();" (focusout)="inactiveFocusRadicado();" [ngClass]="focusRadicado ? 'focusInput' : 'unFocus'" formControlName="radicado"
                                        (input)="formatRadicado($event.target.value, 'radicado')" placeholder="Ej: 01_81234561232_01">
                                    <p class="tex-pl"> Ej: 01_81234561232_01</p>
                                </div>
                            </div>

                            <div class="width-full button-box">
                                <button id="volver" class="btn button-app font-16 button-app-back button-cargar" (click)="goBack()">
                                    ‹ Volver
                                </button>
                                <button id="ingresar" [ngClass]="validForm() ? 'btn button-app font-16 button-app-active button-cargar ' : 'btn button-app font-16 button-app-active button-cargar button-disable'" [disabled]="!validForm()" (click)="loginUser()">
                                    Continuar ›
                                </button>
                            </div>
                        </div>

                        <div class="width-full">
                            <div *ngIf="errorMessage != ''" class="error-text">
                                <p>
                                    {{errorMessage}}
                                </p>
                            </div>

                            <div class="linkQuestions">
                                <a id="click-view-questions" [href]='urlData' target="_blank">Consulte aquí la Guía rápida sobre el programa.</a> <br><br>
                                <a id="click-view-questions" [href]='urlDataPopupWindows' target="_blank">Consulte aquí como activar las ventanas emergentes de su navegador.</a> <br><br>
                                <a id="click-view-restitucion" *ngIf="this.flow.flujo == 2" [href]='urldDataRestitucion' target="_blank"> Consulte el proceso para realizar restitución de recursos</a>
                            </div>
                            <p class="capcha" *ngIf="this.flow.flujo !== 0 && this.flow.flujo !== 5">
                                Este sitio está protegido por reCAPTCHA y Google <a href="https://policies.google.com/privacy"> Política de privacidad </a> y Se aplican <a href="https://policies.google.com/terms"> Términos de servicio </a> .
                            </p>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="isSafari ? 'width-background-blue-s center-align background-image-blue col-hide col-tablet image-tablet' : 'width-background-blue center-align background-image-blue col-hide col-tablet image-tablet'">
        <div class="web">
            <img src="assets/img/BDB-logo.png">
        </div>
        <div class="tablet">
            <img src="assets/img/monogram.svg">
        </div>
        <div class="divFloat">
        </div>
    </div>
</div>