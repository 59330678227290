import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { StoreService } from '@core/service/store.service';


@Component({
  selector: 'aliv-corte',
  templateUrl: './corte.component.html',
  styleUrls: ['./corte.component.scss']
})
export class CorteComponent implements OnInit {

  public data = environment;
  @Input() type: string;
  @Input() message: string;
  flujo = 0;
  title = "";

  constructor(private cookieService: CookieService,
    private storeService: StoreService) {
      if(this.storeService.flujo == null || this.storeService.flujo == undefined){
        this.flujo = this.storeService.get('flujo')
      }else{
        this.flujo = this.storeService.flujo
      }
      this.title = this.message;
     }

  ngOnInit() {
      this.title = this.message;
  }

}
