<app-navbar></app-navbar>
<div class="container">
    <div class="counter-container">
        <div class="counter">
            <div class="counter-label">
                <span>Solicitudes pendientes de revisión</span>
            </div>
            <div class="counter-num">
                <span>{{numberWithCommas(pendingCasesCounter)}}</span>
            </div>
        </div>
        <div class="gray-filling"></div>
    </div>
    <!-- <div class="ts20"></div> -->
    <div class="card-main pad-main">
        <div class="tituloinfo-empresa">
            <div>
                <h1 class="titulo-operativa fon18">
                    INFORMACIÓN DE LA EMPRESA: {{getTipoEmpresaNombre(tipe)}}
                </h1>
            </div>
            <div>
                <p class="titulo-operativa fon13">
                    Devoluciones: {{ devolutions }}
                </p>
            </div>
        </div>
        <div class="ts10"></div>
        <div class="info-empresa">
            <div class="wrp-table-info">
                <div class="itemifo roboto">
                    <p>Tipo ID</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.tipoId }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Nº ID</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.noId }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Nº radicado</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.noRadicado }}
					</span>
                </div>
                <div class="itemifo roboto" *ngIf="infoEmpresa.mes != ''">
                    <p>Mes de postulación</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.mes }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Email Cliente</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.email }}
					</span>
                </div>
            </div>
            <div class="wrp-table-info" style="margin-top: 20px;">
                <div *ngIf="this.storeService.get('flujo') != 4 && this.storeService.get('flujo') != 5 && this.storeService.get('flujo') != 6" class="itemifo roboto">
                    <p>FNG</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.fng }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Cuenta 1</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.cuenta1 }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Cuenta 2</p>
                    <span class="roboto-bold">
						{{ infoEmpresa.cuenta2 }}
					</span>
                </div>
                <div class="itemifo roboto">
                    <p>Tipo de solicitud</p>
                    <span class="roboto-bold">
						{{tipoSolicitud}}
					</span>
                </div>

                <div *ngIf="this.storeService.get('flujo') == 6" class="itemifo roboto">
                    <p>Declara Renta</p>
                    <span class="roboto-bold">
                        {{declaraRenta}}
                    </span>
                </div>

                <div *ngIf="this.storeService.get('flujo') == 6" class="itemifo roboto">
                    <p>Código</p>
                    <span class="roboto-bold">
                        {{codigoDeclaraRenta}}
                    </span>
                </div>

                <div *ngIf="this.storeService.get('flujo') == 6" class="itemifo roboto">
                    <p>Auto-Retenedor</p>
                    <span class="roboto-bold">
                        {{autoRetenedor}}
                    </span>
                </div>
            </div>
            <div class="division"></div>

            <div class="footer-info">
                <p class="titulo">¿Resultado de la revisión de la información?</p>
                <div class="clear ts10"></div>
                <button (click)="devueltoInfo()" [class]="validationInfo !== undefined ? !validationInfo ? 'boton-basic warning radius fon14' : 'boton-basic blue radius fon14' : 'boton-basic blue radius fon14'">Devuelto</button>
                <button (click)="validateInfo(true)" [class]="validationInfo !== undefined ? !!validationInfo ? 'boton-basic bgsolid radius fon14' : 'boton-basic blue radius fon14' : 'boton-basic blue radius fon14'">Aceptado</button>
                <p class="titulo-operativa  warning fon12 light" *ngIf="uploadDocService.info.causes.length > 0">
                    Motivo(s) de rechazo: <span *ngFor="let motivo of uploadDocService.info.causes"> {{motivo['texto']}}</span></p>
            </div>
        </div>


        <div class="ts40"></div>
        <h1 class="titulo-operativa fon18">
            DOCUMENTOS
        </h1>
        <div class="ts10"></div>
        <div class="wrrp-info-empresa-docs">
            <div class="wrrp_documento" *ngFor="let documento of this.uploadDocService.documentUpload; let i=index">
                <div class="table_documentos">
                    <div class="item">
                        <div class="bt-icono" (click)="descargar(i)">
                            <img src="assets/img/icon_download_file.svg">
                        </div>
                    </div>
                    <div class="item">
                        <p class="roboto-bold">
                            {{ documento.documentName }}
                        </p>
                        <span *ngIf="documento.filename != undefined">
							{{formatFileName(documento.filename)}}
						</span>
                    </div>
                    <div class="item">
                        <button (click)="devuelto(i)" [ngClass]="classRechaze(i) ? 'boton-basic blue radius fon14 warning' : 'boton-basic blue radius fon14'">Devuelto</button>
                        <button (click)="aceptar(i)" [ngClass]="classAcep(i) ? 'boton-basic blue radius fon14 bgsolid' : 'boton-basic blue radius fon14'">Aceptado</button>
                    </div>
                </div>

                <p class="titulo-operativa warning fon12 light" *ngIf="documento.causes.length > 0">
                    Motivo(s) de rechazo: <span *ngFor="let motivo of documento.causes"> {{motivo['texto']}}</span></p>
            </div>

            <div class="ts40"></div>
            <div class="ts40"></div>

            <div *ngIf="completeAsignation().disable">
                <p class="titulo">Resultado completo de la revisión</p>
                <p class="titulo-operativa fon18" *ngIf="completeAsignation().validation">ACEPTADO</p>
                <p class="titulo-operativa warning fon18" *ngIf="!completeAsignation().validation">DEVUELTO</p>
            </div>

            <div class="ts23"></div>

            <button class="boton-basic h53 bgyellowsolid radius fon14" (click)="aceptado()" [disabled]="!completeAsignation().disable">Enviar resultado de
				revisión</button>

            <div class="clear"></div>
            <a style="display: none;" id="send-email" [href]="'mailto:'+ infoEmpresa.email">Caso #{{ infoEmpresa.noRadicado }}</a>
        </div>
    </div>
</div>