
import { Routes } from '@angular/router';
import { LoginOperativaComponent } from './login-operativa/login-operativa.component';
import { AsignacionComponent } from './asignacion/asignacion.component'
import { AuthGuardOperativo } from '@app/core/_guards/authOperativo.guard';

export const BdbOperativaRouting: Routes = [
	{
		path: 'operativa', component: LoginOperativaComponent
	},
	{   
		path: 'asignar', component: AsignacionComponent,  canActivate: [AuthGuardOperativo] 
	},
	{ path: '*', redirectTo: '/login', pathMatch: 'full' }
];