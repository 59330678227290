import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from '@app/shared/shared.module';
import { LoginOperativaComponent } from './login-operativa/login-operativa.component';
import { AsignacionComponent } from './asignacion/asignacion.component'


@NgModule({
	declarations: [LoginOperativaComponent, AsignacionComponent],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		FormsModule, ReactiveFormsModule,
		DeviceDetectorModule.forRoot()
	],
	providers: []
})
export class OperativaModule { }
