import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SharedModule } from '@app/shared/shared.module';
import { BdbPaefModule } from '@app/bdb-paef/bdb-paef.module';
import { InboxModule } from '@app/bdb-paef/inbox/inbox.module';
import { LoginComponent } from './login/login.component';
import { DesconectComponent } from './desconect/desconect.component';
import { LoginSelectComponent } from './login-select/login-select.component';


@NgModule({
	declarations: [LoginComponent, DesconectComponent, LoginSelectComponent],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		BdbPaefModule,
		InboxModule,
		FormsModule, ReactiveFormsModule,
		DeviceDetectorModule.forRoot()
	],
	providers: []
})
export class AuthenticationModule { }
