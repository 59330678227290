import { Component, OnInit } from '@angular/core';
import { OutMessageInput } from '@app/shared/model/mcomponent';
import { NoReturnService } from '@app/shared/services/no-return.service';
import { ModalService } from '@app/shared/services/modal.service';

@Component({
  selector: 'app-outservice',
  templateUrl: './outservice.component.html',
  styleUrls: ['./outservice.component.scss']
})
export class OutserviceComponent implements OnInit {

  outMessageInput: OutMessageInput;

  constructor(private nr: NoReturnService, private modalService: ModalService) { }

  ngOnInit() {
    this.modalService.closeModalDefinitive();
    this.nr.initOutService();
    this.outMessageInput = this.nr.outMessageInput;
    console.log("pantalla de error");
  }

}
