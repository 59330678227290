import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  public questionsRepo = {
    // ----------------------------------------PAEF---------------------------------------
    'paef': {
      'messages': {
        resolucion: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en el artículo 5 de la Resolución 2162 de 2020, modificado por el artículo 5 de la Resolución 2430 de 2021 *',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2430 del 2021 para más información.',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2430 del 2021 para más información.'
      },
      // ------------- PAEF Persona juridica
      '0': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'TIene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.',
          number: '8',
          resp: 'si'
        }

      ],
      // ------------- PAEF Persona natural
      '1': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene una participación de la Nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.',
          number: '8',
          resp: 'si'
        },
        {
          text: 'Tuvo dos (2) o más empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) correspondiente al periodo de cotización del mes de febrero de 2020, en las condiciones establecidas en el artículo 7 de la Resolución 2162 de 2020, modificado por el artículo 6 de la Resolución 2430 de 2021.',
          number: '9',
          resp: 'si'
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del Decreto 1081 de 2015',
          number: '10',
          resp: 'no'
        }
      ],
      // ------------- PAEF Entidad sin animo de lucro (Igual que persona juridica)
      '2': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.',
          number: '8',
          resp: 'si'
        }
      ],
      // ------------- PAEF Consorcio o union temporal
      '3': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020',
          number: '8',
          resp: 'si'
        }
      ],
      // ------------- PAEF Patrimonio Autónomo Declarante
      '4': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.',
          number: '8',
          resp: 'si'
        }
      ],
      // ------------- Cooperativas
      '5': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías –FNG? * ',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que de acuerdo con lo dispuesto en el artículo 3 de la Resolución 2162 de 2020, debe presentar su solicitud de subsidio del PAEF en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el  diligenciamiento de los siguientes campos.',
          resp: 'si',
          opc: true
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene una participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de marzo de 2021 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA)',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del Decreto Legislativo 815 de 2020.',
          number: '8',
          resp: 'si'
        }
      ]
    },
    // ----------------------------------------PAP---------------------------------------
    'pap': {
      'messages': {
        resolucion: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en el artículo 3 de la Resolución 1361. *',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 677 del 2020 para más información.',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 677 del 2020 para más información.'
      },
      // ------------- PAP Persona juridica
      '0': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías -FNG? *',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que debe presentar su solicitud de subsidio del PAP en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el diligenciamiento de los siguientes campos.',
          resp: 'si'
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria. *',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 1 de enero de 2020. Para personas naturales, tome como referencia para su respuesta la fecha de inscripción en el registro mercantil. *',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '6',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '7',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron o recibirán el primer pago de la prima de servicios en las condiciones establecidas en el Decreto Legislativo 770 de 2020. *',
          number: '8',
          resp: 'si'
        }
      ],
      // ------------- PAP Persona natural
      '1': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías -FNG? *',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que debe presentar su solicitud de subsidio del PAP en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el diligenciamiento de los siguientes campos.',
          resp: 'si'
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria. *',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '7',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron o recibirán el primer pago de la prima de servicios en las condiciones establecidas en el Decreto Legislativo 770 de 2020. *',
          number: '8',
          resp: 'si'
        },
        {
          text: 'Tuvo tres (3) o más empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) correspondiente al periodo de cotización del mes de febrero de 2020. *',
          number: '9',
          resp: 'si'
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP) o cónyuge, compañero(a) permanente o pariente en el segundo grado de consanguinidad, primero de afinidad o único civil de una PEP, de acuerdo con lo establecido en el artículo 2.1.4.2.3 del Decreto 1081 de 2015. *',
          number: '10',
          resp: 'no'
        }
      ],
      // ------------- PAP Entidad sin animo de lucro (Igual que persona juridica)
      '2': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías -FNG? *',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que debe presentar su solicitud de subsidio del PAP en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el diligenciamiento de los siguientes campos.',
          resp: 'si'
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria. *',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 1 de enero de 2020. Para personas naturales, tome como referencia para su respuesta la fecha de inscripción en el registro mercantil. *',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '6',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '7',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron o recibirán el primer pago de la prima de servicios en las condiciones establecidas en el Decreto Legislativo 770 de 2020. *',
          number: '8',
          resp: 'si'
        }
      ],
      // ------------- PAP Consorcio o union temporal
      '3': [
        {
          text: '¿Es beneficiario del crédito para nómina garantizado por el Fondo Nacional de Garantías -FNG? *',
          number: '1',
          sub: 'Si su respuesta es SI, recuerde que debe presentar su solicitud de subsidio del PAP en la misma entidad financiera en la que tramitó dicho crédito. En cualquier caso, continúe con el diligenciamiento de los siguientes campos.',
          resp: 'si'
        },
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria. *',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 1 de enero de 2020. Para personas naturales, tome como referencia para su respuesta la fecha de inscripción en el registro mercantil. *',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '6',
          resp: 'no'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera. *',
          number: '7',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron o recibirán el primer pago de la prima de servicios en las condiciones establecidas en el Decreto Legislativo 770 de 2020. *',
          number: '8',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 8 del Artículo 2 del Decreto Legislativo 639 de 2020, modificado por el artículo 1 del Decreto Legislativo 677 de 2020 y por el artículo 2 del decreto 815 de 2020. *',
          number: '11',
          resp: 'si'
        }
      ]
    },
    // ----------------------------------------PAP AGRO---------------------------------------
    'papa': {
      'messages': {
        resolucion: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en el artículo 3 de la Resolución 1361. *',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 803 de 2020 para más información.',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 803 del 2020 para más información.'
      },
      // ------------- PAP Persona juridica
      '0': [],
      // ------------- PAP Persona natural
      '1': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Reportó al (s) empleado (s) en la Planilla Integrada de Liquidación de Aportes – PILA en el periodo de cotización del mes de junio de 2020, en las condiciones establecidas en el Decreto 803 de 2020. ',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Ha presentado esta misma solicitud ante otra entidad financiera.',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP) o cónyuge, compañero(a) permanente o pariente en el segundo grado de consanguinidad, primero de afinidad o único civil de una PEP, de acuerdo con lo establecido en el artículo 3 del Decreto 803 de 2020',
          number: '6',
          resp: 'no'
        },
        {
          text: 'Allegó la certificación expedida por el Administrador de la Contribución parafiscal del sector agropecuario, en las condiciones establecidas en la Resolución 178 de 2020 y las resoluciones que la modifican.',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Los empleados beneficiarios del presente programa cotizaron al Sistema General de Seguridad Social en la Planilla de Liquidación de Aportes- PILA con un ingreso base desde un Salario Mínimo Mensual Legal Vigente hasta un millon de pesos. ',
          number: '8',
          resp: 'si'
        },
        {
          text: 'El postulante autoriza para que, de manera libre, previa, expresa y voluntaria se realice el tratamiento de sus datos personales.Tal autorización permite recolectar, transferir, almacenar, usar, circular, suprimir, compartir, actualizar y transmitir de acuerdo con el procedimiento para el tratamiento de los datos personales, con el único fin de verificar, gestionar y demás actividades relacionadas con el Programa de Apoyo para el Pago de la Prima de Servicios PAP para el Sector Agropecuario. El alcance de la autorización comprende la facultad para que se contacte al titular a través de diferentes medios de comunicación (telefónico, correo electrónico o mensaje de texto, entre otros).   <br>    Los titulares podrán ejercer sus derechos de conocer, actualizar, rectificar y suprimir sus datos personales a través de los canales dispuestos para esto.Al diligenciar este formulario usted autoriza que sus datos sean inter-operados (compartidos) entre instituciones públicas/privadas para verificar la información reportada:',
          number: '9',
          resp: 'si'
        }
      ],
      // ------------- PAP Entidad sin animo de lucro (Igual que persona juridica)
      '2': [],
      // ------------- PAP Consorcio o union temporal
      '3': []
    },
    // ----------------------------------------SACUDETE---------------------------------------
    'sacudete': {
      'messages': {
        resolucion: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en la el artículo 5 de la Resolución 2162 o el artículo 3 de la Resolución 0360 *',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 688 del 2021 para más información',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Decreto 688 del 2021 para más información'
      },
      // ------------- PAEF Persona juridica
      '0': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes inmediatamente anterior.',
          number: '4',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).*',
          number: '5',
          resp: 'si'
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true
        }
      ],
      // ------------- PAEF Persona natural
      '1': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes inmediatamente anterior.',
          number: '4',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).*',
          number: '5',
          resp: 'si'
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del decreto 1674 de 2016.',
          number: '7',
          resp: 'no'
        }
      ],
      // ------------- PAEF Entidad sin animo de lucro (Igual que persona juridica)  / Cooperativa
      '2': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes inmediatamente anterior.',
          number: '4',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).*',
          number: '5',
          resp: 'si'
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true
        },
        {
          text: 'Se encuentra debidamente registrada ante la Cámara de su domicilio principal',
          number: '7',
          resp: 'si'
        }
      ],
      // ------------- PAEF Consorcio o union temporal
      '3': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes inmediatamente anterior.',
          number: '4',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).*',
          number: '5',
          resp: 'si'
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true
        }
      ],
      // ------------- PAEF Patrimonio Autónomo Declarante
      '4': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes inmediatamente anterior.',
          number: '4',
          resp: 'si'
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).*',
          number: '5',
          resp: 'si'
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true
        }
      ],
    },
    // ----------------------------------------PARO -- FLUJO 5---------------------------------------
    'paro': {
      'messages': {
        resolucion: 'De acuerdo con lo establecido en el artículo 26 de la Ley 2155 de 2021 y la Resolución 2474 del mismo año, los requisitos manifestados a continuación son habilitantes para continuar con la postulación al beneficio del Programa de apoyo a empresas afectadas por el paro nacional. Si no cumple con alguno de ellos, absténgase de continuar con esta solicitud. Su respuesta puede ser SI o NO dependiendo del caso.',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2474 del 2021 para más información.',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar la Resolución 2474 del 2021 para más información.'
      },

    // ------------- PAEF Persona juridica
    '0': [
      {
        text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
        number: '1',
        resp: 'si'
      },
      {
        text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
        number: '2',
        resp: 'si'
      },
      {
        text: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021',
        number: '3',
        resp: 'si'
      },
      {
        text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
        number: '4',
        resp: 'no'
      },
      {
        text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
        number: '5',
        resp: 'no'
      },
      {
        text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
        number: '6',
        resp: 'si'
      },
      {
        text: 'Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.',
        number: '7',
        resp: 'si'
      }
    ],
    // ------------- PAEF Persona natural
    '1': [
      {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.',
          number: '7',
          resp: 'si'
        },
        {
          text: 'Tuvo dos (2) o más empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) correspondiente al periodo de cotización del mes de febrero de 2020, en las condiciones establecidas en el artículo 7 de la Resolución 2474 de 2021.',
          number: '8',
          resp: 'si'
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del Decreto 1081 de 2015.',
          number: '9',
          resp: 'no'
        }
    ],
    // ------------- PAEF Entidad sin animo de lucro (Igual que persona juridica)
    '2': [
      {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.',
          number: '7',
          resp: 'si'
        }
    ],
    // ------------- PAEF Consorcio o union temporal
    '3': [
      {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si' 
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.',
          number: '7',
          resp: 'si'
        }
    ],
    // ------------- PAEF Patrimonio Autónomo Declarante
    '4': [
      {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si'
        },
        {
          text: 'Fue constituida antes del 6 de mayo de 2020. Para personas naturales que deben estar inscritas en el registro mercantil, tome como referencia la fecha de inscripción.',
          number: '2',
          resp: 'si'
        },
        {
          text: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, al comparar los ingresos del mes del aporte con los ingresos obtenidos en marzo de 2021',
          number: '3',
          resp: 'si'
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '4',
          resp: 'no'
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '5',
          resp: 'no'
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario correspondiente al mes del subsidio.',
          number: '6',
          resp: 'si'
        },
        {
          text: 'Cumple con lo dispuesto en el parágrafo 2 del artículo 2 de la Resolución 2474 del 15 de octubre de 2021.',
          number: '7',
          resp: 'si'
        }
    ]
    },
    // ----------------------------------------NUEVOS EMPLEOS---------------------------------------
    'empleos': {
      'messages': {
        resolucion: 'Tuvo una disminución del veinte por ciento (20%) o más en sus ingresos, en los términos descritos en la el artículo 5 de la Resolución 2162 o el artículo 3 de la Resolución 0360 *',
        respno: 'Si su respuesta es NO su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 3289 del 2021 para más información.',
        respsi: 'Si su respuesta es SI su empresa puede no ser apta para este subsidio. Lo invitamos a consultar el Resolución 3289 del 2021 para más información.'      },
      // ------------- NUEVOS EMPLEOS Persona juridica      
      '0': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si',
          visible: true
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no',
          visible: true
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no',
          visible: true
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.',
          number: '4',
          resp: 'si',
          visible: true
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).',
          number: '5',
          resp: 'si',
          visible: true
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '7',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '8',
          resp: 'no',
          opc: true,
          visible: false
        }
      ],
      // ------------- NUEVOS EMPLEOS Persona natural      
      '1': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si',
          visible: true
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no',
          visible: true
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no',
          visible: true
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.',
          number: '4',
          resp: 'si',
          visible: true
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).',
          number: '5',
          resp: 'si',
          visible: true
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es una Persona Expuesta Políticamente (PEP), de acuerdo con lo establecido en el artículo 2.1.4.2.3 del decreto 1674 de 2016.',
          number: '7',
          resp: 'no',
          visible: true
        },    
        {
          text: 'Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '8',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '9',
          resp: 'no',
          opc: true,
          visible: false
        }
      ],
      // ------------- NUEVOS EMPLEOS Cooperativa     
       '2': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si',
          visible: true
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no',
          visible: true
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no',
          visible: true
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.',
          number: '4',
          resp: 'si',
          visible: true
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).',
          number: '5',
          resp: 'si',
          visible: true
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Se encuentra debidamente registrada ante la Cámara de Comercio de su domicilio principal.',
          number: '7',
          resp: 'si',
          visible: true
        },
        {
          text: 'Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '8',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '9',
          resp: 'no',
          opc: true,
          visible: false
        }
      ],
      // ------------- NUEVOS EMPLEOS Consorcio o union temporal      
      '3': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si',
          visible: true
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no',
          visible: true
        },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no',
          visible: true
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.',
          number: '4',
          resp: 'si',
          visible: true
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).',
          number: '5',
          resp: 'si',
          visible: true
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '7',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '8',
          resp: 'no',
          opc: true,
          visible: false
        }
      ],
      // ------------- NUEVOS EMPLEOS Patrimonio Autónomo Declarante      
      '4': [
        {
          text: 'Cuenta con un producto de depósito (cuenta de ahorros, cuenta corriente o depósito  electrónico) en una entidad financiera vigilada por la Superintendencia Financiera de Colombia o por la Superintendencia de Economía Solidaria.*',
          number: '1',
          resp: 'si',
          visible: true
        },
        {
          text: 'Tiene participación de la nación y/o sus entidades descentralizadas por un monto superior al 50% de su capital. *',
          number: '2',
          resp: 'no',
          visible: true
          },
        {
          text: 'Ha  presentado esta misma solicitud ante otra entidad financiera. *',
          number: '3',
          resp: 'no',
          visible: true
        },
        {
          text: 'Los empleados sobre los cuales recibirá el aporte, efectivamente recibieron el salario o el pago de las compensaciones (según sea el caso) correspondientes al mes inmediatamente anterior.',
          number: '4',
          resp: 'si',
          visible: true
        },
        {
          text: 'Los aportes correspondientes a todos los trabajadores que tuvo en el mes de mayo de 2023 se encuentran pagados en la Planilla Integrada de Liquidación de Aportes (PILA).',
          number: '5',
          resp: 'si',
          visible: true
        },
        {
          text: 'Hizo parte de un proceso de sustitución patronal o de empleador en los términos de los artículos 67 y 68 del Código Sustantivo del Trabajo posterior al mes de marzo de 2021.',
          number: '6',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Declarante de Renta de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '7',
          resp: 'no',
          opc: true,
          visible: true
        },
        {
          text: 'Es Autoretenedor de la renta por otros ingresos de acuerdo a lo registrado en su RUT en la Casilla 53.',
          number: '8',
          resp: 'no',
          opc: true,
          visible: false
        }
      ],
    }
  };

  constructor() { }
  
}
