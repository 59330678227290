export const environment = {
  production: false,
  testing: false,
  cryptoCookies: false,
  mes: 'JUNIO',
  mesd: 'JULIO',
  mesV: 'julio',
  diaV: '19',
  horaV:'4:00 pm',
  planilla: 'Julio',
  endpointManager: 'https://st-portalpaef-gw.awsbanbogota.com/',
  endpointUrlRestitucion: 'https://st-portalpaef-gw.awsbanbogota.com/documents/s3?key=public/Manual_de_Restitucio%CC%81n_PAEF.PDF',
  apiSbx: 'https://st-portalpaef-sbx.awsbanbogota.com/',//recurso que debe generar el devops segun el cloudFront
  PV_KEY: 'NjksMjI0LDEzOC==',//llave necesaria para desencriptar lo que viene del backend, generar la de ustedes de 16 digitos
  PB_KEY: 'NzYsMTA1LDEyMg==',//llave necesaria para desencriptar lo que viene del backend, generar la de ustedes de 16 digitos
  CYPHER_JS_KEY: '',
  tagManagerId: '',
  SBX_PARAMETERS_v2: {
    Parameters: {
      Time: 3,
      Body: 0,
      Iv: 9,
      Pk1: 2,
      Pk2: 6,
      Total: 12,
    },
    Expiration: {
      Time: 120,
      Unit: 'seconds',
    },
  },
  SBX_RQ_PBK://pulica se encripta desde el front hasta el back
  //'NTEsMTkyLDc5LDg4LDI1MCw2NywyNDMsMTI2LDQ1LDcyLDEzMiwzMyw5MCwyNDgsMTQ1LDIxNiwxOTEsMTUyLDE1MCwyNTQsMzgsMjM1LDIzOSw2NiwyMzIsMTIxLDk2LDIzNSwyMCw2NCwzMCwxOA=='
  'MTM3LDIwLDE2MSw0NCwxNDEsMTQwLDI4LDIxOCwxMjYsMTYwLDE5OCwxNjQsMTk4LDEwOCwzMiwxNTYsNTMsMTkyLDEsMjA1LDIzMyw2LDMzLDc0LDExNSwxNDgsMTgwLDE5LDU5LDk5LDE2NiwxMDg=='
};

