<div class="container">
    <h1>
        Guía para la dispersión de los aportes monetarios del Programa de apoyo al empleo formal- PAEF del Gobierno Nacional

    </h1>
    <div class="content">
        <p class="num">1. Objeto</p>
        <p>El Gobierno Nacional creó el “Programa de apoyo al empleo formal- PAEF-“por medio del cual se otorgará, a las empresas del país que cumplan con los requisitos señalados en el Decreto 639 de 2020, un aporte monetario mensual, hasta por tres veces, para apoyar y proteger el empleo formal del país durante la pandemia del coronavirus COVID-19.</p>
        <p>El Banco de Bogotá participará en la dispersión de estos aportes según el procedimiento indicado en el Decreto 639 de 2020 y el Proceso que para el efecto expida el Gobierno Nacional.</p>
        <p>A continuación, una guía sobre el procedimiento indicado por el Gobierno.</p>
        
        <p class="num">2. Beneficiarios </p>        
        <p>Todas las personas jurídicas que cumplan con las siguientes condiciones: </p>
        <p class="spc1">2.1. Certifiquen una disminución del veinte por ciento (20%) o más en sus ingresos. El Ministerio de Hacienda y Crédito Público	determinará el método de cálculo de la disminución en ingresos.</p>
        <p class="spc1">2.2. Hayan sido constituidas antes del 10 de enero de 2020.</p>
        <p class="spc1">2.3. Cuenten con un registro mercantil que haya sido renovado por lo menos en el año 2019. Esto requisito únicamente aplica para	las personas jurídicas constituidas en los años 2018 y anteriores. Las entidades sin ánimo de lucro deberán aportar copia del Registro Único Tributario en el que conste que el postulante es contribuyente del Régimen Tributario Especial.</p>
        <p class="spc1">2.4. No hayan recibido el aporte del Programa en tres ocasiones.</p>
        <p class="spc1">2.5. No hayan estado obligadas a restituir el aporte según lo señalado en el numeral 7 de la presente Guía, salvo lo señalado en el	numeral 4.2.</p>            
        <p class="spc1">2.6.	Contar con un producto de depósito en el Banco de Bogotá, cuando la solicitud se haga a través de este Banco.</p>
        <p>No podrán ser beneficiarios del Programa las entidades cuya participación de la Nación y/o sus entidades descentralizadas sea mayor al 50% de su capital.</p>
        
        <p class="num">3. Cuál es el monto del aporte </p>
        <p>El aporte se calculará multiplicando el número de empleados de cada persona jurídica por hasta el cuarenta por ciento (40%) del valor del salario mínimo legal mensual vigente del año 2020.</p>
        <p>El número de empleados es el menor valor entre: (i) el número de empleados reportados en la Planilla Integrada de Liquidación de Aportes (PILA) (periodo de cotización febrero de 2020) o (ii) el número de trabajadores que la persona jurídica manifiesta planea proteger.</p>
        <p>Para el Programa son empleados:</p>
        <p class="spc1">• Los trabajadores dependientes por los cuales la persona jurídica cotiza al sistema general de seguridad social en la Planilla Integrada de Liquidación de Aportes (PILA).</p>
        <p class="spc1">• Los trabajadores con un ingreso base de cotización de mínimo un salario mínimo mensual legal vigente.</p>
        <p class="spc1">• A los trabajadores que no se les haya aplicado la novedad de suspensión temporal de contrato de trabajo o de licencia no remunerada (SLN).</p>
        <p>El aporte se pagará de manera mensual, en máximo 3 ocasiones, siempre que se cumpla con lo indicado en el Decreto 639 de 2020. </p>
        
        <p class="num">4. Procedimiento de postulación del aporte:</p>
        <p>Según lo señalado en el Decreto 639 de 2020, el procedimiento de postulación es el siguiente:</p>
        <p class="spc1">4.1. Las personas jurídicas que tengan un producto de depósito en el Banco de Bogotá deberán presentar los siguientes documentos:</p>
        <p class="spc1">4.1.1. Solicitud firmada por el representante legal de la persona jurídica, en la cual se manifiesta la intención de ser beneficiario del Programa.</p>
        <p class="spc1">4.1.2. Certificado de existencia y representación legal, en el cual conste el nombre y documento del representante legal.</p>
        <p class="spc1">4.1.3. Certificación, firmada por el representante legal y el revisor fiscal, o por contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal, en la que se certifique:</p>
        <p class="spc2">• El número de empleos formales que se mantendrán en el mes correspondiente a través del aporte estatal objeto de este programa.</p>
        <p class="spc2">• La disminución de ingresos del veinte por ciento (20%) o más.</p>
        <p class="spc2">• Que los recursos solicitados y efectivamente recibidos serán, única y exclusivamente, destinados al pago de los salarios de los empleos formales del beneficiario.</p>
        <p class="spc1">4.2.	Cuando se solicite el aporte por segunda o tercera vez, además de la documentación indicada en el anterior numeral, deberá 	presentar:</p>
        <p class="spc1">4.2.1. Certificación firmada por el representante legal y el revisor fiscal (o por contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal), de que los recursos recibidos previamente en virtud del Programa fueron efectivamente destinados para el pago de la nómina de los trabajadores y que dichos empleados recibieron el salario correspondiente.</p>
        <p class="spc1">4.2.2. Cuando aplique, certificación expedida por el Banco donde se acredita la restitución de los recursos, cuando el aporte recibido con anterioridad fue superior al efectivamente utilizado para el pago de salarios de los trabajadores del respectivo mes.</p>
        <p class="spc1">4.3.	El acto de postularse implica la aceptación, por parte de la persona jurídica, de las condiciones bajo las cuales se otorga el 	aporte estatal.</p>
        <p class="spc1">4.4.	La simple postulación no implica el derecho a recibir el aporte.</p>
        <p class="spc1">4.5.	Para los efectos de la responsabilidad penal, se entiende que los documentos presentados para la postulación, así como los recursos del aporte estatal que reciban los beneficiarios, son de naturaleza pública.</p>
        
        <p class="num">5. Cómo se concede el aporte:</p>
        <p>Según lo señalado en el Decreto 639 de 2020, el procedimiento para conceder el aporte es el siguiente:</p>
        <p class="spc1">5.1.	El Banco recibe los documentos, verificando que se encuentren completos y comprobando la identidad y calidad de quien realiza la postulación al Programa.</p>
        <p class="spc1">5.2.	El Banco informa a la Unidad Administrativa Especial de Gestión Pensional y Contribuciones Parafiscales de la Protección Social- UGPP de la recepción de los documentos.</p>
        <p class="spc1">5.3.	La UGPP verificará que el beneficiario no se ha postulado para el mismo aporte mensual ante otras entidades bancarias.</p>
       
        <p class="num">6. Vigencia del Programa </p>
        <p>Según lo señalado por el Decreto 639 de 2020, la vigencia del Programa es la siguiente:</p>
        <p class="spc1">6.1.	Mayo, junio y julio de 2020. Las personas jurídicas sólo podrán solicitar por una vez mensualmente, e hasta por un máximo de tres veces.</p>
        <p class="spc1">6.2.	Excepcionalmente, cuando las personas jurídicas también tengan la calidad de deudores de líneas de crédito para nómina 	garantizadas por el Fondo Nacional de Garantías, podrán solicitar el aporte estatal por un máximo de tres veces, hasta agosto de 	2020. La suma total de recursos por concepto de los créditos garantizados y el aporte estatal del Programa, no podrá superar el valor total de las obligaciones laborales a cargo de la persona jurídica.</p>
        
        <p class="num">7. Restitución del aporte</p>
        <p class="spc1">7.1.	Cuando no se haya utilizado para el pago de los salarios de los trabajadores que corresponden al número de empleados indicado en el numeral 3 de la presente Guía.</p>
        <p class="spc1">7.2.	Habiendo recibido el aporte, se evidencie que, al momento de la postulación, la persona jurídica no cumplía con los requisitos exigidos.</p>
        <p class="spc1">7.3.	Se compruebe que existió falsedad en los documentos presentados para acreditar los requisitos establecidos para la asignación del aporte. Para estos efectos, bastará comunicación de la entidad originaria de dichos documentos contradiciendo el contenido de los mismos.</p>
        <p class="spc1">7.4.	El beneficiario manifieste que el aporte recibido fue superior al efectivamente utilizado para el pago de salarios de sus trabajadores del respectivo mes. Únicamente para este caso, la restitución del aporte corresponderá a la diferencia entre lo recibido y lo efectivamente desembolsado. El Banco certificará la restitución de dichos recursos.</p>
        
        <p class="num">8.Irregularidades en la asignación del aporte</p>
        <p>Según lo señalado por el Decreto 639 de 2020, las personas jurídicas que reciban uno o más aportes sin el cumplimiento de los requisitos establecidos y no lo informen a la autoridad competente, o las reciban de forma fraudulenta, o los destinen a fines diferentes a los establecidos en el Decreto 639 de 2020, incurrirán en las responsabilidades disciplinarias, fiscales y penales a las que hubiere lugar. </p>
        <p>En caso de verificarse el incumplimiento de uno de los requisitos, la UGPP adelantará el proceso de cobro coactivo, aplicando el procedimiento y sanciones establecido en el Estatuto Tributario para las devoluciones improcedentes.</p>
        
        <p class="num">9.Tratamiento de la información</p>
        <p>De conformidad con lo señalado en el Decreto 639 de 2020, las entidades públicas y privadas están autorizadas a recibir y suministrar los datos personales de los que trata la Ley 1581 de 2012 y la información financiera, crediticia, comercial, de servicios y la proveniente de terceros países conforme a la Ley 1266 de 2008, que sea necesaria para identificar y certificar a los beneficiarios del Programa, así como para garantizar la entrega efectiva de los aportes respectivos.</p>
        
        <p class="num">10.Otros beneficios</p>
        <p>De conformidad con lo señalado en el Decreto 639 de 2020, frente a los aportes se tendrán los siguientes beneficios: </p>
        <p class="spc1">10.1. Los traslados de los dineros correspondientes a los aportes, entre cuentas del Tesoro Nacional - Ministerio de Hacienda y 	Crédito Público y las entidades financieras que dispersen los recursos y entre las entidades financieras y los beneficiarios, estarán exentos del gravamen a los movimientos financieros.</p>
        <p class="spc1">10.2. La comisión o servicio que se cobre por la dispersión de los recursos por parte de las entidades financieras a los beneficiarios del programa estará excluida del impuesto sobre las ventas -IVA.</p>
        <p class="spc1">10.3. Los recursos correspondientes al aporte serán inembargables y deberán destinarse, única y exclusivamente, al pago de los salarios de los empleos formales del beneficiario. Estos no podrán abonarse a ningún tipo de obligación del beneficiario con la entidad financiera a través de la cual se disperse el aporte. No obstante, el beneficiario podrá adelantar, en el marco del pago de nómina, los descuentos previamente autorizados por sus trabajadores.</p>
        








    
    </div>
    <div class="button-data">
        <div>
            <button class="btn-outline" (click)="return() ">
                Cancelar
            </button>
            <button class="btn-defauld" (click)="continuar() ">
                Continuar >
            </button>
        </div>
    </div>
</div>