import { Component, OnInit, HostListener, Input } from '@angular/core';
import { LoginStepService } from '@app/authentication/service/login-step.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { StoreService } from '@core/service/store.service';


@Component({
  selector: 'aliv-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  innerHTML = ""
  constructor(private route: Router,
    public loginStepService: LoginStepService) {
  }

  ngOnInit() {
  }

}
