import { DynQuestion } from './formularios';

export const sacudeteForms: Array<DynQuestion[]> = [
    [
        {
            "name": "date_request"
        },
        {
            "name": "phase_month"
        },
        {
            "name": "tipoIdEmpresa"
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "ciiu"
        },
        {
            "name": "preguntaDlloDichaAct",
            "optional": true
        },
        {
            "name": "cuSusTipoId1",
            "optional": true
        },
        {
            "name": "cuSusNumId1",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId2",
            "optional": true
        },
        {
            "name": "cuSusNumId2",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId3",
            "optional": true
        },
        {
            "name": "cuSusNumId3",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId4",
            "optional": true
        },
        {
            "name": "cuSusNumId4",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId5",
            "optional": true
        },
        {
            "name": "cuSusNumId5",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId6",
            "optional": true
        },
        {
            "name": "cuSusNumId6",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId7",
            "optional": true
        },
        {
            "name": "cuSusNumId7",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId8",
            "optional": true
        },
        {
            "name": "cuSusNumId8",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId9",
            "optional": true
        },
        {
            "name": "cuSusNumId9",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId10",
            "optional": true
        },
        {
            "name": "cuSusNumId10",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            }
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "rlNombre",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlApellido",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlTipoDoc",
        },
        {
            "name": "rlNumDoc",
            "validation": {
                "maxLength": 10
            }
        },
        {
            "name": "rlCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "rlEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numJovenes",
            "validation": {
                "minLength": 0,
                "maxLength": 10
            },
            "optional": true
        }
    ],
    [
        {
            "name": "date_request"
        },
        {
            "name": "phase_month"
        },
        {
            "name": "tipoIdEmpresa"
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "ciiu"
        },
        {
            "name": "preguntaDlloDichaAct",
            "optional": true
        },
        {
            "name": "cuSusTipoId1",
            "optional": true
        },
        {
            "name": "cuSusNumId1",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId2",
            "optional": true
        },
        {
            "name": "cuSusNumId2",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId3",
            "optional": true
        },
        {
            "name": "cuSusNumId3",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId4",
            "optional": true
        },
        {
            "name": "cuSusNumId4",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId5",
            "optional": true
        },
        {
            "name": "cuSusNumId5",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId6",
            "optional": true
        },
        {
            "name": "cuSusNumId6",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId7",
            "optional": true
        },
        {
            "name": "cuSusNumId7",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId8",
            "optional": true
        },
        {
            "name": "cuSusNumId8",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId9",
            "optional": true
        },
        {
            "name": "cuSusNumId9",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId10",
            "optional": true
        },
        {
            "name": "cuSusNumId10",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            }
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numJovenes",
            "validation": {
                "minLength": 0,
                "maxLength": 10
            },
            "optional": true
        }
    ],
    [
        {
            "name": "date_request"
        },
        {
            "name": "phase_month"
        },
        {
            "name": "tipoIdEmpresa"
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "ciiu"
        },
        {
            "name": "preguntaDlloDichaAct",
            "optional": true
        },
        {
            "name": "cuSusTipoId1",
            "optional": true
        },
        {
            "name": "cuSusNumId1",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId2",
            "optional": true
        },
        {
            "name": "cuSusNumId2",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId3",
            "optional": true
        },
        {
            "name": "cuSusNumId3",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId4",
            "optional": true
        },
        {
            "name": "cuSusNumId4",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId5",
            "optional": true
        },
        {
            "name": "cuSusNumId5",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId6",
            "optional": true
        },
        {
            "name": "cuSusNumId6",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId7",
            "optional": true
        },
        {
            "name": "cuSusNumId7",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId8",
            "optional": true
        },
        {
            "name": "cuSusNumId8",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId9",
            "optional": true
        },
        {
            "name": "cuSusNumId9",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId10",
            "optional": true
        },
        {
            "name": "cuSusNumId10",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            }
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "rlNombre",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlApellido",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlTipoDoc",
        },
        {
            "name": "rlNumDoc",
            "validation": {
                "maxLength": 10
            }
        },
        {
            "name": "rlCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "rlEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numJovenes",
            "validation": {
                "minLength": 0,
                "maxLength": 10
            },
            "optional": true
        }
    ],
    [
        {
            "name": "date_request"
        },
        {
            "name": "phase_month"
        },
        {
            "name": "tipoIdEmpresa"
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "ciiu"
        },
        {
            "name": "preguntaDlloDichaAct",
            "optional": true
        },
        {
            "name": "cuSusTipoId1",
            "optional": true
        },
        {
            "name": "cuSusNumId1",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId2",
            "optional": true
        },
        {
            "name": "cuSusNumId2",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId3",
            "optional": true
        },
        {
            "name": "cuSusNumId3",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId4",
            "optional": true
        },
        {
            "name": "cuSusNumId4",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId5",
            "optional": true
        },
        {
            "name": "cuSusNumId5",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId6",
            "optional": true
        },
        {
            "name": "cuSusNumId6",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId7",
            "optional": true
        },
        {
            "name": "cuSusNumId7",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId8",
            "optional": true
        },
        {
            "name": "cuSusNumId8",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId9",
            "optional": true
        },
        {
            "name": "cuSusNumId9",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId10",
            "optional": true
        },
        {
            "name": "cuSusNumId10",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            }
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "rlNombre",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlApellido",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlTipoDoc",
        },
        {
            "name": "rlNumDoc",
            "validation": {
                "maxLength": 10
            }
        },
        {
            "name": "rlCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "rlEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numJovenes",
            "validation": {
                "minLength": 0,
                "maxLength": 10
            },
            "optional": true
        }
    ],
    [
        {
            "name": "date_request"
        },
        {
            "name": "phase_month"
        },
        {
            "name": "tipoIdEmpresa"
        },
        {
            "name": "numIdEmpresa"
        },
        {
            "name": "razonSocial",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "ciiu"
        },
        {
            "name": "preguntaDlloDichaAct",
            "optional": true
        },
        {
            "name": "cuSusTipoId1",
            "optional": true
        },
        {
            "name": "cuSusNumId1",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId2",
            "optional": true
        },
        {
            "name": "cuSusNumId2",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId3",
            "optional": true
        },
        {
            "name": "cuSusNumId3",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId4",
            "optional": true
        },
        {
            "name": "cuSusNumId4",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId5",
            "optional": true
        },
        {
            "name": "cuSusNumId5",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId6",
            "optional": true
        },
        {
            "name": "cuSusNumId6",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId7",
            "optional": true
        },
        {
            "name": "cuSusNumId7",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId8",
            "optional": true
        },
        {
            "name": "cuSusNumId8",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId9",
            "optional": true
        },
        {
            "name": "cuSusNumId9",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "cuSusTipoId10",
            "optional": true
        },
        {
            "name": "cuSusNumId10",
            "validation": {
                "minLength": 6,
                "maxLength": 15
            },
            "optional": true
        },
        {
            "name": "direccion",
            "validation": {
                "maxLength": 150
            }
        },
        {
            "name": "departamento"
        },
        {
            "name": "municipio"
        },
        {
            "name": "conFijo",
            "validation": {
                "minLength": 7,
                "maxLength": 20
            }
        },
        {
            "name": "conCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "conEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "conEmail2",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "rlNombre",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlApellido",
            "validation": {
                "maxLength": 50
            }
        },
        {
            "name": "rlTipoDoc",
        },
        {
            "name": "rlNumDoc",
            "validation": {
                "maxLength": 10
            }
        },
        {
            "name": "rlCelular",
            "validation": {
                "minLength": 10,
                "maxLength": 10
            }
        },
        {
            "name": "rlEmail",
            "validation": {
                "maxLength": 150,
                "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            }
        },
        {
            "name": "tipoCuentaBanc",
        },
        {
            "name": "numCuentaBanc",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "numCuentaBancC",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            }
        },
        {
            "name": "tipoCuentaBanc2",
            "optional": true
        },
        {
            "name": "numCuentaBanc2",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numCuentaBanc2C",
            "validation": {
                "minLength": 9,
                "maxLength": 9
            },
            "optional": true
        },
        {
            "name": "numJovenes",
            "validation": {
                "minLength": 0,
                "maxLength": 10
            },
            "optional": true
        }
    ],
]