<div class="barr">
    <div class="line">
        <div id="progreso" class="progresss"></div>
    </div>
    <div class="text">
        <p class="pint">
            01. Preguntas
        </p>
        <p class="pint">
            02. Formulario
        </p>
        <p>
            03. Documentos
        </p>
    </div>
</div>
<aliv-corte [type]="'type-3'" [message]="flow.subtitle"></aliv-corte>
<div class="title-main">
    <h2>
        Para continuar, cargue los documentos indicados:
    </h2>
</div>
<div class="container">
    <div class="header">
        <div class="data-format">
            <div class="btn-conte">
                <p>
                    Si no ha firmado los formatos, puede descargarlos aquí:
                </p>
                <button class="btn-outline" (click)="descargarDocumentos()">
                    Descargar documentos
                </button>
            </div>
            <div class="utl" *ngIf="flujo != 2 && flujo != 0 && flujo != 4 && flujo != 6">
                <a (click)="openContract()">
                    Consulte aquí la documentación necesaria por tipo de empresa
                </a>
            </div>
            <p class="data-text">
                1 archivo por tipo documental
            </p>
            <div class="container-format">
                <div class="label-format label-left">
                    <p class="label label-web">Puedes subir archivos en formatos</p>
                    <div class="formats">
                        <p>JPG</p>
                        <p>PNG</p>
                        <p>PDF</p>
                    </div>
                </div>
                <div class="label-format label-right">
                    <p class="label">Peso Máximo</p>
                    <div class="size">
                        <img class="icon-cloud" src="assets/img/generic/cloud.svg" />
                        <p>2 MB</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="content" style="padding-top: 45px;">
        <div *ngFor="let card of containersJson; index as i">
            <aliv-card [cardType]="'default'" [card]="card" [id]="card.idDocument + ''" [e]="i"></aliv-card>
        </div>
        <div class="container-btn">
            <button class="btn-outline" (click)="uploadDocService.continueAfter()">
                Continuar después
            </button>
            <!-- [ngClass]="validData() ? 'btn-continue' : 'btn-defauld'" -->
            <button class="btn-continue" (click)="uploadDocService.confirmDocuments()" [disabled]="!validData()">
                Cargar documentos >
            </button>
        </div>
    </div>
</div>