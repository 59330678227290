import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { SessionStorage } from "ngx-webstorage";
import { finishRequest } from '../models/user-login.model';
import { ModalService } from "@app/shared/services/modal.service";
import { UtilsService } from "@app/shared/services/utils.service";
import { StoreService } from './store.service';
//import { LoginStepService } from '../../authentication/service/login-step.service';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { CryptoService } from "./crypto/crypto.service";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class UploadDocService {
	/*   @SessionStorage('docType')
		public docTypeSS = this.storeService.get("docTypeSS")
		@SessionStorage('docNumber')
		public docNumberSS = this.storeService.get("docTypeSS")
		*/
	public local: boolean = false;
	public preview: boolean = false;
	public base64: string = "";
	public key: string = "";
	public id: any;
	public index: any;
	public type: string = "";
	public urlDocumentS3 = environment.endpointManager + 'documents';
	public urlData = environment.endpointManager;
	public urlWebsocket = ""
	public info = {
		causes: [],
		type: false
	}
	public documentUpload = [];//LISTA DE DOCUMENTOS
	public checks = []
	public sendDocuments: boolean = false;
	public docsToUpload: number = 0;
	public docsUploaded: number = 0;
	public docCorrection: boolean;
	public confir: boolean = false;
	public dataToCauses = 0
	public box = [
		// paef
		[
			//Persona Juridica 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales",
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.<br></span><a class='data-resalt'> <br>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Persona Natural 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Entidad Sin Animo de lucro 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Consorcio 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Patrimonio Autonomo Declarante
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.<br><a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Declaración de renta y complementarios',
					info: { "description": "Declaración de impuestos sobre la renta y complementarios del ultimo periodo gravable disponible." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Cooperativa 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales",
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			]
		],
		// pap
		[
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal.Iglesias Cristianas: Certificado del Ministerio del Interior. Entidades educativas: Certificado del ministerio de educación. Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. Iglesias Cristianas: Certificado del Ministerio del Interior.Entidades educativas: Certificado del ministerio de educación.Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. Iglesias Cristianas: Certificado del Ministerio del Interior. Entidades educativas: Certificado del ministerio de educación. Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución) o Registro único tributario (RUT)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT	" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.  <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Declaración de renta y complementarios',
					info: { "description": "Declaración de impuestos sobre la renta y complementarios del ultimo periodo gravable disponible." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			]
		],
		// papa
		[
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal.Iglesias Cristianas: Certificado del Ministerio del Interior. Entidades educativas: Certificado del ministerio de educación. Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. Iglesias Cristianas: Certificado del Ministerio del Interior.Entidades educativas: Certificado del ministerio de educación.Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. Iglesias Cristianas: Certificado del Ministerio del Interior. Entidades educativas: Certificado del ministerio de educación. Centros Comerciales: Certificado de existencia alcaldía local <a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por el representante legal o la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución) o Registro único tributario (RUT)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT	" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PAEF firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.  <a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos, empleados sobre los que recibirá el subsidio y pago de obligaciones laborales',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Intensión de ser beneficiario del Programa de Apoyo al pago de la Prima de Servicios - PAP',
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Declaración de renta y complementarios',
					info: { "description": "Declaración de impuestos sobre la renta y complementarios del ultimo periodo gravable disponible." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			]
		],
		[],
		// sacudete
		[
			// ------------- PAEF Persona juridica
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario programa Sacúdete.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.<br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Sacúdete.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior.<br> Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local.<br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "En caso de adjuntar el registro mercantil, cargue el RUT para validar el representante legal. Si se cargó el certificado de existencia no será necesario adjuntar este documento." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			// ------------- PAEF Persona natural
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario programa Sacúdete.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.<br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Sacúdete.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local.<br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			// ------------- PAEF Cooperativa (Igual que persona juridica)
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario programa Sacúdete.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Sacúdete.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			// ------------- PAEF Consorcio o union temporal
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario programa Sacúdete.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Sacúdete.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal.<span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución) o Registro único tributario (RUT)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT " },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			// ------------- PAEF Patrimonio Autónomo Declarante
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante programa Sacúdete.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.  <br><a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Sacúdete.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro único tributario (RUT)',
					info: { "description": "" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Declaración de renta y complementarios',
					info: { "description": "Declaración de impuestos sobre la renta y complementarios del ultimo periodo gravable disponible." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			]
		],
		// paro
		[
			//Persona Juridica 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PARO firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Certificación cumplimiento de requisitos: sobre disminución de ingresos.",
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'><br>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Persona Natural 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PARO firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Entidad Sin Animo de lucro 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PARO firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Consorcio 
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PARO firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span> <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": " <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			],
			//Patrimonio Autonomo Declarante
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante PARO firmado y escaneado.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora.  <br><a class='data-resalt'> Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: sobre disminución de ingresos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": " <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local.<br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				}
			]
		],
		// Nuevos Empleos
		[
			// ------------- Persona juridica
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante Programa Nuevos Empleos.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Nuevos Empleos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal - Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>La vigencia del RUT debe ser menor a 6 meses.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Resolución autoretenedor',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>Si es autoretenedor de impuesto sobre la renta por otros ingresos debe adjuntar la resolución respectiva.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: [],
					dependentQuestion: true,
					keyDependQuestion: "CUM8-PAEF"
				}				
			],
			// ------------- Persona natural
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante Programa Nuevos Empleos.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Nuevos Empleos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal – Registro mercantil',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					textExtra: "Persona Natural con Profesiones Liberales, adjuntar como documento principal el Registro Único Tributario - RUT dado que no están obligado a matricularse como comerciante en el registro mercantil que lleva la cámara de comercio.",
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a> <p>La vigencia del RUT debe ser menos a 6 meses.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Resolución autoretenedor',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>Si es autoretenedor de impuesto sobre la renta por otros ingresos debe adjuntar la resolución respectiva.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: [],
					dependentQuestion: true,
					keyDependQuestion: "CUM8-PAEF"
				}					
			],
			// ------------- Cooperativa
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante Programa Nuevos Empleos.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Nuevos Empleos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificado de existencia y representación legal',
					info: { "description": "Para entidades oficiales: Decreto de nombramiento y acta de posesión de representante legal. <br>Iglesias Cristianas: Certificado del Ministerio del Interior. <br>Entidades educativas: Certificado del ministerio de educación. <br>Centros Comerciales: Certificado de existencia alcaldía local. <br><a class='data-resalt'>La cámara y comercio debe tener una vigencia no mayor a 90 días.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": " <a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a> <p>La vigencia del RUT debe ser menos a 6 meses.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Resolución autoretenedor',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>Si es autoretenedor de impuesto sobre la renta por otros ingresos debe adjuntar la resolución respectiva.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: [],
					dependentQuestion: true,
					keyDependQuestion: "CUM8-PAEF"
				}				
			],
			// ------------- Consorcio o union temporal
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante Programa Nuevos Empleos.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Nuevos Empleos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Acuerdo consorcial (documento de constitución)',
					info: { "description": "El acuerdo consorcial aplica si el Representante Legal no se encuentra registrado en el RUT." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>La vigencia del RUT debe ser menor a 6 meses.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Resolución autoretenedor',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>Si es autoretenedor de impuesto sobre la renta por otros ingresos debe adjuntar la resolución respectiva.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: [],
					dependentQuestion: true,
					keyDependQuestion: "CUM8-PAEF"
				}				
			],
			// ------------- Patrimonio Autónomo Declarante
			[
				{
					jobInfo: '',
					idDocument: 1,
					documentName: "Formulario postulante programa Nuevos Empleos.",
					info: { "description": "Firmado por el representante legal, promotor (empresas en restructuración), liquidador (empresas en liquidación) o persona natural empleadora. <br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Certificación cumplimiento de requisitos: Programa Nuevos Empleos.',
					info: { "description": "Certificación firmada por la persona natural empleadora y el revisor fiscal* o contador público en los casos en los que la empresa no esté obligada a tener revisor fiscal. <span class='reldate'>Este documento se encuentra disponible para descarga en ‘Descargar documentos’.</span><br><a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas. </a>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Declaración de renta y complementarios',
					info: { "description": "Declaración de impuestos sobre la renta y complementarios del ultimo periodo gravable disponible." },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Registro Único Tributario - RUT',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>La vigencia del RUT debe ser menor a 6 meses.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: []
				},
				{
					jobInfo: '',
					idDocument: 1,
					documentName: 'Resolución autoretenedor',
					info: { "description": "<a class='data-resalt'>Recuerde que su solicitud será rechazada si este documento se carga sin las firmas respectivas.</a><p>Si es autoretenedor de impuesto sobre la renta por otros ingresos debe adjuntar la resolución respectiva.</p>" },
					maxCapacity: 20,
					docsToLoad: [],
					evaluate: false,
					type: false,
					causes: [],
					dependentQuestion: true,
					keyDependQuestion: "CUM8-PAEF"
				}				
			]
		]

	]

	constructor(
		private httpClient: HttpClient,
		public modalService: ModalService,
		private utilsService: UtilsService,
		private storeService: StoreService,
		//public loginStepService: LoginStepService,
		public authServerProviderService: AuthServerProviderService,
		private cryptoService : CryptoService,
		private http: HttpClient,
	) { }

	evaluateType() {
		let selectedOption = this.storeService.get("selectedOption")
		console.log("TIPO DE COMPAÑIA", selectedOption);
		// 0 persona juridica
		// 1 persona natural
		// 2 sin animo de lucro
		// 3 consorcio
		// 4 Patrimonio Autónomo Declarante
		console.log("-----DOCUMENTOS ARRAY----------");
		console.log(this.storeService.get('flujo'));
		console.log(this.box);
		this.documentUpload = []
		this.documentUpload = this.box[this.storeService.get('flujo')][selectedOption]
		for (let index = 0; index < this.documentUpload.length; index++) {
			this.documentUpload[index]['causes'] = [];
			this.documentUpload[index]['type'] = false;
			this.documentUpload[index]['evaluate'] = false;
		}
		console.log("CAJAS CREADAS", this.documentUpload);
	}

	calculateData(id) {
		let r = false
		let element = this.documentUpload[id]
		for (const data of element.docsToLoad) {
			if (data.state == 'CACHE') {
				r = true
			}
		}
		return r
	}

	calculateStatus(id) {
		let r = false
		let element = this.documentUpload[id]
		for (const data of element.docsToLoad) {
			if (data.state == 'DENY') {
				r = true
			}
		}
		return r
	}

	// traer archivos del repositorio
	getDocumentsStored(callback: () => void) {
		/*this.userDataService.getDocumentsById().subscribe(resp => {
		  
	
		  
		});*/

		console.log("DATOS DE DOCUMENTOS");
		let urlFiles = this.storeService.get("urlFiles");
		let documentListStored: any[] = urlFiles;
		let n = 0
		for (const docType of documentListStored) {
			console.log(docType);
			if (docType['ruta'] != undefined && docType['ruta'] != null) {
				this.checks.push(true)
				console.log(this.checks);
				this.docsToUpload++;
				let name = docType['ruta']
				let eachDocumentMapped = docType['ruta'];
				let ext = docType['ruta'].split(".").pop();
				let shortname = '';
				//validar largo de nombre
				let list = {
					key: "",
					shortname: shortname,
					name: '',
					path: docType['ruta'],
					type: ext.toUpperCase() === "PDF" ? "pdf" : "image",
					state: "SAVE"
				};
				this.documentUpload[n].docsToLoad.push(list);
			}
			n++
		}
		console.log(documentListStored);
		setTimeout(() => {
			this.sendDocuments = this.checkFullDocs();
			console.log("data send", this.sendDocuments);

		}, 1000);
		return callback();
	}

	saveFile() {
		if (this.docsToUpload > 0) {
			this.modalService.loadingModal("docs", () => {
			});
			this.uploadToS3();
		}
	}

	sendFile() {
		if (this.sendDocuments) {
			this.modalService.loadingModal("docs", () => {
			});
			this.uploadToS3();
		}
	}

	//subir archivos en cache a s3
	public uploadToS3() {
		this.modalService.loadingModal("docs", () => {
		});
		let filesUploaded = 0;
		let numID = 1;
		let docTypeSS = this.storeService.get("docTypeSS")
		let docNumberSS = this.storeService.get("docNumberSS")
		let numRadicado = this.storeService.get("numRadicado")
		for (const element of this.documentUpload) {          //recorre las cajas
			let folderToSave: string =
				docTypeSS + "_" + docNumberSS + "/" +
				numRadicado + "/" +
				docTypeSS + "_" + docNumberSS + "_RADICADO_" +
				numRadicado + "_DOC" + numID;
			numID++;
			if (element.docsToLoad.length > 0) {
				//folderToSave = folderToSave + element.info.idDocMantiz + "/";
				let indexDocToSent = 0;
				for (const docToSent of element.docsToLoad) {     //recorre los documentos (solo 1)
					if (
						docToSent.state !== undefined &&
						docToSent.state !== null &&
						docToSent.state === "CACHE"
					) {
						//let nameSplit: string[] = docToSent.name.split(".");
						//let nameFinal: string = docToSent.name.replace(nameSplit[nameSplit.length - 1], '');
						//folderToSave = folderToSave + docToSent.name + "..." + docToSent.key;
						//const httpHeaders = new HttpHeaders().set("Content-Type", docToSent.file.type);
						//const params = new HttpParams().set('key', folderToSave);
						let ext = docToSent.file.name.split(".").pop();
						folderToSave = folderToSave + "." + ext;
						this.sendFileByOne(folderToSave, docToSent.file).subscribe(resp => {
							this.docsUploaded++;
							filesUploaded++;
							console.log("ha subido un documento: " + "total a subir: " + this.docsToUpload + " Subidos: " + this.docsUploaded)
							docToSent.state = "SAVE";
							if (this.docsToUpload == this.docsUploaded) {
								//consumir endpoint de notificar estado
								let docTypeSS = this.storeService.get("docTypeSS")
								let docNumberSS = this.storeService.get("docNumberSS")
								let numRadicado = this.storeService.get("numRadicado")
								let token = this.storeService.get("isLoggedIn");

								let requestDTO = new finishRequest();
								requestDTO.typedoc = docTypeSS
								requestDTO.doc = docNumberSS
								requestDTO.token = token
								requestDTO.numradicado = numRadicado

								this.finishRequest(requestDTO).subscribe(res => {
									console.log(res)
									this.modalService.closeModalDefinitive();
									this.modalService.closeModal("docs");
									this.deleteAllDocuments();
									// controlar respuesta
									if (res == 'null') {
										this.storeService.set("estado", "error");
										this.modalService.errorModal(resp => {
											this.return()
										});
									} else {
										let respuesta = (res.body)
										console.log("ENVIADO", respuesta);
										if (respuesta['statusCode'] == 200) {
											this.sendMail();
											this.storeService.set("estado", "finalizado");
											let msgFlujo = this.getMsgByFlujo()
											this.modalService.requestReceived(msgFlujo, res => {
												this.return()
											});
										} else if (respuesta['statusCode'] == 401) {
											this.storeService.set("estado", "error");
											this.modalService.closeSession((resp) => {
												this.return()
											});
										}
										else {
											this.storeService.set("estado", "error");
											this.modalService.errorModal(resp => {
												this.return()
											});
										}
									}
								});
							}
						});
					} else if (
						docToSent.state !== undefined &&
						docToSent.state !== null &&
						docToSent.state === "DEL"
					) {
						/* this.deleteFileByOne(docToSent.path).subscribe(resp => {
						   element.docsToLoad.splice(indexDocToSent, 1);
						   filesUploaded++;
						   //if (this.docsToUpload <= filesUploaded - 1) {
						   setTimeout(() => {
							 this.modalService.closeModal("docs");
						   }, 500);
						   //}
						 });*/
					} else if (
						docToSent.state !== undefined &&
						docToSent.state !== null &&
						docToSent.state === "SAVE"
					) {
						filesUploaded++;
						/*             setTimeout(() => {
													this.modalService.closeModal("docs");
												}, 500); */
					}
					console.log(docToSent);
					indexDocToSent++;
				}

			}
		}

	}

	sendMail() {
		let req = {
			tipoynumdoc: `${this.storeService.get("docTypeSS")},${this.storeService.get("docNumberSS")}`,
			caseNumber: this.storeService.get("numRadicado")
		}
		this.sendMailRequest(req).subscribe((resp) => {
			console.log("RESPUESTAAAAAAAAA")
			console.log(resp)
			/*this.modalService.requestReceived(res => {
				this.return()
			});	*/
		}, (err) => {
			console.log(err);
		})
	}

	//añadir datos a la lista
	addCard(campaignDocument) {
		this.documentUpload.push(campaignDocument);
		console.log("carga final", this.documentUpload);
	}

	//añadir archivo a la lista
	addDocumentToUpload(file: File, key, base64, id, shortname, name, type) {
		console.log("carga final al remplazar", this.documentUpload);
		let list = {
			key: "" + key,
			file: file,
			base64: base64,
			shortname: shortname,
			name: name,
			type: type,
			path: "",
			state: "CACHE"
		};
		let folderToSave: string =
			this.storeService.get("control").idMantiz + "/" +
			this.storeService.get("userDataLogin").userType + "/" +
			this.storeService.get("userDataLogin").userDocument + "/";
		//folderToSave = folderToSave + this.documentUpload[id].info.idDocMantiz + "/";
		folderToSave = folderToSave + "/"
		folderToSave = folderToSave + list.name + "..." + list.key;
		list.path = folderToSave;
		list.state = "CACHE";
		this.documentUpload[id].docsToLoad.push(list);
		this.docsToUpload++;
		//console.log(this.documentUpload[id].docsToLoad[this.documentUpload[id].docsToLoad.length - 1]);
		console.log("carga final al remplazar", this.documentUpload);
	}

	replaceDocument(file: File, key, base64, id, shortname, name, i, type) {
		console.log("index", i);
		let ext = name.split(".").pop();
		let newkEY = this.utilsService.generateUUID() + "." + ext;
		let list = {
			key: "" + newkEY,
			file: file,
			base64: base64,
			shortname: shortname,
			name: name,
			type: type,
			path: "",
			state: "CACHE"
		};
		let folderToSave: string =
			this.storeService.get("control").idMantiz +
			"/" +
			this.storeService.get("userDataLogin").userType +
			"/" +
			this.storeService.get("userDataLogin").userDocument +
			"/";
		//folderToSave = folderToSave + this.documentUpload[id].info.idDocMantiz + "/";
		folderToSave = folderToSave + "/";
		folderToSave = folderToSave + list.name + "..." + list.key;
		list.path = folderToSave;
		/*this.sendFileByOne(folderToSave, list.file).subscribe(resp => {*/
		this.deleteDocument(key, id, () => { });
		this.documentUpload[id].docsToLoad.splice(i, 1, list);
		console.log("index", i);
		console.log("index", this.documentUpload[id].docsToLoad);
		this.documentUpload[id].docsToLoad[i].state = "CACHE";
		this.selectBase64(newkEY, id);
		console.log("carga final al remplazar", this.documentUpload);
		/*});*/

	}

	deleteDocument(key, id, callback?: () => void) {
		console.log(this.documentUpload);
		for (
			let index = 0;
			index < this.documentUpload[id].docsToLoad.length;
			index++
		) {
			let dato = this.documentUpload[id].docsToLoad[index].key;
			if (dato === key) {
				if (this.documentUpload[id].docsToLoad[index].state == "CACHE") {
					this.documentUpload[id].docsToLoad[index].state = "DEL";
					this.docsToUpload++;
				} else {
					var e = this.documentUpload[id].docsToLoad.indexOf(
						this.documentUpload[id].docsToLoad[index]
					);
					if (e !== -1) {
						this.documentUpload[id].docsToLoad.splice(e, 1);
					}
				}
			}
		}
		console.log("carga final", this.documentUpload);
		return callback();
	}

	selectBase64(key, id) {
		this.key = key;
		this.id = id;
		for (
			let index = 0;
			index < this.documentUpload[id].docsToLoad.length;
			index++
		) {
			let dato = this.documentUpload[id].docsToLoad[index].key;
			if (dato === key) {
				console.log(this.documentUpload[id].docsToLoad[index]);
				if (this.documentUpload[id].docsToLoad[index].path != undefined) {
					this.base64 = this.urlDocumentS3 + "/s3?key=" + this.documentUpload[id].docsToLoad[index].path;
					this.local = true
					this.type = this.documentUpload[id].docsToLoad[index].type;
					console.log("URL", this.base64);
				} else {
					this.local = false
					this.base64 = this.documentUpload[id].docsToLoad[index].base64;
					this.type = this.documentUpload[id].docsToLoad[index].type;
				}
				break
			}
		}
	}

	checkFullDocs() {
		let checkAllTypes: boolean = false;
		let n = 0
		let c = this.checks.length
		for (let i = 0; i < c; i++) {
			if (this.checks[i] == true) {
				if (!this.calculateStatus(i) && !this.calculateData(i)) {
					n++
				}
			}
		}
		if (n == c) {
			checkAllTypes = true;
		} else {
			checkAllTypes = false;
		}
		console.log(checkAllTypes);
		return checkAllTypes;
	}

	  /* <------------- SERVICE SANDBOX -------------> */

	  // finalizar solicitud

	finishRequest(requestDTO) {
		const headers = { 'Content-Type': 'application/json' };
		const requestparam = (requestDTO)
		const { encryptedBody, options } =
			this.cryptoService.setEncryptionRequest(requestparam, '',
				this.cryptoService.assingHeaders(new HttpHeaders(), headers),
				headers
			);
		return this.http.post(environment.apiSbx.concat(`core/finish/request`), encryptedBody,
			{
				headers: options,
				params: undefined,
				responseType: 'json',
				observe: 'response',
			}
		).pipe(
			tap((response: any) => {
				this.cryptoService.decryptResponse(response);
			}),
		);
	}

	sendMailRequest(req: any) {
		const headers = { 'Content-Type': 'application/json' };
		const requestparam = (req)
		const { encryptedBody, options } =
			this.cryptoService.setEncryptionRequest(requestparam, '',
				this.cryptoService.assingHeaders(new HttpHeaders(), headers),
				headers
			);
		return this.http.post(environment.apiSbx.concat(`core/send/request`), encryptedBody,
			{
				headers: options,
				params: undefined,
				responseType: 'json',
				observe: 'response',
			}
		).pipe(
			tap((response: any) => {
				this.cryptoService.decryptResponse(response);
			}),
		);
	}

	public sendFileByOne1(path: string, file: File, httpHeaders?: any) {
		const headers = {
			'Content-Type': 'application/json',
		};
		const headers2 = {
			'Content-Type': 'application/json',
		};
		const queryParams = {
			file: file,
			httpHeaders: httpHeaders
		  };
		const { encryptedBody, options } =
			this.cryptoService.setEncryptionRequest(queryParams,'',
				this.cryptoService.assingHeaders(new HttpHeaders(), headers2),
				headers
			);
		return this.http.put(environment.apiSbx.concat(`documents/s3?key=${path}`), encryptedBody,
		{
			headers: options,
			params: undefined,
			responseType: 'json',
			observe: 'response',
		  }
		).pipe(
		  tap((response: any) => {
			this.cryptoService.decryptResponse(response);
		  }),
		);
		// return this.httpClient.put(this.urlDocumentS3 + "/s3?key=" + path, file);
	}

	getUploadedDocuments(body: any) {
		const headers = { 'Content-Type': 'application/json' };
		const requestparam = (body)
		const { encryptedBody, options } =
			this.cryptoService.setEncryptionRequest(requestparam, '',
				this.cryptoService.assingHeaders(new HttpHeaders(), headers),
				headers
			);
		return this.http.post(environment.apiSbx.concat(`core/linksdocuments`), encryptedBody,
			{
				headers: options,
				params: undefined,
				responseType: 'json',
				observe: 'response',
			}
		).pipe(
			tap((response: any) => {
				this.cryptoService.decryptResponse(response);
			}),
		);
	}

	/* <------------- END SERVICE SANDBOX -------------> */

	// finalizar solicitud
	finishRequest1(requestDTO) {
		return this.httpClient.post(this.urlData + 'core/finish/request', requestDTO, { responseType: 'text' });
	}

	sendMailRequest1(req: any) {
		return this.httpClient.post(this.urlData + 'core/send/request', req);
	}

	//enviar archivo a s3
	public sendFileByOne(path: string, file: any, httpHeaders?: any) {
		return this.httpClient.put(this.urlDocumentS3 + "/s3?key=" + path, file);
	}

	//traer archivo en blod
	public getFileByOne(path: string) {
		return this.httpClient.get(this.urlDocumentS3 + "/s3?key=" + path);
	}

	//eliminar archivo
	public deleteFileByOne(path: string) {
		return this.httpClient.delete(this.urlDocumentS3 + "/s3?key=" + path);
	}

	getUploadedDocuments1(body: any) {
		return this.httpClient.post(this.urlData + 'core/linksdocuments', body);
	}

	confirmDocuments() {
		console.log('confirmDocuments');

		if (this.storeService.get("selectedOption") == 3 && this.docsToUpload == ((this.checks.length) - 1)) {
			this.checks.pop();
		}

		if (this.storeService.get('flujo') == 4 && this.storeService.get("selectedOption") == 0 
			&& this.docsToUpload == ((this.checks.length) - 1)) {
			this.checks.pop();
		}

		if (this.docsToUpload == this.checks.length) {
			if(this.storeService.get('flujo') == 2){
				this.modalService.confirmRequestDocumentsAgri(resp => {
					if (resp == "continue") {
						if (this.storeService.get("estado") == "documentos") {
							this.storeService.set("estado", "upload")
							this.uploadToS3()
						} else {
							// modal de error de usuario
							if (this.storeService.get("estado") == "upload" || this.storeService.get("estado") == "finalizado") {
								this.modalService.errorModalUpload(resp => {
									this.return();
								});
							}
							console.log("ya estas subiendo documentos");
						}
					} else {
						this.modalService.closeModalDefinitive();
					}
				})
			}else{

				this.modalService.confirmRequestDocuments(resp => {
					if (resp == "continue") {
						if (this.storeService.get("estado") == "documentos") {
							this.storeService.set("estado", "upload")
							this.uploadToS3()
						} else {
							// modal de error de usuario
							if (this.storeService.get("estado") == "upload" || this.storeService.get("estado") == "finalizado") {
								this.modalService.errorModalUpload(resp => {
									this.return();
								});
							}
							console.log("ya estas subiendo documentos");
						}
					} else {
						this.modalService.closeModalDefinitive();
					}
				})
			}

		}

	}

	continueAfter() {
		let typeModal = 1; // 1 es cliente externo, 2 operativa
		this.modalService.closeSessionNavbar(typeModal, resp => {
			if (resp == "continue") {
				this.authServerProviderService.logoutBasic()
			} else {
				this.modalService.closeModalDefinitive();
			}
		})
	}
	
	return() {
		this.authServerProviderService.logoutBasic()
	}

	validbot() {
		//console.log("boton: " + this.docsToUpload + " " + this.checks.length, this.docsUploaded);
		if (this.storeService.get("selectedOption") == 3 && this.docsToUpload == ((this.checks.length) - 1)) {
			if (this.documentUpload[this.documentUpload.length - 1].docsToLoad.length == 0) {
				return true;
			} else {
				return false;
			}
		}
		if (this.storeService.get('flujo') == 4 && this.storeService.get("selectedOption") == 0 
			&& this.docsToUpload == ((this.checks.length) - 1)) {
			if (this.documentUpload[this.documentUpload.length - 1].docsToLoad.length == 0) {
				return true;
			} else {
				return false;
			}
		}
		if (this.docsToUpload >= this.checks.length) {
			return true
		}
		else {
			return false
		}
	}

	deleteAllDocuments() {
		this.checks = [];
		this.docsToUpload = 0;
		this.docsUploaded = 0;
		for (let i = 0; i < this.documentUpload.length; i++) {
			this.documentUpload[i].docsToLoad = []
		}
		console.log("carga final", this.documentUpload);
	}

	getMsgByFlujo(){
		let msgFlujo = "\"PAEF\"";
		let flujo = this.storeService.get('flujo');
		if(this.storeService.get('flujo') == 0){
			msgFlujo = "\"PAEF MICRO\"";
		}
		if(this.storeService.get('flujo') == 6){
			msgFlujo = "\"NUEVOS EMPLEOS\"";
		}
		return msgFlujo;
	}
}
