import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalGlobalComponent } from '@app/shared/modal-global/modal-global.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { NoReturnService } from '@app/shared/services/no-return.service';
import { ModalService } from '@app/shared/services/modal.service';
import { OutputObject } from '../models/output-object';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  modal1: OutputObject;
  public isLoggedIn;
  public isFirst: boolean = true;
  @SessionStorage('channel')
  public channel

  constructor(private injector: Injector, private route: Router, public dialog: MatDialog, 
    private sessionStorage: SessionStorageService,
    private nr: NoReturnService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {

      console.log("flag value ", this.isFirst, " ACTIVADO HTTP INTERCEPTOR ", err);
      const router = this.injector.get(Router)

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        // if (this.isFirst) {
        //   this.isFirst = false;
        //   /*           this.modal1 = new OutputObject('modal', '', { tipoModal: 'unBoton', icon: 'assets/img/equis.svg', title: 'La sesión ha expirado', body: 'Inicie sesión nuevamente.' });
        //             this.openDialog(this.modal1); */
        //   this.clear()
        //   this.sessionStorage.clear()
        //   location.reload()
        // }
      }

      if ((err.status === 0 || err.status === 400 || err.status >= 402)) {
        // this.clear()
        // this.sessionStorage.clear()
        
        /*        if (this.isFirst) {
                  this.isFirst = false;
                    this.modal1 = new OutputObject('modal', '', { tipoModal: 'type_one', icon: 'assets/img/equis.svg', title: 'Servicio no disponible', body: "Estamos presentando fallas técnicas, <br> por favor intente más tarde." , tbn_one:"Salir"});
                    this.openDialog(this.modal1);
                   
                } */
      }
      //const error = err.error.message || err.statusText;
      return throwError(err);
    }))
  }

  openDialog(modal: any) {

    const dialogRef = this.dialog.open(ModalGlobalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      disableClose: true,
      data: modal
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.isLoggedIn) {
        this.clear()
      } else {

      }
      this.isFirst = true;

    });
  }
  clear() {
    this.sessionStorage.clear();
    if(this.channel == "natural"){
      this.route.navigate(['natural'])
    }else{
      this.channel = 'internal'
    }
  }
}