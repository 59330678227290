import { Injectable } from '@angular/core';
import { AuthServerProviderService } from './auth-server-provider.service';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorage } from 'ngx-webstorage';
import { UserLogin } from '../models/user-login.model';
import { CryptoService } from './crypto/crypto.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public radicado : any
  public dataRadicado : any
  public resultRadicado : any
  public modalState = ''
  public activeError = false
  public errorMessage = ''
/*   @SessionStorage('product')
  public product */
  public urlData = environment.endpointManager;
  public urlReqByCustomer = /*environment.endpointManager +*/ "core/get/requestbycustomer";
  public sendData = ""
  public back: boolean = false
  public extencion: boolean = false

  constructor(
    private http: HttpClient,
    private cryptoService : CryptoService
  ) { }

  getUserObject (typeD:string, numD:string, digit:string, radicado:string, request_type:string,date?:string):UserLogin{
    let loginObject:UserLogin={
      typedoc:typeD,
      doc:numD,
      verifcode:digit,
      phase_month:date,
    }
    if(radicado != null){
      loginObject.numradicado = radicado
    }
    if(request_type != null){
      loginObject.request_type = request_type
    }   
    console.log(loginObject)
    return loginObject;  
 }
  /* <------------- SERVICE SANDBOX -------------> */

  getRazonSocial(typeDoc, numDoc, digit, token,request_type,date){
    const headers = {'Authorization': token,'Content-Type': 'application/json'};
    const requestparam = {'typedoc': typeDoc,'doc': numDoc, 'verifcode': digit , 'request_type': request_type, 'phase_month': date}
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers),
        headers
      );
    return this.http.post(environment.apiSbx.concat(`${this.urlReqByCustomer}`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getLoginDiligenciar(typeDoc, numDoc, digit, token,request_type){
    const headers = {'Authorization': token,'Content-Type': 'application/json'};
    const requestparam = {'typedoc': typeDoc,'doc': numDoc, 'verifcode': digit , 'request_type': request_type}
    const { encryptedBody, options } =
    this.cryptoService.setEncryptionRequest(requestparam,'',
      this.cryptoService.assingHeaders(new HttpHeaders(), headers),
      headers
    );
    return this.http.post(environment.apiSbx.concat(`login/auth`),encryptedBody,
    {
      headers: options,
      params: undefined,
      responseType: 'json',
      observe: 'response',
    }
  ).pipe(
    tap((response: any) => {
      this.cryptoService.decryptResponse(response);
    }),
  );
  }
  
  getLoginCargar(typeDoc, numDoc, digit, radicado, token,request_type){
    const headers = {'Authorization': token,'Content-Type': 'application/json'};
    const requestparam = {'typedoc': typeDoc,'doc': numDoc, 'verifcode': digit , 'numradicado': radicado, 'request_type': request_type}
    const { encryptedBody, options } =
    this.cryptoService.setEncryptionRequest(requestparam,'',
      this.cryptoService.assingHeaders(new HttpHeaders(), headers),
      headers
    );
    return this.http.post(environment.apiSbx.concat(`login/auth`),encryptedBody,
    {
      headers: options,
      params: undefined,
      responseType: 'json',
      observe: 'response',
    }
  ).pipe(
    tap((response: any) => {
      this.cryptoService.decryptResponse(response);
    }),
  );
  }

  getCiiuCodes(){
    const requestparam = {
      "table": "PAEF.PARAMS",
      "id": "CIIUCODES"
    }
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`catalog/datatable`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getValidatedBill(data){
    const requestparam = (data)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`core/forms/datavalidator`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'text',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getCiudades(){
    const headers = {
      'Content-Type': 'application/json',
    };
    const headers2 = {
      'Content-Type': 'application/json',
    };
    const queryParams = {
    };
    const { encryptedBody,options } =
    this.cryptoService.setEncryptionRequest(queryParams,'',
      this.cryptoService.assingHeaders(new HttpHeaders(), headers2),
      headers
    );
    return this.http.get(environment.apiSbx.concat(`catalog/dane?cypher=${encryptedBody}`),
    {
      headers: options,
      params: undefined,
      responseType: 'json',
      observe: 'response',
    }
  ).pipe(
    tap((response: any) => {
      this.cryptoService.decryptResponse(response);
    }),
  );
  }

  getCloseSesssion(requestDTO): Observable<any> {
    const headers = {
      'content-type': 'application/json',
    };
    const requestparam = (requestDTO)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`login/logout`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        tap((response: any) => {
          this.cryptoService.decryptResponse(response);
        }),
      )
  }

  saveRequest(requestDTO){
    const requestparam = (requestDTO)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`core/save/request`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'text',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getLoginOperative(data){
    const requestparam = (data)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`loginoperative/internal/login`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response' as 'body',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getDataRadicated(data){
    const requestparam = (data)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), ''),
        ''
      );
    return this.http.post(environment.apiSbx.concat(`core/forms/getbyradic`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }
    /* <------------- END SERVICE SANDBOX -------------> */

  getLoginDiligenciar1(typeDoc, numDoc, digit, token,request_type){
    return this.http.post(this.urlData + 'login/auth', this.getUserObject(typeDoc, numDoc, digit, null,request_type),{headers: {'Authorization':token}, responseType: 'text'})
  }
  
  getLoginCargar1(typeDoc, numDoc, digit, radicado, token,request_type){
    return this.http.post(this.urlData + 'login/auth', this.getUserObject(typeDoc, numDoc, digit, radicado,request_type),{headers: {'Authorization':token}, responseType: 'text'})
  }
  
  getRazonSocial1(typeDoc, numDoc, digit, token,request_type,date){
    return this.http.post(this.urlReqByCustomer, this.getUserObject(typeDoc, numDoc, digit, null,request_type,date),{headers: {'Authorization':token}, responseType: 'text'});
  }

  saveRequest1(requestDTO){
    return this.http.post(this.urlData + 'core/save/request', requestDTO, {responseType: 'text'})
  }

  getCiudades1(){
    return this.http.get(this.urlData + 'catalog/dane', {})
  }

  getCiiuCodes1(){
    let requestDTO = {
      "table": "PAEF.PARAMS",
      "id": "CIIUCODES"
    }
    return this.http.post(this.urlData + 'catalog/datatable', requestDTO, {responseType: 'text'})
  }

  getLoginOperative1(data){
    return this.http.post(this.urlData + 'loginoperative/internal/login', data)
  }

  getDataRadicated1(data){
    return this.http.post(this.urlData + 'core/forms/getbyradic', data)
  }

  getValidatedBill1(data){
    return this.http.post(this.urlData + 'core/forms/datavalidator', data)
  }
  
}
