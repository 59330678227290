import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '@app/shared/services/modal.service';
import { StoreService } from '@core/service/store.service';
import { UploadDocService } from '@core/service/upload-doc.service';
import { MotivosRechazoService } from '@core/service/motivos-rechazo.service';
import { environment } from '@env/environment';
import { ChangeStatusRequest } from '@app/core/models/operativa.model';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { SessionStorage } from 'ngx-webstorage';


@Component({
	selector: 'aliv-asignacion',
	templateUrl: './asignacion.component.html',
	styleUrls: ['./asignacion.component.scss']
})

export class AsignacionComponent implements OnInit {
	@SessionStorage('username')
	public usernameSS;
	@SessionStorage('form')
	public formSesion;
	public Url = environment.endpointManager + 'documents/s3?key='
	public tipe: any = 0
	public documentValidated = [];
	public validationInfo: any;
	public pendingCasesCounter: number;
	public tipoSolicitud: '';
	public declaraRenta: '';
	public codigoDeclaraRenta: '';
	public autoRetenedor: '';
	public flow: any = [];
	public months = [];
	public flujo: number;
	public devolutions = 0;
	public infoEmpresa = {
		tipoId: '',
		noId: '',
		noRadicado: '',
		email: '',
		fng: '',
		cuenta1: '',
		cuenta2: '',
		mes: ''
	}
	
	public tipoEmpresaArray = [
		{id: -1, code: -1,  name: "-"},
		{id: 0,  code: 1,   name: "Persona jurídica"},
		{id: 1,  code: 2,   name: "Persona Natural"},
		{id: 2,  code: 3,   name: "Cooperativa@Entidad sin ánimo de lucro"},
		{id: 3,  code: 4,   name: "Consorcio o unión temporal"},
		{id: 4,  code: 5,   name: "Patrimonio autónomo"},
		{id: 5,  code: 6,   name: "Cooperativas"}
	];

	public tipoEmpresaArraySA = [
		{id: -1, code: -1,  name: "-"},
		{id: 1,  code: 0,   name: "Persona jurídica"},
		{id: 4,  code: 1,   name: "Persona Natural"},
		{id: 2,  code: 2,   name: "Cooperativa@Entidad sin ánimo de lucro"},
		{id: 3,  code: 3,   name: "Consorcio o unión temporal"},
		{id: 5,  code: 4,   name: "Patrimonio autónomo"},
		{id: 6,  code: 5,   name: "Cooperativas"}
	];

	constructor(
		private dialog: MatDialog,
		private modalService: ModalService,
		public storeService: StoreService,
		private rechazoService: MotivosRechazoService,
		private authServerProviderService: AuthServerProviderService,
		public uploadDocService: UploadDocService,
	) {
		this.flow = this.storeService.get("flow");
		this.months = this.flow.months;
	}

	ngOnInit() {
		this.flujo = this.storeService.get("flujo");
		console.log("DATA", this.storeService.get("flujo"));
		if (this.storeService.get("estado") == "asignado") {
			this.modalService.successAssigment(resp => {
				this.storeService.set("estado", false)
				if (resp == "continue") {
					//TENER EN CUENTA volver a asignar caso
					this.modalService.closeModalDefinitive();
					this.modalService.loadingModal('consult', resp => {});
					let period = this.storeService.get("periodDate");
					this.rechazoService.consulCase({
						username: this.usernameSS,
						request_type: this.storeService.comberStep(this.storeService.get("flujo")),
						period: period
					}).subscribe((respues) => {
						this.modalService.closeModal('consult');
						if (respues.body.statusCode == 400 || respues.errorType != undefined) {
							this.authServerProviderService.logoutOperative();
						} else {
							console.log(respues);
							setTimeout(() => {
								window.scrollBy(0, -document.body.scrollHeight * 5);
							}, 500);
							let info = JSON.parse(respues['body']['body']['info'])
							let questions = respues['body']['body']['questions'] != undefined ? JSON.parse(respues['body']['body']['questions']) : false;
							console.log("PREGUNTAS", info);
							this.storeService.set("showPap", false);
							for (const iterator of info) {
								if (iterator.k == 'PreguntaPAP') {
									if (iterator.v == 'SI') {
										this.storeService.set("showPap", true);
									}
								}
							}
							this.storeService.set("data", respues['body']['companyInfo'])
							this.storeService.set("questions", questions);
							this.asyncLoad();
						}
					}, (err) => {
						this.modalService.closeModal('consult');
					})
				} else {
					this.modalService.closeModalDefinitive();
					this.authServerProviderService.logoutOperative();
				}
			})
		}
		this.validationInfo = undefined
		this.uploadDocService.info.causes = []
		this.asyncLoad();
		let flujo = this.storeService.get("flujoBack")
		this.rechazoService.consulList(flujo).subscribe((resp: any) => {//TENER EN CUENTA lista casos de rechazo
			//this.rechazoService.consulList(flujo).subscribe((resp: any)=>{//TENER EN CUENTA lista casos de rechazo
			console.log("-----------CAUSALES--------", resp.body);

			let papa = []
			let n = 0
			this.rechazoService.categoriasRechazo = [];
			for (const iterator of resp['body']) {
				if (iterator.parent == 0 && iterator.status == 1) {
					papa.push(iterator.name)
					this.rechazoService.categoriasRechazo.push({ nombre: iterator.name, idPapa: iterator.id, texto: [], type: iterator.type })
					for (const iterator2 of resp['body']) {
						if (iterator2.parent == iterator.id && iterator2.status == 1) {
							this.rechazoService.categoriasRechazo[n].texto.push({ texto: iterator2.name, id: iterator2.id, parent: iterator2.parent });
						}
					}
					n++
				}
				/* } */
			}
			console.log(papa);
		})
	}
	
	numberWithCommas(x) {
		try {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		} catch (error) {
			return '-';
		}
	}

	async asyncLoad() {	//TENER EN CUENTA cargar datos	
		this.setInfoEmpresa()
		this.uploadDocService.evaluateType();
		//consultar info
		this.loadDocuments();
		this.loadCounterValues();
		if(this.storeService.get("flujo") == 6) this.loadQuestions();
	}

	loadCounterValues() {
		this.rechazoService.getPendingCases().subscribe((resp) => {
			let respuesta = resp.body
			if (respuesta.statusCode == 200 && respuesta) {
				this.pendingCasesCounter = respuesta.body[0].pending_request_count;
			}
		}, (err) => {
			console.log(err)
		});
	}

	setInfoEmpresa() {
		this.validationInfo = undefined
		this.uploadDocService.info.causes = []
		//TENER EN CUENTA cargar datos
		this.storeService.set("selectedOption", 1);
		if (this.storeService.get("data") != undefined) {
			let empresa = this.storeService.get("data")
			this.tipoSolicitud = this.storeService.get("tipoSolicitud")
			let x = this.formSesion
			let mes = JSON.parse(this.formSesion)[1]['v'];
			let monthFilter = this.months.map(data => data.id == mes ? data : {})[0];
			console.log("-----------------",);
			console.log(empresa.companyType);
			this.infoEmpresa = {
				tipoId: empresa.idType,
				noId: empresa.numberID,
				noRadicado: empresa.radicatedNumber,
				email: empresa.email,
				fng: empresa.fng,
				cuenta1: empresa.accountNumber1,
				cuenta2: empresa.accountNumber2,
				mes: this.getTipoMes(mes)
			}
			console.log("TIPO DE EMPRESA EN BASE DE DATOS", empresa.companyType);

			let t = this.getTipoEmpresa(empresa.companyType)
			
			console.log("TIPO DE EMPRESA EN BASE DE DATOS", t);
			this.tipe = t
			this.storeService.set("selectedOption", this.tipe);
			this.devolutions = empresa.rejects
		}
	}

	//deprecated
	getTipoMes(id) {
		id = id + ""
		console.log("FECHA",id);
		
		switch (id) {
			case '':
				return '';
			case "9,2020":
				return 'septiembre';
			case "10,2020":
				return 'octubre';
			case "11,2020":
				return 'noviembre';
			case "12,2020":
				return 'diciembre';
			case "1,2021":
				return 'enero';
			case "2,2021":
				return 'febrero';
			case "3,2021":
				return 'marzo';
			case "4,2021":
				return 'abril';
			case "5,2021":
				return 'mayo';
			case "6,2021":
				return 'junio';
			case "7,2021":
				return 'julio';
			case "8,2021":
				return 'agosto';
			case "9,2021":
				return 'septiembre';
			case "10,2021":
				return 'octubre';
			case "11,2021":
				return 'noviembre';
			case "12,2021":
				return 'Diciembre';
			case "1,2022":
				return 'Enero';
			case "2,2022":
				return 'Febrero';
			case "3,2022":
				return 'Marzo';
			case "4,2022":
				return 'Abril';
			case "5,2022":
				return 'Mayo';
			case "6,2022":
				return 'Junio';
			case "7,2022":
				return 'Julio';
			case "8,2022":
				return 'Agosto';
			case "12,2022":
				return 'Diciembre';
			case "1,2023":
				return 'Enero';
			case "2,2023":
				return 'Febrero';
			case "3,2023":
				return 'Marzo';
			case "4,2023":
				return 'Abril';
			case "5,2023":
				return 'Mayo';
			case "6,2023":
				return 'Junio';
			case "7,2023":
				return 'Julio';
			case "8,2023":
				return 'Agosto';
			case "9,2023":
				return 'Septiembre';	
			case "10,2023":
				return 'Octubre';
			case "11,2023":
				return 'Noviembre';
			case "12,2023":
				return 'Diciembre';
			case "1,2024":
				return 'Enero';
			case "2,2024":
				return 'Febrero';
			case "3,2024":
				return 'Marzo';
			case "4,2024":
				return 'Abril';
			case "5,2024":
				return 'Mayo';
			case "6,2024":
				return 'Junio';
			case "7,2024":
				return 'Julio';
			case "8,2024":
				return 'Agosto';
			case "9,2024":
				return 'Septiembre';	
			case "10,2024":
				return 'Octubre';
			case "11,2024":
				return 'Noviembre';
			case "12,2024":
				return 'Diciembre';
			case undefined:
				return '';
			default:
				break;
		}
	}

	getTipoEmpresa(id) {
		return this.tipoEmpresaArraySA.find(data => data.id == id).code;
		//console.log()
		//deprecated
		/*
		id = id + ""
		switch (id) {
			case -1:
				return "-";
			case "1":
				return 0;//"Persona jurídica";
			case "4":
				return 1;//"Persona Natural";
			case "2":
				return 2;//"Entidad sin ánimo de lucro";
			case "3":
				return 3;//"Consorcio o unión temporal";
			case "5":
				return 4;//"Patrimonio autónomo";
			case "6":
				return 5;//"Cooperativa";
			default:
				break;
		}
		*/
	}

	getTipoEmpresaNombre(id) {
		let nombreEmpresa = this.tipoEmpresaArray.find(data => data.id == id).name;
		return (id == 2) ? (this.flujo == 6) ? nombreEmpresa.split("@")[0] : nombreEmpresa.split("@")[1] : nombreEmpresa;
		//deprecated
		/*
		switch (id) {
			case -1:
				return "-";
			case 0:
				return "Persona jurídica";
			case 1:
				return "Persona Natural";
			case 2:
				return this.flujo == 6 ? "Cooperativa" : "Entidad sin ánimo de lucro";
			case 3:
				return "Consorcio o unión temporal";
			case 4:
				return "Patrimonio autónomo";
			case 5:
				return "Cooperativas";
			default:
				break;
		}
		*/
	}

	completeAsignation() {
		let docsEvalueates = 0;
		let docsCuses = 0;
		this.uploadDocService.documentUpload.forEach(documento => {
			if (!!documento.evaluate) docsEvalueates++;
			if (documento.causes.length > 0) docsCuses++;
		})
		return {
			disable: docsEvalueates === this.uploadDocService.documentUpload.length && this.validationInfo !== undefined,
			validation: docsCuses === 0 && this.validationInfo
		}
	}

	loadDocuments() {
		this.documentValidated = this.uploadDocService.documentUpload;
		console.log(this.documentValidated);
		this.getUploadedDocs();
	}

	getUploadedDocs() {//TENER EN CUENTA traer url documentos
		let req = { //TENER EN CUENTA
			typeId: this.infoEmpresa.tipoId,
			identification: this.infoEmpresa.noId,
			requestId: this.infoEmpresa.noRadicado
		}

		this.uploadDocService.getUploadedDocuments(req).subscribe((resp: any) => {
			const respDecode = JSON.parse(Buffer.from(resp.body, 'base64').toString('utf-8'));
			if (respDecode != undefined && respDecode != null && respDecode.length > 0) {
				this.uploadDocService.documentUpload.forEach((element, index) => {
					//Se valida que no se tenga en cuenta el documento original

					if (!respDecode[index].filename.includes('ORIGINAL')) {
						element['filename'] = respDecode[index].filename;
					} else {
						this.uploadDocService.documentUpload.splice(index);
					}
				});
			}
		}, (err) => {
			console.log(err);
		});
	}

	validateInfo(val) {
		this.uploadDocService.info.causes = []
		console.log('esto es');
		console.log(this.validationInfo);
		this.validationInfo = !!val;
		console.log(this.validationInfo);
	}

	formatFileName(text: string) {
		let splitted = text.split('/');
		return splitted[splitted.length - 1];
	}

	aceptado() {
		let rejectCauses = [] //TENER EN CUENTA motivos rechazo documentos
		if (!this.validationInfo) {
			let infoCauses = [];
			for (const iterator of this.uploadDocService.info.causes) {
				infoCauses.push(iterator.id);
			}
			if (infoCauses.length > 0) {
				let document = {
					"document": `información`,
					"causes": infoCauses
				}
				rejectCauses.push(document);
			}
		}
		for (let index = 0; index < this.uploadDocService.documentUpload.length; index++) {
			let arrayCauses = [];
			const element = this.uploadDocService.documentUpload[index];
			for (let index = 0; index < element['causes'].length; index++) {
				const elementCause = element['causes'][index];
				arrayCauses.push(elementCause.id);
			}
			if (arrayCauses.length > 0) {
				let document = {
					"document": `DOC${+(index + 1)}`,
					"causes": arrayCauses
				}
				rejectCauses.push(document);
			}
		}
		console.log(rejectCauses);
		let req: ChangeStatusRequest;
		/* 	req = {
				typeDoc: "N",
				docNumber: "456987123",
				radicado: "01_456987123_00091",
				state: "Nuevo"
			} */
		let req_type = this.storeService.get("data")["request_type"]
		req = {//TENER EN CUENTA enviar los motivos de rechazo
			typeDoc: this.infoEmpresa.tipoId,
			docNumber: this.infoEmpresa.noId,
			radicado: this.infoEmpresa.noRadicado,
			state: this.areThereRejections() ? 'Devuelto' : 'Aceptado',
			username: this.usernameSS,
			devolutionCauses: rejectCauses,
			request_type: req_type,
			token: this.storeService.get("isLoggedInComercial")
		}
		console.log(req);

		this.modalService.loadingModal('answer', resp => {});

		this.rechazoService.answerRequest(req).subscribe((resp) => {

			/* this.modalService.closeModalDefinitive(); */
			this.modalService.closeModal('answer');
			this.storeService.set("estado", "asignado")
			this.modalService.successAssigment(resp => {
				this.storeService.set("estado", false)
				if (resp == "continue") {
					//TENER EN CUENTA volver a asignar caso
					this.modalService.closeModalDefinitive();

					this.modalService.loadingModal('consult', resp => {});
					let period = this.storeService.get("periodDate");
					this.rechazoService.consulCase({
						username: this.usernameSS,
						request_type: this.storeService.comberStep(this.storeService.get("flujo")),
						period: period
					}).subscribe((respues) => {
						this.modalService.closeModal('consult');
						if (respues.body.statusCode == 400 || respues.errorType != undefined) {
							this.modalService.modalNoCasesFound((resp) => {
								this.authServerProviderService.logoutOperative();
							});
						} else {
							console.log('RESPUESSSS ', respues);
							this.formSesion = respues['body']['body']['info'] //cargar mes
							setTimeout(() => {
								window.scrollBy(0, -document.body.scrollHeight * 5);
							}, 500);
							let info = JSON.parse(respues['body']['body']['info'])
							let questions = respues['body']['body']['questions'] != undefined ? JSON.parse(respues['body']['body']['questions']) : false;

							console.log(info);
							this.storeService.set("showPap", false);
							for (const iterator of info) {
								if (iterator.k == 'PreguntaPAP') {
									if (iterator.v == 'SI') {
										this.storeService.set("showPap", true);
									}
								}
							}
							this.storeService.set("data", respues['body']['companyInfo'])
							this.storeService.set("questions", questions);
							this.asyncLoad();
						}
					}, (err) => {
						this.modalService.closeModal('consult');
					})
				} else {
					this.modalService.closeModalDefinitive();
					this.authServerProviderService.logoutOperative();
				}
			})
		}, (err) => {
			/* this.modalService.closeModalDefinitive(); */
			this.modalService.closeModal('answer');
		});
	}

	areThereRejections() {
		let rejection = false;
		for (const iterator of this.uploadDocService.documentUpload) {
			if (!iterator.type) {
				rejection = true;
			}
		}
		return (rejection || !this.validationInfo);
	}

	classAcep(i) {
		if (this.uploadDocService.documentUpload[i].type && this.uploadDocService.documentUpload[i].evaluate) {
			return true
		} else {
			return false
		}
	}

	classRechaze(i) {
		if (!this.uploadDocService.documentUpload[i].type && this.uploadDocService.documentUpload[i].evaluate) {
			return true
		} else {
			return false
		}
	}

	descargar(i) {
		let number = this.infoEmpresa.tipoId + '_' + this.infoEmpresa.noId;
		let radicado = this.infoEmpresa.noRadicado
		let ext = '.pdf'
		/* let documento = this.Url + '' + number + '/' + radicado + '/' + number + '_RADICADO_' + radicado + '_DOC' + (i+1) + ext */
		let documento = this.Url + this.uploadDocService.documentUpload[i].filename;
		console.log(documento);
		window.open(documento, "_blank");
	}

	aceptar(i) {
		this.uploadDocService.documentUpload[i].type = true
		this.uploadDocService.documentUpload[i].evaluate = true
		this.uploadDocService.documentUpload[i].causes = []
	}

	devuelto(i) {
		this.rechazoService.clearList()
		this.uploadDocService.dataToCauses = i
		this.modalService.rejections(2, resp => {
			if (resp == "continue") {
				if (this.rechazoService.listSelect.length > 0) {
					this.uploadDocService.documentUpload[i].causes = this.rechazoService.listSelect
					this.uploadDocService.documentUpload[i].type = false
					this.uploadDocService.documentUpload[i].evaluate = true
				} else {
					this.uploadDocService.documentUpload[i].causes = this.rechazoService.listSelect
				}
				this.modalService.closeModalDefinitive();
			} else {
				this.modalService.closeModalDefinitive();
			}
		})
	}

	loadQuestions(){
		let questions = this.storeService.get('questions');
		this.declaraRenta = '';
		this.autoRetenedor = '';
		this.codigoDeclaraRenta = '';

		if(questions != undefined && questions){
			let questFilter = questions.filter((question) => ['CUM7-PAEF', 'CUM8-PAEF', 'CUM71-PAEF'].indexOf(question.k) != -1);
			if(questFilter != undefined && questFilter.length > 0){
				let declaraRentaFilter = questFilter.filter((question) => question.k == 'CUM7-PAEF');
				let autoRetenedorFilter = questFilter.filter((question) => question.k == 'CUM8-PAEF');
				let codigoDeclaraRentaFilter = questFilter.filter((question) => question.k == 'CUM71-PAEF');
				this.declaraRenta = (declaraRentaFilter != undefined && declaraRentaFilter.length > 0) ? declaraRentaFilter[0].v.toUpperCase()  : '';
				this.autoRetenedor = (autoRetenedorFilter != undefined && autoRetenedorFilter.length > 0) ? autoRetenedorFilter[0].v.toUpperCase()  : '';
				this.codigoDeclaraRenta = (codigoDeclaraRentaFilter != undefined && codigoDeclaraRentaFilter.length > 0) ? codigoDeclaraRentaFilter[0].v.toUpperCase()  : '';
			}
		}
	}

	devueltoInfo() {
		this.rechazoService.clearList()
		this.uploadDocService.dataToCauses = 20
		this.modalService.rejections(1, resp => {
			if (resp == "continue") {
				if (this.rechazoService.listSelect.length > 0) {
					this.validateInfo(false)
					this.uploadDocService.info.causes = this.rechazoService.listSelect
					this.uploadDocService.info.type = false
				} else {
					this.uploadDocService.info.causes = this.rechazoService.listSelect
				}
				this.modalService.closeModalDefinitive();
			} else {
				this.modalService.closeModalDefinitive();
			}
		})
	}

}
