import { Injectable } from '@angular/core';
import { ModalGlobalComponent } from "@app/shared/modal-global/modal-global.component";
import { MatDialog } from '@angular/material/dialog';
import { InputModal } from '../model/mmodal';
import { element } from '@app/core/models/user-login.model';
import { OutputObject } from '@app/core/models/output-object';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    
    dialogRef: any;
    keyClose: string;

    constructor(public dialog: MatDialog) {
        this.keyClose = '';
        this.dialogRef = null;
    }

    rejections(typeIn: number,callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtonsReject',
            title: 'Motivo(s) de devolución',
            icon: 'assets/img/new/warningred.svg',
            body: "Seleccione el motivo general de devolución para este item:",
            body2: "Seleccione el/los motivo(s) específico(s) de devolución:",
            btn: "Cancelar",
            btnR: "Devolver item",
            type: typeIn
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    solicitudEnProgreso(razon: string,callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "oneButtonsOnCourse",
            title: "Solicitud en progreso",
            body: "<p>Usted ya tiene una solicitud en progreso para la siguiente empresa:</p>" + razon,
            body2: "Si va a corregir la solicitud o cargar documentos debe seleccionar dicha opción en el inicio.",
            body3: "Si va  a radicar una nueva solicitud para otra empresa seleccione radicar nueva solicitud.",
            icon: 'assets/img/modals/Info.svg',
            btn: "Continuar solicitud existente",
            btnR: "Radicar nueva solicitud"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    applicationStateModal(tableArrayData: element[], state: string, btnArray1: any[], text: string, causes: string, callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "threeButtonsApplicationState",
            title: "Estado de su solicitud",
/*             body: "(Si encuentra inconsistencias en la información de su correo electrónico o en su celular, puede editarlos directamente en cada campo.)",             */
            body3: `Estado de la solicitud..${state}..${text} ..${causes}`,
            icon: 'assets/img/modals/equis.svg',
            tableArray: tableArrayData,
            btn: "Cerrar",
            btnR: "Corregir solicitud;Cargar documentos",
            btnArray: btnArray1
        }
        console.log(modal);
        
        this.setModal(modal, result => {
            callback(result);
        });
    }

    stepToRetake(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtonsRadio",
            title: "Solicitud en progreso",
            body: "<p>¿Qué desea hacer?</p>",
            //body2: "",
            body2: "Corregir formulario",
            body3: "Cargar documentos",
            icon: 'assets/img/modals/Info.svg',
            btn: "Cancelar",
            btnR: "Continuar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    caseAssigment(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtonsAssigment',
            title: 'Asignación de caso',
            icon: 'assets/img/new/infos.svg',
            body: "La asignación del caso es aleatoria. Usted deberá",
            body2: "terminar de gestionar el caso asignado para continuar con el siguiente.",
            btn: "Salir",
            btnR: "Asignarme caso"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    restrictedAccess(tipe, callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtonsRestrictedAccess',
            title: 'Confirmación tipo de solicitud',
            icon: 'assets/img/new/infos.svg',
            body: "Usted ha seleccionado la opción " + tipe + " ¿Desea continuar?",
            btn: "Atrás",
            btnR: "Continuar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    successAssigment(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtonsSuccessAssigment',
            title: 'Resultado enviado',
            icon: 'assets/img/modals/check.svg',
            body: "¿Qué desea hacer?",
            btn: "Salir",
            btnR: "Asignarme otro caso"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }


    firstModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBotonBlue',
            title: 'Antes de comenzar',
            body: 'Asegúrese de tener habilitadas las ventanas emergentes de su navegador para poder descargar los archivos necesarios para su firma.',
            btn: 'Salir',
            icon: 'assets/img/modals/Info.svg'            
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    firstModalPAP(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons3",
            title: "Programa Apoyo al Pago de Prima de Servicios - PAP",
            body: "Recuerde que usted solo podrá beneficiarse de este subsidio una única vez.",
            icon: 'assets/img/modals/Info.svg',
            btn: "Atrás",
            btnR: "Continuar"
        }
        this.setModal(modal, result => {
            callback(result);
        })
    }

    firstModalPAPAgro(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons3",
            title: "Programa Apoyo al Pago de Prima de Servicios - PAP Agropecuario",
            body: "Recuerde que usted solo podrá beneficiarse de este subsidio una única vez.",
            icon: 'assets/img/modals/Info.svg',
            btn: "Atrás",
            btnR: "Continuar"
        }
        this.setModal(modal, result => {
            callback(result);
        })
    }

    /**
     *     
     * @param callback 
     */
    invalidSize(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBoton4',
            title: 'Archivo demasiado grande',
            body: 'El archivo que seleccionaste excede el peso permitido. Recuerda que puedes subir archivos de hasta 2MB.',
            icon: 'assets/img/modals/warning.svg',
            OpenSansLight: 'true'
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    inactiveSessionModal(callback: (result) => void, count) {
        const modal: InputModal = {
            tipoModal: 'inactiveSession',
            title: 'Sesión inactiva',
            body: 'Por seguridad y debido a inactividad, su sesión se cerrará en ',
            body2: ' segundos.',
            btn: 'CONTINUAR CON LA SOLICITUD',
            icon: 'assets/img/modals/warning.svg',
            OpenSansLight: 'true'
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    blackListModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBotonError',
            title: 'Error en el proceso',
            body: 'Por favor contactar al Gerente de su oficina.',
            btn: 'Salir',
            icon: 'assets/img/modals/warning-error.svg',
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    closeSession(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBoton',
            title: 'Su sesión ha expirado',
            body: 'El tiempo de su sesión ha expirado, para continuar vuelva a iniciar sesión.',
            icon: 'assets/img/modals/alert.svg',
            OpenSansLight: 'true'
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    loadingModal(key: string, callback: (result) => void) {
        if (this.keyClose == undefined || this.keyClose == '') {
            this.keyClose = key;

            const modal: InputModal = {
                tipoModal: "loading",
                body: "Estamos procesando tu solicitud",
                title: null,
                btn: null
            }

            this.setModal(modal, result => {
                callback(result);
            });
        }
    }

    confirmRequestDocuments(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Envío de solicitud",
            body: "Está a punto de enviar la solicitud para revisión de documentación y validación por parte de la UGPP.<br><span>Recuerde que su solicitud será rechazada si alguno de los formatos NO está firmados por el representante legal o su equivalente.</span>",
            body2: "¿Está seguro que desea continuar?",
            icon: 'assets/img/new/warning.svg',
            btn: "Cancelar",
            btnR: "Enviar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmRequestDocumentsAgri(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Envío de solicitud",
            body: "Está a punto de enviar la solicitud para revisión de documentación y validación por parte de la UGPP.",
            body2: "¿Está seguro que desea continuar?",
            icon: 'assets/img/new/warning.svg',
            btn: "Cancelar",
            btnR: "Enviar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmUploadDocuments(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Cargue los documentos firmados",
            body: "Recuerde que su solicitud será rechazada si los documentos se cargan sin las firmas respectivas. ¿Desea continuar?",
            icon: 'assets/img/new/warning.svg',
            btn: "Cancelar",
            btnR: "Cargar documento"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmTermsDocuments(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "terms2",
            title: "",
            body: "",
            body2: "",
            icon: '',
            btn: "Cancelar",
            btnR: "Enviar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    
    requestReceived(msg: string, callback: (result) => void) {
        var msg = (msg == undefined || msg == null || msg == '') ? "\"PARO\"" : msg;
        const modal: InputModal = {
            tipoModal: "unBoton3",
            title: "Solicitud recibida",
            body: "Su solicitud ha sido recibida satisfactoriamente, se inicia el proceso de validación.",  
            body2: '<span>Recuerde</span>realizar seguimiento a su radicado por el botón "consulte el estado de la solicitud" en la pantalla principal de postulación al programa ' + msg + ', para poder identificar el estado en tiempo real del proceso.',
            icon: 'assets/img/modals/check.svg',
            btn: "Salir"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }


    confirmSendFormModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Creación de formulario",
            body: "Está a punto de enviar la información para crear el formulario.",
            body2: "¿Esta seguro que desea continuar?",
            icon: 'assets/img/new/warning.svg',
            btn: "Cancelar",
            btnR: "Enviar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmCreationFormModal(numRad:string, callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "formCreated",
            title: "Formulario Creado",
            body: numRad,
            icon: 'assets/img/new/correct.png',
            btn: "Descargar documentos y continuar"
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    popupWindowsWarning(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBotonBlue',
            title: 'Verifique los archivos descargados',
            body: 'Verifique que se hayan descargado 2 formularios para firmar. Si no es así, asegúrese de tener habilitadas las ventanas emergentes de su navegador para poder descargar los archivos necesarios para firma.',
            btn: 'Continuar',
            icon: 'assets/img/modals/Info.svg'            
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    constructionModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "En construcción",
            body: "Estamos trabajando para ti.",
            btn: "Cerrar"
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmModalInternal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "formulario",
            title: "Búsqueda de cliente",
            btn: "Atrás",
            btnR: "Continuar",
            icon: 'assets/img/new/info.svg',
            OpenSansLight: 'false'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmCancelProcessModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons2",
            title: "Cancelar Proceso",
            body: "No podrás deshacer esta acción.",
            body2: "¿Deseas cancelar el proceso?",
            icon: 'assets/img/new/info.svg',
            btn: "NO",
            btnR: "SI",
            OpenSansLight: 'true'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    closeSessionNavbar(typeM: number, callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtons',
            title: '¿Cerrar sesión?',
            icon: 'assets/img/new/warning.svg',
            body: typeM == 1 ? "Al salir, los datos ingresados y archivos subidos NO se guardarán. Si va a cargar documentos, recuerde ingresar con su número de radicado." : "Usted podrá retomar el caso en curso cuando vuelva a iniciar sesión.",
            body2: "¿Esta seguro que desea continuar?",
            btn: "Cancelar",
            btnR: "Cerrar sesión",
            type: typeM
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    RedirectProduct(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtons',
            title: 'Gestionar solicitud de alivio',
            body: 'A continuación serás redirigido a la página para gestionar la solicitud del alivio.',
            body2: 'Si tuviste inconvenientes diligenciando la solicitud puedes intentarlo nuevamente.',
            icon: 'assets/img/new/infos.svg',
            btn: "Atrás",
            btnR: "Continuar",
            OpenSansLight: 'true',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    RedirectProduct2(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'twoButtons',
            title: 'Gestionar solicitud de alivio',
            body: 'A continuación serás redirigido a la página para gestionar la solicitud del alivio.',
            body2: 'Si tuviste inconvenientes diligenciando la solicitud puedes intentarlo nuevamente.',
            icon: 'assets/img/new/infos.svg',
            btn: "Atrás",
            btnR: "Continuar",
            OpenSansLight: 'true',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }


    showModalProcessFinalize(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Has firmado electrónicamente el pagaré",
            body: "Continúa con la activación de tu tarjeta.",
            btn: "CONTINUAR",
            icon: 'assets/img/modals/check.svg',
            OpenSansLight: 'true'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    showModalSignFinalize(message,callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: message.modals.finalize.title,
            btn: "CONTINUAR",
            icon: 'assets/img/modals/check.svg',
            OpenSansLight: 'true'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    doubleSessionModal(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Sesión abierta",
            body: "Ya cuentas con una sesión abierta.",
            body2: "¿Deseas cerrarla para iniciar una nueva?",
            btn: "NO",
            btnR: "SI",
            icon: 'assets/img/new/info.svg',
            OpenSansLight: 'true'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    extention(date,callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Extensión de alivio",
            body: "Puedes extender tu alivio antes del " + date + ". A continuación serás redirigido a la página para gestionar la solicitud del alivio.  Si tuviste inconvenientes diligenciando la solicitud, puedes intentarlo nuevamente. ",
            btn: "Atrás",
            btnR: "Continuar",
            icon: 'assets/img/generic/info-azul.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }
    //Modal para radicados consultados en flujos incorrectos
    wrongFlowRequest(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'restrictedFlow',
            title: 'Estado de su solicitud',
            body: 'Número de radicado no está asignado para este subsidio.',
            btn: 'Salir',
            icon: 'assets/img/modals/warning-error.svg',
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }
// Start PAEF
    solicitudEnviada(title,callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: title,
            body: "En los próximos 10 días hábiles podrás ver la aplicación del alivio.",
            btn: "Cerrar",
            icon: 'assets/img/generic/info-azul.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    solicitudCompletada(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Solicitud completada",
            body: "El número de radicado ingresado ya fue utilizado para diligenciar una solicitud.",
            body2: "Para volver a cargar documentos vuelva diligenciar el formulario.",
            btn: "Salir",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    accessDenied(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Acceso restringido",
            body: "Su solicitud se encuentra en proceso de revisión.",
            btn: "Salir",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    solicitudRechazada(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton5",
            title: "Su empresa puede no ser apta para este",
            body: "Lo invitamos a revisar sus respuestas",
            body2: "o consultar el ",
            body3: "Decreto 677 para más información sobre los requisitos.",
            btn: "Continuar",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    confirmacionEnvio(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Envío de solicitud",
            body: "Está a punto de enviar la información para crear el formulario. ¿Está seguro que desea continuar?",
            btn: "Cancelar",
            btnR: "Enviar",
            icon: 'assets/img/modals/warning.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }
    creacionFormulario(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "twoButtons",
            title: "Creación de formulario",
            body: "Está a punto de enviar la información para crear el formulario. ¿Está seguro que desea continuar?",
            btn: "Cancelar",
            btnR: "Enviar",
            icon: 'assets/img/modals/warning.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    errorModal(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Servicio no disponible",
            body: "Estamos presentando fallas técnicas, por favor intente de nuevo más tarde",
            btn: "Salir",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    customErrorModal(title="Servicio no disponible", body="Estamos presentando fallas técnicas, ", btn="Salir", callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: title,
            body: body + "\nIntente nuevamente por favor",
            btn: btn,
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    modalSearchRadicated(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "searchRadicate",
            title: "Cargar información de solicitud anterior",
            body: "Si realizó una solicitud en el mes de JULIO y quiere traer la información del formulario, ingrese el número de radicado correspondiente: ",
            OpenSansLight: 'true',
            btn: "Continuar sin cargar información",
            btnR: "Cargar información",
            icon: 'assets/img/modals/Info.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    errorModalUpload(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Servicio no disponible",
            body: "Ya se ha intentado cargar los documentos en esta sesión",
            btn: "Salir",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    modalNoCasesFound(callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: "Casos no disponibles para revisión",
            body: "No se encontraron más casos para atender en este momento. Por favor intente de nuevo más tarde.",
            btn: "Salir",
            icon: 'assets/img/modals/alert.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    cuentaNoHabilitada(cuentaValida, cuentaInvalida, callback: (result) => void){
        const modal: InputModal = {
            tipoModal: "unBotonError",
            title: "Cuenta no habilitada",
            body: "Su cuenta " + cuentaInvalida + " no se encuentra habilitada por lo cual será excluida del proceso y se utilizará unicamente la cuenta " + cuentaValida + ".",
            body2: "Comuníquese con su oficina o gerente para validar el estado de su producto.",
            btn: "Continuar",
            icon: 'assets/img/modals/warning-error.svg',
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

// finish PAEF
    setModal(inputModal: InputModal, callback: (result) => void) {
        const modal = new OutputObject("modal", "", {
            tipoModal: inputModal.tipoModal,
            title: inputModal.title,
            body: inputModal.body,
            body2: inputModal.body2,
            body3: inputModal.body3,
            tableArray: inputModal.tableArray,
            btn: inputModal.btn,
            btnR: inputModal.btnR,
            btnArray: inputModal.btnArray,
            icon: inputModal.icon,
            OpenSansLight: inputModal.OpenSansLight,
            type: inputModal.type
        });

        this.openModal(modal, (resp) => {
            callback(resp);
        })
    }

    openModal(modal: any, callback: (result) => void) {
        this.dialogRef = this.dialog.open(ModalGlobalComponent, {
            panelClass: "myapp-no-padding-dialog",
            disableClose: true,
            data: modal
        });

        this.dialogRef.afterClosed().subscribe(result => {
            callback(result);
        });
    }

    closeModal(key: string) {
        if (this.dialogRef != null && this.keyClose == key) {
            this.dialogRef.close();
            this.keyClose = '';
            this.dialogRef = null;
        }
    }

    closeModalDefinitive() {
        if (this.dialogRef != null) {
            this.dialogRef.close();
            this.keyClose = '';
            this.dialogRef = null;
        }
    }

    noValidateBrowser(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBoton2',
            title: 'Navegador no compatible',
            body: 'Para poder acceder al portal te recomendamos utilizar los navegadores: Chrome',
            icon: 'assets/img/modals/warning.svg',
            OpenSansLight: 'true',
            btn: 'Cerrar'
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    terms(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'terms',
            title: 'PREGUNTAS Y RESPUESTAS SOBRE EL SUBSIDIO A LA NÓMINA PARA SU EMPRESA',
            body: '',
            icon: '',
            btn: 'SALIR'
        }

        this.setModal(modal, result => {
            callback(result);
        });
    }

    //Modal accept will to pay
    showModalAcceptPay(message,productName,callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: "unBoton",
            title: message.modals.acceptWillPay.title + productName,
            body: "",
            btn: "CONTINUAR",
            icon: 'assets/img/modals/check.svg',
            OpenSansLight: 'true'
        }
        this.setModal(modal, result => {
            callback(result);
        });
    }

    showModalNoFound(callback: (result) => void) {
        const modal: InputModal = {
            tipoModal: 'unBoton',
            title: 'Este cliente no tiene productos que apliquen',
            body: 'El cliente seleccionado no cuenta con ningún producto de crédito que sea susceptible de alivios financieros COVID-19.',
            icon: 'assets/img/new/area.svg',
            OpenSansLight: 'true',
            btn: 'SALIR'
        }

        this.setModal(modal, result => {
        });
    }

}