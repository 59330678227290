import { Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { BdbPaefRouting } from '@app/bdb-paef/bdb-paef.routing';

export const WelcomeRouting: Routes = [
  { path: 'paef', component: WelcomeComponent,
  children: [
    ...BdbPaefRouting
  ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

