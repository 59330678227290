import { Injectable } from '@angular/core';
import { UserLogin } from '@app/core/models/user-login.model';
import { StoreService } from '@core/service/store.service';

@Injectable({
  providedIn: 'root'
})
export class LoginStepService {
  public select = 0
  public cc: string = ""
  public userLogin: UserLogin
  
  constructor(private storeService: StoreService) { }

}
