import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';
import { AuthServerProviderService } from '../../core/services/auth-server-provider.service';
import { ModalService } from './modal.service';
import { StoreService } from '@core/service/store.service';
import { environment } from '@env/environment';
const publicIp = require('public-ip');
@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  public showNav: boolean = true;
  public city = '';
  public facs = '';
  public city2 = '';
  public facs2 = '';
  public checkCountrie = [false,false]

  statusProcess = [
  ]

  public actualStep;
  timeLeft = 0;
  interval: any;

  constructor(private route: Router,
    public modalService: ModalService,
    public authServerProviderService: AuthServerProviderService,
    private storeService: StoreService) { }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  startTimer() {
    this.timeLeft = 0;
    console.log('Comienza el contador de tiempo');
    this.interval = setInterval(() => {
      this.timeLeft++;
      // console.log(this.timeLeft + 'segundos han pasados')
      if (this.timeLeft === 300) {
        clearInterval(this.interval);
      }
    }, 1000)
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  startTimerNoClient() {
    this.timeLeft = 0;
    console.log('Comienza el contador de tiempo');
    this.interval = setInterval(() => {
      this.timeLeft++;
      // console.log(this.timeLeft + 'segundos han pasados')
      if (this.timeLeft === 300) {
        clearInterval(this.interval);
      }
    }, 1000)
  }

  stopTimerNoClient() {
    clearInterval(this.interval);
  }

  getStatusProcess(command: string) {
    console.log(command);
    if (this.storeService.get("control").control.status === "APR") {
      this.route.navigate(['pagd/inbox/accept-product-list']);
    } else {
      for (let element of this.statusProcess) {
        if (element.step == command) {
          this.storeService.set("showCancel", element.showCancel)
          if (element.continue) {
            this.route.navigate([element.path]);
          } else {
            this.authServerProviderService.logoutBasic();
          }
          break;
        }
        console.log(element);
      }
    }
  }

  verifyProduct(products){
    let cont = 0
      if(products.libreDestino != null){
        cont++
        this.storeService.set("product", "Libre Destino");
      } if (products.crediService != null){
        cont++
        this.storeService.set("product", "Crediservice");
      } if (products.tarjetaCredito != null){
        cont++
        this.storeService.set("product", "Tarjeta de credito");
      } if (products.sustitucion != null){
        cont++
        this.storeService.set("product", "Sustitucion");
      }

      if(cont > 1){
        this.storeService.set("product", "Multiproducto");
      }
      this.storeService.set("products", products);
  }

  changeRouter() {
    this.route.events.subscribe((val) => {
      // see also 
      console.log(val)
    });
  }

  dontLogin() {
    if (this.storeService.get("isLoggedIn")) {
      this.getStatusProcess(this.storeService.get("step"));
    }
  }

  showIp(callback: (result) => void) {
     publicIp.v4().then((ip) => {
       console.log(ip);
       callback(ip)
     }, error => {
       console.log(error);
     });
  }
}