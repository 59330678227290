import { Component, OnInit, Inject, Directive} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { Observable, timer } from 'rxjs';
import { SessionStorageService, SessionStorage } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { LoginService } from '@core/service/login.service';
import { MotivosRechazoService } from '@core/service/motivos-rechazo.service';
import { UploadDocService } from '@core/service/upload-doc.service';
import { FormsModule } from "@angular/forms";
import { StoreService } from '@core/service/store.service';
import { OutputObject } from '@app/core/models/output-object';

@Component({
  selector: 'aliv-modal-global',
  templateUrl: './modal-global.component.html',
  styleUrls: ['./modal-global.component.scss']
})
export class ModalGlobalComponent implements OnInit {

  showModal = "";
  captchaSuccess = false;
  second: number = 60;
  down = false;
  clock: Observable<Date>;
  test = false;
  activeError: boolean = false;
  loginForm: FormGroup;
  checkRadio = [true, false];
  docs = []
  indexDocs = 0
  docTypes: {name:string,id:string}[] = [{name:'Cédula de ciudadanía',id:'CC'},{name:'Tarjeta de Identidad',id:'TI'},
            {name:'Cédula de extranjería',id:'CE'},{name:'NIT Persona Natural',id:'NEX'},{name:'Pasaporte',id:'PAS'},
            {name:'Registro Civil',id:'RC'},{name:'NIT',id:'NIT'},{name:'Carné Diplomático',id:'TSE'},
            {name:'Soc. Extranjera sin NIT',id:'SES'},{name:'Patrimonios Autónomos',id:'PAT'} ];

  errorMessage:string;

  constructor(private fb: FormBuilder,
    public loginService: LoginService, 
    private sessionStorage: SessionStorageService,
    public dialogRef: MatDialogRef<ModalGlobalComponent>, 
    private route: Router,
    public storeService : StoreService,
    public rechazoService: MotivosRechazoService,
    @Inject(MAT_DIALOG_DATA) public data: OutputObject) {
    dialogRef.disableClose = true;
    this.modalShow(data);
  }

  seleccionado :string;

  ngOnInit() {
    
    this.test = environment.testing
    if (this.test === true) {
      this.captchaSuccess = true
    } 
    this.loginForm = this.fb.group({
      type: new FormControl('CC', Validators.required),
      number: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9ñÑ]*$/), Validators.minLength(3), Validators.maxLength(30)]),
    });
    this.loginService.activeError = false
    this.loginService.errorMessage = ''
  }

  validForm() {
    if (this.loginForm.controls.number.value !== "") {
      return true
    } else {
      return false
    }
  }

  searchRadicated(){
    if(this.validForm){
      this.loginService.radicado = this.loginForm.controls.number.value
      this.loginService.activeError = false
      this.loginService.errorMessage = ''
      this.loginService.dataRadicado.numradicado = this.loginForm.controls.number.value
      this.loginService.getDataRadicated(this.loginService.dataRadicado).subscribe((dataRadicate) => {/// TRAER RADICADO
        if(dataRadicate['statusCode'] != undefined && dataRadicate['statusCode'] != null){
          if (dataRadicate['statusCode'] == 200) {
            this.loginService.resultRadicado = dataRadicate
            this.dialogRef.close('continue'); 
          }else if (dataRadicate['statusCode'] == 404){
            this.loginService.errorMessage = 'Readicado no encontrado. Ingrese un número válido.'
            this.loginService.activeError = true
          }
        }else{
          this.loginService.errorMessage = 'Readicado no encontrado. Ingrese un número válido.'
          this.loginService.activeError = true
        }
      })
    }
  }

  click(i){
    console.log(i);
    let c = this.rechazoService.listSelect
    let data = this.listaParametros[i]
    console.log(data);
    let n = 0
    let d = 0
    for (let index = 0; index < c.length; index++) {
      if(c[index] == data){
        n++
        let d = i
      }else{
      }
    }
    if(n > 0){
      //c.splice(d-1,1);
      var i = c.indexOf( data );
 
      if ( i !== -1 ) {
          c.splice( i, 1 );
      }
    }else{
      c[c.length] = data;
    }
    //console.log(c);
  }

  stateCusal(i){
    let c = this.rechazoService.listSelect
    let data = this.listaParametros[i]
    //console.log(data);
    let n = 0
    
    for (let index = 0; index < c.length; index++) {
      if(c[index] == data){
        n++
      }else{
      }
    }
    if(n > 0){
      return true
    }else{
      return false
    }
  }

  downPaginator() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    const verticalOffset = scrollTop + document.documentElement.clientHeight * 2;
    if (this.down) {
      console.log(document.body.scrollHeight);
      document.getElementById('content-terms').scrollBy(0, -document.body.scrollHeight * 5);
      setTimeout(() => {
        
      }, 500);
      this.down = false
    } else {
      document.getElementById('content-terms').scrollBy(0, document.body.scrollHeight * 5);
      setTimeout(() => {
      }, 500);
      this.down = true
    }
  }

  activeBtn(text: string) {
      return (this.data.attribute['body3'].split("..")[1] == 'DEVUELTO') || 
      this.data.attribute['body3'].split("..")[1] == 'INICIADO'
  }

  filterRejectCategories(neededType: number) {
    let finalArray = [];
    for (const iterator of this.rechazoService.categoriasRechazo) {
      if (iterator.type == neededType) 
      finalArray.push(iterator);
    }
    return finalArray;    
  }

  tagColor(text: string) {
    let type = this.data.attribute['body3'].split("..")[1];
    if (text == 'state') {
      if (type == 'DEVUELTO' || type == 'RECHAZADO UGPP' || type == 'ABONO NO REALIZADO') {
        return 'red';        
      } else if (type == 'EN REVISIÓN UGPP' || type == 'APROBADO UGPP' || type == 'ABONO REALIZADO') {
        return 'green';
      } else {
        return 'blue';
      }            
    } else if (text == 'reason') {
      return (type == 'DEVUELTO' || type == 'ABONO NO REALIZADO'  ? 'red' : 'blue');
    }
  }

  changeData(){
    if(this.validForm()){
    }else{
      this.activeError = true;
      this.errorMessage = 'Falta completar los campos.';
    }
  }

  onClick(text: string): void {
    console.log(text);
    
    if (text == "select-radio") {
      this.dialogRef.close(this.checkRadio[0]);            
    } else {
      this.dialogRef.close(text);
    }
  }

  onClickResultList(): void {
    this.dialogRef.close('continue');
  }

  onContinueClick(): void {

    if (this.captchaSuccess) {
      this.dialogRef.close('si');
    }
  }

  formatRadicado(value, field) {
    let newValue = '';
    newValue = value.replace(/[^0-9_]/g, '');
    this.loginForm.controls[field].setValue(newValue);
  }

  changeRadioButton(position) {
    for (let index = 0; index < this.checkRadio.length; index++) {
      this.checkRadio[index] = false;
    }
      this.checkRadio[position] = true;      
  }

  modalShow(data: any) {
    const modal = data.attribute['tipoModal'];
    this.showModal = modal;
    if (modal === 'inactiveSession') {
      this.beginCount()
    }
  }
  
  resolved(captchaResponse: string) {
    this.captchaSuccess = true;
  }

  retrunStyle(){
    //console.log(this.data.attribute['styleTitle']);
    if(this.data['OpenSansLight'] != undefined){
      return this.data.attribute['styleTitle'] != undefined ? 'title-modal-light ' + this.data.attribute['styleTitle'] : 'title-modal-light'
    } else{
      return this.data.attribute['styleTitle'] != undefined ? 'title-modal' + this.data.attribute['styleTitle'] : 'title-modal'
    } 
  }

  beginCount() {
    this.clock = timer(0, 1000).pipe(map(t => new Date()), shareReplay(1));
    this.clock.subscribe(t => {
      this.second -= 1
      if (this.second === 0) {
        this.onClick('si')
      }
    });
  }

  formatNotSpace(inputEvent) {
    let newValue = "";
    newValue = inputEvent.value.replace(/[^A-Za-z0-9\u00E0-\u00FC]/gi, "");
    inputEvent.value = newValue;
  }

  listaParametros : string[] = null;

  llenarLista(valor){
    let element;
    for (const iterator of this.rechazoService.categoriasRechazo) {
      if (iterator.idPapa == valor) {        
        element = iterator  
      }
    }
    console.log("******element***", element.texto);

    this.listaParametros = element.texto;
  }
  

}


