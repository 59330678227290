import { DynQuestion } from './formularios';

export const papForms: Array<DynQuestion[]> = [
    [        
    {
        "name": "numeroPrimas"   
    },
    {
        "name": "tipoIdEmpresa"   
    },
    {
        "name": "numIdEmpresa"
    },
    {
        "name": "razonSocial",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "ciiu"
    },
    {
        "name": "direccion",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "departamento"
    },
    {
        "name": "municipio"
    },
    {
        "name": "conFijo",
        "validation": {
            "minLength": 7,
            "maxLength": 20
        },
        "optional": true
    },
    {
        "name": "conCelular",
        "validation": {
            "minLength": 10,
            "maxLength": 10
        }
    },
    {
        "name": "conEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "conEmail2",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "rlNombre",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlApellido",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlTipoDoc",   
    },
    {
        "name": "rlNumDoc",
        "validation": {
            "maxLength": 10
        } 
    },
    {
        "name": "rlfechaExp", 
    },
    {
        "name": "rlCargo",
        "validation": {
            "maxLength": 50
        }
    },
    {
        "name": "rlCelular",  
        "validation": {
            "minLength": 10,
            "maxLength": 10
        } 
    },
    {
        "name": "rlEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "tipoCuentaBanc",   
    },
    {
        "name": "numCuentaBanc",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "numCuentaBancC",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "tipoCuentaBanc2",
        "optional": true
    },
    {
        "name": "numCuentaBanc2",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numCuentaBanc2C",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numEmpleados",
        "optional": true
    },
    {
        "name": "numPlanA1",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    },
    {
        "name": "numPlanA2",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    }
],
[     
    {
        "name": "numeroPrimas"   
    },
    {
        "name": "tipoIdEmpresa"   
    },
    {
        "name": "numIdEmpresa"
    },
    {
        "name": "razonSocial",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "ciiu"
    },
    {
        "name": "direccion",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "departamento"
    },
    {
        "name": "municipio"
    },
    {
        "name": "conFijo",
        "validation": {
            "minLength": 7,
            "maxLength": 20
        },
        "optional": true
    },
    {
        "name": "conCelular",
        "validation": {
            "minLength": 10,
            "maxLength": 10
        }
    },
    {
        "name": "conEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "conEmail2",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "rlNombre",
        "optional": true
    },
    {
        "name": "rlApellido",
        "optional": true
    },
    {
        "name": "rlTipoDoc",   
        "optional": true
    },
    {
        "name": "rlNumDoc",
        "optional": true
    },
    {
        "name": "rlfechaExp", 
        "optional": true
    },
    {
        "name": "rlCargo",
        "optional": true
    },
    {
        "name": "rlCelular",  
        "optional": true
    },
    {
        "name": "rlEmail",
        "optional": true
    },
    {
        "name": "tipoCuentaBanc",   
    },
    {
        "name": "numCuentaBanc",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "numCuentaBancC",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "tipoCuentaBanc2",
        "optional": true
    },
    {
        "name": "numCuentaBanc2",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numCuentaBanc2C",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numEmpleados",
        "optional": true
    },
    {
        "name": "numPlanA1",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    },
    {
        "name": "numPlanA2",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    }
],
[        
    {
        "name": "numeroPrimas"   
    },
    {
        "name": "tipoIdEmpresa"   
    },
    {
        "name": "numIdEmpresa"
    },
    {
        "name": "razonSocial",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "ciiu"
    },
    {
        "name": "direccion",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "departamento"
    },
    {
        "name": "municipio"
    },
    {
        "name": "conFijo",
        "validation": {
            "minLength": 7,
            "maxLength": 20
        },
        "optional": true
    },
    {
        "name": "conCelular",
        "validation": {
            "minLength": 10,
            "maxLength": 10
        }
    },
    {
        "name": "conEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "conEmail2",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "rlNombre",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlApellido",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlTipoDoc",   
    },
    {
        "name": "rlNumDoc",
        "validation": {
            "maxLength": 10
        } 
    },
    {
        "name": "rlfechaExp", 
    },
    {
        "name": "rlCargo",
        "validation": {
            "maxLength": 50
        }
    },
    {
        "name": "rlCelular",  
        "validation": {
            "minLength": 10,
            "maxLength": 10
        } 
    },
    {
        "name": "rlEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "tipoCuentaBanc",   
    },
    {
        "name": "numCuentaBanc",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "numCuentaBancC",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "tipoCuentaBanc2",
        "optional": true
    },
    {
        "name": "numCuentaBanc2",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numCuentaBanc2C",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numEmpleados",
        "optional": true
    },
    {
        "name": "numPlanA1",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    },
    {
        "name": "numPlanA2",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    }
],
[     
    {
        "name": "numeroPrimas"   
    },
    {
        "name": "tipoIdEmpresa"   
    },
    {
        "name": "numIdEmpresa"
    },
    {
        "name": "razonSocial",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "ciiu"
    },
    {
        "name": "cuTipoId1"
    },
    {
        "name": "cuNumId1",
        "validation": {
            "maxLength": 15
        }
    },
    {
        "name": "cuTipoId2"
    },
    {
        "name": "cuNumId2",
        "validation": {
            "maxLength": 15
        }
    },
    {
        "name": "cuTipoId3",
        "optional": true 
    },
    {
        "name": "cuNumId3",
        "optional": true,
        "validation": {
            "maxLength": 15
        }
    },
    {
        "name": "cuTipoId4",
        "optional": true
    },
    {
        "name": "cuNumId4",
        "optional": true,
        "validation": {
            "maxLength": 15
        }
    },
    {
        "name": "direccion",
        "validation": {
            "maxLength": 150
        }
    },
    {
        "name": "departamento"
    },
    {
        "name": "municipio"
    },
    {
        "name": "conFijo",
        "validation": {
            "minLength": 7,
            "maxLength": 20
        },
        "optional": true
    },
    {
        "name": "conCelular",
        "validation": {
            "minLength": 10,
            "maxLength": 10
        }
    },
    {
        "name": "conEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "conEmail2",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "rlNombre",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlApellido",
        "validation": {
            "maxLength": 50
        }  
    },
    {
        "name": "rlTipoDoc",   
    },
    {
        "name": "rlNumDoc",
        "validation": {
            "maxLength": 10
        } 
    },
    {
        "name": "rlfechaExp", 
    },
    {
        "name": "rlCargo",
        "validation": {
            "maxLength": 50
        }
    },
    {
        "name": "rlCelular",  
        "validation": {
            "minLength": 10,
            "maxLength": 10
        } 
    },
    {
        "name": "rlEmail",
        "validation": {
            "maxLength": 150,
            "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        }
    },
    {
        "name": "tipoCuentaBanc",   
    },
    {
        "name": "numCuentaBanc",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "numCuentaBancC",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        } 
    },
    {
        "name": "tipoCuentaBanc2",
        "optional": true
    },
    {
        "name": "numCuentaBanc2",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numCuentaBanc2C",
        "validation": {
            "minLength": 9,
            "maxLength": 9
        },
        "optional": true
    },
    {
        "name": "numEmpleados",
        "optional": true
    },
    {
        "name": "numPlanA1",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    },
    {
        "name": "numPlanA2",
        "validation": {
            "minLength": 6,
            "maxLength": 10
        },
        "optional": true
    }
]
]