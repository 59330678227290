import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginStepService } from '@app/authentication/service/login-step.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { StoreService } from './store.service';
import { ClientLogout, UserLogout } from '../models/user-login.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServerProviderService {
  public progressBar = "10%";

  constructor(
    public route: Router,
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    public loginStepService: LoginStepService,
    private cookieService: CookieService,
    public storeService: StoreService,
    private loginService: LoginService,
  ) { }

  logoutBasic() {
    this.getcloseSession();
    this.cookieService.deleteAll('/')
    setTimeout(() => {
      this.$sessionStorage.clear();
      setTimeout(() => {
          this.route.navigate(['login'])
      }, 200);
    }, 300);
  }

  logoutBasic2() {
    let f = this.storeService.get("flujo")
    let flow = this.storeService.get("flow")
    this.cookieService.deleteAll('/')
    setTimeout(() => {
      this.$sessionStorage.clear();
      setTimeout(() => {
          this.storeService.set("flujo",f)
          this.storeService.set("flow",flow)
          this.route.navigate(['logins'])
      }, 200);
    }, 300);
  }

  logoutOperative() {
    this.getcloseSessionUser();
    this.cookieService.deleteAll('/')
    setTimeout(() => {
      this.$sessionStorage.clear();
      setTimeout(() => {
        console.log('salir');
        
        this.route.navigate(['/operativa'])
      }, 200);
    }, 300);
  }

  clearSesion() {
    this.cookieService.deleteAll('/')
    setTimeout(() => {
      this.clearData()
    }, 300);
  }

  clearData(){
    this.$sessionStorage.clear();
    this.loginStepService.userLogin = null;
  }

  getcloseSession(){
    let requestDTO = new ClientLogout();
    requestDTO.typedoc  = this.storeService.get("docTypeSS");
    requestDTO.doc = this.storeService.get("docNumberSS");
    requestDTO.token = this.storeService.get("isLoggedIn");
    this.loginService.getCloseSesssion(requestDTO).subscribe(() => {})
}

getcloseSessionUser(){
  let requestDTO = new UserLogout();
  requestDTO.user = this.storeService.get("aliv.username");
  requestDTO.token = this.storeService.get("isLoggedInComercial");
  this.loginService.getCloseSesssion(requestDTO).subscribe(() => {})
}
}
