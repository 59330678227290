import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { CampaignDocumentInfo } from '@app/core/models/user-login.model';
import { ViewChild } from "@angular/core";
import { JimpService } from '@app/shared/services/jimp.service';
import { UtilsService } from '@app/shared/services/utils.service';
import { UploadDocService } from '@core/service/upload-doc.service';


@Component({
  selector: 'aliv-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() cardType: any;
  @Input() card: CampaignDocumentInfo;
  @Input() id: any;
  @Input() e: any; //index for list documentUpload
  @Input() checkStored: boolean;
  containers: any = [];
  // check: boolean = false;
  checkRadio = new Array();
  fileUploaded: boolean = false;
  load: boolean = true;
  message: String = "";
  selectedPayment: String = "";
  selectedPosition: number;
  showPay: boolean = false;
  selectOther: string = "";
  reemplazar : boolean = false;
  @ViewChild("fileInput") fileInput: any;

  constructor(
    private modalService: ModalService,
    public jimpService: JimpService,
    public uploadDocService: UploadDocService,
    public utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.checkContainer()
      if (this.card.docsToLoad.length > 0) {
        if(!this.uploadDocService.calculateStatus(this.e)){
          this.uploadDocService.checks[this.e] = true;
        }
      }
  }

  parseImg(t) {
    return t.split(".")[0];
  }

  previewData(key, index) {
    this.uploadDocService.preview = true;
    this.uploadDocService.index = index;
    this.uploadDocService.selectBase64(key, this.e);
  }

  loadDocument($event, reemplazar:boolean) {
    let fileList = $event;

        console.log("carga final al remplazar", this.uploadDocService.documentUpload);
        console.log(fileList);
        this.modalService.loadingModal("loading", resp => { });
        if (
          this.uploadDocService.documentUpload[this.e].docsToLoad.length + 1 <= 2
        ) {
          for (let i = 0; i < fileList.length; i++) {
            let fileData: File = fileList[i];
            //tamaño maximo
            if (fileData.size <= 2242880) {
              //extencion
              let ext = fileData.name.split(".").pop();
              let shortname = fileData.name;
              //validar largo de nombre
              if (fileData.name.length > 35) {
                console.log("muy grande");
                shortname =
                  fileData.name.split(".")[0].substring(0, 35) + "..." + ext;
              }
              //validar la extencion del archivo
              if (
                ext === "jpg" ||
                ext === "png" ||
                ext === "jpeg" ||
                ext === "JPG" ||
                ext === "PNG" ||
                ext === "JPEG" ||
                ext === "pdf" ||
                ext === "PDF"
              ) {
                let nameKey = this.utilsService.generateUUID() + "." + ext;
                console.log(shortname);
    
                if (ext === "pdf" || ext === "PDF") {
                  this.jimpService.loadPdf(
                    fileData,
                    fileData.name,
                    nameKey,
                    this.e,
                    shortname,
                    i,
                    reemplazar,
                    () => {
                      this.loader(fileList, i);
                    }
                  );
                } else {
                  this.jimpService.optimize(
                    fileData,
                    fileData.name,
                    nameKey,
                    this.e,
                    shortname,
                    i,
                    reemplazar,
                    result => {
                      this.loader(fileList, i);
                    }
                  );
                }
              } else {
                this.modalService.closeModal("loading");
                if (i === fileList.length - 1) {
                  this.fileInput.nativeElement.value = "";
                }
                this.checkDocuments()
              }
            } else {
              this.modalService.closeModal("loading");
              if (i === fileList.length - 1) {
                this.fileInput.nativeElement.value = "";
              }
              this.modalService.invalidSize(resp => { });
              this.checkDocuments()
            }
          }
        } else {
          this.modalService.closeModal("loading");
          console.log("capacidad maxima de documentos alcanzada");
          this.checkDocuments()
          for (let i = 0; i < fileList.length; i++) {
            if (i === fileList.length - 1) {
              this.fileInput.nativeElement.value = "";
            }
          }
          this.uploadDocService.sendDocuments = this.uploadDocService.checkFullDocs()
        }
      /*}else{
        for (let i = 0; i < fileList.length; i++) {
          if (i === fileList.length - 1) {
            this.fileInput.nativeElement.value = "";
          }
        }
        this.modalService.closeModal("loading");
        this.checkDocuments()
        this.uploadDocService.sendDocuments = this.uploadDocService.checkFullDocs()
      }
    })*/
  }

  checkDocuments(){
    if ( this.uploadDocService.documentUpload[this.e].docsToLoad.length == 0) {
      this.uploadDocService.checks[this.e] = false;
      return false
    } else{
      return true
    }
  }

  checkContainer(){
    if ( this.uploadDocService.documentUpload[this.e].docsToLoad.length == 0) {
      this.uploadDocService.checks[this.e] = false
    }else{
        if (this.uploadDocService.documentUpload[this.e].docsToLoad.length >=
          1){
          this.verifiedDel()
        }else{
          this.uploadDocService.checks[this.e] = false
        }
    }
  }

  verifiedDel(){
    let n = 0
    for (let index = 0; index < this.uploadDocService.documentUpload[this.e].docsToLoad.length; index++) {
      if (this.uploadDocService.documentUpload[this.e].docsToLoad[index].state !== 'DEL') {
        console.log("detectado " + this.e);
        n++
      }
    }
    if(n <= 0){
      this.uploadDocService.checks[this.e] = false;
    }else{
      if( !this.uploadDocService.calculateStatus(this.e)){
        this.uploadDocService.checks[this.e] = true;
      }
    }
  }

  loader(fileList, i) {
    this.checkContainer()
    this.fileUploaded = true;
    this.modalService.closeModal("loading");
    if (i === fileList.length - 1) {
      this.fileInput.nativeElement.value = "";
    }
    this.uploadDocService.sendDocuments = this.uploadDocService.checkFullDocs()
  }

  delete(key) {
    this.uploadDocService.deleteDocument(key, this.e, () => {

    });
    this.checkContainer()
    if (this.uploadDocService.documentUpload[this.e].docsToLoad.length == 0) {
      this.fileUploaded = false;
    }
    this.uploadDocService.sendDocuments = this.uploadDocService.checkFullDocs()
  }


  clickFile(replace: boolean) {
    this.modalService.confirmUploadDocuments((resp)=>{
      if (resp == "continue") {
        this.reemplazar = replace;
          document.getElementById("fileDefault" + this.e).click();
      }
    })
  }
}
