<div id="container">
    <div class="box">
        <img height="40px" width="40px" [src]="outMessageInput.emoji">
        <p id="heading">{{outMessageInput.title}}</p>
        <p id="description">{{outMessageInput.description}}</p>
        <button *ngIf="outMessageInput.buttonRedirectInternal === undefined && outMessageInput.back === undefined" id="finish" (click)="goTo()">
            {{outMessageInput.button}}
        </button>

        <button *ngIf="outMessageInput.buttonRedirectInternal !== undefined && outMessageInput.back == undefined" id="finish" (click)="goToRedirectInternal()">
            {{outMessageInput.button}}
        </button>


        <button *ngIf="outMessageInput.back !== undefined" id="finish" (click)="goBack()">
            {{outMessageInput.button}}
        </button>



    </div>
</div>