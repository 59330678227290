import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { ChangeStatusRequest } from '../models/operativa.model';
import { CryptoService } from './crypto/crypto.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MotivosRechazoService {
  radicado = ''
  listSelect: any = [

  ]
  urlManager = environment.endpointManager
  constructor(
  private htppClient: HttpClient,
  private cryptoService : CryptoService,
  private http: HttpClient) { }

  categoriasRechazo = [
    {
      nombre: "",//papa
      idPapa: 0,
      texto : [//hijos
      ],
      type: 2
    }
  ]

  getLista(){
    return this.categoriasRechazo;
  }

  //Consultar lista de causales de rechazo
  consulList(flujo) {
    const headers = {'Content-Type': 'application/json'};
    const requestparam = {'request_type': flujo}
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers),
        headers
      );
    return this.http.post(environment.apiSbx.concat(`core/post/requestcausal`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  changeStatus(body: ChangeStatusRequest) {
    const headers = {'Content-Type': 'application/json'};
    const requestparam = (body)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers),
        headers
      );
    return this.http.put(environment.apiSbx.concat(`core/changestatus`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  getPendingCases() {
    const headers = {
      'Content-Type': 'application/json',
    };
    const headers2 = {
      'Content-Type': 'application/json',
    };
    const queryParams = {
    };
    const { encryptedBody,options } =
    this.cryptoService.setEncryptionRequest(queryParams,'',
      this.cryptoService.assingHeaders(new HttpHeaders(), headers2),
      headers
    );
    return this.http.get(environment.apiSbx.concat(`core/count-pending-request?cypher=${encryptedBody}`),
    {
      headers: options,
      params: undefined,
      responseType: 'json',
      observe: 'response',
    }
  ).pipe(
    tap((response: any) => {
      this.cryptoService.decryptResponse(response);
    }),
  );
  }

  answerRequest(body:ChangeStatusRequest) {
    const headers = {'Content-Type': 'application/json'};
    const requestparam = (body)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers),
        headers
      );
    return this.http.post(environment.apiSbx.concat(`core/answerrequest`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  consulCase(body: any) {
    const headers = {'Content-Type': 'application/json'};
    const requestparam = (body)
    const { encryptedBody, options } =
      this.cryptoService.setEncryptionRequest(requestparam,'',
        this.cryptoService.assingHeaders(new HttpHeaders(), headers),
        headers
      );
    return this.http.post(environment.apiSbx.concat(`core/takerequest`),encryptedBody,
      {
        headers: options,
        params: undefined,
        responseType: 'json',
        observe: 'response',
      }
    ).pipe(
      tap((response: any) => {
        this.cryptoService.decryptResponse(response);
      }),
    );
  }

  clearList(){
    this.listSelect = []
  }

      /* <------------- END SERVICE SANDBOX -------------> */

  getPendingCases1() {
    return this.htppClient.get(this.urlManager+ 'core/count-pending-request');
  }

  changeStatus1(body: ChangeStatusRequest) {
    return this.htppClient.put(this.urlManager + "core/changestatus", body);
  }

  answerRequest1(body: ChangeStatusRequest) {
    return this.htppClient.post(this.urlManager + "core/answerrequest", body);
  }

  consulCase1(body: any) {
    return this.htppClient.post(this.urlManager + "core/takerequest", body);
  }

  //Consultar lista de causales de rechazo
  consulList1(flujo) {
    return this.htppClient.post(this.urlManager + "core/post/requestcausal",{"request_type":flujo});
  }
  



}
