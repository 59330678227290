export class UserLogin {
    typedoc: String;
    doc: String;
    verifcode: String;
    numradicado?: String;
    request_type?: String;
    phase_month?: String;
}

export class CampaignDocumentInfo {
    jobInfo: String;
    idDocument: number;
    documentName: String;
    info: InfoCampaignDocument;
    textExtra?: String;
    maxCapacity: number;
    docsToLoad?: any[];
  }

  export class InfoCampaignDocument {
    icon: String;
    description: String;
    idDocMantiz: number;
    min: number;
    multiple?: MultipleCampaignDocument;
    error?: String;
    others?: OthersCampaignDocument[];
  }

  export class MultipleCampaignDocument {
    title: String;
    options: OptionsMultiple[];
  }

  export class OthersCampaignDocument {
    description: String;
    idDocMantiz: String;
    min?: String;
    name?: OptionsMultiple[];
  }

  export class OptionsMultiple {
    option: String;
    description?: String;
    min: number;
  }

  export class saveRequest{
    tipoId: String;
    id: String;
    token: String;
    info: element[];
    questions: element[]
    numradicado?: String;
    request_type?: number;
  }

  export class finishRequest{
    typedoc: String;
    doc: String;
    token: String;
    numradicado: String;
  }

  export class element{
    k: String;
    v: String;
  }

  export class ClientLogout {
    typedoc: string;
    doc: string;
    token: String;
}

export class UserLogout {
  user: string;
  token: String;
}