import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { SharedModule } from "@app/shared/shared.module";
import { MaterialModule } from "@app/shared/material.module";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormularioComponent } from './formulario/formulario.component';
import { TerminosComponent } from './terminos/terminos.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { CardComponent } from './documentos/card/card.component';
import { NewPreguntasComponent } from './new-preguntas/new-preguntas.component';


@NgModule({
  declarations: [FormularioComponent, TerminosComponent, PreguntasComponent, DocumentosComponent, CardComponent, NewPreguntasComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-AU" }],
})
export class InboxModule { }
