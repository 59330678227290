<div class="card-document fadeInDown animated" *ngIf="cardType == 'default'">
    <div class="header-card">
        <div class="title-type">
            <h3>{{card.documentName}}</h3>
            <p [innerHtml]="card.info.description"></p>
        </div>
        <div *ngIf="uploadDocService.checks[this.e]" [ngClass]="uploadDocService.checks[this.e] ? 'line-2 icon-document' : 'icon-document'">
            <div class="icon reemplazar" (click)="clickFile(true)">
                <img src="assets/img/documents/check-container.svg" alt="" />
                <p> Reemplazar </p>
            </div>
        </div>
        <div *ngIf="!uploadDocService.checks[this.e]" class="icon-document">
            <div [id]="'upload-' + id" (click)="clickFile(false)" class="icon">
                <img src="assets/img/documents/upload.svg" alt="" />
                <p>Adjuntar</p>
            </div>
        </div>
    </div>
    <div class="content-card">
        <input #fileInput accept="application/pdf , image/*" class="file-delete" (change)="loadDocument($event.target.files, reemplazar)" type="file" [id]="'fileDefault' + e" />
    </div>
    <p class="data-Extra" *ngIf="card.textExtra != undefined">
        {{card.textExtra}}
    </p>
</div>