<!-- option 1 (template) -->

<!-- <div class="container">
    <div class="icon">
        <img src="assets/img/icon-out.svg" alt="">
    </div>
    <div class="msg">
        <div class="c1">
            <h1>
                Aquí encontrará nuestra solución digital para solicitar el subsidio del Programa de Apoyo al Empleo Formal (PAEF).
            </h1>
            <p>
                La fecha y hora límites para el cargue de información de solicitud de subsidio del mes de MAYO han caducado.
                <br>
                <span>
                    Para realizar la solicitud del mes de JUNIO, lo invitamos a aplicar desde el 1 de junio hasta el 17 de junio. 
                </span>
                <br> Continuamos apoyando a nuestros clientes que hacen empresa en el país.
                <span>
                    Colombia, ¡Estamos contigo!
                </span>
            </p>
        </div>
        <div class="c2">
            <img src="assets/img/logo-out.svg" alt="">
        </div>
    </div>
</div>

 -->
<!-- option 2 (img) -->

<!-- <div class="wrapper">			
    <div class="one">
        <img src="assets/img/proximamente-junio.png">
    </div>
    
</div> -->

<div class="contentn" style="text-align:center">
    <img src="assets/expired-session.svg" alt="">
    <h1>En mantenimiento</h1>
    <h5>
        En este momento el canal se encuentra en mantenimiento.
    </h5>
</div>