import { Component, OnInit } from '@angular/core';
import { OutMessageInput } from '@app/shared/model/mcomponent';
import { NoReturnService } from '@app/shared/services/no-return.service';


@Component({
  selector: 'app-outdated',
  templateUrl: './outdated.component.html',
  styleUrls: ['./outdated.component.scss']
})
export class OutdatedComponent implements OnInit {

  outMessageInput: OutMessageInput;
  
  constructor(private nr: NoReturnService) { }

  ngOnInit() {
    this.nr.initOutdated();
    this.outMessageInput = this.nr.outMessageInput;
  }

}
