import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoreModule } from "@app/core/core.module";
import { BrowserModule } from "@angular/platform-browser";
//import { RecaptchaModule, RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { ModalGlobalComponent } from "./modal-global/modal-global.component";
import { OutMessageComponent } from "./out-message/out-message.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { ContractsComponent } from './contracts/contracts.component';
import { MaterialModule } from './material.module';
import { CorteComponent } from './corte/corte.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  providers: [
/*     {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "es"
    } */
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    
  ],
  declarations: [
    ModalGlobalComponent,
    NavbarComponent,
    ContractsComponent,
    CorteComponent,
    OutMessageComponent
  ],
  exports: [
    OutMessageComponent,
    NavbarComponent,
    ContractsComponent,
    CorteComponent
  ],
  entryComponents: [ModalGlobalComponent]
})
export class SharedModule {}
