import { Component, OnInit } from '@angular/core';
import { CampaignDocumentInfo } from '@app/core/models/user-login.model';
import { StoreService } from '@core/service/store.service';
import { UploadDocService } from '@core/service/upload-doc.service';
import { ModalService } from '@app/shared/services/modal.service';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';


@Component({
  selector: 'aliv-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  public TextInfo: any;
  public flow: any = {};
  public containers = [];
  public questions = this.storeService.get("preguntasObject");
  public containersJson: CampaignDocumentInfo[] = [];
  public loadContent = false;
  public flujo = 0;

  constructor(
    private modalService: ModalService,
    public uploadDocService: UploadDocService,
    private authServerProviderService: AuthServerProviderService,
    private storeService: StoreService) {
      this.flow = this.storeService.get("flow");
    }

  ngOnInit() {

    this.flujo = this.storeService.get('flujo');
    console.log(this.flujo);

    // this.flowService.getFlow(this.storeService.get('flujo'))
    // .then(
    //   (response: any) => {
    //     this.flow = response;
    //     this.storeService.set('flow', this.flow);
    //   }
    // ).catch((error)=>{
    //   console.log("error getting flow");
    //   //this.route.navigate(['login']);
    // });

    this.uploadDocService.deleteAllDocuments();
    this.uploadDocService.evaluateType();
    setTimeout(() => {
      console.log('data');
      document.body.style.backgroundColor = '#f6f8fa'
      window.scrollBy(0, -document.body.scrollHeight * 5);
      var elems = document.getElementsByTagName('html');
      for (var i = 0; i < elems.length; i++) {
        elems[i].style.backgroundColor = '#f6f8fa';
      }
      document.getElementById('progreso').style.width = '67%';
    }, 500)

    let estado = this.storeService.get("estado");
    let numR = this.storeService.get("numRadicado");
    console.log("estado: " + estado);
    if (numR != false && (estado == "login" || estado == "formulario" || estado == "documentos")) {
      this.storeService.set("estado", "documentos")
    }
    else {
      this.return();
    }
    console.log(this.uploadDocService.documentUpload);
    this.loadTables(() => {
    })
  }

  //cargar datos aca puede colocarse el end point
  loadTables(callback: () => void) {
    this.containers = this.uploadDocService.documentUpload;
    console.log("OBJETO EN JSON");
    console.log(this.containers);
    if (this.containers.length > 0) {
      this.containers.forEach((element, index) => {
        //let infoCampaignDocument = JSON.parse(element.info);
        //console.log(infoCampaignDocument);
        console.log("DocElement->", element);
        console.log("Questions->", this.questions);
        console.log("Flujo->", this.flujo);
        let campaignDocument: CampaignDocumentInfo = new CampaignDocumentInfo();
        //datos para mostrar
        //campaignDocument.jobInfo = element.jobInfo;
        //campaignDocument.idDocument = element.idDocument;
        campaignDocument.documentName = element.documentName;
        campaignDocument.info = element.info;
        //campaignDocument.maxCapacity = element.maxCapacity;
        campaignDocument.docsToLoad = this.setDocsStored(element.documents);
        if (element.textExtra != undefined) {
          campaignDocument.textExtra = element.textExtra
        }
        if (element.dependentQuestion != undefined && element.dependentQuestion == true) {
          //let keys = this.questions.keys();
          for (const question of this.questions) {
            if (question.k == element.keyDependQuestion && question.v == "si") {
              this.containersJson.push(campaignDocument);
              break;
            }
          }
        } else {
          this.containersJson.push(campaignDocument);
        }
        //this.uploadDocService.addCard(campaignDocument);//añade la caja al array global
        if (index === this.containers.length - 1) {
          console.log("OBJETO EN JSON");
          console.log(this.containersJson);
          return callback();
        }
        index++
      });
    }
    console.log("CARGAR");
    /*this.uploadDocService.getDocumentsStored(()=>{})*/
  }

  setDocsStored(documentsStored: any): any {
    let documentListStored = new Array();
    for (const key in documentsStored) {
      console.log(key);

      let eachDocumentMapped = documentsStored[key];
      let ext = eachDocumentMapped.name.split(".").pop();
      let shortname = eachDocumentMapped.name;
      //validar largo de nombre
      if (eachDocumentMapped.name.length > 35) {
        console.log("muy grande");
        shortname =
          eachDocumentMapped.name.substring(0, 35) + "..." + ext;
      }
      eachDocumentMapped.path;
      let list = {
        key: "" + key,
        shortname: shortname,
        name: eachDocumentMapped.name,
        path: eachDocumentMapped.path,
        type: ext.toUpperCase() === "PDF" ? "pdf" : "image",
        state: "SAVE"
      };
      this.uploadDocService.docsToUpload++;
      documentListStored.push(list);
    }
    return documentListStored;
  }

  async descargarDocumentos() {
    console.log("Comienza a descargar documentos");
    let urlFiles = this.storeService.get("urlFiles");
    for (let i = 0; i < urlFiles.length; i++) {
      let url = urlFiles[i].ruta;
      if( i == (urlFiles.length-1) ) await new Promise(resolve => setTimeout(resolve, 700));
      this.openNewWindow(url);
      //window.open(url, "_blank");
    }
    this.modalService.popupWindowsWarning(resp2 => { });
  }

  async openNewWindow(url, type="_blank",location=false,scrollbars=false,stats=false,resizable=false){
    let windowToOpen = window;
    let options = ""; 
    options += location ? "location=yes," : "";
    options += scrollbars ? "scrollbars=yes," : "";
    options += stats ? "status=yes," : "";
    options += resizable ? "resizable=yes," : ""
    windowToOpen.open(url, type, options);
  }

  openContract() {
    this.modalService.confirmTermsDocuments(() => {})
  }

  validData() {
    return this.uploadDocService.validbot();
  }

  return() {
    this.authServerProviderService.logoutBasic()
  }
}