import { Component, OnInit, Input } from '@angular/core';
import { OutMessageInput } from '../model/mcomponent';
import { Router } from '@angular/router';
import { AuthServerProviderService } from '@core/service/auth-server-provider.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-out-message',
  templateUrl: './out-message.component.html',
  styleUrls: ['./out-message.component.scss']
})
export class OutMessageComponent implements OnInit {

  @Input()
  outMessageInput: OutMessageInput;

  constructor(
    private route: Router,
    private _location: Location,
    public authServerProviderService: AuthServerProviderService) { }

  ngOnInit() {
  }

  goTo() {
    this.authServerProviderService.clearSesion();
    setTimeout(() => {
      location.href = this.outMessageInput.buttonRedirect;
    }, 300);
  }

  goToRedirectInternal() {
    this.authServerProviderService.clearSesion();
    setTimeout(() => {
      this.route.navigate([this.outMessageInput.buttonRedirectInternal + ""])
    }, 300);
  }

  goBack(){
    this._location.back();
  }

}
