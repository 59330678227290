export class OutputObject {
    operation: any;
    attribute: any;
    source : any
    attributeList: any;
    permission: any;
    /**
    // * @param {?} operation
    // * @param {?=} source
    // * @param {?=} attribute
    // * @param {?=} attributeList
    // * @param {?=} permission
    // */
    
    constructor(operation, source?, attribute?, attributeList?, permission?) {
    
    this.operation = operation;
    
    this.source = source;
    
    this.attribute = attribute;
    
    this.attributeList = attributeList;
    
    this.permission = permission;
    
    }
}