import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayDefinitionService {

//login - formularios
public readonly tipoEmpresaArray = [
	{code: -1, id: "-", name: "-"},
	{code: 0,  id: 1,  name: "Persona Jurídica"},
	{code: 1,  id: 4,  name: "Persona Natural"},
	{code: 2,  id: 2,  name: "Entidad sin ánimo de lucro"},
	{code: 3,  id: 3,  name: "Consorcio o unión temporal"},
	{code: 4,  id: 5,  name: "Patrimonio Autónomo"},
	{code: 5,  id: 6,  name: "Cooperativas"}
];

private readonly stepFlujoArray : Array<{ id: number; code: number }> = [
    {id: 1, code: 0},   //"PAEF";
    {id: 2, code: 1},   //"PAP";
    {id: 3, code: 2},   //"PAP + PAEF"";
    {id: 4, code: 3},   //??
    {id: 5, code: 4},   //"SACUDETE";
    {id: 6, code: 5},   //PAEF PARO
    {id: 7, code: 6},   //EMPLEADOS
];

private readonly docTypes: { name: string, id: string }[] = [
	{ name: 'NIT Persona Jurídica', id: 'N' },    //OK
	{ name: 'Cédula de ciudadanía', id: 'C' },    //OK
	{ name: 'Cédula de extranjería', id: 'E' },   //OK
	{ name: 'Pasaporte', id: 'P' },               //OK
	{ name: 'NIT Persona Natural', id: 'L' },     //OK
	{ name: 'NIT de Extranjería', id: 'I' }       //OK
];

//formularios
private readonly tipoEmpresaSAArray = [
	{code: -1, id: "-", name: "-"},
	{code: 0,  id: 1,  name: "Persona Jurídica"},
	{code: 1,  id: 5,  name: "Persona Natural"},
	{code: 2,  id: 4,  name: "Entidad sin ánimo de lucro / cooperativa"},
	{code: 3,  id: 2,  name: "Consorcio o unión temporal"},
	{code: 4,  id: 3,  name: "Patrimonio Autónomo Declarante"}
];
 
//preguntas
private readonly casilla53RUTValues: { name: string, id: string }[] = [
	{ name: '04 - Régimen especial', id: '04' },
	{ name: '05 - Régimen ordinario', id: '05' },
	{ name: '47 - Régimen de simple tributación', id: '47' }
];

//formulario
private readonly docTypesRl: { name: string, id: string }[] = [
	{ name: 'Cédula de ciudadanía', id: 'CC' },
	{ name: 'Cédula de extranjería', id: 'CE' },
	{ name: 'Pasaporte', id: 'PA' },
	{ name: 'Salvoconducto de Permanencia', id: 'SC' },
	{ name: 'Carnet diplomático', id: 'CD' }
];

// private docTypes: { name: string, id: string }[] = [
// 	{ name: 'NIT Persona Jurídica', id: 'N' },    //OK
// 	{ name: 'Cédula de ciudadanía', id: 'C' },    //OK
// 	{ name: 'Cédula de extranjería', id: 'E' },   //OK
// 	{ name: 'Pasaporte', id: 'P' },               //OK
// 	{ name: 'NIT Persona Natural', id: 'L' },     //OK
// 	{ name: 'NIT de Extranjería', id: 'I' }       //OK
// ];

private readonly typeAccount: { name: string, id: string }[] = [
	{ name: 'Ahorros', id: 'AH' },
	{ name: 'Corriente', id: 'CC' }
];

private readonly typeGender: { name: string, id: string }[] = [
	{ name: 'Hombre', id: 'Hombre' },
	{ name: 'Mujer', id: 'Mujer' }
];

private readonly typeNoYes: { name: string, id: string }[] = [
	{ name: 'SI', id: 'SI' },
	{ name: 'NO', id: 'NO' }
];

private readonly questionShow = [
	5511,   5512,   5530,  5513,
	5520, 	5514, 	5519,  5590,
	5611,   5613,   5612,  5621,
	5629,   5619,   5630,  7911,
	7912,   7990,   9101,  9001,
	9002,   9003,   9004,  9005,
	9006,   9007,   9008,  9321,
	9329,   9102,   9103,  9311,
	9312,   9319,   9200
];

  constructor() {}

  public getTipoEmpresaArray(){
    return this.tipoEmpresaArray;
  }

  public getTipoEmpresaSAArray(){
    return this.tipoEmpresaSAArray;
  }

  public getStepFlujoArray(){
    return this.stepFlujoArray;
  }

  public getDocTypes(){
    return this.docTypes;
  }

  public getCasilla53RUTValues(){
    return this.casilla53RUTValues;
  }

  public getDocTypesRl(){
    return this.docTypesRl;
  }

  public getTypeAccount(){
    return this.typeAccount;
  }

  public getTypeGender(){
    return this.typeGender;
  }

  public getTypeNoYes(){
    return this.typeNoYes;
  }

  public getQuestionShow(){
    return this.questionShow;
  }

  public transformStepFlujo(key) {
    return this.stepFlujoArray.find(data => data.id == key).code;
    //return this.stepFlujoArray.map(item => {if(item.id == key) return item;}).find(item => item).code;
  }

  public transformReverseFlujo(key) {
    return this.stepFlujoArray.find(data => data.code == key).id;
    //return this.stepFlujoArray.map(item => {if(item.code == key) return item;}).find(item => item).id;
  }

  public getTipoEmpresa(id) {
    return this.tipoEmpresaArray.find(data => data.id == id).code;
    //return this.tipoEmpresaArray.map(item => {if(item.id == id) return item;}).find(item => item).code;
  }

  public getTipoEmpresaReverse(code) {
    return this.tipoEmpresaArray.find(data => data.code == code).id;
    //return this.tipoEmpresaArray.map(item => {if(item.id == id) return item;}).find(item => item).code;
  }

  public getTipoEmpresaSA(code) {
    return this.tipoEmpresaSAArray.find(data=>data.code == code).id;
    //return this.tipoEmpresaSAArray.map(item => {if(item.id == id) return item;}).find(item => item).code;
  }
}
